import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { defineMessages, injectIntl } from 'react-intl';

import './index.sass';
import * as actions from '../../../store/actions';
import InputText from '../../Molecules/Inputs/Form';
import { checkValidity, fillFormFieldsWithCustomer, parseFormItem } from '../../../shared/utility';
import Button from '../../Molecules/Buttons/Button';
import Text from '../../Atoms/Text';
import IconButton from '../../Molecules/Buttons/IconButton';
import Modal from '../../Molecules/Modal';

class EditCustomer extends Component {
	state = {
		formControls: [],
		isFormValid: false,
		updatedCustomer: false,
		removedField: false
	}

	componentDidMount() {
		const { formFields, showingEditCustomer } = this.props;
		if (formFields && showingEditCustomer) {
			this.setState({ formControls: fillFormFieldsWithCustomer(formFields, showingEditCustomer) });
		}
	}

	static getDerivedStateFromProps(props, state) {
		const {
			updateCustomerStatus = {},
			intl,
			addNotification,
			updateCustomerClear
		} = props;
		const { formatMessage } = intl;
		const messages = defineMessages({
			alreadyRegistered: {
				id: 'editCustomer.alreadyRegistered',
				defaultMessage: 'já cadastrado'
			},
			updated: {
				id: 'editCustomer.updated',
				defaultMessage: 'Contato alterado'
			},
			updatedContent: {
				id: 'editCustomer.updatedContent',
				defaultMessage: 'Os dados foram alterados com sucesso'
			},
			notUpdated: {
				id: 'editCustomer.notUpdated',
				defaultMessage: 'Contato não alterado'
			},
			notUpdatedContent: {
				id: 'editCustomer.notUpdatedContent',
				defaultMessage: 'Verifique os campos do formulário'
			}
		});

		const { error, success } = updateCustomerStatus;
		if (error) {
			const updatedFormControls = state.formControls.map((field) => {
				const { label, name } = field;
				const updatedField = { ...field };
				const hasError = error[name];
				if (hasError === 'taken') {
					updatedField.validationMessage = `${label} ${formatMessage(messages.alreadyRegistered)}`;
					updatedField.isValid = false;
				}
				return updatedField;
			});

			addNotification({
				title: formatMessage(messages.notUpdated),
				content: formatMessage(messages.notUpdatedContent),
				type: 'error'
			});

			updateCustomerClear();

			return {
				formControls: updatedFormControls,
				isFormValid: false
			};
		}

		if (success) {
			addNotification({
				title: formatMessage(messages.updated),
				content: formatMessage(messages.updatedContent),
				type: 'success'
			});

			updateCustomerClear();
		}

		return null;
	}

	inputChangedHandler = (event, inputIdentifier) => {
		const { intl } = this.props;
		const { formatMessage } = intl;
		const { formControls } = this.state;
		const { updateCustomerClear, updateCustomerStatus = {} } = this.props;
		const { error } = updateCustomerStatus;
		const updatedControls = formControls.map((formItem) => {
			if (formItem.id === inputIdentifier) {
				const { isValid, validationMessage } = checkValidity(
					event.target.value, formItem.validation, formItem.label
				);

				let formattedMessage = '';
				if (Object.keys(validationMessage).length > 0) {
					const { label, intlObject } = validationMessage;
					formattedMessage = `${label} ${formatMessage(intlObject)}`;
				}

				if (error) updateCustomerClear();
				return {
					...formItem,
					value: event.target.value,
					isValid,
					validationMessage: formattedMessage,
					wasTouched: true
				};
			}
			return formItem;
		});

		let isFormValid = true;
		updatedControls.forEach((field, controlsIndex) => {
			const { validation, wasTouched, value } = field;
			const formField = updatedControls[controlsIndex];
			if (validation.isRequired && !wasTouched && value !== '') {
				isFormValid = !formField.isValid && isFormValid;
			} else if (validation.isRequired || wasTouched) {
				isFormValid = formField.isValid && isFormValid;
			}
		});

		this.setState({
			formControls: updatedControls,
			isFormValid,
			updatedCustomer: false,
			removedField: false
		});
	}

	onUpdateCustomer = () => {
		const {
			isFormValid,
			formControls,
			updatedCustomer,
			removedField
		} = this.state;

		const {
			updateCustomer,
			accountId,
			showingEditCustomer,
			fetchAgentContacts
		} = this.props;
		const { customerKey } = showingEditCustomer;

		const fields = {};
		formControls.forEach((formItem) => {
			const { name } = formItem;
			fields[name] = parseFormItem(formControls, formItem, removedField);
		});

		if ((isFormValid && !updatedCustomer) || removedField) {
			updateCustomer({
				customerKey,
				accountId,
				fields,
				reload: true,
				fetchAgentContacts
			});
		}

		this.setState({
			updatedCustomer: isFormValid || removedField,
			removedField: false
		});
	}

	closeEditCustomer = () => {
		const { hideEditCustomer } = this.props;
		hideEditCustomer();
	}

	addMoreFields = (fieldName) => {
		const { formControls } = this.state;

		let field = {};
		let index = 0;
		const updatedContols = formControls.map((control, controlIndex) => {
			if (fieldName === control.name) {
				field = {
					...control,
					id: new Date().getTime(),
					value: '',
					createdField: true
				};
				index = controlIndex;
				return control;
			}
			return control;
		});

		updatedContols.splice(index + 1, 0, field);

		this.setState({
			formControls: updatedContols,
			isFormValid: false,
			updatedCustomer: false
		});
	}

	removeField = (id) => {
		const { formControls } = this.state;
		this.setState({
			formControls: formControls.filter(control => control.id !== id),
			removedField: true,
			updatedCustomer: false
		});
	};

	render() {
		const {
			formControls,
			isFormValid,
			removedField
		} = this.state;
		const {
			blockEditCustomerInfo,
			updateCustomerStatus = {},
			intl,
			onClose
		} = this.props;
		const { loading } = updateCustomerStatus;
		const { formatMessage } = intl;
		const messages = defineMessages({
			save: {
				id: 'editCustomer.save',
				defaultMessage: 'Salvar'
			},
			saved: {
				id: 'editCustomer.saved',
				defaultMessage: 'Salvo'
			},
			main_identifier: {
				id: 'editCustomer.main_identifier',
				defaultMessage: 'Nome'
			},
			channel_phone: {
				id: 'editCustomer.channel_phone',
				defaultMessage: 'Telefone'
			},
			channel_email: {
				id: 'editCustomer.channel_email',
				defaultMessage: 'Email'
			},
			document: {
				id: 'editCustomer.document',
				defaultMessage: 'CPF'
			},
			editCustomer: {
				id: 'editCustomer.callToAction',
				defaultMessage: 'Editar Contato'
			},
			info: {
				id: 'editCustomer.info',
				defaultMessage: 'Edite abaixo os dados do cadastro:'
			}
		});

		return (
			<Modal className="EditCustomer" onClose={onClose}>
				<div className="EditCustomer__info">
					<Text size="large">{formatMessage(messages.editCustomer)}</Text>
					<Text>{formatMessage(messages.info)}</Text>
				</div>
				<div className="EditCustomer__form">
					{
						formControls ? formControls.map((field) => {
							const {
								value,
								wasTouched,
								id,
								validationMessage,
								isValid,
								name,
								label,
								validation,
								isMultiple,
								createdField
							} = field;
							const { isRequired } = validation;
							const locale = localStorage.getItem('locale');
							const translatedFields = ['main_identifier', 'document', 'channel_phone', 'channel_email'];
							let translatedLabel = label;

							if (translatedFields.includes(name)) {
								translatedLabel = locale === 'es' || locale === 'en' ? formatMessage(messages[name]) : label;
							}

							return (
								<div className="EditCustomer__form__item" key={id}>
									<InputText
										label={isRequired ? `${translatedLabel}*` : translatedLabel}
										validationMessage={validationMessage}
										value={value || ''}
										onChange={event => this.inputChangedHandler(event, id)}
										touched={wasTouched}
										valid={isValid}
										disabled={blockEditCustomerInfo}
									/>
									{isMultiple && !createdField && !blockEditCustomerInfo && (
										<IconButton
											square
											name="plus"
											fill="green"
											click={() => this.addMoreFields(name)}
										/>
									)}
									{createdField && !blockEditCustomerInfo && (
										<IconButton
											square
											name="cross"
											fill="red"
											click={() => this.removeField(id)}
										/>
									)}
								</div>
							);
						}) : null
					}
					<Button
						disabled={blockEditCustomerInfo}
						active={(isFormValid || removedField) && !loading}
						click={this.onUpdateCustomer}
						selected
					>
						<Text size="bigger">{formatMessage(messages.save)}</Text>
					</Button>
				</div>
			</Modal>
		);
	}
}
const mapStateToProps = state => ({
	formFields: state.formFields.fields,
	accountId: state.agent.info.account.id,
	updateCustomerStatus: state.interaction.updateCustomerStatus.find(
		status => status.hash === state.interaction.currentInteractionHash
	),
	showingEditCustomer: state.interface.showingEditCustomer,
	blockEditCustomerInfo: state.agent.blockEditCustomerInfo && !['ADMIN', 'ACCESS_SUPPORT'].includes(state.agent.info.profile)
});

const mapActionsToProps = dispatch => ({
	updateCustomer: requestInfo => dispatch(actions.updateCustomer(requestInfo)),
	updateCustomerClear: () => dispatch(actions.updateCustomerClear()),
	fetchAgentContacts: info => dispatch(actions.fetchAgentContacts(info)),
	hideEditCustomer: () => dispatch(actions.hideEditCustomer()),
	addNotification: notification => dispatch(actions.addNotification(notification))
});

EditCustomer.propTypes = {
	formFields: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
	updateCustomer: PropTypes.func.isRequired,
	updateCustomerClear: PropTypes.func.isRequired,
	accountId: PropTypes.number.isRequired,
	updateCustomerStatus: PropTypes.shape({
		loading: PropTypes.bool,
		error: PropTypes.shape({})
	}),
	intl: PropTypes.shape({
		formatMessage: PropTypes.func.isRequired
	}).isRequired,
	showingEditCustomer: PropTypes.shape({
		customer: PropTypes.shape({
			fields: PropTypes.shape({
				main_identifier: PropTypes.string
			})
		}),
		customerKey: PropTypes.string
	}).isRequired,
	blockEditCustomerInfo: PropTypes.bool.isRequired,
	fetchAgentContacts: PropTypes.func.isRequired,
	hideEditCustomer: PropTypes.func.isRequired,
	onClose: PropTypes.func.isRequired
};

export default connect(mapStateToProps, mapActionsToProps)(injectIntl(EditCustomer));
