import './index.sass';

import React from 'react';
import PropTypes from 'prop-types';
import Linkify from 'omz-react-linkify';
import { injectIntl, defineMessages } from 'react-intl';

import InternalMessageBubble from '../../../Atoms/InternalMessageBubble';

const messages = defineMessages({
	warningMessage: {
		id: 'messageInternal.warningMessage',
		defaultMessage: 'A mensagem abaixo não é visível para o cliente.'
	}
});

const MessageInternal = ({ messageInfo, intl, children }) => (
	<div>
		<span className="MessageInternal--warning">
			{intl.formatMessage(messages.warningMessage)}
		</span>
		<InternalMessageBubble messageInfo={messageInfo}>
			{children}
			<div className="MessageInternal">
				<span className="MessageInternal__name">{messageInfo.agentName}</span>
				<Linkify properties={{ target: '_blank' }}>
					{messageInfo.content}
				</Linkify>
			</div>
		</InternalMessageBubble>
	</div>
);

MessageInternal.propTypes = {
	messageInfo: PropTypes.shape({
		content: PropTypes.string.isRequired,
		referenceId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
		isInternalHistory: PropTypes.bool,
		agentName: PropTypes.string
	}).isRequired,
	intl: PropTypes.shape({
		formatMessage: PropTypes.func.isRequired
	}).isRequired
};

export default injectIntl(MessageInternal);
