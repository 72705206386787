import './index.sass';

import React, {
	useEffect,
	useState,
	useCallback,
	useRef
} from 'react';
import PropTypes from 'prop-types';
import { injectIntl, defineMessages } from 'react-intl';
import { connect } from 'react-redux';

import * as actions from '../../../store/actions';

import Text from '../../Atoms/Text';
import ImgSvg from '../../Atoms/ImgSvg';
import Spinner from '../../Atoms/Spinner';
import Wrapper from '../../Atoms/Wrapper';
import Tooltip from '../../Atoms/Tooltip';
import RadioButton from '../../Atoms/RadioButton';
import Input from '../../Molecules/Inputs/Common';
import InfiniteList from '../../Molecules/InfiniteList';
import Button from '../../Molecules/Buttons/Button';
import ModalDefault from '../../Sirius/ModalDefault';
import SelectCreatable from '../../Sirius/SelectCreatable';


const messages = defineMessages({
	breakStatusTitle: {
		id: 'breakStatus.title',
		defaultMessage: 'Atualizar status de pausa'
	},
	breakStatusPlaceholder: {
		id: 'breakStatus.placeholder',
		defaultMessage: 'Buscar status de pausa'
	},
	breakStatusPreBreak: {
		id: 'breakStatus.preBreak',
		defaultMessage: 'Pré-pausa'
	},
	breakStatusBreak: {
		id: 'breakStatus.break',
		defaultMessage: 'Pausa'
	},
	breakStatusSave: {
		id: 'breakStatus.save',
		defaultMessage: 'Atualizar status'
	},
	breakStatusTooltipPreBreak: {
		id: 'breakStatus.tooltip.preBreak',
		defaultMessage: 'Ao selecionar a pré-pausa você poderá seguir realizando e finalizando atendimentos em andamento, mas sem receber novos.'
	},
	breakStatusTooltipBreak: {
		id: 'breakStatus.tooltip.break',
		defaultMessage: 'Ao selecionar a pausa, você irá pausar o andamento dos atendimentos atuais.'
	},
	breakStatusBreakTimeTitle: {
		id: 'breakStatus.breakTime.title',
		defaultMessage: 'Defina o tempo de pausa'
	},
	breakStatusBreakTimeSelectAddTime: {
		id: 'breakStatus.breakTime.selectAddTime',
		defaultMessage: 'Selecione ou adicione um tempo'
	},
	breakStatusBreakTimeSelectTime: {
		id: 'breakStatus.breakTime.selectTime',
		defaultMessage: 'Selecione um tempo'
	},
	breakStatusBreakTimeMinute: {
		id: 'breakStatus.breakTime.minute',
		defaultMessage: 'minutos'
	}
});

const BreakStatus = ({
	hideBreakStatus,
	fetchBreakStatus,
	updateAgentBreakStatus,
	agentBreakStatus = {},
	breakStatus = {
		data: [],
		page: 1,
		ended: false,
		loading: false
	},
	intl
}) => {
	const searchRef = useRef();
	const [searchValue, setSearchValue] = useState('');
	const [selectedBreakStatus, setSelectedBreakStatus] = useState(agentBreakStatus);
	const [durationMinutesSelected, setDurationMinutesSelected] = useState(null);
	const {
		loading,
		data,
		page,
		ended
	} = breakStatus;
	useEffect(() => {
		fetchBreakStatus();
	}, [fetchBreakStatus]);

	const statusHasDuration = selectedBreakStatus.hasDuration;

	const isCreatable = !!selectedBreakStatus.customDuration;

	// eslint-disable-next-line max-len
	const durationMinutesPlaceholder = selectedBreakStatus.customDuration ? intl.formatMessage(messages.breakStatusBreakTimeSelectAddTime) : intl.formatMessage(messages.breakStatusBreakTimeSelectTime);

	const formattedInitialValues = Array.isArray(selectedBreakStatus?.durationMinutes)
		? selectedBreakStatus.durationMinutes
			.filter(value => Number(value) !== 0)
			.map(value => ({
				value: Number(value),
				label: `${value} ${intl.formatMessage(messages.breakStatusBreakTimeMinute)}`
			}))
		: [];

	useEffect(() => {
		if (selectedBreakStatus?.durationMinutes) {
			setDurationMinutesSelected(null);
		}
	}, [selectedBreakStatus.durationMinutes]);


	const onSearch = useCallback((search) => {
		setTimeout(() => {
			if (searchRef.current
				&& searchRef.current.input
				&& searchRef.current.input.current
				&& searchRef.current.input.current.value === search
			) {
				fetchBreakStatus({ search });
			}
		}, 1200);
	}, [searchRef, fetchBreakStatus]);

	const changeDurationMinutes = (option) => {
		setDurationMinutesSelected(option);
	};

	const onChangeBreakStatusSearch = (e) => {
		const { value } = e.target;

		setSearchValue(value);
		onSearch(value);
	};

	const onChangeBreakStatusChoice = (name) => {
		const preBreak = name === 'preBreakStatus';

		setSelectedBreakStatus({ ...selectedBreakStatus, preBreak });
	};

	const onBottomBreakStatusList = () => {
		if (ended) return;

		fetchBreakStatus({ page, search: searchValue });
	};

	const onSelectBreakStatusItem = (breakStatusItem) => {
		const { preBreak } = selectedBreakStatus;

		setSelectedBreakStatus({ ...breakStatusItem, preBreak });
	};

	const onSaveBreakStatus = () => {
		const params = {
			breakStatus: selectedBreakStatus,
			action: 'PAUSE',
			durationMinutes: durationMinutesSelected?.value
		};

		updateAgentBreakStatus(params);
		hideBreakStatus();

		const breakTimer = JSON.parse(window.localStorage.getItem('breakTimer'));
		const currentTime = Date.now();
		const lastAgentStatus = JSON.parse(window.localStorage.getItem('lastAgentStatus'));


		if (selectedBreakStatus.preBreak === false && !breakTimer?.endedAt) {
			const duration = breakTimer?.breakDuration || durationMinutesSelected?.value * 60 * 1000;
			window.localStorage.setItem('breakTimer', JSON.stringify({ startedAt: currentTime }));
			// Se o status do agente possui duração configurada
			if (lastAgentStatus?.breakStatus?.hasDuration === true) {
				const endedAt = currentTime + duration;
				window.localStorage.setItem('breakTimer', JSON.stringify({ startedAt: currentTime, endedAt }));
			}
		}

		if (selectedBreakStatus.preBreak === true && breakTimer?.breakDuration) {
			const breakDuration = durationMinutesSelected?.value * 60 * 1000;
			window.localStorage.setItem('breakTimer', JSON.stringify({ startedAt: currentTime, breakDuration }));
		}

		if (selectedBreakStatus?.autoBreak) {
			const durationMinutesValue = durationMinutesSelected ? durationMinutesSelected.value : null;
			window.localStorage.setItem('durationMinutesPrevious', JSON.stringify({ durationMinutesValue }));
		}
	};

	const breakTimer = JSON.parse(window.localStorage.getItem('breakTimer'));

	const showSelectDurationTime = (statusHasDuration && !breakTimer?.breakDuration) || (selectedBreakStatus.preBreak && statusHasDuration);

	function canSaveBreakStatus(breakStatusSave, timer, showDurationTime, durationSelected, hasDuration) {
		if (!hasDuration) {
			return breakStatusSave.id !== undefined
				&& breakStatusSave.preBreak !== undefined;
		}
		return breakStatusSave.id !== undefined
			&& breakStatusSave.preBreak !== undefined
			&& (
				timer?.breakDuration !== undefined
				|| (showDurationTime && durationSelected?.value !== undefined)
			);
	}

	return (
		<div className="BreakStatusModal" data-testid="component-breakStatus">
			<div className="BreakStatus">
				<ModalDefault.Root
					isOpen
					onClose={hideBreakStatus}
				>
					<ModalDefault.Header
						title={intl.formatMessage(messages.breakStatusTitle)}
						onClose={hideBreakStatus}
						className="has-background-grey-lighter"
					/>
					<ModalDefault.Body className="has-background-grey-lighter">
						<div className="BreakStatus__choice mb-3">
							{/* eslint-disable-next-line jsx-a11y/label-has-for */}
							<label
								className="BreakStatus__choice--item"
								htmlFor="preBreakStatus"
							>
								<RadioButton
									checked={!!selectedBreakStatus.preBreak}
									id="preBreakStatus"
									onChange={e => onChangeBreakStatusChoice(e.target.name)}
									data-testid="component-breakStatus-inputPreBreak"
								/>
								{intl.formatMessage(messages.breakStatusPreBreak)}
								<Tooltip header={<ImgSvg name="info-circle" />} position="right">
									{intl.formatMessage(messages.breakStatusTooltipPreBreak)}
								</Tooltip>
							</label>
							{/* eslint-disable-next-line jsx-a11y/label-has-for */}
							<label
								className="BreakStatus__choice--item"
								htmlFor="breakStatus"
							>
								<RadioButton
									checked={selectedBreakStatus.preBreak === false}
									id="breakStatus"
									onChange={e => onChangeBreakStatusChoice(e.target.name)}
									data-testid="component-breakStatus-inputBreak"
								/>
								{intl.formatMessage(messages.breakStatusBreak)}
								<Tooltip header={<ImgSvg name="info-circle" />} position="right">
									{intl.formatMessage(messages.breakStatusTooltipBreak)}
								</Tooltip>
							</label>
						</div>
						<div className="BreakStatus__statusContainer">
							<Input
								ref={searchRef}
								value={searchValue}
								onChange={onChangeBreakStatusSearch}
								placeholder={intl.formatMessage(messages.breakStatusPlaceholder)}
								data-testid="component-breakStatus-inputSearch"
							/>
							<InfiniteList
								onBottom={onBottomBreakStatusList}
								customClass="BreakStatus__list"
								data-testid="component-breakStatus-list"
							>
								{data.map(({
									id,
									emoji,
									status,
									hasDuration,
									customDuration,
									durationMinutes,
									autoBreak
								}) => (
									<Wrapper
										key={id}
										className="BreakStatus__list--item"
										action={() => {
											onSelectBreakStatusItem({
												id,
												emoji,
												status,
												hasDuration,
												customDuration,
												durationMinutes,
												autoBreak
											});
										}}
										data-testid="component-breakStatus-listItem"
									>
										<div className="BreakStatus__list--item-emote">{emoji}</div>
										<div className="BreakStatus__list--item-label">
											<Text size="big">{status}</Text>
										</div>
										{selectedBreakStatus.id === id && (
											<div className="BreakStatus__list--item-selected" data-testid="component-breakStatus-selectedItem">
												<ImgSvg name="confirm-changes" />
											</div>
										)}
									</Wrapper>
								))}
							</InfiniteList>
						</div>
						{showSelectDurationTime && (
							<div className="BreakStatus__durationMinutes mt-3">
								<h1 style={{ fontSize: '16px' }} className="BreakStatus__durationMinutes--title title is-4">{intl.formatMessage(messages.breakStatusBreakTimeTitle)}</h1>
								<SelectCreatable
									initialValues={formattedInitialValues}
									menuIsFullwidth
									placeholder={durationMinutesPlaceholder}
									isCreatable={isCreatable}
									hasItemDefault={false}
									onSelectItem={changeDurationMinutes}
									allowOnlyNumbers
									sufix={intl.formatMessage(messages.breakStatusBreakTimeMinute)}
									selectItem={selectedBreakStatus}
								/>
							</div>
						)}

					</ModalDefault.Body>
					<ModalDefault.Footer className="has-background-grey-lighter">
						<Button
							className="BreakStatus__button"
							click={() => onSaveBreakStatus()}
							active={canSaveBreakStatus(selectedBreakStatus, breakTimer, showSelectDurationTime, durationMinutesSelected, statusHasDuration)}
							data-testid="component-breakStatus-buttonSave"
						>
							{intl.formatMessage(messages.breakStatusSave)}
						</Button>
						{loading && !data.length && <Spinner />}

					</ModalDefault.Footer>
				</ModalDefault.Root>
			</div>
		</div>
	);
};

BreakStatus.propTypes = {
	hideBreakStatus: PropTypes.func.isRequired,
	fetchBreakStatus: PropTypes.func.isRequired,
	updateAgentBreakStatus: PropTypes.func.isRequired,
	agentBreakStatus: PropTypes.shape({
		id: PropTypes.number,
		emoji: PropTypes.string,
		status: PropTypes.string,
		preBreak: PropTypes.bool
	}),
	breakStatus: PropTypes.shape({
		data: PropTypes.arrayOf(PropTypes.shape({
			id: PropTypes.number,
			emoji: PropTypes.string,
			status: PropTypes.string
		})),
		page: PropTypes.number,
		ended: PropTypes.bool,
		loading: PropTypes.bool
	}),
	intl: PropTypes.shape({
		formatMessage: PropTypes.func
	}).isRequired
};

const mapStateToProps = ({ agent }) => ({
	breakStatus: agent.breakStatus,
	agentBreakStatus: agent.agentBreakStatus
});

const mapActionsToProps = dispatch => ({
	hideBreakStatus: () => dispatch(actions.hideBreakStatus()),
	fetchBreakStatus: info => dispatch(actions.fetchBreakStatus(info)),
	updateAgentBreakStatus: info => dispatch(actions.updateAgentBreakStatus(info))
});

export default connect(mapStateToProps, mapActionsToProps)(injectIntl(BreakStatus));
