import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import './index.sass';
import ImgSvg from '../../Atoms/ImgSvg';
import Wrapper from '../../Atoms/Wrapper';

const ToggleNavBar = ({ infos, vertical }) => {
	const navBarRef = useRef(null);

	useEffect(() => {
		const handleWheel = (event) => {
			if (navBarRef.current) {
				if (vertical) {
					navBarRef.current.scrollTop += event.deltaY || event.deltaX;
				} else {
					navBarRef.current.scrollLeft += event.deltaY || event.deltaX;
				}
				event.preventDefault();
			}
		};

		const element = navBarRef.current;
		if (element) {
			element.addEventListener('wheel', handleWheel);
		}

		return () => {
			if (element) {
				element.removeEventListener('wheel', handleWheel);
			}
		};
	}, [vertical]);

	return (
		<div className={`ToggleNavBar ${vertical ? 'ToggleNavBar--vertical' : ''}`} ref={navBarRef}>
			{infos && infos.constructor === Array && infos.map(({
				active,
				info,
				label,
				title,
				click,
				notify,
				tooltip,
				icon,
				key
			}) => {
				const toggleNavItemClasses = ['ToggleNavBar__item'];

				if (active) toggleNavItemClasses.push('ToggleNavBar__item--active');
				if (info) toggleNavItemClasses.push('ToggleNavBar__item--info');
				if (notify) toggleNavItemClasses.push('ToggleNavBar__item--notify');
				if (vertical) toggleNavItemClasses.push('ToggleNavBar__item--vertical');

				// TODO: posicioamento do tooltip
				const renderTooltip = content => (
					<div className="tooltip-container">
						<span className="tooltip" data-tooltip={tooltip} data-tooltip-position="left">
							{content}
						</span>
					</div>
				);

				const iconContent = icon ? (
					<img src={icon} alt={label} />
				) : (
					<ImgSvg color="#000" name={title} />
				);

				const contentToRender = tooltip ? renderTooltip(iconContent) : iconContent;

				return (
					<Wrapper info={tooltip} ariaLabel={label} className={toggleNavItemClasses.join(' ')} action={click} key={key} data-testid={`toggleNavItem${title}`}>
						{contentToRender}
					</Wrapper>
				);
			})}
		</div>
	);
};

ToggleNavBar.propTypes = {
	infos: PropTypes.arrayOf(PropTypes.shape({
		title: PropTypes.string.isRequired,
		label: PropTypes.string.isRequired,
		click: PropTypes.func.isRequired,
		active: PropTypes.bool.isRequired,
		info: PropTypes.number,
		notify: PropTypes.bool,
		tooltip: PropTypes.string
	})).isRequired,
	vertical: PropTypes.bool
};

export default ToggleNavBar;
