import React, { Component } from 'react';

import './index.css';
import ImgSvg from '../../components/Atoms/ImgSvg';

class ErrorBoundary extends Component {
	constructor(props) {
		super(props);
		this.state = { hasError: false };
	}

	reconnect = () => window.location.reload();

	componentDidCatch() {
		this.setState({ hasError: true });
	}

	render() {
		const { hasError } = this.state;
		const { children } = this.props;

		if (hasError) {
			return (
				<div className="ErrorBoundary">
					<div className="ErrorBoundary__image">
						<ImgSvg name="megaphone" />
					</div>
					<h1>Algo inesperado aconteceu, já fomos notificados.</h1>
					<button
						type="button"
						onClick={() => this.reconnect}
					>
						Reconectar
					</button>
				</div>
			);
		}
		return children;
	}
}

export default ErrorBoundary;
