import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl, defineMessages } from 'react-intl';

import './index.sass';
import * as actions from '../../../store/actions';
import Text from '../../Atoms/Text';
import CallWave from '../../Atoms/Animations/CallWave';
import IconButton from '../../Molecules/Buttons/IconButton';
import ConferenceUser from '../../Molecules/ConferenceUser';

const intlMessages = defineMessages({
	calling: {
		id: 'phoneInteraction.calling',
		defaultMessage: 'Chamando'
	},
	talking: {
		id: 'phoneInteraction.talking',
		defaultMessage: 'Chamada em andamento'
	},
	finished: {
		id: 'phoneInteraction.finished',
		defaultMessage: 'Chamada encerrada'
	},
	transferred: {
		id: 'phoneInteraction.transferred',
		defaultMessage: 'Transferência concluída'
	}
});

class PhoneInteraction extends Component {
	state = {
		hours: '',
		minutes: '',
		seconds: '',
		startedInterval: false,
		muted: false,
		showTime: true
	}

	componentDidMount() {
		const {
			isEnded,
			endTime = '',
			startTime = '',
			phoneData = {},
			finishInteraction = () => {}
		} = this.props;

		const { startedInterval } = this.state;
		const { status } = phoneData;

		// finish interaction if the call was finished at totalVoice.
		if (status === 'encerrada' && !isEnded) {
			window.ttvSdk.currentInteraction = '';
			finishInteraction();
			this.setState({ startedInterval: false });
		}

		// finish interaction if totalVoice interaction is empty;
		if (!isEnded && !window.ttvSdk.currentInteraction && !window.ttvSdk.conferenceId) {
			finishInteraction();
			this.setState({ startedInterval: false });
		}

		// Sets the called time at ended interactions;
		if (isEnded) this.secondsToHms(new Date(startTime), new Date(endTime));

		// Starts interval if the interaction is active
		if (status === 'conversando' && !startedInterval) {
			this.startInterval();
			this.setState({ startedInterval: true });
		}
	}

	componentDidUpdate(nextProps) {
		const {
			isEnded,
			phoneData = {},
			interactionHash,
			startTime = '',
			endTime = '',
			finishInteraction = () => {},
			closeReceptiveModal = () => {}
		} = this.props;
		const { status } = phoneData;
		const { startedInterval } = this.state;

		// starts interval if changes to an active interaction
		if (status === 'conversando' && interactionHash === window.ttvSdk.currentInteraction && !startedInterval) {
			this.startInterval();
			this.setState({ startedInterval: true, muted: false });
		}

		// sets called time at ended interactions
		if (nextProps.interactionHash !== interactionHash && isEnded) {
			this.setState({ startedInterval: false });
			this.secondsToHms(new Date(startTime), new Date(endTime));
		}

		// when the call is finished by the user the infos are cleared and the interaction is finished.
		if (status === 'encerrada' && !isEnded && window.ttvSdk.currentInteraction) {
			window.ttvSdk.currentInteraction = '';
			finishInteraction(interactionHash);
			clearInterval(this.interval);
			closeReceptiveModal();
			this.setState({ startedInterval: false, muted: false });

			window.ttvSdk.muted = false;
		}
	}

	startInterval = () => {
		this.interval = setInterval(() => {
			const { phoneData = {}, interactionHash } = this.props;
			if (interactionHash === window.ttvSdk.currentInteraction) {
				this.secondsToHms(phoneData.initialTime, new Date());
			}
		}, 1000);
	}

	secondsToHms = (initialTime, endTime) => {
		if (initialTime && endTime) {
			const seconds = ((endTime.getTime() - initialTime.getTime()) / 1000);
			const parsedSeconds = Number(seconds);
			const h = Math.floor(parsedSeconds / 3600);
			const m = Math.floor((parsedSeconds % 3600) / 60);
			const s = Math.floor((parsedSeconds % 3600) % 60);

			this.setState({
				hours: h,
				minutes: m,
				seconds: s
			});
		} else {
			this.setState({ showTime: false });
		}
	}

	onFinishCall = () => {
		const {
			phoneData = {},
			finishInteraction,
			clearConferenceUsers,
			onChangeInputPhone
		} = this.props;

		if (phoneData.name === 'totalVoice') {
			clearConferenceUsers();
			window.ttvSdk.hangup();
			finishInteraction();
			onChangeInputPhone('');
		}
	}

	toggleMute = () => {
		if (window.ttvSdk.muted) {
			this.setState({ muted: false });
			window.ttvSdk.muted = false;
		} else {
			this.setState({ muted: true });
			window.ttvSdk.muted = true;
		}

		window.ttvSdk.mute();
	}

	onShowDial = () => {
		const { showDial } = this.props;
		showDial();
	}

	onShowConference = () => {
		const { showConference, showDial } = this.props;
		showDial();
		showConference();
	}

	goToContacts = () => {
		const { defineMainBoxSection } = this.props;
		defineMainBoxSection('contacts');
	}

	render() {
		const {
			hours,
			minutes,
			seconds,
			muted,
			showTime
		} = this.state;
		const {
			phoneNumber = '',
			isEnded,
			phoneData = {},
			interactionHash,
			intl,
			currentInteraction
		} = this.props;
		const { formatMessage } = intl;
		const isConference = window.ttvSdk.conferenceId !== '' && window.ttvSdk.conferenceId !== null;
		const { status } = phoneData;
		const parsedHours = hours < 10 ? `0${hours}` : hours;
		const parsedMinutes = minutes < 10 ? `0${minutes}` : minutes;
		const parsedSeconds = seconds < 10 ? `0${seconds}` : seconds;
		const { messages } = currentInteraction;
		const audioMessages = messages && messages.filter(({ contentType }) => contentType === 'audio');
		const callStatuses = { chamando: 'calling', conversando: 'talking', encerrada: 'finished' };
		let callStatus = callStatuses[status] ? formatMessage(intlMessages[callStatuses[status]]) : '';
		const canFinish = (status === 'chamando' || status === 'conversando');
		const phoneButtons = [
			{
				name: muted ? 'microphone-off-off' : 'microphone-off',
				fill: muted ? 'grey-light' : 'grey',
				click: this.toggleMute,
				show: true
			},
			{
				name: 'dial-pad',
				fill: 'grey-light',
				click: this.onShowDial,
				show: true
			},
			{
				name: 'addToConf',
				fill: 'grey-light',
				click: this.onShowConference,
				show: isConference
			},
			{
				name: 'contact',
				fill: 'grey-light',
				click: this.goToContacts,
				show: isConference
			},
			{
				name: 'hangup-off',
				fill: 'red',
				click: this.onFinishCall,
				show: canFinish
			}
		];

		if (isEnded) callStatus = formatMessage(intlMessages.finished);

		if (interactionHash !== window.ttvSdk.currentInteraction) {
			callStatus = formatMessage(intlMessages.finished);
		}

		if (window.ttvSdk.onTransfer && status === 'encerrada') {
			callStatus = formatMessage(intlMessages.transferred);
		}

		return (
			<div className="PhoneInteraction">
				<Text size="large">{callStatus}</Text>
				<CallWave animate={!isEnded && status !== 'encerrada'} />
				{(isEnded || status === 'conversando') && typeof parsedHours === 'string' ? (
					<Text className="PhoneInteraction__time">
						{`${parsedHours}:${parsedMinutes}:${parsedSeconds}`}
					</Text>
				) : <Text />}
				{!isConference && showTime && <Text size="large">{phoneNumber}</Text>}
				{!isEnded && (
					<div className="PhoneInteraction__actions">
						{phoneButtons.map(({
							show,
							name,
							fill,
							click
						}) => (
							<Fragment key={name}>
								{show && <IconButton name={name} fill={fill} click={click} />}
							</Fragment>
						))}
					</div>
				)}
				{isConference && phoneData.conferenceUsers && phoneData.conferenceUsers.length > 0 && (
					<div className="PhoneInteraction__users">
						{phoneData.conferenceUsers.map(({ name, number }) => (
							<ConferenceUser name={name} number={number} key={number} />
						))}
					</div>
				)}
				{audioMessages && audioMessages.map(({ content, time }) => <audio src={content} key={time} controls><track kind="captions" /></audio>)}
			</div>
		);
	}
}

const mapStateToProps = ({ agent, interaction }) => {
	const {
		currentInteractionHash,
		historyInteractions,
		interactions,
		missedInteractions
	} = interaction;
	const allInteractions = interactions.concat(historyInteractions).concat(missedInteractions);

	return ({
		currentInteraction: allInteractions.find(({ interactionHash }) => interactionHash === currentInteractionHash) || {},
		phoneData: agent.phoneData
	});
};

const mapActionsToProps = dispatch => ({
	clearConferenceUsers: () => dispatch(actions.clearConferenceUsers()),
	onChangeInputPhone: value => dispatch(actions.changeInputPhone(value)),
	showDial: () => dispatch(actions.showDial()),
	showConference: () => dispatch(actions.showConference()),
	defineMainBoxSection: section => dispatch(actions.defineMainboxSection(section))
});

PhoneInteraction.propTypes = {
	phoneNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
	isEnded: PropTypes.bool,
	endTime: PropTypes.oneOfType([
		PropTypes.instanceOf(Date),
		PropTypes.string
	]),
	phoneData: PropTypes.shape({
		name: PropTypes.string,
		status: PropTypes.string,
		initialTime: PropTypes.oneOfType([PropTypes.string]),
		conferenceUsers: PropTypes.arrayOf(PropTypes.shape({}))
	}),
	startTime: PropTypes.string,
	finishInteraction: PropTypes.func,
	interactionHash: PropTypes.string.isRequired,
	closeReceptiveModal: PropTypes.func,
	clearConferenceUsers: PropTypes.func.isRequired,
	intl: PropTypes.shape({
		formatMessage: PropTypes.func
	}).isRequired,
	onChangeInputPhone: PropTypes.func.isRequired,
	showConference: PropTypes.func.isRequired,
	showDial: PropTypes.func.isRequired,
	defineMainBoxSection: PropTypes.func.isRequired,
	currentInteraction: PropTypes.shape({
		messages: PropTypes.arrayOf(PropTypes.shape({}))
	}).isRequired
};

export default connect(mapStateToProps, mapActionsToProps)(injectIntl(PhoneInteraction));
