import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl, defineMessages } from 'react-intl';

import * as actions from '../../../store/actions';
import './index.sass';
import IconButton from '../../Molecules/Buttons/IconButton';
import Button from '../../Molecules/Buttons/Button';
import Text from '../../Atoms/Text';
import ImgSvg from '../../Atoms/ImgSvg';
import Transfer from '../Transfer';
// import Transfer from '../../Sirius/ModalDefault/TransferInteraction';
import Call from '../../Molecules/Call';
import { parseCustomerName, getCustomerFieldValue, verifyPermissions } from '../../../shared/utility';
import { ENDED_INTERACTION_STATES } from '../../../shared/consts';
import ChannelImage from '../../Atoms/ChannelImage';
import UnavailableModal from '../UnavailableModal';

const messages = defineMessages({
	notRegistered: {
		id: 'chatHeader.notRegistered',
		defaultMessage: 'Não identificado'
	},
	transfer: {
		id: 'chatHeader.transfer',
		defaultMessage: 'Transferir'
	},
	fontSize: {
		id: 'chatHeader.fontSize',
		defaultMessage: 'Tamanho do texto da conversa'
	},
	pending: {
		id: 'chatHeader.pending',
		defaultMessage: 'Pendente'
	},
	finish: {
		id: 'chatHeader.finish',
		defaultMessage: 'Encerrar'
	},
	leave: {
		id: 'chatHeader.leave',
		defaultMessage: 'Sair'
	},
	tagNotification: {
		id: 'chatHeader.tagNotification',
		defaultMessage: 'Tags obrigatórias'
	},
	tagNotificationContent: {
		id: 'chatHeader.tagNotificationContent',
		defaultMessage: 'Adicione tags a interação'
	},
	pendingInteraction: {
		id: 'chatHeader.pendingInteraction',
		defaultMessage: 'Interação movida'
	},
	pendingInteractionContent: {
		id: 'chatHeader.pendingInteractionContent',
		defaultMessage: 'Interação disponível em pendentes'
	},
	finished: {
		id: 'chatHeader.finished',
		defaultMessage: 'Interação finalizada'
	},
	finishedContent: {
		id: 'chatHeader.finishedContent',
		defaultMessage: 'O atendimento foi encerrado'
	},
	removed: {
		id: 'chatHeader.removed',
		defaultMessage: 'Interação removida'
	},
	removedContent: {
		id: 'chatHeader.removedContent',
		defaultMessage: 'Interação disponível no histórico'
	},
	transferInteraction: {
		id: 'chatHeader.transferInteraction',
		defaultMessage: 'Transferir atendimento'
	},
	moveToPending: {
		id: 'chatHeader.moveToPending',
		defaultMessage: 'Mover para pendentes'
	},
	finishInteraction: {
		id: 'chatHeader.finishInteraction',
		defaultMessage: 'Finalizar atendimento'
	},
	closeInteraction: {
		id: 'chatHeader.closeInteraction',
		defaultMessage: 'Fechar conversa'
	},
	active: {
		id: 'chatHeader.active',
		defaultMessage: 'Atender'
	}
});

const activeTypes = ['TEXT', 'PHONE', 'PHONE_CONF'];
const blockedToShowPending = ['POSTPONED', 'PENDING', 'REPLYING', 'MISSED'];

const ChatHeader = ({
	onClose,
	currentInteraction,
	showTransfer,
	showCall,
	agent,
	intl,
	isHistoryInteraction,
	onShowTransfer,
	onHideTransfer,
	onClearActions,
	onFinish,
	onChangeInputPhone,
	onExit,
	phoneData,
	registeredPhone,
	tagRequired,
	onShowTag,
	addNotification,
	blockedToInteract,
	onAcceptInteraction,
	replyInteraction,
	agentBlocked
}) => {
	const {
		currentState,
		interactionHash,
		media,
		customerInfo = {},
		interactionType,
		interactionTags,
		rejected = false
	} = currentInteraction;
	const { formatMessage } = intl;
	const { info, isFullAutomatic } = agent;
	const { fields = {} } = customerInfo;
	const [name, setName] = useState('');
	const [phone, setPhone] = useState('');
	const [email, setEmail] = useState('');
	const [showBlockModal, setBlockModal] = useState(false);
	const interactionTypeMedia = interactionType || media;
	const showPending = !blockedToShowPending.includes(currentState) && !activeTypes.includes(interactionTypeMedia) && !blockedToInteract;
	const permissions = agent && agent.info ? agent.info.permissions : [];
	const canTransfer = verifyPermissions(permissions, 'canTransfer');
	const isMissingTag = ((!interactionTags && tagRequired) || (interactionTags && interactionTags.length === 0 && tagRequired)) && !isHistoryInteraction;

	useEffect(() => {
		const fieldPhone = getCustomerFieldValue('channel_phone', fields);
		const fieldEmail = getCustomerFieldValue('channel_email', fields);
		setEmail(fieldEmail && fieldEmail.constructor === Array ? fieldEmail[0] : fieldEmail);
		setPhone(fieldPhone && fieldPhone.constructor === Array ? fieldPhone[0] : fieldPhone);
		setName(parseCustomerName(getCustomerFieldValue('main_identifier', fields) || formatMessage(messages.notRegistered)));
	}, [fields, formatMessage]);

	const askForTags = useCallback(() => {
		addNotification({
			title: formatMessage(messages.tagNotification),
			content: formatMessage(messages.tagNotificationContent),
			type: 'warning'
		});
		onShowTag(interactionHash);
	}, [
		addNotification, formatMessage, interactionHash, onShowTag
	]);

	const toggleTransfer = useCallback(() => {
		if (showTransfer) return onHideTransfer(interactionHash);
		onClearActions(interactionHash);
		return onShowTransfer(interactionHash);
	}, [interactionHash, onClearActions, onHideTransfer, onShowTransfer, showTransfer]);

	const finishInteraction = useCallback((interactionInfo) => {
		if (interactionType.toLowerCase() === 'phone') onChangeInputPhone('');
		if (currentState.toLowerCase() === 'missed' || currentState.toLowerCase() === 'replying') {
			const parsedInteractionInfo = { ...interactionInfo, removeThisInteraction: true };
			onFinish(parsedInteractionInfo);
		} else {
			onFinish(interactionInfo);
		}
	}, [currentState, interactionType, onChangeInputPhone, onFinish]);

	const addRemovedNotification = useCallback(() => {
		addNotification({
			title: formatMessage(messages.removed),
			content: formatMessage(messages.removedContent),
			type: 'success'
		});
	}, [addNotification, formatMessage]);

	const onExitInteraction = useCallback(() => {
		if (rejected) {
			onExit({
				interactionHash,
				registeredPhone,
				phoneData
			});

			return;
		}

		if (isMissingTag) {
			askForTags();
		} else {
			onExit({
				interactionHash,
				registeredPhone,
				phoneData
			});
			addRemovedNotification();
		}
	}, [
		addRemovedNotification,
		interactionHash,
		isMissingTag,
		rejected,
		onExit,
		phoneData,
		registeredPhone,
		askForTags
	]);

	const addPendingNotification = useCallback(() => {
		addNotification({
			title: formatMessage(messages.pendingInteraction),
			content: formatMessage(messages.pendingInteractionContent),
			type: 'success'
		});
	}, [addNotification, formatMessage]);

	const addFinishedNotification = useCallback(() => {
		addNotification({
			title: formatMessage(messages.finished),
			content: formatMessage(messages.finishedContent),
			type: 'success'
		});
	}, [addNotification, formatMessage]);

	const onTryCloseInteraction = () => {
		if (isMissingTag) {
			askForTags();
		} else {
			finishInteraction({
				interactionHash,
				media,
				id: info.account.id,
				agentName: info.name
			});
			addFinishedNotification();
		}
	};
	const verifyBlocked = () => {
		const blockAgent = agentBlocked.blocked;

		if (blockAgent) {
			setBlockModal(true);
			return true;
		}

		return false;
	};

	const handleCloseModal = () => {
		setBlockModal(false);
	};

	const acceptOrReplyAction = () => {
		if (verifyBlocked()) {
			return;
		}
		if (currentState === 'MISSED') {
			replyInteraction(interactionHash);
		} else {
			onAcceptInteraction(interactionHash);
		}
	};

	return (
		<div className="ChatHeader__wrapper">
			<div className="ChatHeader">
				<div className="ChatHeader__user">
					<div className="ChatHeader__user__avatar">
						<ChannelImage suffix="gray" name={interactionType} />
					</div>
					<div className="ChatHeader__user__info">
						<Text size="bigger" className="ChatHeader__user__info--responsive">
							{name}
						</Text>
						<Text size="big" className="ChatHeader__user__info--responsive">{phone || email}</Text>
					</div>
				</div>
				<div className="ChatHeader__actions">
					{!ENDED_INTERACTION_STATES.includes(currentState) && !rejected ? (
						<>
							{interactionTypeMedia !== 'PHONE_CONF' && canTransfer && currentState !== 'REPLYING' && !blockedToInteract && (
								<IconButton
									ariaLabel={formatMessage(messages.transferInteraction)}
									name="transfer"
									click={toggleTransfer}
									fill="white"
									className="ChatHeader__actions__button"
									info={formatMessage(messages.transfer)}
									data-testid="btnIconTransfer"
								/>
							)}
							{showPending && !isFullAutomatic && (
								<IconButton
									ariaLabel={formatMessage(messages.moveToPending)}
									name="clock-off"
									click={() => {
										onClose({ interactionHash, registeredPhone, phoneData });
										addPendingNotification();
									}}
									fill="white"
									className="ChatHeader__actions__button"
									info={formatMessage(messages.pending)}
									data-testid="btnIconClock-off"
								/>
							)}
							{!blockedToInteract ? (
								<Button className="ChatHeader__actions__close-button" data-testid="btnIconClose" click={onTryCloseInteraction}>{formatMessage(messages.finish)}</Button>
							) : (
								<Button className="ChatHeader__actions__active-button" click={acceptOrReplyAction}>
									{formatMessage(messages.active)}
									{' '}
									<ImgSvg name="confirm-changes" color="#FFF" />
								</Button>
							)}
						</>
					) : (
						<>
							{!isHistoryInteraction && (
								<IconButton
									ariaLabel={formatMessage(messages.closeInteraction)}
									name="out"
									click={onExitInteraction}
									fill="white"
									className="ChatHeader__actions__button"
									info={formatMessage(messages.leave)}
									data-testid="btnIconOut"
								/>
							)}
						</>
					)}
				</div>
			</div>
			{showTransfer && !rejected && (<Transfer toggleTransfer={toggleTransfer} />)}
			{showCall && !rejected && (<Call />)}
			{showBlockModal && (
				<UnavailableModal onClose={handleCloseModal} tryToAnswer />
			)}
		</div>
	);
};

ChatHeader.propTypes = {
	currentInteraction: PropTypes.shape({
		status: PropTypes.string,
		interactionHash: PropTypes.string,
		interactionType: PropTypes.string,
		currentState: PropTypes.string,
		rejected: PropTypes.bool,
		interactionTags: PropTypes.arrayOf(PropTypes.shape({})),
		media: PropTypes.string,
		customerInfo: PropTypes.shape({
			fields: PropTypes.arrayOf(PropTypes.shape({}))
		})
	}).isRequired,
	onClose: PropTypes.func.isRequired,
	onFinish: PropTypes.func.isRequired,
	onExit: PropTypes.func.isRequired,
	onShowTransfer: PropTypes.func.isRequired,
	onHideTransfer: PropTypes.func.isRequired,
	onClearActions: PropTypes.func.isRequired,
	showTransfer: PropTypes.bool.isRequired,
	showCall: PropTypes.bool.isRequired,
	agent: PropTypes.shape({
		isFullAutomatic: PropTypes.bool.isRequired,
		info: PropTypes.shape({
			account: PropTypes.shape({
				id: PropTypes.number
			}),
			name: PropTypes.string,
			permissions: PropTypes.arrayOf(PropTypes.string)
		}),
		registeredPhone: PropTypes.bool,
		phoneData: PropTypes.shape({})
	}).isRequired,
	intl: PropTypes.shape({
		formatMessage: PropTypes.func.isRequired
	}).isRequired,
	isHistoryInteraction: PropTypes.bool,
	onChangeInputPhone: PropTypes.func.isRequired,
	phoneData: PropTypes.shape({
		name: PropTypes.string,
		token: PropTypes.string,
		session: PropTypes.number
	}).isRequired,
	registeredPhone: PropTypes.bool.isRequired,
	tagRequired: PropTypes.bool.isRequired,
	onShowTag: PropTypes.func.isRequired,
	addNotification: PropTypes.func.isRequired,
	blockedToInteract: PropTypes.bool,
	onAcceptInteraction: PropTypes.func.isRequired,
	replyInteraction: PropTypes.func.isRequired,
	agentBlocked: PropTypes.shape({
		blocked: PropTypes.bool,
		blockModalViewed: PropTypes.bool
	})
};

const MapActionsToProps = dispatch => ({
	onClose: requestInfo => dispatch(actions.onCloseInteraction(requestInfo)),
	onFinish: (info) => {
		dispatch(actions.onFinish(info));
	},
	onExit: (info) => {
		dispatch(actions.defineSelectedInteraction(''));
		dispatch(actions.removeInteraction(info));
	},
	onShowTransfer: hash => dispatch(actions.showTransfer(hash)),
	onHideTransfer: hash => dispatch(actions.hideTransfer(hash)),
	onShowCall: hash => dispatch(actions.showCall(hash)),
	onClearActions: hash => dispatch(actions.clearActions(hash)),
	onChangeInputPhone: value => dispatch(actions.changeInputPhone(value)),
	onShowTag: interactionHash => dispatch(actions.showTag(interactionHash)),
	addNotification: notification => dispatch(actions.addNotification(notification)),
	onAcceptInteraction: interactionHash => dispatch(actions.acceptInteraction(interactionHash)),
	replyInteraction: interactionHash => dispatch(actions.replyInteraction(interactionHash))
});

const MapStateToProps = (state) => {
	const { interaction, agent } = state;
	const {
		currentInteractionHash,
		historyInteractions,
		interactions,
		missedInteractions,
		pendingInteractions
	} = interaction;

	const allInteractions = interactions.concat(pendingInteractions).concat(historyInteractions).concat(missedInteractions);

	return ({
		currentInteraction: allInteractions.find(({ interactionHash }) => interactionHash === currentInteractionHash) || {},
		showTransfer: state.interface.showingTransfer.some(hash => hash === currentInteractionHash),
		showCall: state.interface.showingCall.some(hash => hash === currentInteractionHash),
		agent,
		phoneData: agent.phoneData,
		registeredPhone: agent.registeredPhone,
		tagRequired: agent.tagRequired,
		agentBlocked: agent.agentBlocked
	});
};

export default connect(MapStateToProps, MapActionsToProps)(injectIntl(ChatHeader));
