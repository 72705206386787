import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import '../index.css';
import TaskBox from '../../Organism/TaskBox';
import TaskSidebar from '../../Molecules/TaskSidebar';

const TasksTemplate = ({ selectedTask, taskSidebarNew }) => (
	<div className={`Template__tasks ${selectedTask || taskSidebarNew ? 'Template__tasks--task' : ''}`}>
		<TaskBox />
		<TaskSidebar />
	</div>
);

TasksTemplate.propTypes = {
	selectedTask: PropTypes.bool.isRequired,
	taskSidebarNew: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
	selectedTask: state.agent.selectedTask.constructor === Object
		&& Object.keys(state.agent.selectedTask).length > 0,
	taskSidebarNew: state.interface.taskSidebar === 'new'
});

export default connect(mapStateToProps)(TasksTemplate);
