/* eslint-disable react/no-danger */

import React from 'react';
import PropTypes from 'prop-types';
import Linkify from 'omz-react-linkify';

import Bubble from '../../../Atoms/Bubble';
import { useBan } from '../../../../context/bannedContent';

const MessageText = ({ messageInfo, children }) => {
	const { cleanBadWords } = useBan();

	const isClientMessage = messageInfo.origin === 'CLIENT';
	const message = isClientMessage ? cleanBadWords('receivedText', messageInfo.content) : messageInfo.content;

	return (
		<Bubble messageInfo={messageInfo}>
			{children}
			<Linkify properties={{ target: '_blank' }}>
				{message}
			</Linkify>
		</Bubble>
	);
};

MessageText.propTypes = {
	messageInfo: PropTypes.shape({
		content: PropTypes.string,
		origin: PropTypes.string,
		referenceId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
	}).isRequired
};

export default MessageText;
