import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import './index.sass';
import DropDownItem from '../DropDownItem';
import LanguageChooser from '../LanguageChooser';
import Wrapper from '../../Atoms/Wrapper';

const NavbarDropDown = ({
	dropDownCustomHeader, dropDownCustomFooter, dropDownItems, onChangeLanguage, onClose
}) => (
	<Wrapper
		className="wrapper"
		action={(e) => {
			e.stopPropagation();
			onClose();
		}}
	>
		<Wrapper
			className="NavbarDropDown"
			action={e => e.stopPropagation()}
		>
			{dropDownCustomHeader && <div className="NavbarDropDown--header">{dropDownCustomHeader}</div>}

			{dropDownItems.map(({
				sectionName,
				items
			}, index) => (
				<div key={index} className={index !== dropDownItems.length - 1 ? 'NavbarDropDown__separator' : ''}>
					{sectionName && <span className="NavbarDropDown__sectionName has-text-white">{sectionName}</span>}
					<div>
						{items.map(({
							label,
							click,
							active,
							hasToggle,
							show
						}, tabIndex) => (
							<Fragment key={label}>
								{show && (
									<DropDownItem
										tabIndex={tabIndex}
										label={label}
										click={click}
										active={active}
										showStatus={hasToggle}
									/>
								)}
							</Fragment>
						))}
					</div>
				</div>
			))
			}
			{onChangeLanguage && <LanguageChooser onlyFlags={false} onChange={onChangeLanguage} />}
			{dropDownCustomFooter && <div className="NavbarDropDown--footer">{dropDownCustomFooter}</div>}

		</Wrapper>
	</Wrapper>
);

NavbarDropDown.propTypes = {
	dropDownCustomHeader: PropTypes.element,
	dropDownCustomFooter: PropTypes.element,
	dropDownItems: PropTypes.arrayOf(PropTypes.shape({
		sectionName: PropTypes.string,
		items: PropTypes.arrayOf(PropTypes.shape({
			label: PropTypes.string,
			click: PropTypes.func,
			active: PropTypes.bool,
			hasToggle: PropTypes.bool,
			show: PropTypes.bool
		}))
	})).isRequired,
	logoutItem: PropTypes.shape({
		label: PropTypes.string,
		click: PropTypes.func,
		active: PropTypes.bool,
		hasToggle: PropTypes.bool,
		show: PropTypes.bool
	}),
	onChangeLanguage: PropTypes.func,
	onClose: PropTypes.func.isRequired
};

export default NavbarDropDown;
