import React from 'react';
import PropTypes from 'prop-types'; // ES6
import './index.sass';
import IconButton from '../../Molecules/Buttons/IconButton';
import { convertWAVtoMP3, createWAVFile } from '../../../shared/utility';

export default class VoiceRecorder extends React.Component {
	constructor(props) {
		super(props);

		this.canvasRef = React.createRef();
		this.state = {
			seconds: 0,
			audioUrl: ''
		};
		this.timer = 0;
		this.countSecs = this.countSecs.bind(this);
	}

	componentDidMount() {
		this.init();
	}

	init = async () => {
		this.leftchannel = [];
		this.rightchannel = [];
		this.recorder = null;
		this.recording = false;
		this.recordingLength = 0;
		this.volume = null;
		this.audioInput = null;
		this.sampleRate = null;
		this.AudioContext = window.AudioContext || window.webkitAudioContext;
		this.context = null;
		this.analyser = null;
		this.canvas = null;
		this.canvasCtx = null;
		this.stream = null;
		this.tested = false;

		navigator.getUserMedia = navigator.getUserMedia
			|| navigator.webkitGetUserMedia
			|| navigator.mozGetUserMedia;
	}

	getStream = constraints => navigator.mediaDevices.getUserMedia(constraints || { audio: true, video: false })

	setUpRecording = () => {
		this.context = new this.AudioContext();
		const { audioWorklet } = this.context;

		audioWorklet.addModule('recorderWorkletProcessor.js').then(() => {
			// creates an audio processor that runs outside the main thread
			this.recorder = new AudioWorkletNode(this.context, 'recorder-processor');

			const {
				sampleRate,
				createGain,
				createMediaStreamSource,
				createAnalyser,
				destination
			} = this.context;

			this.sampleRate = sampleRate;

			// creates a gain node
			this.volume = createGain.call(this.context);

			// creates an audio node from teh microphone incoming stream
			this.audioInput = createMediaStreamSource.call(this.context, this.stream);

			// Create analyser
			this.analyser = createAnalyser.call(this.context);

			// connect audio input to the analyser
			this.audioInput.connect(this.analyser);

			// connect analyser to the audio processor
			this.analyser.connect(this.recorder);

			// finally connect the processor to the output
			this.recorder.connect(destination);

			const self = this;

			// receiving message from the audio processor thread
			this.recorder.port.onmessage = (e) => {
				if (!self.recording) return;
				const { bufferLeft, bufferRight, bufferSize } = e.data;
				self.leftchannel.push(bufferLeft);
				self.rightchannel.push(bufferRight);
				self.recordingLength += bufferSize;
			};
			this.visualize();
		});
	}

	mergeBuffers = (channelBuffer, recordingLength) => {
		const result = new Float32Array(recordingLength);
		let offset = 0;
		const lng = channelBuffer.length;
		for (let i = 0; i < lng; i += 1) {
			const buffer = channelBuffer[i];
			result.set(buffer, offset);
			offset += buffer.length;
		}
		return result;
	}

	interleave = (leftChannel, rightChannel) => {
		const length = leftChannel.length + rightChannel.length;
		const result = new Float32Array(length);

		let inputIndex = 0;

		for (let index = 0; index < length;) {
			result[index] = leftChannel[inputIndex];
			result[index + 1] = rightChannel[inputIndex];
			inputIndex += 1;
			index += 2;
		}
		return result;
	}

	timeFormat = totalSeconds => ({
		seconds: String(totalSeconds % 60).padStart(2, '0'),
		minutes: String(Math.floor(totalSeconds / 60)).padStart(2, '0')
	});


	visualize = () => {
		const { backgroundColor = 'rgb(256, 256, 256)', foregroundColor = 'rgb(0, 0, 0)', maxTime = 30 } = this.props;

		this.WIDTH = this.canvas.width;
		this.HEIGHT = this.canvas.height;
		this.CENTERX = this.canvas.width / 2;
		this.CENTERY = this.canvas.height / 2;

		if (!this.analyser) {
			return;
		}

		this.analyser.fftSize = 2048;
		const bufferLength = this.analyser.fftSize;
		const dataArray = new Uint8Array(bufferLength);

		this.canvasCtx.clearRect(0, 0, this.WIDTH, this.HEIGHT);

		const self = this;
		const draw = () => {
			const elapsedTime = self.timeFormat(self.state.seconds);
			const maximumTime = self.timeFormat(maxTime);
			const textString = `${elapsedTime.minutes}:${elapsedTime.seconds} / ${maximumTime.minutes}:${maximumTime.seconds}`;
			const textWidth = self.canvasCtx.measureText(textString).width;

			if (self.state.audioUrl || !self.recording) {
				return;
			}

			self.drawVisual = requestAnimationFrame(draw);
			self.analyser.getByteTimeDomainData(dataArray);
			self.canvasCtx.fillStyle = backgroundColor;
			self.canvasCtx.fillRect(0, 0, self.WIDTH, self.HEIGHT);
			self.canvasCtx.fillStyle = 'black';
			self.canvasCtx.font = '1.3rem Hind';
			self.canvasCtx.fillText(textString, self.CENTERX - textWidth / 2, self.HEIGHT / 3);
			self.canvasCtx.lineWidth = 2;
			self.canvasCtx.strokeStyle = foregroundColor;
			self.canvasCtx.beginPath();

			const sliceWidth = (self.WIDTH * 1.0) / bufferLength;
			let x = 0;

			for (let i = 0; i < bufferLength; i += 1) {
				const v = dataArray[i] / 128.0;
				const y = 2 * (v * self.HEIGHT) / 3;

				if (i === 0) {
					self.canvasCtx.moveTo(x, y);
				} else {
					self.canvasCtx.lineTo(x, y);
				}

				x += sliceWidth;
			}

			self.canvasCtx.lineTo(self.canvas.width, 2 * self.canvas.height / 3);
			self.canvasCtx.stroke();
		};
		draw();
	}

	setupMic = async () => {
		try {
			this.stream = await this.getStream();
			window.stream = this.stream;
		} catch (err) {
			clearInterval(this.timer);
		}
		this.setUpRecording();
	}

	start = async () => {
		this.setState({ seconds: 0, audioUrl: '' }, () => {
			this.canvas = this.canvasRef.current;
			this.canvasCtx = this.canvas.getContext('2d');
		});
		this.recording = true;
		// reset the buffers for the new recording
		this.rightchannel.length = 0;
		this.leftchannel.length = 0;
		this.recordingLength = 0;
		this.timer = setInterval(this.countSecs, 1000);
		await this.setupMic();
	}

	stop = () => {
		const { type = 'audio/mpeg' } = this.props;

		this.recording = false;
		clearInterval(this.timer);
		this.setState({ seconds: 0 });
		this.closeMic();

		// flat the left and right channels down
		this.leftBuffer = this.mergeBuffers(this.leftchannel, this.recordingLength);
		this.rightBuffer = this.mergeBuffers(
			this.rightchannel,
			this.recordingLength
		);

		const interleaved = this.interleave(this.leftBuffer, this.rightBuffer);
		const WAVFile = createWAVFile(interleaved, this.sampleRate);
		const MP3Buffer = convertWAVtoMP3(WAVFile.dataView, WAVFile.buffer);

		const blob = new Blob(MP3Buffer, { type });
		const audioUrl = URL.createObjectURL(blob);

		this.setState({ audioUrl });
	}

	closeMic = () => {
		this.stream.getAudioTracks().forEach((track) => {
			track.stop();
		});
		this.audioInput.disconnect(0);
		this.analyser.disconnect(0);
		this.recorder.disconnect(0);
	}

	delete = () => {
		this.setState({ seconds: 0, audioUrl: '' });
	}

	send = async () => {
		const { audioUrl } = this.state;
		const { onSend } = this.props;
		const blob = await fetch(audioUrl).then(r => r.blob());
		onSend(blob);
		this.setState({ audioUrl: '' });
	}

	countSecs() {
		const { maxTime = 30 } = this.props;
		const { seconds } = this.state;
		this.setState(prevState => ({
			seconds: prevState.seconds + 1
		}));

		if (seconds >= maxTime) {
			clearInterval(this.timer);
			this.stop();
		}
	}

	renderButtons = () => {
		const { audioUrl } = this.state;
		if (audioUrl) {
			return (
				<>
					<IconButton
						name="checked"
						fill="green"
						click={this.send}
						data-testid="component-voiceRecorder-sendButton"
						ariaLabel="Enviar a gravação"
					/>
					<IconButton
						name="cross"
						fill="red"
						click={this.delete}
						data-testid="component-voiceRecorder-deleteButton"
						ariaLabel="Cancelar a gravação"
					/>
				</>
			);
		}
		return this.recording
			? (
				<IconButton
					name="stop"
					fill="red"
					click={this.stop}
					data-testid="component-voiceRecorder-stopButton"
					ariaLabel="Finalizar a gravação"
				/>
			)
			: (
				<IconButton
					name="microphone-off"
					fill="red"
					click={this.start}
					data-testid="component-voiceRecorder-recordButton"
					ariaLabel="Começar a gravação"
				/>
			);
	}

	render() {
		const { canvasWidth = 300, canvasHeight = 70 } = this.props;
		const { audioUrl } = this.state;

		return (
			<div className="voiceRecorder" data-testid="component-voiceRecorder">
				<div className="voiceRecorder__container">
					{this.recording ? (
						<canvas
							ref={this.canvasRef}
							width={canvasWidth}
							height={canvasHeight}
							className="voiceRecorder__canvas"
							data-testid="component-voiceRecorder-canvas"
						/>
					)
						// eslint-disable-next-line jsx-a11y/media-has-caption
						: <audio src={audioUrl} controls data-testid="component-voiceRecorder-audioPlayer" />
					}
					<div className="voiceRecorder__buttons">
						{this.renderButtons()}
					</div>
				</div>
			</div>
		);
	}
}

VoiceRecorder.propTypes = {
	type: PropTypes.string,
	backgroundColor: PropTypes.string,
	foregroundColor: PropTypes.string,
	canvasWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	canvasHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	onSend: PropTypes.func.isRequired,
	maxTime: PropTypes.number
};
