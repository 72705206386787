/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { defineMessages, injectIntl } from 'react-intl';

import './index.sass';
import Text from '../../Atoms/Text';
import Wrapper from '../../Atoms/Wrapper';
import ImgSvg from '../../Atoms/ImgSvg';
import ChannelImage from '../../Atoms/ChannelImage';
import PhoneInteraction from '../../Organism/PhoneInteraction';
import TagItem from '../TagItem';
import MessageList from '../../Organism/MessageList';
import { timeInfoFormat1, interactionTypeLabel } from '../../../shared/utility';

const messagesIntl = defineMessages({
	protocol: {
		id: 'historyItem.protocol',
		defaultMessage: 'Protocolo'
	},
	noDepartment: {
		id: 'historyItem.noDepartment',
		defaultMessage: 'Sem departamento'
	},
	department: {
		id: 'historyItem.department',
		defaultMessage: 'Departamento'
	}
});

const HistoryItem = ({
	historyInfo,
	onSelectHistory,
	showHistoryMessages,
	intl
}) => {
	const {
		id,
		startTime,
		interactionHash,
		type,
		interactionType,
		department,
		note,
		tags,
		messages
	} = historyInfo;
	const { formatMessage } = intl;

	const [classes, setClasses] = useState('');
	useEffect(() => {
		const currentClasses = ['HistoryItem'];
		if (showHistoryMessages === interactionHash) currentClasses.push('HistoryItem--selected');
		setClasses(currentClasses.join(' '));
	}, [showHistoryMessages, interactionHash]);

	return (
		<>
			<Wrapper className={classes} action={() => onSelectHistory(interactionHash)}>
				<div className="HistoryItem__header">
					<div className="History__info">
						<div>
							<div className="HistoryItem__icon">
								<ChannelImage suffix="gray" name={type} />
								<Text size="big">{interactionTypeLabel(interactionType)}</Text>
							</div>
							<Text size="bigger">{timeInfoFormat1(startTime)}</Text>
						</div>
						<Text className="History__info__protocol" size="bigger">
							<span>{formatMessage(messagesIntl.protocol)}</span>
							{' '}
							{`#${id}`}
						</Text>
						<Text className="History__info__department" size="bigger">
							<span>{formatMessage(messagesIntl.department)}</span>
							{' '}
							{department.name}
						</Text>
					</div>
					<div className="HistoryItem__arrow">
						<ImgSvg name="arrow" />
					</div>
				</div>
			</Wrapper>
			{showHistoryMessages === interactionHash && (
				<div>
					<div className="History__chat">
						{interactionType.includes('phone') ? (
							<PhoneInteraction isEnded interactionHash={interactionHash} />
						) : (
							<MessageList isChatHistory messages={messages} />
						)}
					</div>
					{note && note.body && (
						<div className="HistoryList__item__note">
							{note.body && <Text>{note.body}</Text>}
							{note.url && <a href={note.url} target="_blank" rel="noopener noreferrer" download>{note.s3.filename}</a>}
						</div>
					)}
					{tags && tags.constructor === Array && tags.length > 0 && (
						<div className="HistoryList__item__tags">
							{tags.map(tag => <TagItem tag={tag} key={tag.id} />)}
						</div>
					)}
				</div>
			)}
		</>
	);
};

HistoryItem.propTypes = {
	historyInfo: PropTypes.shape({
		department: PropTypes.shape({
			name: PropTypes.string
		}),
		id: PropTypes.number,
		startTime: PropTypes.string,
		interactionHash: PropTypes.string,
		type: PropTypes.string,
		interactionType: PropTypes.string,
		note: PropTypes.shape({
			body: PropTypes.string,
			url: PropTypes.string,
			s3: PropTypes.shape({
				key: PropTypes.string,
				bucket: PropTypes.string,
				filename: PropTypes.string
			})
		}),
		tags: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.number })),
		messages: PropTypes.arrayOf(PropTypes.object)
	}).isRequired,
	onSelectHistory: PropTypes.func.isRequired,
	showHistoryMessages: PropTypes.string.isRequired,
	intl: PropTypes.shape({
		formatMessage: PropTypes.func.isRequired
	}).isRequired
};

export default injectIntl(HistoryItem);
