import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import MainBox from '../../Organism/MainBox';
import Interaction from '../../Organism/Interaction';

const DefaultTemplate = ({ selectedInteraction }) => (
	<div className={`Template__main ${selectedInteraction ? 'Template__main--interaction' : ''}`}>
		<MainBox />
		<Interaction />
	</div>
);

const mapStateToProps = state => ({
	selectedInteraction: state.interaction.currentInteractionHash
		? state.interaction.interactions
			.concat(state.interaction.pendingInteractions)
			.concat(state.interaction.historyInteractions)
			.some(({ interactionHash }) => interactionHash === state.interaction.currentInteractionHash)
		: false
});

DefaultTemplate.propTypes = {
	selectedInteraction: PropTypes.bool.isRequired
};

export default connect(mapStateToProps)(DefaultTemplate);
