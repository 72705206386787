import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

import './index.sass';
import AgentTaskItem from '../../Organism/AgentTaskItem';
import Text from '../../Atoms/Text';

const AgentTaskGroup = ({ date, tasks }) => (
	<div className="AgentTasks__group">
		<Text className="AgentTasks__group__title">{moment(date).format('DD/MM/YYYY')}</Text>
		<div className="AgentTasks__list">
			{tasks.map(task => <AgentTaskItem task={task} key={task.id} />)}
		</div>
	</div>
);

AgentTaskGroup.propTypes = {
	date: PropTypes.string.isRequired,
	tasks: PropTypes.arrayOf(PropTypes.shape({
		task: PropTypes.shape({
			id: PropTypes.number.isRequired
		})
	})).isRequired
};

export default AgentTaskGroup;
