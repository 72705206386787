import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, defineMessages } from 'react-intl';
import { connect } from 'react-redux';
import moment from 'moment';

import './index.sass';
import * as actions from '../../../store/actions';
import TaskCheckbox from '../../Molecules/TaskCheckbox';
import ImgSvg from '../../Atoms/ImgSvg';
import { getTaskCustomerName, verifyTaskExtrasBeforeEnd } from '../../../shared/utility';
import formData from '../TaskForm/formData';
import Wrapper from '../../Atoms/Wrapper';
import Text from '../../Atoms/Text';

const messages = defineMessages({
	createdBy: {
		id: 'agentTaskItem.createdBy',
		defaultMessage: 'Criado por'
	},
	customer: {
		id: 'agentTaskItem.customer',
		defaultMessage: 'Cliente:'
	},
	expiredStatus: {
		id: 'agentTaskItem.expiredStatus',
		defaultMessage: 'Expirada'
	},
	isExpiring: {
		id: 'agentTaskItem.isExpiring',
		defaultMessage: 'O prazo desta tarefa encerra em breve'
	},
	measure: {
		id: 'agentTaskItem.measure',
		defaultMessage: 'Medida'
	},
	projectName: {
		id: 'agentTaskItem.projectName',
		defaultMessage: 'Nome do Projeto'
	},
	responsibleEngineer: {
		id: 'agentTaskItem.responsibleEngineer',
		defaultMessage: 'Engenheiro Responsável'
	},
	uninformed: {
		id: 'agentTaskItem.uninformed',
		defaultMessage: 'Não informado'
	},
	unfilledTask: {
		id: 'agentTaskItem.unfilledTask',
		defaultMessage: 'Tarefas'
	},
	unfilledTaskContent: {
		id: 'agentTaskItem.unfilledTaskContent',
		defaultMessage: 'Preencha os campos na task antes de finalizar'
	}
});

const AgentTaskItem = ({
	task,
	fetchAgentTask,
	selectedTask = {},
	defineTaskSidebar,
	updateTask,
	isExpiring,
	intl,
	taskExtras,
	blockReopenTasks = true,
	addNotification
}) => {
	const { aditionalFields } = task;
	const { formatMessage } = intl;
	const [classes, setClasses] = useState('');
	useEffect(() => {
		const currentClasses = ['AgentTaskItem'];
		if (selectedTask.id === task.id) currentClasses.push('AgentTaskItem--active');
		if (isExpiring) currentClasses.push('AgentTaskItem--expiring');
		setClasses(currentClasses.join(' '));
	}, [isExpiring, selectedTask.id, task.id]);

	const [agentsNames, setAgentsNames] = useState([]);
	useEffect(() => {
		setAgentsNames(task && task.agents ? task.agents.map(({ name }) => name) : []);
	}, [task]);

	const getTasksFilters = useCallback((name) => {
		if (task && task.extra && task.extra.filters) return task.extra.filters[name];
		return '';
	}, [task]);

	const handleTaskClick = useCallback((e) => {
		if (e.target.classList.contains('TaskCheckbox')) return;
		defineTaskSidebar('default');
		fetchAgentTask(task);
	}, [defineTaskSidebar, fetchAgentTask, task]);

	const onUpdateTask = useCallback((value) => {
		updateTask({
			taskInfo: {
				id: task.id,
				name: task.name,
				description: task.description,
				status: value ? 'FINISHED' : 'OPENED',
				customerId: task.customer.id,
				expiresAt: moment(task.expiresAt.slice(0, -1)).utc(),
				agentIds: task.agents.map(agent => agent.id)
			},
			taskExtras
		});
	}, [task, taskExtras, updateTask]);

	const toggleTaskStatus = useCallback((value) => {
		if (taskExtras) {
			if (aditionalFields) {
				if (verifyTaskExtrasBeforeEnd(task, formData)) {
					onUpdateTask(value);
				} else {
					addNotification({
						title: formatMessage(messages.unfilledTask),
						content: formatMessage(messages.unfilledTaskContent),
						type: 'warning'
					});
				}
			} else {
				onUpdateTask(value);
			}
		} else {
			onUpdateTask(value);
		}
	}, [addNotification, aditionalFields, formatMessage, onUpdateTask, task, taskExtras]);

	return (
		<Wrapper className={classes} action={e => handleTaskClick(e)}>
			<div className="AgentTaskItem__checkbox">
				<TaskCheckbox
					onChecked={value => toggleTaskStatus(value)}
					checked={task.status === 'FINISHED'}
					blockReopenTasks={blockReopenTasks}
				/>
			</div>
			<Text size="big" className="AgentTaskItem__time">
				{moment.utc(task.expiresAt).format('HH:mm')}
			</Text>
			<div className="AgentTaskItem__info">
				{isExpiring && (
					<Text className="AgentTaskItem__warning">{formatMessage(messages.isExpiring)}</Text>
				)}
				<Text size="bigger" className="AgentTaskItem__title">{task.name}</Text>
				<Text size="big" className="AgentTaskItem__data">
					{formatMessage(messages.createdBy)}
					{': '}
					<strong>{agentsNames.join(', ')}</strong>
				</Text>
				<Text size="big" className="AgentTaskItem__data">
					{formatMessage(messages.customer)}
					{' '}
					<strong>{getTaskCustomerName(task.customer)}</strong>
				</Text>
				{taskExtras && (
					<>
						<Text size="big" className="AgentTaskItem__data">
							{formatMessage(messages.measure)}
							{': '}
							<strong>{getTasksFilters('medida') || formatMessage(messages.uninformed)}</strong>
						</Text>
						<Text size="big" className="AgentTaskItem__data">
							{formatMessage(messages.projectName)}
							{': '}
							<strong>{getTasksFilters('nomeDoProjeto') || formatMessage(messages.uninformed)}</strong>
						</Text>
						<Text size="big" className="AgentTaskItem__data">
							{formatMessage(messages.responsibleEngineer)}
							{': '}
							<strong>{getTasksFilters('engenheiroResponsavel') || formatMessage(messages.uninformed)}</strong>
						</Text>
					</>
				)}
			</div>
			{task.status === 'EXPIRED' && !taskExtras && (
				<div className="AgentTaskItem__status">
					<div className="TaskSidebarInfo__status TaskSidebarInfo__status--expired">
						{formatMessage(messages.expiredStatus)}
					</div>
				</div>
			)}
			<div className="AgentTaskItem__arrow">
				<ImgSvg name="arrow" />
			</div>
		</Wrapper>
	);
};

AgentTaskItem.propTypes = {
	intl: PropTypes.shape({
		formatMessage: PropTypes.func.isRequired
	}).isRequired,
	selectedTask: PropTypes.shape({
		id: PropTypes.number
	}),
	task: PropTypes.shape({
		id: PropTypes.number.isRequired,
		status: PropTypes.string.isRequired,
		name: PropTypes.string.isRequired,
		agents: PropTypes.arrayOf(PropTypes.shape({
			name: PropTypes.string.isRequired
		})).isRequired,
		customer: PropTypes.shape({
			id: PropTypes.number,
			fields: PropTypes.arrayOf(
				PropTypes.shape({
					name: PropTypes.string.isRequired
				})
			).isRequired
		}).isRequired,
		expiresAt: PropTypes.string.isRequired,
		aditionalFields: PropTypes.shape({}),
		description: PropTypes.string,
		extra: PropTypes.shape({
			filters: PropTypes.shape({
				medida: PropTypes.string,
				nomeDoProjeto: PropTypes.string,
				engenheiroResponsavel: PropTypes.string
			})
		})
	}).isRequired,
	fetchAgentTask: PropTypes.func.isRequired,
	defineTaskSidebar: PropTypes.func.isRequired,
	updateTask: PropTypes.func.isRequired,
	isExpiring: PropTypes.bool,
	taskExtras: PropTypes.bool.isRequired,
	blockReopenTasks: PropTypes.bool,
	addNotification: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
	selectedTask: state.agent.selectedTask,
	taskExtras: state.agent.taskExtras,
	blockReopenTasks: state.agent.blockReopenTasks,
	tasks: state.agent.tasks
});

const mapActionsToProps = dispatch => ({
	fetchAgentTask: task => dispatch(actions.fetchAgentTask(task)),
	defineTaskSidebar: content => dispatch(actions.defineTaskSidebar(content)),
	updateTask: (taskInfo, success) => dispatch(actions.updateTask(taskInfo, success)),
	addNotification: notification => dispatch(actions.addNotification(notification))
});

export default connect(mapStateToProps, mapActionsToProps)(injectIntl(AgentTaskItem));
