import * as actionTypes from './actionTypes';

export const setInteractionList = type => (
	dispatch => dispatch({
		type: actionTypes.SET_INTERACTION_LIST,
		payload: type
	})
);

export const setCustomerSection = (info) => {
	const { hash, section } = info;
	return dispatch => dispatch({
		type: actionTypes.SET_CUSTOMER_SECTION,
		payload: {
			hash,
			section
		}
	});
};

export const setEditCustomer = (info) => {
	const { hash, value } = info;
	return dispatch => dispatch({
		type: actionTypes.SET_EDIT_CUSTOMER,
		payload: {
			hash,
			value
		}
	});
};

export const clearActions = hash => (
	dispatch => dispatch({
		type: actionTypes.CLEAR_ACTIONS,
		payload: hash
	})
);

export const changeInputText = (inputInfo) => {
	const { value, hash } = inputInfo;
	return dispatch => dispatch({
		type: actionTypes.CHANGE_INPUT_TEXT,
		payload: { hash, value }
	});
};

export const changeInputAnnotation = (inputInfo) => {
	const { value, hash } = inputInfo;
	return dispatch => dispatch({
		type: actionTypes.CHANGE_INPUT_ANNOTATION,
		payload: { hash, value }
	});
};

export const changeInputTags = (inputInfo) => {
	const { value, hash } = inputInfo;
	return dispatch => dispatch({
		type: actionTypes.CHANGE_INPUT_TAGS,
		payload: { hash, value }
	});
};

export const clearInputAnnotation = interactionHash => (
	dispatch => dispatch({
		type: actionTypes.CLEAR_INPUT_ANNOTATION,
		payload: { interactionHash }
	})
);

export const changeInputSearch = (inputInfo) => {
	const { value, hash } = inputInfo;
	return dispatch => dispatch({
		type: actionTypes.CHANGE_INPUT_SEARCH,
		payload: { hash, value }
	});
};

export const changeInputPhone = value => (
	dispatch => dispatch({
		type: actionTypes.CHANGE_INPUT_PHONE,
		payload: value
	})
);

export const setTag = (inputInfo) => {
	const { tag, hash } = inputInfo;
	return dispatch => dispatch({
		type: actionTypes.SET_TAG,
		payload: { hash, tag }
	});
};

export const removeTag = (inputInfo) => {
	const { id, hash } = inputInfo;
	return dispatch => dispatch({
		type: actionTypes.REMOVE_TAG,
		payload: { hash, id }
	});
};

export const showTransfer = hash => (
	dispatch => dispatch({
		type: actionTypes.SHOW_TRANSFER,
		payload: hash
	})
);
export const hideTransfer = hash => (
	dispatch => dispatch({
		type: actionTypes.HIDE_TRANSFER,
		payload: hash
	})
);

export const showAnswer = hash => (
	dispatch => dispatch({
		type: actionTypes.SHOW_ANSWER,
		payload: hash
	})
);
export const hideAnswer = hash => (
	dispatch => dispatch({
		type: actionTypes.HIDE_ANSWER,
		payload: hash
	})
);
export const showWhatsappTemplate = hash => (
	dispatch => dispatch({
		type: actionTypes.SHOW_WHATSAPP_TEMPLATE,
		payload: hash
	})
);
export const hideWhatsappTemplate = hash => (
	dispatch => dispatch({
		type: actionTypes.HIDE_WHATSAPP_TEMPLATE,
		payload: hash
	})
);
export const showEmoji = hash => (
	dispatch => dispatch({
		type: actionTypes.SHOW_EMOJI,
		payload: hash
	})
);
export const hideEmoji = hash => (
	dispatch => dispatch({
		type: actionTypes.HIDE_EMOJI,
		payload: hash
	})
);

export const showAnnotation = hash => (
	dispatch => dispatch({
		type: actionTypes.SHOW_ANNOTATION,
		payload: hash
	})
);
export const hideAnnotation = hash => (
	dispatch => dispatch({
		type: actionTypes.HIDE_ANNOTATION,
		payload: hash
	})
);

export const showInternalMessages = () => (
	dispatch => dispatch({
		type: actionTypes.SHOW_INTERNAL_MESSAGES
	})
);
export const hideInternalMessages = () => (
	dispatch => dispatch({
		type: actionTypes.HIDE_INTERNAL_MESSAGES
	})
);

export const showTag = hash => (
	dispatch => dispatch({
		type: actionTypes.SHOW_TAG,
		payload: hash
	})
);
export const hideTag = hash => (
	dispatch => dispatch({
		type: actionTypes.HIDE_TAG,
		payload: hash
	})
);

export const showReplyMessage = (interactionHash, message) => (
	dispatch => dispatch({
		type: actionTypes.SHOW_REPLY_MESSAGE,
		payload: {
			hash: interactionHash,
			message
		}
	})
);

export const hideReplyMessage = hash => (
	dispatch => dispatch({
		type: actionTypes.HIDE_REPLY_MESSAGE,
		payload: hash
	})
);

export const showVoiceRecording = hash => (
	dispatch => dispatch({
		type: actionTypes.SHOW_VOICE_RECORDING,
		payload: hash
	})
);

export const hideVoiceRecording = hash => (
	dispatch => dispatch({
		type: actionTypes.HIDE_VOICE_RECORDING,
		payload: hash
	})
);

export const showCall = hash => (
	dispatch => dispatch({
		type: actionTypes.SHOW_CALL,
		payload: hash
	})
);
export const hideCall = hash => (
	dispatch => dispatch({
		type: actionTypes.HIDE_CALL,
		payload: hash
	})
);

export const showCreateTask = hash => (
	dispatch => dispatch({
		type: actionTypes.SHOW_CREATE_TASK,
		payload: hash
	})
);
export const hideCreateTask = hash => (
	dispatch => dispatch({
		type: actionTypes.HIDE_CREATE_TASK,
		payload: hash
	})
);

export const defineDefaultIdentity = () => (
	dispatch => dispatch({ type: actionTypes.DEFINE_DEFAULT_IDENTITY })
);

export const fetchIdentityBegin = () => ({
	type: actionTypes.FETCH_IDENTITY_BEGIN
});
export const fetchIdentityFailed = () => ({
	type: actionTypes.FETCH_IDENTITY_FAILED
});
export const fetchIdentitySuccess = logoUrl => ({
	type: actionTypes.FETCH_IDENTITY_SUCCESS,
	payload: logoUrl
});
export const fetchIdentity = url => (
	(dispatch) => {
		dispatch(fetchIdentityBegin());
		return fetch(`${process.env.OMZ_ZAPI}/external/partners/info`, {
			method: 'POST',
			headers: {
				'Content-type': 'application/json'
			},
			body: JSON.stringify({ url })
		})
			.then(res => res.json())
			.then((json) => {
				if (json.status === 200) {
					const { data } = json;
					dispatch(fetchIdentitySuccess(data));
				} else {
					dispatch(fetchIdentityFailed());
				}
			}).catch(() => {
				dispatch(fetchIdentityFailed());
			});
	}
);

export const defineMainboxSection = section => (
	dispatch => dispatch({
		type: actionTypes.DEFINE_MAINBOX_SECTION,
		payload: section
	})
);

export const defineTemplate = template => (
	dispatch => dispatch({
		type: actionTypes.DEFINE_TEMPLATE,
		payload: template
	})
);

export const defineTaskSidebar = content => (
	dispatch => dispatch({
		type: actionTypes.DEFINE_TASK_SIDEBAR,
		payload: content
	})
);

export const activePhoneInterface = () => (
	dispatch => dispatch({
		type: actionTypes.ACTIVE_PHONE_INTERFACE
	})
);

export const showReceptiveModal = () => (
	dispatch => dispatch({
		type: actionTypes.SHOW_RECEPTIVE_MODAL
	})
);
export const hideReceptiveModal = () => (
	dispatch => dispatch({
		type: actionTypes.HIDE_RECEPTIVE_MODAL
	})
);

export const showDial = () => (
	dispatch => dispatch({
		type: actionTypes.SHOW_DIAL
	})
);
export const hideDial = () => (
	dispatch => dispatch({
		type: actionTypes.HIDE_DIAL
	})
);

export const showConference = () => (
	dispatch => dispatch({
		type: actionTypes.SHOW_CONFERENCE
	})
);
export const hideConference = () => (
	dispatch => dispatch({
		type: actionTypes.HIDE_CONFERENCE
	})
);

export const showAddCustomer = () => (
	dispatch => dispatch({
		type: actionTypes.SHOW_ADD_CUSTOMER
	})
);
export const hideAddCustomer = () => (
	dispatch => dispatch({
		type: actionTypes.HIDE_ADD_CUSTOMER
	})
);

export const showEditCustomer = customer => (
	dispatch => dispatch({
		type: actionTypes.SHOW_EDIT_CUSTOMER,
		payload: customer
	})
);
export const hideEditCustomer = () => (
	dispatch => dispatch({
		type: actionTypes.HIDE_EDIT_CUSTOMER
	})
);

export const clearUpdateInterface = interactionHash => (
	dispatch => dispatch({
		type: actionTypes.CLEAR_UPDATE_INTERFACE,
		payload: interactionHash
	})
);

export const addNotification = notification => (
	dispatch => dispatch({
		type: actionTypes.ADD_NOTIFICATION,
		payload: {
			...notification,
			id: notification.id !== undefined ? `${notification.id}-${new Date().getTime()}` : new Date().getTime(),
			automaticClose: notification.automaticClose !== undefined ? notification.automaticClose : true
		}
	})
);
export const removeNotification = notificationId => (
	dispatch => dispatch({
		type: actionTypes.REMOVE_NOTIFICATION,
		payload: notificationId
	})
);

export const showEditAgent = () => dispatch => (
	dispatch({
		type: actionTypes.SHOW_EDIT_AGENT
	})
);

export const hideEditAgent = () => dispatch => (
	dispatch({
		type: actionTypes.HIDE_EDIT_AGENT
	})
);

export const showBreakStatus = () => dispatch => (
	dispatch({
		type: actionTypes.SHOW_BREAK_STATUS
	})
);

export const hideBreakStatus = () => dispatch => (
	dispatch({
		type: actionTypes.HIDE_BREAK_STATUS
	})
);
