/* eslint-disable no-empty-pattern */
/* eslint-disable no-console */
/* eslint-disable no-param-reassign */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { defineMessages, injectIntl } from 'react-intl';
import Toast from '../../Sirius/Toast';
import './index.sass';
import BreakStatusTimer from '../BreakStatusTimer';

const messages = defineMessages({
	pausedFor: {
		id: 'breakStatus.breakTime.pausedFor',
		defaultMessage: 'Em pausa por:'
	}
});
const BreakToast = ({ intl }) => {
	const [isVisible, setIsVisible] = useState(true);
	const [isYellow, setIsYellow] = useState(false);

	const breakTimer = JSON.parse(window.localStorage.getItem('breakTimer'));

	useEffect(() => {
		const checkTimer = () => {
			if (breakTimer && breakTimer.lessOneMinute) {
				setIsYellow(true);
			}
		};

		checkTimer();

		const interval = setInterval(checkTimer, 1000);

		return () => clearInterval(interval);
	}, [breakTimer, isYellow]);


	const handleClose = () => {
		setIsVisible(false);
	};

	if (!isVisible) return null;

	return (
		<Toast.Container type={isYellow ? 'warning' : 'success'} className="BreakToast">
			<Toast.Content>
				<div className="BreakToast__label">
					<div>
						{intl.formatMessage(messages.pausedFor)}
						<span style={{ marginLeft: '4px' }}><BreakStatusTimer /></span>
					</div>
				</div>
			</Toast.Content>
			<Toast.Action>
				<Toast.CloseButton onClick={handleClose} />
			</Toast.Action>
		</Toast.Container>
	);
};

BreakToast.propTypes = {
	intl: PropTypes.shape({
		formatMessage: PropTypes.func.isRequired
	}).isRequired
};


export default injectIntl(BreakToast);
