
/* eslint-disable */

var OMZ_TTV_API = process.env.OMZ_TTV_API;

export var TtvApi = function (aoba_url) {
	this.aoba_url = aoba_url;
	this.events = {};
	this.token = null;
	this.owner = false;
	this.connected = false;
	this.currentInteraction = null;
	this.onTransfer = false;
	this.conferenceId = null;
	this.chamadaId = '';
	this.refusedId = '';
	this.callingNumber = '';
	this.muted = false;
	this.numeroChegando = '';
  };

  TtvApi.prototype = {
	isOwner: function () {
	  return this.owner;
	},
	hasWebphone: function () {
	  return typeof(webphone) !== 'undefined' && webphone !== null;
	},
	connect: function (token, phoneExtension) {
	  if (this.hasWebphone()) {
			webphone.contentWindow.postMessage({ message : 'conectar' }, '*');
			this.connected = true;
	  } else {
			this.conferenceId = null;
	  	this.startTotalVoice(token, phoneExtension);
		}
	},
	disconnect: function (token, phoneExtension) {
	  this.conferenceId = null;
	  if (this.hasWebphone()) {
			webphone.contentWindow.postMessage({ message : 'desconectar', chamada_id: this.chamadaId || null }, '*');
			this.connected = false;
	  }
	},
	answer: function () {
	  webphone.contentWindow.postMessage({ message: 'answer' }, '*');
	},
	callTo: function (number){
	  this.owner = true;
	  try {
		webphone.contentWindow.postMessage({ message: 'chamaNumero', 'numero': number }, '*');
		return true;
	  } catch (error) {
		return false;
	  }
	},
	callConf: function (agentNumber, customerNumber) {
		this.owner = true;
		try {
		  var self = this;
		  var xhr = new window.XMLHttpRequest();
		  xhr.open('POST', `${OMZ_TTV_API}/conferencia`);
		  xhr.setRequestHeader('Content-Type', 'application/json');
		  xhr.setRequestHeader('Access-Token', this.token);
		  xhr.onreadystatechange = function () {
			if (xhr.readyState === 4) {
			  if (xhr.status === 200) {
				self.conferenceId = JSON.parse(xhr.response).dados.id;
				setTimeout(() => {
				  self.addNumber(agentNumber);
				  setTimeout(() => {
					self.addNumber(customerNumber);
				  }, 500);
				}, 500);
			  }
			}
		  };
		  xhr.send();
		  return true;
		} catch (error) {
		  return false;
		}
	  },
	addNumber: function (number) {
	  try {
		const body = JSON.stringify({ numero: number, gravar_audio: true })
		var xhr = new window.XMLHttpRequest();
		xhr.open('POST', `${OMZ_TTV_API}/conferencia/${this.conferenceId}`);
		xhr.setRequestHeader('Content-Type', 'application/json');
		xhr.setRequestHeader('Access-Token', this.token);
		return xhr.send(body);
	  } catch (error) {
		return false;
	  }
	},
	hangup: function () {
	  this.owner = false;
	  this.chamadaId = ''
	  if (this.conferenceId) {
		var xhr = new window.XMLHttpRequest();
		xhr.open('DELETE', `${OMZ_TTV_API}/conferencia/${this.conferenceId}`);
		xhr.setRequestHeader('Content-Type', 'application/json');
		xhr.setRequestHeader('Access-Token', this.token);
		xhr.send();
	  }
	  this.conferenceId = null;
	  this.numeroChegando = '';
	  webphone.contentWindow.postMessage({ message: 'hangup' }, '*');
	},
	transfer: function (number) {
	  webphone.contentWindow.postMessage({ message: 'transferir', 'numeroTelefone': number.toString() }, '*');
	},
	sendDtmf: function (dtmf) {
	  webphone.contentWindow.postMessage({ message: 'enviaDTMF', 'dtmf': dtmf }, '*');
	},
	mute: function () {
	  webphone.contentWindow.postMessage({ message: 'mute' }, '*');
	},
	createConf: function (params) {
	  const body = JSON.stringify({ totalVoiceToken: params.totalVoiceToken, from: params.from, to: params.to })
	  var xhr = new window.XMLHttpRequest();
	  xhr.open('POST', `${this.aoba_url}/phone_conferences?accountId=${params.accountId}`);
	  xhr.setRequestHeader('Content-Type', 'application/json');
	  xhr.setRequestHeader('Authorization', params.token);
	  xhr.send(body);
	},
	addToConf: function (params) {
	  const body = JSON.stringify({ totalVoiceToken: params.totalVoiceToken, to: params.to, interactionHash: params.interactionHash })
	  var xhr = new window.XMLHttpRequest()
	  xhr.open('POST', `${this.aoba_url}/phone_conferences/add?accountId=${params.accountId}`);
	  xhr.setRequestHeader('Content-Type', 'application/json');
	  xhr.setRequestHeader('Authorization', params.token);
	  xhr.send(body);
	},
	startTotalVoice: function (token, phoneExtension) {
	  var self = this;
	  if (!this.connected) {
		this.token = token;
		var xhr = new window.XMLHttpRequest();
		xhr.open('GET', `${OMZ_TTV_API}/webphone?tipo=hidden&ramal=${phoneExtension}&fechar_fim=false`);
		xhr.setRequestHeader('Accept', 'application/json');
		xhr.setRequestHeader('Access-Token', token);
		xhr.timeout =  0;
		xhr.withCredentials =  false;
		xhr.overrideMimeType && xhr.overrideMimeType('text/xml');
		xhr.onreadystatechange = function() {
		  if (xhr.readyState === 4) {
			if (xhr.status === 200) {
			  var script = document.createElement('script');
			  script.src = JSON.parse(xhr.response).dados.url;
			  script.onload = (function(_this) {
				return function() {
				  window.onmessage = function(e) {
					  if (e.data.message == 'chegandoChamada') {
						self.events['newCall'](e.data);
						this.owner = false;
					  }
					  //conectado, desconectado, chamando, encerrada, conversando
					  if (e.data.message == 'status') {
						self.events['status'](e.data.status);
					  }
					  //o id é único e pode ser utilizado na api para recuperação de mais informações (get na api ou webhooks)
					  if (e.data.message == 'chamada_id') {
						  self.events['acceptedCall'](window.ttvSdk.callingNumber, e.data.chamada_id);
					  }
					  //os erros são finais
					  if (e.data.message == 'status_erro') {
						self.events['error'](e.data.status_erro);
					  }
				  };
				  return console.log('Webphone loaded');
				};
			  })(this);
			  document.head.appendChild(script);

			}
		  }
		};
		this.connected = true;
		return xhr.send();
	  }
	},
	on: function (customEvent, callback) {
	  if (typeof callback === 'function') {
		this.events[customEvent] = callback;
	  }
	}
  };

  if (!window.ttvSdk) {
	window.ttvSdk = new TtvApi();
}

  /* eslint-enable */
