import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { defineMessages, injectIntl } from 'react-intl';
import { SHA256 } from 'crypto-js';

import './index.sass';
import * as actions from '../../../store/actions';
import Modal from '../../Molecules/Modal';
import UpdateAvatar from '../../Molecules/UpdateAvatar';
import Text from '../../Atoms/Text';
import InputText from '../../Molecules/Inputs/Form';
import Button from '../../Molecules/Buttons/Button';
import Spinner from '../../Atoms/Spinner';
import {
	isAuth0Enabled, isZenviaDomain, mapFormFieldItem, checkValidity
} from '../../../shared/utility';

const messages = defineMessages({
	currentPassword: {
		id: 'editAgent.currentPassword',
		defaultMessage: 'Senha atual'
	},
	newPassword: {
		id: 'editAgent.newPassword',
		defaultMessage: 'Nova Senha'
	},
	confirmPassword: {
		id: 'editAgent.confirmPassword',
		defaultMessage: 'Confirmar senha'
	},
	save: {
		id: 'editAgent.save',
		defaultMessage: 'Salvar'
	},
	admin: {
		id: 'editAgent.admin',
		defaultMessage: 'Administrador'
	},
	agent: {
		id: 'editAgent.agent',
		defaultMessage: 'Atendente'
	},
	updatePhoto: {
		id: 'editAgent.updatePhoto',
		defaultMessage: 'Alteração de foto'
	},
	successUpdatedPhoto: {
		id: 'editAgent.successUpdatedPhoto',
		defaultMessage: 'Foto alterada'
	},
	errorUpdatedPhoto: {
		id: 'editAgent.errorUpdatedPhoto',
		defaultMessage: 'Foto não alterada, tente novamente'
	},
	updatePassword: {
		id: 'editAgent.updatePassword',
		defaultMessage: 'Alteração de senha'
	},
	successUpdatedPassword: {
		id: 'editAgent.successUpdatedPassword',
		defaultMessage: 'Senha alterada com sucesso'
	},
	errorUpdatedPassword: {
		id: 'editAgent.errorUpdatedPassword',
		defaultMessage: 'Não foi possível alterar a senha, tente novamente'
	},
	samePasswordError: {
		id: 'editAgent.samePasswordError',
		defaultMessage: 'A senha é igual a anterior'
	},
	invalidOldPassword: {
		id: 'editAgent.invalidOldPassword',
		defaultMessage: 'Senha atual incorreta'
	}
});

class EditAgent extends Component {
	state = {
		currentPassword: '',
		newPassword: '',
		confirmPassword: '',
		canUpdatePassword: false,
		agentImage: ''
	};

	componentDidMount() {
		this.configNewPasswordState();
	}

	componentDidUpdate() {
		const {
			updatePhotoStatus,
			updatePhotoClear,
			addNotification,
			intl,
			updatePasswordStatus,
			updatePasswordClear
		} = this.props;
		const updatePhotoSuccess = updatePhotoStatus.success;
		const updatePhotoError = updatePhotoStatus.error;
		const updatePasswordSuccess = updatePasswordStatus.success;
		const updatePasswordError = updatePasswordStatus.error;
		const { newPassword, confirmPassword, canUpdatePassword } = this.state;
		const { formatMessage } = intl;

		if (newPassword.value !== '' && newPassword.value === confirmPassword.value && !canUpdatePassword && newPassword.isValid && confirmPassword.isValid) {
			this.setState({ canUpdatePassword: true });
		} else if (newPassword.value !== '' && newPassword.value !== confirmPassword.value && canUpdatePassword) {
			this.setState({ canUpdatePassword: false });
		}

		if (updatePhotoSuccess || updatePhotoError) {
			const type = updatePhotoSuccess ? 'success' : 'error';
			addNotification({
				title: formatMessage(messages.updatePhoto),
				content: formatMessage(messages[`${type}UpdatedPhoto`]),
				type
			});
			updatePhotoClear();
		}

		if (updatePasswordSuccess || updatePasswordError) {
			const type = updatePasswordSuccess ? 'success' : 'error';
			let contentMessage = `${type}UpdatedPassword`;

			if (updatePasswordError && updatePasswordError.constructor === String) {
				switch (updatePasswordError) {
				case 'same_password':
					contentMessage = 'samePasswordError';
					break;
				case 'invalid_old_password':
					contentMessage = 'invalidOldPassword';
					break;
				default:
					break;
				}
			}

			addNotification({
				title: formatMessage(messages.updatePassword),
				content: formatMessage(messages[contentMessage]),
				type
			});

			updatePasswordClear();
		}
	}

	configNewPasswordState = () => {
		this.setState({
			newPassword: mapFormFieldItem(
				{
					id: 'newPassword',
					name: 'newPassword',
					isRequired: true,
					type: 'password',
					isUnique: true,
					label: 'editAgent.newPassword',
					isMultiple: false
				},
				''
			)
		});
		this.setState({
			confirmPassword: mapFormFieldItem(
				{
					id: 'confirmPassword',
					name: 'confirmPassword',
					isRequired: true,
					type: 'password',
					isUnique: true,
					label: 'editAgent.confirmPassword',
					isMultiple: false
				},
				''
			)
		});
	}

	onChangeInput = (e, name) => this.setState({ [name]: e.target.value });

	newPasswordChangedHandler = (event) => {
		const { newPassword } = this.state;
		const { intl } = this.props;
		const { isValid, validationMessage } = checkValidity(
			event.target.value,
			newPassword.validation,
			newPassword.label
		);

		let formattedMessage = '';
		if (Object.keys(validationMessage).length > 0) {
			const { intlObject } = validationMessage;
			formattedMessage = `${intl.formatMessage(intlObject)}`;
		}

		this.setState({
			newPassword: {
				...newPassword,
				value: event.target.value,
				validationMessage: formattedMessage,
				isValid,
				wasTouched: true
			}
		});
	}

	confirmPasswordChangedHandler = (event) => {
		const { confirmPassword } = this.state;
		const { intl } = this.props;
		const { isValid, validationMessage } = checkValidity(
			event.target.value,
			confirmPassword.validation,
			confirmPassword.label
		);

		let formattedMessage = '';
		if (Object.keys(validationMessage).length > 0) {
			const { intlObject } = validationMessage;
			formattedMessage = `${intl.formatMessage(intlObject)}`;
		}

		this.setState({
			confirmPassword: {
				...confirmPassword,
				value: event.target.value,
				validationMessage: formattedMessage,
				isValid,
				wasTouched: true
			}
		});
	}

	onSaveAgentInfo = () => {
		const { updatePhoto, agent, updatePassword } = this.props;
		const {
			agentImage,
			currentPassword,
			newPassword,
			confirmPassword
		} = this.state;
		const { account, id, auth0UserID } = agent;

		if (agentImage !== '') {
			updatePhoto({ photo: agentImage, accountId: account.id, agentId: id });
			this.setState({ agentImage: '' });
		}

		const showCurrentPasswordField = currentPassword || isAuth0Enabled();

		if (showCurrentPasswordField && newPassword.value && confirmPassword.value && newPassword.value === confirmPassword.value) {
			updatePassword({
				accountId: account.id,
				email: agent.email,
				agentId: id,
				oldPassword: SHA256(currentPassword).toString(),
				password: newPassword.value,
				auth0UserID
			});
		}

		this.setState({
			currentPassword: ''
		});
		this.configNewPasswordState();
	}

	onUpdateAgentImage = agentImage => this.setState({ agentImage });

	render() {
		const {
			agent,
			intl,
			hideEditAgent,
			updatePhotoStatus
		} = this.props;
		const {
			agentImage,
			canUpdatePassword,
			confirmPassword,
			currentPassword,
			newPassword
		} = this.state;
		const { name = '', photo = '', subscription = '' } = agent;
		const { formatMessage } = intl;
		const { loading } = updatePhotoStatus;
		const userLabel = ['ADMIN', 'ACCESS_SUPPORT'].includes(subscription.toUpperCase()) ? formatMessage(messages.admin) : formatMessage(messages.agent);
		const showChangePassword = !['ADMIN', 'ACCESS_SUPPORT'].includes(agent.profile);
		const auth0Enabled = isAuth0Enabled() && isZenviaDomain();

		return (
			<Modal onClose={hideEditAgent} className="EditAgentModal" isDark>
				<div className="EditAgent">
					<div className="EditAgent__user">
						<div className="EditAgent__avatar">
							{loading ? (
								<Spinner />
							) : (
								<UpdateAvatar
									image={agentImage || photo}
									name={name}
									onUpdateAvatar={this.onUpdateAgentImage}
									updated={agentImage !== ''}
								/>
							)}
						</div>
						<div className="EditAgent__user__info">
							<Text size="large">{name}</Text>
							<Text size="small">{userLabel}</Text>
						</div>
					</div>
					{
						showChangePassword && (
							<div className="EditAgent__password">
								{!auth0Enabled
								&& (
									<InputText
										label={formatMessage(messages.currentPassword)}
										value={currentPassword}
										onChange={e => this.onChangeInput(e, 'currentPassword')}
										showIcon={false}
										type="password"
										valid
										isDark
									/>
								)}
								<InputText
									label={newPassword.isRequired ? `${formatMessage(messages.newPassword)}*` : formatMessage(messages.newPassword)}
									value={newPassword.value}
									onChange={e => this.newPasswordChangedHandler(e)}
									showIcon={false}
									type="password"
									validationMessage={newPassword.validationMessage}
									valid={newPassword.isValid}
									touched={newPassword.wasTouched}
									isDark
								/>
								<InputText
									label={newPassword.isRequired ? `${formatMessage(messages.confirmPassword)}*` : formatMessage(messages.confirmPassword)}
									value={confirmPassword.value}
									onChange={e => this.confirmPasswordChangedHandler(e)}
									showIcon={false}
									type="password"
									validationMessage={confirmPassword.validationMessage}
									valid={confirmPassword.isValid}
									touched={confirmPassword.wasTouched}
									isDark
								/>
							</div>
						)
					}

					<Button
						active={(agentImage !== '' || canUpdatePassword)}
						click={this.onSaveAgentInfo}
					>
						{formatMessage(messages.save)}
					</Button>
				</div>
			</Modal>
		);
	}
}

EditAgent.propTypes = {
	agent: PropTypes.shape({
		name: PropTypes.string,
		email: PropTypes.string,
		photo: PropTypes.string,
		settings: PropTypes.shape({}),
		subscription: PropTypes.string,
		account: PropTypes.shape({
			id: PropTypes.number
		}),
		profile: PropTypes.string,
		id: PropTypes.number,
		auth0UserID: PropTypes.string
	}).isRequired,
	hideEditAgent: PropTypes.func.isRequired,
	intl: PropTypes.shape({
		formatMessage: PropTypes.func
	}).isRequired,
	updatePhoto: PropTypes.func.isRequired,
	updatePhotoStatus: PropTypes.shape({
		loading: PropTypes.bool,
		success: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
	}).isRequired,
	addNotification: PropTypes.func.isRequired,
	updatePhotoClear: PropTypes.func.isRequired,
	updatePassword: PropTypes.func.isRequired,
	updatePasswordStatus: PropTypes.shape({
		loading: PropTypes.bool,
		success: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
	}).isRequired,
	updatePasswordClear: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
	agent: state.agent.info,
	updatePhotoStatus: state.agent.updatePhotoStatus,
	updatePasswordStatus: state.agent.updatePasswordStatus
});

const mapActionsToProps = dispatch => ({
	hideEditAgent: () => dispatch(actions.hideEditAgent()),
	updatePhoto: requestInfo => dispatch(actions.updatePhoto(requestInfo)),
	addNotification: notification => dispatch(actions.addNotification(notification)),
	updatePhotoClear: () => dispatch(actions.updatePhotoClear()),
	updatePassword: requestInfo => dispatch(actions.updatePassword(requestInfo)),
	updatePasswordClear: () => dispatch(actions.updatePasswordClear())
});

export default connect(mapStateToProps, mapActionsToProps)(injectIntl(EditAgent));
