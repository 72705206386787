import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';

import './index.sass';
import ImgSvg from '../../../Atoms/ImgSvg';
import Spinner from '../../../Atoms/Spinner';
import Text from '../../../Atoms/Text';

class CommonInput extends Component {
	constructor(props) {
		super(props);
		this.input = createRef();
	}

	onFocusInput = () => this.input.current.focus();

	render() {
		const {
			placeholder,
			value,
			onChange,
			disabled,
			loading,
			clear,
			showIcon = true,
			type = 'text',
			iconType = 'search',
			label
		} = this.props;

		const icon = loading ? <Spinner /> : <ImgSvg name={iconType} />;

		return (
			<div className={`CommonInput ${clear ? 'CommonInput--clear' : ''}`}>
				{label && <Text>{label}</Text>}
				<div className="CommonInput__input">
					<div className="CommonInput__image">
						{showIcon && (icon)}
					</div>
					<input
						value={value}
						placeholder={placeholder}
						onChange={onChange}
						type={type}
						disabled={disabled}
						ref={this.input}
					/>
				</div>
			</div>
		);
	}
}

CommonInput.propTypes = {
	value: PropTypes.string.isRequired,
	placeholder: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
	disabled: PropTypes.bool,
	loading: PropTypes.bool,
	clear: PropTypes.bool,
	showIcon: PropTypes.bool,
	type: PropTypes.string,
	iconType: PropTypes.string,
	label: PropTypes.string
};

export default CommonInput;
