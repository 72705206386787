import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, injectIntl } from 'react-intl';

import './index.sass';
import CircleDisplay from '../../Atoms/CircleDisplay';
import Text from '../../Atoms/Text';

const messages = defineMessages({
	video: { id: 'videoNotification.video', defaultMessage: 'vídeo' }
});

const VideoNotification = ({ intl }) => (
	<div className="VideoNotification">
		<CircleDisplay color="white" small animate />
		<Text size="smallest">{intl.formatMessage(messages.video)}</Text>
	</div>
);

VideoNotification.propTypes = {
	intl: PropTypes.shape({
		formatMessage: PropTypes.func
	}).isRequired
};

export default injectIntl(VideoNotification);
