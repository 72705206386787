import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { defineMessages, injectIntl } from 'react-intl';

import './index.sass';
import * as actions from '../../../store/actions';
import ActionsHeader from '../ActionsHeader';
import { useMountEffect } from '../../../shared/utility';
import Wrapper from '../../Atoms/Wrapper';
import Text from '../../Atoms/Text';
import Backdrop from '../Backdrop';
import InfiniteList from '../InfiniteList';

const messages = defineMessages({
	templateTitle: {
		id: 'chatInput.whatsappCall.templateTitle',
		defaultMessage: 'Escolha o template'
	},
	templateItemTitle: {
		id: 'chatInput.whatsappCall.templateItemTitle',
		defaultMessage: 'Template'
	},
	templateItemDescription: {
		id: 'chatInput.whatsappCall.templateItemDescription',
		defaultMessage: 'Ligação Whatsapp'
	}
});

const ChooseWhatsappTemplate = ({
	intl,
	agent,
	onClose,
	fetchAnswers,
	currentInteraction,
	onSelectWhatsapTemplate
}) => {
	const { info } = agent;
	const { interactionHash } = currentInteraction;

	const templates = [{
		id: 1,
		name: intl.formatMessage(messages.templateItemTitle),
		body: intl.formatMessage(messages.templateItemDescription)
	}];

	useMountEffect(() => {
		fetchAnswers({
			accountId: info.account.id,
			departmentId: currentInteraction && currentInteraction.department && currentInteraction.department.id,
			interactionHash
		});
	});

	const shortcutItem = (template) => {
		const { name, body } = template;
		return (
			<>
				<Text>{name}</Text>
				<Text>{body}</Text>
			</>
		);
	};

	return (
		<>
			<div className="Answers">
				<ActionsHeader
					title={intl.formatMessage(messages.templateTitle)}
					type="answer"
					validAction
				/>
				<InfiniteList customClass="Answers__answers">
					{templates.map(template => (
						<Wrapper
							className="Answers__item"
							action={() => onSelectWhatsapTemplate(template)}
							key={template.id}
						>
							{shortcutItem(template)}
						</Wrapper>
					))}
				</InfiniteList>
			</div>
			<Backdrop closeAction={onClose} clear higher />
		</>
	);
};

const mapStateToProps = state => ({
	agent: state.agent
});

const mapActionsToProps = dispatch => ({
	fetchAnswers: requestInfo => dispatch(actions.fetchAnswers(requestInfo))
});

ChooseWhatsappTemplate.propTypes = {
	intl: PropTypes.shape({
		formatMessage: PropTypes.func.isRequired
	}).isRequired,
	agent: PropTypes.shape({
		info: PropTypes.shape({
			account: PropTypes.shape({
				id: PropTypes.number
			})
		})
	}).isRequired,
	onClose: PropTypes.func.isRequired,
	fetchAnswers: PropTypes.func.isRequired,
	currentInteraction: PropTypes.shape({
		interactionHash: PropTypes.string,
		department: PropTypes.shape({
			id: PropTypes.number
		})
	}).isRequired,
	onSelectWhatsapTemplate: PropTypes.func
};


export default connect(mapStateToProps, mapActionsToProps)(injectIntl(ChooseWhatsappTemplate));
