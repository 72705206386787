import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import './index.sass';
import FormInput from '../Inputs/Form';

const SearchInput = ({
	value,
	label,
	touched,
	valid,
	validationMessage,
	type = 'text',
	onKeyUp = () => {},
	disabled,
	list,
	openList,
	onSelect,
	onChange
}) => {
	const [stateValue, setStateValue] = useState('');
	useEffect(() => setStateValue(value), [value]);

	const onHandleListClick = (event, clickedValue, clickedLabel) => {
		event.preventDefault();
		setStateValue(clickedLabel);
		onSelect(clickedValue, clickedLabel);
	};

	const inputHandleChange = (event) => {
		setStateValue(event.target.value);
		onChange(event);
	};

	return (
		<div className="SearchInput">
			<FormInput
				label={label}
				value={stateValue}
				onChange={e => inputHandleChange(e)}
				touched={touched}
				valid={valid}
				validationMessage={validationMessage}
				type={type}
				onKeyUp={onKeyUp}
				disabled={disabled}
			/>
			{openList && (
				<ul className="SearchInput__list">
					{list.map(item => (
						<li key={item.value}>
							<a href={`#item-${item.value}`} onClick={event => onHandleListClick(event, item.value, item.label)}>
								{item.label}
							</a>
						</li>
					))}
				</ul>
			)}
		</div>
	);
};

SearchInput.propTypes = {
	label: PropTypes.string.isRequired,
	value: PropTypes.string,
	onChange: PropTypes.func.isRequired,
	onSelect: PropTypes.func.isRequired,
	touched: PropTypes.bool.isRequired,
	valid: PropTypes.bool.isRequired,
	validationMessage: PropTypes.string.isRequired,
	type: PropTypes.oneOf(['password', 'text']),
	onKeyUp: PropTypes.func,
	disabled: PropTypes.bool,
	list: PropTypes.arrayOf(PropTypes.shape({
		label: PropTypes.string,
		value: PropTypes.oneOfType([
			PropTypes.string,
			PropTypes.number
		]).isRequired
	})).isRequired,
	openList: PropTypes.bool
};

export default SearchInput;
