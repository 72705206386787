import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
	departments: [],
	loading: false,
	error: ''
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
	case actionTypes.FETCH_DEPARTMENTS_BEGIN:
		return updateObject(state, {
			loading: true,
			error: ''
		});
	case actionTypes.FETCH_DEPARTMENTS_SUCCESS:
		return updateObject(state, {
			loading: false,
			departments: action.payload
		});
	case actionTypes.FETCH_DEPARTMENTS_FAILED:
		return updateObject(state, {
			loading: false,
			error: 'ERROR'
		});
	default:
		return state;
	}
};

export default reducer;
