import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withAuth0 } from '@auth0/auth0-react';
import { injectIntl, defineMessages } from 'react-intl';
import PropTypes from 'prop-types';

import * as actions from '../../../store/actions';
import { checkValidity, isZenviaDomain, isAuth0Enabled } from '../../../shared/utility';
import InputText from '../../Molecules/Inputs/Form';
import Button from '../../Molecules/Buttons/Button';
import Text from '../../Atoms/Text';
import LanguageChooser from '../../Molecules/LanguageChooser';
import Logo from '../../Atoms/Logo';
import Modal from '../../Molecules/Modal';

import './index.sass';
import ImgSvg from '../../Atoms/ImgSvg';
import InitialLoader from '../../Molecules/InitialLoader';

const messages = defineMessages({
	callMessage: {
		id: 'login.callMessage',
		defaultMessage: 'Entrar na sua conta'
	},
	send: {
		id: 'login.send',
		defaultMessage: 'Entrar'
	},
	welcome: {
		id: 'login.welcome',
		defaultMessage: 'Bem-vindo à plataforma de atendimento'
	},
	resetCallMessage: {
		id: 'login.resetPassword',
		defaultMessage: 'Esqueci minha senha'
	},
	resetPasswordInfo: {
		id: 'login.resetPasswordInfo',
		defaultMessage: 'Informe o seu e-mail para receber uma nova senha'
	},
	reset: {
		id: 'login.reset',
		defaultMessage: 'Enviar senha temporária'
	},
	returnToLogin: {
		id: 'login.returnToLogin',
		defaultMessage: 'Voltar para o Login'
	},
	reseted: {
		id: 'login.reseted',
		defaultMessage: 'Senha temporária enviada'
	},
	unauthorized: {
		id: 'login.unauthorized',
		defaultMessage: 'Email ou senha inválidos'
	},
	emailNotFound: {
		id: 'login.emailNotFound',
		defaultMessage: 'Email não encontrado'
	},
	unauthorizedContent: {
		id: 'login.unauthorizedContent',
		defaultMessage: 'Verifique os dados inseridos'
	},
	auth0LoginError: {
		id: 'login.auth0LoginError',
		defaultMessage: 'Acesso inválido ou não autorizado.\nTente o login novamente ou entre em contato com o Administrador'
	},
	auth0LoginErrorButton: {
		id: 'login.auth0LoginErrorButton',
		defaultMessage: 'Novo login'
	}
});

class Login extends Component {
	state = {
		loginFormControls: [
			{
				isValid: false,
				label: 'Email *',
				name: 'username',
				validation: {
					isEmail: true,
					isRequired: true
				},
				validationMessage: '',
				value: '',
				wasTouched: false,
				isPassword: false
			},
			{
				isValid: false,
				label: 'Password *',
				name: 'password',
				validation: {
					isRequired: true
				},
				validationMessage: '',
				value: '',
				wasTouched: false,
				isPassword: true
			}
		],
		isLoginFormValid: false,
		changedLoginForm: false,
		resetPassword: false,
		resetFormControls: [
			{
				isValid: false,
				label: 'Email *',
				name: 'username',
				validation: {
					isEmail: true,
					isRequired: true
				},
				validationMessage: '',
				value: '',
				wasTouched: false,
				isPassword: false
			}
		],
		isResetFormValid: false,
		changedResetForm: false,
		sendReset: false,
		notifiedInvalidLogin: false
	}

	componentDidUpdate() {
		const { authUnauthorized = true, addNotification, intl } = this.props;
		const { notifiedInvalidLogin } = this.state;
		const { formatMessage } = intl;

		if (authUnauthorized && !notifiedInvalidLogin) {
			addNotification({
				title: formatMessage(messages.unauthorized),
				content: formatMessage(messages.unauthorizedContent),
				type: 'error'
			});
			this.setState({ notifiedInvalidLogin: true });
		}

		if (!authUnauthorized && notifiedInvalidLogin) this.setState({ notifiedInvalidLogin: false });
	}

	inputChangedHandler = (event, inputIdentifier) => {
		const { loginFormControls, resetFormControls, resetPassword } = this.state;
		const { intl, resetPasswordClear } = this.props;
		const { formatMessage } = intl;

		const selectedForm = resetPassword ? resetFormControls : loginFormControls;

		const updatedControls = selectedForm.map((formItem) => {
			if (formItem.name === inputIdentifier) {
				const { isValid, validationMessage } = checkValidity(
					event.target.value, formItem.validation, formItem.label
				);

				let formattedMessage = '';
				if (Object.keys(validationMessage).length > 0) {
					const { label, intlObject } = validationMessage;
					formattedMessage = `${label} ${formatMessage(intlObject)}`;
				}

				return {
					...formItem,
					value: event.target.value,
					isValid,
					validationMessage: formattedMessage,
					wasTouched: true
				};
			}
			return formItem;
		});

		let isFormValid = true;
		updatedControls.forEach((field, controlsIndex) => {
			const { validation, wasTouched, value } = field;
			const formField = updatedControls[controlsIndex];
			if (validation.isRequired && !wasTouched && value !== '') {
				isFormValid = !formField.isValid && isFormValid;
			} else if (validation.isRequired || wasTouched) {
				isFormValid = formField.isValid && isFormValid;
			}
		});

		if (resetPassword) {
			resetPasswordClear();
			this.setState({
				resetFormControls: updatedControls,
				isResetFormValid: isFormValid,
				changedResetForm: true,
				sendReset: false
			});
		} else {
			this.setState({
				loginFormControls: updatedControls,
				isLoginFormValid: isFormValid,
				changedLoginForm: true
			});
		}
	}

	onAuth = () => {
		const { loginFormControls } = this.state;
		const { onAuth } = this.props;
		const username = loginFormControls.find(field => field.name === 'username');
		const password = loginFormControls.find(field => field.name === 'password');

		if (username.value && password.value) {
			onAuth({
				username: username.value,
				password: password.value
			});
		}

		this.setState({
			changedLoginForm: false
		});
	}

	onChangeLanguage = (value) => {
		const currentValue = localStorage.getItem('locale');

		if (currentValue !== value) {
			localStorage.setItem('locale', value);
			document.location.reload(true);
		}
	};

	onKeyUp = (e) => {
		const { isLoginFormValid } = this.state;
		if (e.key === 'Enter' && isLoginFormValid) this.onAuth();
	}

	toggleResetPassword = () => {
		const { resetPassword } = this.state;
		this.setState({ resetPassword: !resetPassword });
	}

	onReset = () => {
		const { onReset } = this.props;
		const { resetFormControls } = this.state;
		const email = resetFormControls.find(field => field.name === 'username').value;
		this.setState({ sendReset: true });
		onReset(email);
	}

	render() {
		const {
			isLoginFormValid,
			isResetFormValid,
			loginFormControls,
			resetFormControls,
			changedLoginForm,
			changedResetForm,
			resetPassword,
			sendReset
		} = this.state;
		const {
			authUnauthorized,
			authenticating,
			identity = { logo_url: '' },
			defaultIdentity,
			intl,
			loading,
			resetedPassword,
			resetingPassword,
			auth0
		} = this.props;
		const { formatMessage } = intl;

		// eslint-disable-next-line camelcase
		const { logo_url, login_color } = identity;
		if (identity.name) document.title = identity.name;
		const selectedForm = resetPassword ? resetFormControls : loginFormControls;
		const zenviaIdentity = isZenviaDomain();
		const isUsingAuth0 = (isZenviaDomain() && isAuth0Enabled());

		if (loading && isUsingAuth0) {
			return <InitialLoader />;
		}

		const renderContent = () => {
			if (isUsingAuth0) {
				return (
					<React.Fragment>
						<div
							className="Login__auth0Error"
							data-testid="component-loginError"
						>
							<p className="ErrorMessage">{formatMessage(messages.auth0LoginError)}</p>
							<Button
								active
								selected
								click={() => {
									window.FS.anonymize();
									auth0.logout();
								}}
								data-testid="component-login-logoutAuth0"
								className="ButtonError"
							>
								<Text size="bigger">
									{formatMessage(messages.auth0LoginErrorButton)}
								</Text>
							</Button>
						</div>
					</React.Fragment>
				);
			}

			return (
				<React.Fragment>
					<div
						className="Login__welcome"
						data-testid="component-loginWelcome"
					>
						<p>
							{resetPassword ? (
								formatMessage(messages.resetCallMessage)
							) : (
								formatMessage(messages.callMessage)
							)}
						</p>
						<p>{resetPassword ? formatMessage(messages.resetPasswordInfo) : `${formatMessage(messages.welcome)}.`}</p>
					</div>
					<div
						className="Login__form"
						data-testid="component-loginForm"
					>
						{
							selectedForm ? selectedForm.map((field) => {
								const {
									label, value, wasTouched, name, validationMessage, isValid, isPassword
								} = field;
								return (
									<InputText
										key={name}
										disabled={isUsingAuth0}
										label={label}
										validationMessage={validationMessage}
										value={value || ''}
										onChange={event => this.inputChangedHandler(event, name)}
										touched={wasTouched}
										valid={isValid}
										type={isPassword ? 'password' : 'text'}
										onKeyUp={this.onKeyUp}
										data-testid={name}
									/>
								);
							}) : null
						}
						{resetPassword ? (
							<Button
								active={(isResetFormValid && changedResetForm) && !isUsingAuth0}
								selected
								click={isResetFormValid && !resetedPassword ? this.onReset : () => { }}
								loading={resetingPassword}
								success={resetedPassword && sendReset}
								data-testid="component-login-submitForgotPassword"
							>
								<Text size="bigger">
									{resetedPassword && sendReset ? (
										formatMessage(messages.reseted)
									) : (
										formatMessage(messages.reset)
									)}
								</Text>
							</Button>
						) : (
							<Button
								active={(isLoginFormValid && changedLoginForm) && !isUsingAuth0}
								selected
								click={isLoginFormValid ? this.onAuth : () => { }}
								loading={authenticating}
								data-testid="component-login-submiLogin"
							>
								<Text size="bigger">{formatMessage(messages.send)}</Text>
							</Button>
						)}
						<Text size="small" className="Login__form__error">
							{authUnauthorized && !changedLoginForm && formatMessage(messages.unauthorized)}
							{sendReset && !resetingPassword && !resetedPassword && (
								formatMessage(messages.emailNotFound)
							)}
						</Text>
						<Button
							onlyText
							active={!isUsingAuth0}
							click={this.toggleResetPassword}
							data-testid={resetPassword ? formatMessage(messages.returnToLogin) : formatMessage(messages.resetCallMessage)}
						>
							{resetPassword ? (
								formatMessage(messages.returnToLogin)
							) : (
								formatMessage(messages.resetCallMessage)
							)}
						</Button>
					</div>
				</React.Fragment>
			);
		};

		return (
			<>
				<style>
					{/* eslint-disable-next-line camelcase */}
					{`:root { --color-primary: ${defaultIdentity ? '#ea4144' : login_color || '#fff'}}`}
				</style>
				<div className={`Login ${!defaultIdentity ? 'Login--color' : ''} ${zenviaIdentity ? 'Login--zenvia' : ''}`}>
					<div className="Login__background_1">
						<ImgSvg name="login-background1" />
					</div>
					<div className="Login__background_2">
						<ImgSvg name="login-background2" />
					</div>
					<Modal clear>
						<div className="Login__box">
							<div className="Login__header">
								<Logo
									/* eslint-disable-next-line camelcase */
									url={logo_url}
									defaultIdentity={defaultIdentity}
									logoName
								/>
								<LanguageChooser onChange={this.onChangeLanguage} />
							</div>
							<div className="Login__image">
								<ImgSvg name="chatImage" />
							</div>
							{renderContent()}
						</div>
					</Modal>
				</div>
			</>
		);
	}
}

const mapStateToProps = state => ({
	logo: state.interface.logo,
	loading: state.agent.loading,
	error: state.agent.error,
	authUnauthorized: state.agent.authUnauthorized,
	authenticating: state.agent.authenticating,
	identity: state.interface.identity,
	defaultIdentity: state.interface.defaultIdentity,
	resetedPassword: state.agent.resetedPassword,
	resetingPassword: state.agent.resetingPassword
});

const mapActionsToProps = dispatch => ({
	onAuth: info => dispatch(actions.auth(info)),
	onReset: email => dispatch(actions.resetPassword(email)),
	resetPasswordClear: () => dispatch(actions.resetPasswordClear()),
	addNotification: notification => dispatch(actions.addNotification(notification))
});

Login.propTypes = {
	auth0: PropTypes.shape({
		user: PropTypes.shape({
			name: PropTypes.string,
			email: PropTypes.string
		}),
		isAuthenticated: PropTypes.bool,
		loginWithRedirect: PropTypes.func,
		logout: PropTypes.func,
		getAccessTokenSilently: PropTypes.func
	}).isRequired,
	intl: PropTypes.shape({
		formatMessage: PropTypes.func.isRequired
	}).isRequired,
	loading: PropTypes.bool.isRequired,
	onAuth: PropTypes.func.isRequired,
	authUnauthorized: PropTypes.bool,
	authenticating: PropTypes.bool,
	identity: PropTypes.shape({
		logo_url: PropTypes.string,
		login_color: PropTypes.string,
		name: PropTypes.string
	}),
	defaultIdentity: PropTypes.bool,
	onReset: PropTypes.func.isRequired,
	resetingPassword: PropTypes.bool,
	resetedPassword: PropTypes.bool,
	resetPasswordClear: PropTypes.func.isRequired,
	addNotification: PropTypes.func.isRequired
};

export default withAuth0(connect(mapStateToProps, mapActionsToProps)(injectIntl(Login)));
