import React from 'react';
import PropTypes from 'prop-types';

import './index.sass';
import { ZENVIA_LOGO } from '../../../shared/consts';

const Logo = ({ url = '', defaultIdentity }) => (
	<div className="Logo" data-testid="component-logo">
		<img src={defaultIdentity ? ZENVIA_LOGO : url} alt="logo" />
	</div>
);

Logo.propTypes = {
	url: PropTypes.string,
	defaultIdentity: PropTypes.bool
};

export default Logo;
