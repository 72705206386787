import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Auth0Provider, withAuth0, withAuthenticationRequired } from '@auth0/auth0-react';

import { isZenviaDomain, isAuth0Enabled } from './shared/utility';
import * as actions from './store/actions';

import App from './App';
import InitialLoader from './components/Molecules/InitialLoader';

class AppAuth extends Component {
	constructor() {
		super();
		this.state = {
			isTokenReceived: false
		};
	}

	// eslint-disable-next-line camelcase
	UNSAFE_componentWillMount() {
		const { verifyToken } = this.props;
		const params = (new URL(document.location)).searchParams;
		const autologin = params && params.get('autologin');

		window.addEventListener('message', (e) => {
			const token = e.data && e.data.token;
			if (token && autologin) {
				sessionStorage.clear();
				sessionStorage.setItem('omz_token', token);
				this.setState({ isTokenReceived: true });
				verifyToken(token, false, true);
				window.history.replaceState(null, null, window.location.pathname);
			}
		}, false);

		if (window.opener && !sessionStorage.getItem('omz_token')) {
			window.opener.postMessage('ready', '*');
		}
	}

	render() {
		if (!isAuth0Enabled() || !isZenviaDomain()) {
			return <App />;
		}

		const { loading } = this.props;
		const { isTokenReceived } = this.state;
		const AppComponent = isTokenReceived ? App : withAuthenticationRequired(App, {
			onRedirecting: () => <InitialLoader />
		});

		if (isTokenReceived && loading) {
			return <InitialLoader />;
		}

		return (
			<Auth0Provider
				domain={`${process.env.OMZ_SSO_DOMAIN}`}
				clientId={`${process.env.OMZ_SSO_CLIENT_ID}`}
				redirectUri={window.location.origin}
			>
				<AppComponent />
			</Auth0Provider>
		);
	}
}

const mapActionsToProps = dispatch => ({
	verifyToken: (token, setOnlyChatOrPhone, isLogin) => dispatch(actions.verifyToken(token, setOnlyChatOrPhone, isLogin))
});

const mapStateToProps = state => ({
	loading: state.agent.loading
});

AppAuth.propTypes = {
	auth0: PropTypes.shape({
		user: PropTypes.shape({
			name: PropTypes.string,
			email: PropTypes.string
		}),
		isAuthenticated: PropTypes.bool,
		loginWithRedirect: PropTypes.func,
		logout: PropTypes.func,
		getAccessTokenSilently: PropTypes.func
	}).isRequired,
	loading: PropTypes.bool.isRequired,
	verifyToken: PropTypes.func.isRequired
};

export default withAuth0(connect(mapStateToProps, mapActionsToProps)(AppAuth));
