import React, {
	useRef,
	useCallback,
	useState,
	useEffect
} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl, defineMessages } from 'react-intl';

import './index.css';
import * as actions from '../../../store/actions';
import { useMountEffect } from '../../../shared/utility';
import TagItem from '../../Molecules/TagItem';
import CommomInput from '../../Molecules/Inputs/Common';
import ActionsHeader from '../../Molecules/ActionsHeader';
import Backdrop from '../../Molecules/Backdrop';
import Modal from '../../Molecules/Modal';
import Button from '../../Molecules/Buttons/Button';
import Text from '../../Atoms/Text';
import ImgSvg from '../../Atoms/ImgSvg';

const messages = defineMessages({
	select: {
		id: 'tags.select',
		defaultMessage: 'Adicionar tags ao atendimento'
	},
	search: {
		id: 'tags.search',
		defaultMessage: 'Busque por tag'
	},
	save: {
		id: 'tags.save',
		defaultMessage: 'Salvar'
	},
	subtag: {
		id: 'tags.subtag',
		defaultMessage: 'Subtag'
	},
	addSubtags: {
		id: 'tags.subtags.add',
		defaultMessage: 'Adicionar tags'
	},
	selectSubtags: {
		id: 'tags.subtags.select',
		defaultMessage: 'Selecione uma opção'
	}
});

const Tags = ({
	currentInteraction,
	currentInteractionHash,
	selectedTags = { tags: [] },
	onSetTag,
	onFetchTags,
	onFetchSubtags,
	agent,
	onChangeInputTags,
	removeTag,
	blockRemoveTag,
	updateTags,
	updateTagsStatus = {},
	inputTag,
	intl,
	onlyShowValue,
	onClose
}) => {
	const input = useRef();
	const { formatMessage } = intl;
	const { tags, subtags1 = [], subtags2 = [] } = currentInteraction;

	useMountEffect(() => {
		const { department } = currentInteraction;
		const departmentId = department && department.id;

		onFetchTags({
			accountId: agent.info.account.id,
			interactionHash: currentInteractionHash,
			departmentId
		});

		if (currentInteraction.interactionTags && selectedTags.tags.length === 0) {
			currentInteraction.interactionTags.map(tag => (
				onSetTag({ hash: currentInteractionHash, tag })
			));
		}

		if (input.current) input.current.onFocusInput();
	});

	useEffect(() => {
		document.addEventListener('keyup', (e) => {
			if (e.which === 27) onClose();
		});
	}, [onClose]);

	const [showModal, setShowModal] = useState(false);
	const [valueTag, setValueTag] = useState('');
	const [valueSubtag1, setValueSubtag1] = useState('');
	const [valueSubtag2, setValueSubtag2] = useState('');

	const onCloseModal = () => {
		setShowModal(false);
		setValueTag('');
		setValueSubtag1('');
		setValueSubtag2('');
	};

	const onFetchDynamicTags = useCallback((inputValue, departmentId) => {
		if (input.current && input.current.input && input.current.input.current) {
			setTimeout(() => {
				if ((input.current
					&& input.current.input
					&& input.current.input.current
					&& input.current.input.current.value === inputValue && inputValue.length > 2)) {
					onFetchTags({
						search: inputValue,
						accountId: agent.info.account.id,
						interactionHash: currentInteractionHash,
						departmentId
					});
				}
			}, 1200);
		}
	}, [agent.info.account.id, currentInteractionHash, onFetchTags]);

	const onFetchDynamicSubtags = useCallback((parentId, level = 1) => {
		if (parentId) {
			onFetchSubtags({
				level,
				parentId,
				interactionHash: currentInteractionHash
			});
		}
	}, [currentInteractionHash, onFetchSubtags]);

	const onType = useCallback((e) => {
		const inputValue = e.target.value;
		const { department } = currentInteraction;
		const departmentId = department && department.id;

		onChangeInputTags({ hash: currentInteractionHash, value: inputValue });
		if (inputValue === '') {
			return onFetchTags({
				search: inputValue,
				accountId: agent.info.account.id,
				interactionHash: currentInteractionHash,
				departmentId
			});
		}
		return onFetchDynamicTags(inputValue, departmentId);
	}, [
		agent.info.account.id,
		currentInteraction,
		currentInteractionHash,
		onChangeInputTags,
		onFetchDynamicTags,
		onFetchTags
	]);

	const onDynamicUpdateTags = useCallback((currentTags) => {
		setTimeout(() => {
			updateTags({
				agentId: agent.info.id,
				interactionHash: currentInteractionHash,
				tags: currentTags,
				accountId: agent.info.account.id
			});
		}, 500);
	}, [agent.info.account.id, agent.info.id, currentInteractionHash, updateTags]);

	const findTag = (id, tagsFind, selectedsFind) => {
		const selectedTag = tagsFind.find(tag => tag.id === id);
		const existTag = selectedsFind.some(tag => tag.id === id);

		return [
			existTag,
			selectedTag
		];
	};

	const onSelectTag = useCallback((id) => {
		const [existTag, selectedTag] = findTag(id, tags, selectedTags.tags);

		if (selectedTag.subtags > 0) {
			onFetchDynamicSubtags(selectedTag.id);
			setValueTag(selectedTag.id);
			setShowModal(true);
		} else if (!existTag) {
			onSetTag({ hash: currentInteractionHash, tag: selectedTag });
			onDynamicUpdateTags([...selectedTags.tags, selectedTag]);
		}

		input.current.onFocusInput();
	}, [currentInteractionHash, onDynamicUpdateTags, onFetchDynamicSubtags, onSetTag, selectedTags, tags]);

	const onSaveTag = useCallback(() => {
		let existTag;
		let selectedTag;

		if (valueSubtag2) {
			const id = Number(valueSubtag2);
			[existTag, selectedTag] = findTag(id, subtags2, selectedTags.tags);
		} else if (valueSubtag1) {
			const id = Number(valueSubtag1);
			[existTag, selectedTag] = findTag(id, subtags1, selectedTags.tags);
		} else {
			const id = Number(valueTag);
			[existTag, selectedTag] = findTag(id, tags, selectedTags.tags);
		}

		if (!existTag) {
			onSetTag({ hash: currentInteractionHash, tag: selectedTag });
			onDynamicUpdateTags([...selectedTags.tags, selectedTag]);
		}

		onCloseModal();

		input.current.onFocusInput();
	}, [onDynamicUpdateTags, onSetTag, selectedTags, currentInteractionHash, tags, subtags1, subtags2, valueTag, valueSubtag1, valueSubtag2]);

	const onRemoveTag = useCallback((id) => {
		removeTag({ hash: currentInteractionHash, id });
		onDynamicUpdateTags(selectedTags.tags.filter(tag => tag.id !== id));
		input.current.onFocusInput();
	}, [currentInteractionHash, onDynamicUpdateTags, removeTag, selectedTags.tags]);

	const onChangeSubtag1 = (event) => {
		const { value } = event.target;

		onFetchDynamicSubtags(value, 2);

		setValueSubtag1(value);
		setValueSubtag2('');
	};

	const onChangeSubtag2 = (event) => {
		setValueSubtag2(event.target.value);
	};

	const selectSubtags = (onChange, subtags, value, label, disabled = false) => (
		<div className="Select">
			<Text className="Select__label">{label}</Text>
			<div className="Select__select">
				<select onChange={e => onChange(e)} value={value} disabled={disabled}>
					<input />
					<option value="" disabled>{formatMessage(messages.selectSubtags)}</option>
					{subtags.map(subtag => <option key={subtag.id} value={String(subtag.id)}>{subtag.name}</option>)}
				</select>
				<ImgSvg className="Select__arrow" name="arrow" />
			</div>
		</div>
	);

	const [value, setValue] = useState('');
	useEffect(() => setValue(inputTag ? inputTag.value : ''), [inputTag]);

	const [currentTags, setCurrentTags] = useState([]);
	useEffect(() => setCurrentTags(tags), [tags, value]);

	return (
		<>
			<div className="Tags">
				<ActionsHeader
					title={!onlyShowValue ? formatMessage(messages.select) : ''}
					validAction={selectedTags && selectedTags.tags.length > 0}
					loading={updateTagsStatus.loading}
					success={updateTagsStatus.success}
					error={updateTagsStatus.error}
				/>
				{
					selectedTags.tags.length > 0 && (
						<div className="Tags__selected">
							{
								selectedTags.tags.map(tag => (
									<TagItem
										key={tag.id}
										tag={tag}
										onClick={onlyShowValue || blockRemoveTag ? () => {} : onRemoveTag}
										action
										selected
										type={blockRemoveTag ? () => '' : 'remove'}
									/>
								))
							}
						</div>
					)
				}
				{!onlyShowValue && (
					<div className="Tags__search">
						<CommomInput
							placeholder={formatMessage(messages.search)}
							disabled={onlyShowValue}
							onChange={onType}
							value={value}
							ref={input}
						/>
					</div>
				)}
				{!onlyShowValue && currentTags && currentTags.length > 0 && (
					<div className="Tags__tags">
						{
							currentTags.map((tag) => {
								if (selectedTags.tags.some(({ id }) => id === tag.id && !tag.subtags)) {
									return false;
								}

								return <TagItem key={tag.id} tag={tag} onClick={onSelectTag} action />;
							})
						}
					</div>
				)}
			</div>
			{showModal ? (
				<Modal onClose={onCloseModal}>
					<div className="Modal_Tags_title">
						<Text size="large">{formatMessage(messages.addSubtags)}</Text>
					</div>
					<div className="Modal_Tags_form">
						{selectSubtags(onChangeSubtag1, subtags1, valueSubtag1, `${formatMessage(messages.subtag)} 1`, !valueTag || !subtags1.length)}
						{selectSubtags(onChangeSubtag2, subtags2, valueSubtag2, `${formatMessage(messages.subtag)} 2`, !valueSubtag1 || !subtags2.length)}
					</div>
					<div className="Modal_Tags_button">
						<Button click={onSaveTag}>
							<Text size="bigger">{formatMessage(messages.save)}</Text>
						</Button>
					</div>
				</Modal>
			) : (
				<Backdrop closeAction={onClose} clear higher />
			)}
		</>
	);
};

const mapStateToProps = (state) => {
	const { agent, interaction } = state;
	const {
		currentInteractionHash,
		historyInteractions,
		interactions,
		missedInteractions,
		pendingInteractions,
		updateTagsStatus
	} = interaction;
	const allInteractions = interactions.concat(pendingInteractions).concat(historyInteractions).concat(missedInteractions);

	return ({
		agent,
		answers: agent.answers,
		currentInteraction: allInteractions.find(({ interactionHash }) => interactionHash === currentInteractionHash) || {},
		currentInteractionHash,
		inputTag: state.interface.inputTag.find(({ hash }) => hash === currentInteractionHash) || { value: '' },
		selectedTags: state.interface.interactionTags.find(({ hash }) => hash === currentInteractionHash) || { tags: [] },
		updateTagsStatus: updateTagsStatus.find(({ hash }) => hash === currentInteractionHash) || {}
	});
};

const mapActionsToProps = dispatch => ({
	updateTags: requestInfo => dispatch(actions.updateTags(requestInfo)),
	onSetTag: tagInfo => dispatch(actions.setTag(tagInfo)),
	removeTag: tagsInfo => dispatch(actions.removeTag(tagsInfo)),
	onChangeInputTags: inputInfo => dispatch(actions.changeInputTags(inputInfo)),
	onFetchTags: requestInfo => dispatch(actions.fetchTags(requestInfo)),
	onFetchSubtags: requestInfo => dispatch(actions.fetchSubtags(requestInfo))
});

Tags.propTypes = {
	agent: PropTypes.shape({
		info: PropTypes.shape({
			id: PropTypes.number,
			account: PropTypes.shape({
				id: PropTypes.number
			})
		})
	}).isRequired,
	currentInteraction: PropTypes.shape({
		interactionHash: PropTypes.string,
		tags: PropTypes.arrayOf(PropTypes.shape({
			id: PropTypes.number,
			name: PropTypes.string
		})),
		subtags1: PropTypes.arrayOf(PropTypes.shape({
			id: PropTypes.number,
			name: PropTypes.string
		})),
		subtags2: PropTypes.arrayOf(PropTypes.shape({
			id: PropTypes.number,
			name: PropTypes.string
		})),
		interactionTags: PropTypes.arrayOf(PropTypes.shape({
			id: PropTypes.number,
			name: PropTypes.string
		})),
		department: PropTypes.shape({
			id: PropTypes.number
		})
	}).isRequired,
	currentInteractionHash: PropTypes.string.isRequired,
	updateTags: PropTypes.func.isRequired,
	updateTagsStatus: PropTypes.shape({
		loading: PropTypes.bool,
		success: PropTypes.bool,
		error: PropTypes.bool
	}),
	inputTag: PropTypes.shape({
		value: PropTypes.string
	}).isRequired,
	onSetTag: PropTypes.func.isRequired,
	removeTag: PropTypes.func.isRequired,
	blockRemoveTag: PropTypes.bool,
	onChangeInputTags: PropTypes.func.isRequired,
	selectedTags: PropTypes.shape({
		tags: PropTypes.arrayOf(PropTypes.shape({
			hash: PropTypes.string
		}))
	}).isRequired,
	onFetchTags: PropTypes.func.isRequired,
	onFetchSubtags: PropTypes.func.isRequired,
	intl: PropTypes.shape({
		formatMessage: PropTypes.func.isRequired
	}).isRequired,
	onlyShowValue: PropTypes.bool,
	onClose: PropTypes.func.isRequired
};

export default connect(mapStateToProps, mapActionsToProps)(injectIntl(Tags));
