import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { defineMessages, injectIntl } from 'react-intl';

import ModalDefault from '../../Sirius/ModalDefault';

import './index.sass';
import * as actions from '../../../store/actions';
import Button from '../../Molecules/Buttons/Button';


const messages = defineMessages({
	unavailableModalTitle: {
		id: 'unavailable.modal.title',
		defaultMessage: 'Mudança para status de Indisponível'
	},
	unavailableModalMoreInformation: {
		id: 'unavailable.modal.moreInformation',
		defaultMessage: 'Para mais informações entre em contato com o(a) Administrador(a) da conta.'
	},
	unavailableModalButton: {
		id: 'unavailable.modal.button',
		defaultMessage: 'Entendi'
	},
	unavailableModalAutomaticallyLabel: {
		id: 'unavailable.modal.automaticallyLabel',
		defaultMessage: 'Você foi automaticamente colocado(a) como indisponível e não poderá atender novas conversas da fila, mas pode continuar e finalizar suas conversas em andamento.'
	},
	unavailableModalUnableLabel: {
		id: 'unavailable.modal.unableLabel',
		defaultMessage: 'Você está impossibilitado(a) de atender novas conversas mas pode continuar e finalizar suas conversas em andamento.'
	}
});

const UnavailableModal = ({
	onClose,
	tryToAnswer,
	intl
}) => {
	const { formatMessage } = intl;

	return (
		<ModalDefault.Root
			isOpen
			onClose={onClose}
		>
			<div className="unavailableModal">
				<ModalDefault.Header
					title={formatMessage(messages.unavailableModalTitle)}
					onClose={onClose}
					className="has-background-grey-lighter"
				/>
				<ModalDefault.Body className="has-background-grey-lighter is-overflow-hidden unavailableModal__label">
					<p>
						{tryToAnswer ? formatMessage(messages.unavailableModalUnableLabel) : formatMessage(messages.unavailableModalAutomaticallyLabel)}
					</p>
					<p className="mt-2">
						{formatMessage(messages.unavailableModalMoreInformation)}
					</p>
				</ModalDefault.Body>

				<ModalDefault.Footer className="has-background-grey-lighter">
					<div className="is-flex">
						<Button className="button is-primary is-large" click={onClose}>{formatMessage(messages.unavailableModalButton)}</Button>
					</div>
				</ModalDefault.Footer>

			</div>
		</ModalDefault.Root>
	);
};

const mapActionsToProps = dispatch => ({
	addNotification: notification => dispatch(actions.addNotification(notification))
});

UnavailableModal.propTypes = {
	onClose: PropTypes.func.isRequired,
	intl: PropTypes.shape({
		formatMessage: PropTypes.func.isRequired
	}).isRequired,
	tryToAnswer: PropTypes.bool
};

export default connect(mapActionsToProps)(injectIntl(UnavailableModal));
