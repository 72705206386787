import React, {
	useState, useCallback, useRef
} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import './index.sass';
import Wrapper from '../../Atoms/Wrapper';
import ImgSvg from '../../Atoms/ImgSvg';
import MainBoxHeader from '../../Molecules/MainBoxHeader';
import InteractionList from '../InteractionList';
import * as actions from '../../../store/actions';
import InteractionListActives from '../InteractionListActives';
import AgentHistory from '../AgentHistory';
import AgentContacts from '../AgentContacts';
import { ACTIVE_INTERACTION_STATES } from '../../../shared/consts';

const MainBox = ({
	isFullAutomatic,
	fetchInboxInteractions,
	setInteractionList,
	fetchQueueInteractions,
	fetchQueueInteractionsStatus = {},
	fetchInboxInteractionsStatus = {},
	searchInboxInteractions,
	searchQueueInteractions,
	searchMissedInteractions,
	interactionsCount,
	interactionList,
	searchInteractionsStatus = {},
	mainBoxSection,
	notifyInboxMessage,
	fetchMissedInteractions,
	fetchMissedInteractionsStatus = {},
	talkingInteractionsCount
}) => {
	const interactionLists = {
		talking: { action: () => {}, status: {} },
		queue: { action: fetchQueueInteractions, status: fetchQueueInteractionsStatus },
		inbox: { action: fetchInboxInteractions, status: fetchInboxInteractionsStatus },
		missed: { action: fetchMissedInteractions, status: fetchMissedInteractionsStatus }
	};

	const fetchIfNecessary = useCallback((list) => {
		if (!interactionLists[list].status.success) {
			interactionLists[list].action(1, true);
		}
	}, [interactionLists]);

	const searchRef = useRef();
	const [search, setSearch] = useState('');
	const [showOnlyActiveInteractions, setShowOnlyActiveInteractions] = useState(JSON.parse(localStorage.getItem('showOnlyActiveInteractions')));
	const onSelectList = useCallback((list) => {
		setShowOnlyActiveInteractions(false);
		localStorage.setItem('showOnlyActiveInteractions', false);
		fetchIfNecessary(list);
		setInteractionList(list);
		setSearch('');
	}, [fetchIfNecessary, setInteractionList]);

	const loadMoreInteractions = useCallback((list) => {
		const { count, loading, ended } = interactionLists[list].status;

		if (!loading && !ended) interactionLists[list].action(count);
	}, [interactionLists]);

	const onSearch = useCallback((inputValue) => {
		setTimeout(() => {
			if (searchRef.current === inputValue) {
				if (interactionList === 'inbox') searchInboxInteractions(inputValue);
				if (interactionList === 'queue') searchQueueInteractions(inputValue);
				if (interactionList === 'missed') searchMissedInteractions(inputValue);
			} else if (inputValue === '') {
				fetchInboxInteractions();
			}
		}, 1200);
	}, [
		fetchInboxInteractions,
		searchInboxInteractions,
		searchQueueInteractions,
		searchMissedInteractions,
		interactionList
	]);

	const onType = (e) => {
		setSearch(e.target.value);
		onSearch(e.target.value);
		searchRef.current = e.target.value;
	};

	const defaultListType = isFullAutomatic ? 'talking' : 'queue';

	return (
		<div className={`MainBox ${mainBoxSection === 'default' ? 'MainBox--default' : ''}`}>
			{mainBoxSection === 'default' && (
				<>
					{!isFullAutomatic && (
						<>
							<div className={showOnlyActiveInteractions ? 'MainBoxActive__interactions' : ''}>
								<InteractionListActives showOnlyActiveInteractions={showOnlyActiveInteractions} />
							</div>
							<div className="MainBox__collapse">
								<Wrapper
									className={showOnlyActiveInteractions ? 'MainBox__collapse__actived' : ''}
									action={() => {
										setShowOnlyActiveInteractions(!showOnlyActiveInteractions);
										localStorage.setItem('showOnlyActiveInteractions', !showOnlyActiveInteractions);
									}}
								>
									<ImgSvg color="#FFF" name="arrow" />
								</Wrapper>
							</div>
						</>
					)}


					<MainBoxHeader
						hiddenSearch={!showOnlyActiveInteractions}
						isFullAutomatic={isFullAutomatic}
						interactionList={interactionList || defaultListType}
						selectList={onSelectList}
						interactionQuantity={interactionsCount}
						talkingInteractionsCount={talkingInteractionsCount}
						onSearch={onType}
						search={search}
						loading={searchInteractionsStatus.loading}
						notifyInboxMessage={notifyInboxMessage}
					/>
					{!showOnlyActiveInteractions && (
						<div className="MainBox__interactions">
							<InteractionList
								interactionList={interactionList || defaultListType}
								loadMoreInteractions={loadMoreInteractions}
								searchValue={search}
							/>
						</div>
					)}
				</>
			)}
			{mainBoxSection === 'history' && <AgentHistory />}
			{mainBoxSection === 'contacts' && <AgentContacts />}
		</div>
	);
};

const mapStateToProps = state => ({
	isFullAutomatic: state.agent.isFullAutomatic,
	interactionsCount: state.interaction.count,
	interactionList: state.interface.interactionList,
	searchInteractionsStatus: state.interaction.searchInteractionsStatus,
	fetchInboxInteractionsStatus: state.interaction.fetchInboxInteractionsStatus,
	fetchQueueInteractionsStatus: state.interaction.fetchQueueInteractionsStatus,
	fetchMissedInteractionsStatus: state.interaction.fetchMissedInteractionsStatus,
	mainBoxSection: state.interface.mainBoxSection,
	notifyInboxMessage: state.interaction.pendingInteractions.some(({ currentState }) => currentState === 'PENDING'),
	talkingInteractionsCount: state.interaction.interactions.filter(({ currentState, rejected = false }) => (
		ACTIVE_INTERACTION_STATES.includes(currentState) || rejected
	)).length
});

const MapActionsToProps = dispatch => ({
	fetchInboxInteractions: (page, reset) => dispatch(actions.fetchInboxInteractions(page, reset)),
	fetchQueueInteractions: (page, reset) => dispatch(actions.fetchQueueInteractions(page, reset)),
	fetchMissedInteractions: (page, reset) => dispatch(actions.fetchMissedInteractions(page, reset)),
	searchInboxInteractions: search => dispatch(actions.searchInboxInteractions(search)),
	searchQueueInteractions: search => dispatch(actions.searchQueueInteractions(search)),
	searchMissedInteractions: search => dispatch(actions.searchMissedInteractions(search)),
	setInteractionList: list => dispatch(actions.setInteractionList(list))
});

MainBox.propTypes = {
	isFullAutomatic: PropTypes.bool.isRequired,
	interactionsCount: PropTypes.shape({
		inbox: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		queue: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
	}).isRequired,
	fetchInboxInteractions: PropTypes.func.isRequired,
	fetchQueueInteractions: PropTypes.func.isRequired,
	fetchMissedInteractions: PropTypes.func.isRequired,
	setInteractionList: PropTypes.func.isRequired,
	searchInboxInteractions: PropTypes.func.isRequired,
	searchQueueInteractions: PropTypes.func.isRequired,
	searchMissedInteractions: PropTypes.func.isRequired,
	interactionList: PropTypes.string.isRequired,
	talkingInteractionsCount: PropTypes.number.isRequired,
	searchInteractionsStatus: PropTypes.shape({
		loading: PropTypes.bool
	}),
	fetchInboxInteractionsStatus: PropTypes.shape({
		count: PropTypes.number,
		loading: PropTypes.bool,
		ended: PropTypes.bool,
		success: PropTypes.bool
	}),
	fetchQueueInteractionsStatus: PropTypes.shape({
		count: PropTypes.number,
		loading: PropTypes.bool,
		ended: PropTypes.bool,
		success: PropTypes.bool
	}),
	fetchMissedInteractionsStatus: PropTypes.shape({
		count: PropTypes.number,
		loading: PropTypes.bool,
		ended: PropTypes.bool,
		success: PropTypes.bool
	}),
	mainBoxSection: PropTypes.string.isRequired,
	notifyInboxMessage: PropTypes.bool.isRequired
};

export default connect(mapStateToProps, MapActionsToProps)(MainBox);
