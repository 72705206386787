import React from 'react';
import PropTypes from 'prop-types';

import './index.sass';
import SelectOptionsItem from '../SelectOptionsItem';

const SelectOptions = ({ options, action }) => (
	<div className="SelectOptions">
		{options.map(option => (
			<SelectOptionsItem
				value={option}
				action={action}
				key={`${option}${new Date().getTime()}`}
			/>
		))}
	</div>
);

SelectOptions.propTypes = {
	options: PropTypes.arrayOf(PropTypes.string).isRequired,
	action: PropTypes.func.isRequired
};

export default SelectOptions;
