import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import InputMask from 'react-input-mask';
import { injectIntl, defineMessages } from 'react-intl';

import './index.sass';
import Text from '../../../Atoms/Text';

const messages = defineMessages({
	maskDate: {
		id: 'formInput.maskDate',
		defaultMessage: 'dd/mm/yyyy'
	}
});

const Mask = ({
	label,
	value,
	onChange,
	touched,
	valid,
	validationMessage,
	type = 'text',
	onKeyUp = () => {},
	isEditing,
	disabled,
	mask = '',
	name = '',
	intl,
	'data-testid': dataTestId
}) => {
	const { formatMessage } = intl;

	const [inputClasses, setInputClasses] = useState('');
	const [alertClasses, setAlertClasses] = useState('');
	const [errorMessage, setErrorMessage] = useState('');
	useEffect(() => {
		const currentInputClasses = ['Mask'];
		const currentAlertClasses = ['Mask__alert'];

		if (touched && !valid) {
			setErrorMessage(validationMessage);
			currentInputClasses.push('Mask--invalid');
			currentAlertClasses.push('Mask__alert--show');
		}
		setInputClasses(currentInputClasses.join(' '));
		setAlertClasses(currentAlertClasses.join(' '));
	}, [touched, valid, validationMessage, inputClasses, alertClasses]);

	return (
		<div className={inputClasses}>
			<div className={`Mask__wrapper Mask__wrapper${isEditing ? '--editing' : '--not-editing'}`}>
				<Text size="bigger" data-testid={errorMessage}>{label}</Text>
				{isEditing ? (
					<>
						{type === 'textarea' ? (
							<textarea
								data-testid={dataTestId}
								value={value}
								onChange={e => onChange(e)}
								onKeyUp={onKeyUp}
								disabled={disabled}
								placeholder={label}
							/>
						) : (
							<InputMask
								data-testid={dataTestId}
								value={value}
								onChange={e => onChange(e)}
								onKeyUp={onKeyUp}
								type={type}
								disabled={disabled}
								mask={mask}
								maskPlaceholder={name === 'taskDate' ? formatMessage(messages.maskDate) : null}
								placeholder={label}
							/>
						)}
					</>
				) : (
					<Text size="bigger" data-testid={errorMessage}>{value}</Text>
				)}
			</div>
			<Text size="small" className={alertClasses} data-testid={errorMessage}>{errorMessage}</Text>
		</div>
	);
};

Mask.propTypes = {
	label: PropTypes.string.isRequired,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
	onChange: PropTypes.func.isRequired,
	touched: PropTypes.bool.isRequired,
	valid: PropTypes.bool.isRequired,
	validationMessage: PropTypes.string.isRequired,
	type: PropTypes.oneOf(['password', 'text', 'textarea', 'date', 'time']),
	onKeyUp: PropTypes.func,
	disabled: PropTypes.bool,
	isEditing: PropTypes.bool.isRequired,
	mask: PropTypes.string,
	name: PropTypes.string,
	intl: PropTypes.shape({
		formatMessage: PropTypes.func.isRequired
	}).isRequired,
	'data-testid': PropTypes.string
};

export default injectIntl(Mask);
