import React, {
	useRef, useEffect, useCallback, useState
} from 'react';
import PropTypes from 'prop-types';
import DragAndDropFileUpload from '../../DragAndDropFileUpload';

import './index.sass';

const ChatText = ({
	placeholder,
	disabled,
	onChange,
	onType = () => {},
	value,
	maxHeight = 250,
	autoResize
}) => {
	const [files, setFiles] = useState();
	const textAreaRef = useRef();
	const resizeTextArea = useCallback(() => {
		if (textAreaRef.current && textAreaRef.current.scrollHeight > 36) {
			textAreaRef.current.style.height = 'auto';
			if (textAreaRef.current.scrollHeight <= 46) {
				textAreaRef.current.style.height = '36px';
				return false;
			}
			textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`;
		}
		return false;
	}, []);

	useEffect(() => {
		resizeTextArea();
	}, [resizeTextArea, value]);

	useEffect(() => {
		if (!autoResize) {
			resizeTextArea();
		}
	}, [autoResize, maxHeight, resizeTextArea, value]);

	const handlePaste = (event) => {
		const item = Array.from(event.clipboardData.items)[0];
		const blob = item.getAsFile();

		if (blob) {
			event.preventDefault();
			setFiles(Object.assign(blob, {
				preview: URL.createObjectURL(blob)
			}));
		}
	};

	return (
		<div className="ChatText">
			<textarea
				style={{ maxHeight: maxHeight < 250 ? 250 : maxHeight - 45 }}
				rows={1}
				ref={textAreaRef}
				onInput={resizeTextArea}
				className={`ChatText__input ${disabled ? 'ChatText__input--disabled' : ''}`}
				onKeyUp={onType}
				placeholder={placeholder}
				onChange={onChange}
				value={value}
				onKeyPress={(e) => { if (e.which === 13 && !e.shiftKey) e.preventDefault(); }}
				disabled={disabled}
				onPaste={handlePaste}
			/>
			{files && (
				<DragAndDropFileUpload
					open
					onClose={() => setFiles(null)}
					newFile={files}
				/>
			)}
		</div>
	);
};

ChatText.propTypes = {
	onChange: PropTypes.func.isRequired,
	placeholder: PropTypes.string,
	disabled: PropTypes.bool,
	maxHeight: PropTypes.number,
	onType: PropTypes.func,
	value: PropTypes.string.isRequired,
	autoResize: PropTypes.bool.isRequired
};

export default ChatText;
