import PropTypes from 'prop-types';
import React, {
	useCallback, useMemo, useEffect, useState
} from 'react';
import { defineMessages, injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import { ACTIVE_INTERACTION_STATES } from '../../../shared/consts';
import { sortInteractionsBySla } from '../../../shared/utility';
import * as actions from '../../../store/actions';
import Alert from '../../Atoms/Alert';
import ImgSvg from '../../Atoms/ImgSvg';
import Text from '../../Atoms/Text';
import InteractionListTitle from '../../Molecules/InteractionListTitle';
import AutomaticallyPause from '../AutomaticallyPause';
import ClientMessage from '../ClientMessage';
import './index.sass';

const messages = defineMessages({
	header: {
		id: 'interactionListActives.header',
		defaultMessage: 'Minhas conversas ativas'
	},
	newInteraction: {
		id: 'interactionListActives.newInteraction',
		defaultMessage: 'Nova Interação'
	},
	empty: {
		id: 'interactionListActives.empty',
		defaultMessage: 'Você não tem conversas em andamento'
	},
	obs: {
		id: 'interactionListActives.obs',
		defaultMessage: 'Confira os chats disponíveis para atendimento abaixo e inicie uma conversa.'
	},
	slaServiceTime: {
		id: 'interactionList.slaServiceTime',
		defaultMessage: 'Atendimento'
	},
	slaQueuedTime: {
		id: 'interactionList.slaQueuedTime',
		defaultMessage: 'Espera'
	},
	slaFirstReplyTime: {
		id: 'interactionList.slaFirstReplyTime',
		defaultMessage: 'Primeira resposta'
	},
	slaMessageTime: {
		id: 'interactionList.slaMessageTime',
		defaultMessage: 'Mensagem'
	}
});

const InteractionList = ({
	currentInteractionHash,
	interactions = [],
	fetchQueueInteractionsStatus = {},
	isRingingInteraction,
	shouldNotify,
	intl,
	defineSelectedInteraction,
	setCurrentInteraction,
	showOnlyActiveInteractions,
	clearPendingMessages,
	updateAgentBreakStatus,
	agent,
	agentBreakStatus
}) => {
	const [showAutomaticallyPause, setShowAutomaticallyPause] = useState(false);
	const { formatMessage } = intl;
	const isLoadingQueue = fetchQueueInteractionsStatus.loading;

	const currentList = useMemo(() => {
		const activeInteractions = interactions.filter(({ currentState, rejected = false }) => (
			ACTIVE_INTERACTION_STATES.includes(currentState) || rejected
		));

		return sortInteractionsBySla(activeInteractions);
	}, [interactions]);

	const agentBreakStatusAutoBreak = agentBreakStatus?.autoBreak;
	const agentBreakStatusPreBreak = agentBreakStatus?.preBreak;
	const agentBreakStatusId = agentBreakStatus?.id;

	useEffect(() => {
		const durationMinutesPrevious = JSON.parse(window.localStorage.getItem('durationMinutesPrevious'));

		if (currentList.length === 0 && agentBreakStatusPreBreak === true && agentBreakStatusAutoBreak === true) {
			const breakStatus = {
				...agentBreakStatus,
				preBreak: false,
				id: agentBreakStatusId
			};
			const params = {
				action: 'PAUSE',
				breakStatus,
				durationMinutes: durationMinutesPrevious?.durationMinutesValue
			};

			updateAgentBreakStatus(params);
			setShowAutomaticallyPause(true);
		}
	}, [agentBreakStatus, agentBreakStatusAutoBreak, agentBreakStatusId, agentBreakStatusPreBreak, currentList.length, updateAgentBreakStatus]);


	const hideInteractionFeature = agent.features.some(({ name }) => name === 'hide_messages_preview');

	const onSelectClient = useCallback((interaction) => {
		const { interactionHash, currentState } = interaction;

		if (hideInteractionFeature && currentState === 'RINGING') return () => {};
		if (currentInteractionHash && interactionHash === currentInteractionHash) return defineSelectedInteraction('');
		clearPendingMessages(interactionHash);
		setCurrentInteraction(interaction);
		return defineSelectedInteraction(interactionHash);
	}, [hideInteractionFeature, currentInteractionHash, defineSelectedInteraction, clearPendingMessages, setCurrentInteraction]);

	return (
		<>
			<Alert
				notify={isRingingInteraction && shouldNotify}
				type="ringing"
				content={formatMessage(messages.newInteraction)}
				loop
			/>
			<InteractionListTitle title={formatMessage(messages.header)} loading={isLoadingQueue} interactionsCount={currentList.length} />
			{currentList.length > 0 ? (
				<div
					className="InteractionListActive__section"
					style={
						currentList.length > 2 ? {
							overflowY: 'auto',
							maxHeight: showOnlyActiveInteractions ? 'calc(100vh - 190px)' : 'calc(50vh - 85px)'
						} : {}
					}
				>
					{currentList.map((activeInteraction, index) => (
						<ClientMessage
							key={activeInteraction.id}
							interactionInfo={activeInteraction}
							onSelect={onSelectClient}
							selected={currentInteractionHash === activeInteraction.interactionHash}
							messageIndex={index}
						/>
					))}
				</div>
			) : (
				<div className="InteractionList__empty">
					<div className="InteractionList__empty__image">
						<ImgSvg name="empty" />
					</div>
					<span className="InteractionList__empty__info">
						<Text>{formatMessage(messages.empty)}</Text>
						<Text size="bigger">{formatMessage(messages.obs)}</Text>
					</span>
				</div>
			)}
			{showAutomaticallyPause && (
				<AutomaticallyPause onClose={setShowAutomaticallyPause} />
			)}
		</>
	);
};

const mapStateToProps = state => ({
	agent: state.agent.info,
	agentBreakStatus: state.agent.agentBreakStatus,
	currentInteractionHash: state.interaction.currentInteractionHash,
	interactions: state.interaction.interactions,
	isRingingInteraction: state.interaction.interactions.concat(state.interaction.pendingInteractions)
		.some(interaction => interaction.currentState === 'RINGING'),
	shouldNotify: state.agent.notify
});

const mapActionsToProps = dispatch => ({
	defineSelectedInteraction: (interactionHash) => {
		dispatch(actions.defineSelectedInteraction(interactionHash));
	},
	setCurrentInteraction: (interaction) => {
		dispatch(actions.setCurrentInteraction(interaction));
	},
	clearPendingMessages: interactionHash => dispatch(actions.clearPendingMessages(interactionHash)),
	updateAgentBreakStatus: info => dispatch(actions.updateAgentBreakStatus(info))
});

InteractionList.propTypes = {
	agent: PropTypes.shape({
		features: PropTypes.arrayOf(
			PropTypes.shape({
				status: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
			})
		),
		agentBreakStatus: PropTypes.shape({
			preBreak: PropTypes.bool,
			autoBreak: PropTypes.bool
		})
	}).isRequired,
	interactions: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.number
		})
	),
	fetchQueueInteractionsStatus: PropTypes.shape({
		loading: PropTypes.bool,
		error: PropTypes.bool
	}),
	defineSelectedInteraction: PropTypes.func.isRequired,
	currentInteractionHash: PropTypes.string.isRequired,
	setCurrentInteraction: PropTypes.func.isRequired,
	isRingingInteraction: PropTypes.bool.isRequired,
	showOnlyActiveInteractions: PropTypes.bool,
	clearPendingMessages: PropTypes.func.isRequired,
	shouldNotify: PropTypes.bool.isRequired,
	intl: PropTypes.shape({
		formatMessage: PropTypes.func.isRequired
	}).isRequired,
	updateAgentBreakStatus: PropTypes.func.isRequired,
	agentBreakStatus: PropTypes.shape({
		preBreak: PropTypes.bool,
		autoBreak: PropTypes.bool,
		id: PropTypes.number
	})
};

export default connect(mapStateToProps, mapActionsToProps)(injectIntl(InteractionList));
