import React from 'react';
import PropTypes from 'prop-types';

import './index.sass';

const ChatEmail = ({
	placeholder = '',
	disabled,
	onChange,
	value
}) => (
	<div className="ChatEmail">
		<textarea
			className={`ChatEmail__input ${disabled ? 'ChatEmail__input--disabled' : ''}`}
			onChange={onChange}
			disabled={disabled}
			placeholder={!disabled ? placeholder : ''}
			value={value}
		/>
	</div>
);

ChatEmail.propTypes = {
	onChange: PropTypes.func.isRequired,
	placeholder: PropTypes.string,
	disabled: PropTypes.bool,
	value: PropTypes.string.isRequired
};

export default ChatEmail;
