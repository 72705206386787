import React from 'react';
import PropTypes from 'prop-types';

import './index.sass';

const MessageDate = ({ date, fontSize }) => (
	<div className="MessageDate">
		<span style={{ fontSize }}>{date}</span>
	</div>
);

MessageDate.propTypes = {
	date: PropTypes.string.isRequired,
	fontSize: PropTypes.string.isRequired
};

export default MessageDate;
