import * as actionTypes from './actionTypes';

export const fetchDepartmentsBegin = () => ({
	type: actionTypes.FETCH_DEPARTMENTS_BEGIN
});
export const fetchDepartmentsSuccess = payload => ({
	type: actionTypes.FETCH_DEPARTMENTS_SUCCESS,
	payload
});
export const fetchDepartmentsFailed = () => ({
	type: actionTypes.FETCH_DEPARTMENTS_FAILED
});
export const fetchDepartments = () => (
	(dispatch) => {
		dispatch(fetchDepartmentsBegin());
		return fetch(`${process.env.OMZ_ZAPI}/admin/departments?limit=300`, {
			method: 'GET',
			headers: {
				Authorization: sessionStorage.getItem('omz_token'),
				'Content-type': 'application/json'
			}
		})
			.then(res => res.json())
			.then(({ status, departments }) => {
				if (status === 200) {
					const departmentsFiltered = departments.map(({ id, name }) => ({ value: id, label: name }));
					dispatch(fetchDepartmentsSuccess(departmentsFiltered));
				} else {
					dispatch(fetchDepartmentsFailed());
				}
			}).catch(() => {
				dispatch(fetchDepartmentsFailed());
			});
	}
);
