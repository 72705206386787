/* eslint-disable no-console */
/* eslint-disable no-param-reassign */
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { injectIntl, defineMessages } from 'react-intl';
import Icon from '../../Atoms/ImgSvg';
import './index.sass';
import * as actions from '../../../store/actions';

const messages = defineMessages({
	callDuration: {
		id: 'chatInput.whatsappCall.callDuration',
		defaultMessage: 'Duração da chamada'
	}
});

const CallDuration = ({
	callEnd, mediaStream, callDuration, intl
}) => {
	const formatTime = (secs) => {
		const parsedHours = Math.floor(secs / 3600);
		const parsedMinutes = Math.floor((secs % 3600) / 60);
		const parsedSeconds = secs % 60;
		return `${parsedHours.toString().padStart(2, '0')}:${parsedMinutes.toString().padStart(2, '0')}:${parsedSeconds.toString().padStart(2, '0')}`;
	};

	const isMuted = mediaStream?.getAudioTracks()?.some(track => !track.enabled) || false;

	const toggleMute = () => {
		if (mediaStream) {
			const audioTracks = mediaStream.getAudioTracks();
			if (audioTracks.length > 0) {
				audioTracks.forEach((track) => {
					if (track.readyState === 'live') {
						track.enabled = !track.enabled;
					} else {
						console.error('Track de áudio já foi encerrada.');
					}
				});
			} else {
				console.error('Nenhuma track de áudio encontrada.');
			}
		}
	};


	return (
		<div className="CallDuration">
			<div className="CallDuration--info">
				<div className="CallDuration--signal">
					<Icon color="default" name="callSignal" />
				</div>
				<div className="CallDuration--title">
					{intl.formatMessage(messages.callDuration)}
				</div>
				<div className="CallDuration--time">
					{formatTime(callDuration)}
				</div>
			</div>

			<div className="CallDuration--buttons">
				<button type="button" className="CallDuration--button callEnd" onClick={callEnd}>
					<Icon color="default" name="callEnd" />
				</button>

				<button type="button" className="CallDuration--button callMute" onClick={toggleMute}>
					<Icon color="default" name={isMuted ? 'callUnmute' : 'callMute'} />
				</button>
			</div>
		</div>
	);
};

const mapStateToProps = state => ({
	callDuration: state.interaction.callDuration
});

const mapActionsToProps = dispatch => ({
	updateCallDuration: callDuration => dispatch(actions.updateCallDuration(callDuration))
});

CallDuration.propTypes = {
	intl: PropTypes.shape({
		formatMessage: PropTypes.func.isRequired
	}),
	callEnd: PropTypes.func,
	mediaStream: PropTypes.shape({
		getAudioTracks: PropTypes.func
	}),
	callDuration: PropTypes.number
};

export default connect(mapStateToProps, mapActionsToProps)(injectIntl(CallDuration));
