import React from 'react';
import PropTypes from 'prop-types';

import './index.sass';
import AvatarName from '../../Atoms/AvatarName';
import Text from '../../Atoms/Text';

const ConferenceUser = ({ name, number }) => (
	<div className="ConferenceUser">
		<AvatarName name={name} />
		<Text size="smallest">{number}</Text>
	</div>
);

ConferenceUser.propTypes = {
	name: PropTypes.string.isRequired,
	number: PropTypes.string.isRequired
};

export default ConferenceUser;
