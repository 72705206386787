import React, { useState, useEffect } from 'react';
import Sound from 'react-sound';
import PropTypes from 'prop-types';
import { injectIntl, defineMessages } from 'react-intl';
import Icon from '../../Atoms/ImgSvg';
import './index.sass';


const messages = defineMessages({
	receivingVoiceCall: {
		id: 'chatInput.whatsappCall.receivingVoiceCall',
		defaultMessage: 'Recebendo chamada de voz'
	}
});

// TODO: Receber interactionHash
const ReceivingWhatsappCall = ({ callAccept, callRefuse, intl }) => {
	const [playSound, setPlaySound] = useState(Sound.status.PLAYING);

	const receptiveCall = () => {
		setPlaySound(Sound.status.PLAYING);
	};

	useEffect(() => {
		receptiveCall();
	}, []);

	return (
		<div className="ReceivingWhatsappCall">
			<Sound
				url="https://zchat-customer-audios-prd.s3.amazonaws.com/Omnize/OpeningAudio.mp3"
				playStatus={playSound}
				loop
			/>
			<div className="ReceivingWhatsappCall--title">
				{intl.formatMessage(messages.receivingVoiceCall)}
			</div>
			<div className="ReceivingWhatsappCall--buttons">
				<button
					type="button"
					className="ReceivingWhatsappCall--button callAccept"
					onClick={() => {
						setPlaySound(Sound.status.STOPPED);
						callAccept();
					}}
				>
					<Icon
						color="default"
						name="callAccept"
					/>
				</button>

				<button
					type="button"
					className="ReceivingWhatsappCall--button callRefuse"
					onClick={() => {
						setPlaySound(Sound.status.STOPPED);
						callRefuse();
					}}
				>
					<Icon
						color="default"
						name="callRefuse"
					/>
				</button>
			</div>
		</div>
	);
};

ReceivingWhatsappCall.propTypes = {
	intl: PropTypes.shape({
		formatMessage: PropTypes.func.isRequired
	}),
	callAccept: PropTypes.func,
	callRefuse: PropTypes.func
};

export default injectIntl(ReceivingWhatsappCall);
