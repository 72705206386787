import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { defineMessages, injectIntl } from 'react-intl';

import './index.sass';
import * as actions from '../../../store/actions';
import Text from '../../Atoms/Text';
import Modal from '../../Molecules/Modal';
import Button from '../../Molecules/Buttons/Button';
import Wrapper from '../../Atoms/Wrapper';

const messages = defineMessages({
	description: {
		id: 'fontSize.description',
		defaultMessage: 'Arraste ou clique em uma das letras abaixo para escolher um tamanho de texto para visualização no chat.'
	},
	header: {
		id: 'fontSize.header',
		defaultMessage: 'Tamanho do texto da conversa'
	},
	preview: {
		id: 'fontSize.preview',
		defaultMessage: 'Pré-visualização'
	},
	example: {
		id: 'fontSize.example',
		defaultMessage: 'Exemplo de uma mensagem de texto no chat.'
	},
	cancel: {
		id: 'fontSize.cancel',
		defaultMessage: 'Cancelar'
	},
	save: {
		id: 'fontSize.save',
		defaultMessage: 'Aplicar novo tamanho'
	}
});

const FontSize = ({
	agent,
	updateSettings,
	onClose,
	intl
}) => {
	const { formatMessage } = intl;
	const [fontSize, setFontSize] = useState(agent.settings.fontSize || '14px');
	const sizes = {
		'14px': 'small',
		'16px': 'medium',
		'20px': 'big'
	};

	const handleChange = () => {
		const settings = { fontSize };

		updateSettings({
			settings,
			agentId: agent.id
		});

		onClose();
	};

	return (
		<Modal onClose={onClose} higher size="bg" className="FontSizeModal" isDark>
			<div className="FontSize">
				<div className="FontSize__header">
					<Text size="large">{formatMessage(messages.header)}</Text>
				</div>
				<div>
					<div className="FontSize__description">
						<Text size="bigger">{formatMessage(messages.description)}</Text>
					</div>
					<div className="FontSize__range">
						<input
							data-size={sizes[fontSize]}
							onChange={e => setFontSize(Object.keys(sizes)[Number(e.target.value) - 1])}
							type="range"
							step="1"
							min="1"
							max="3"
							value={Object.keys(sizes).findIndex(s => s === fontSize) + 1}
						/>
						<div className="FontSize__range__list">
							<Wrapper className={fontSize === '14px' ? 'selected' : ''} action={() => setFontSize('14px')}>A</Wrapper>
							<Wrapper className={fontSize === '16px' ? 'selected' : ''} action={() => setFontSize('16px')}>A</Wrapper>
							<Wrapper className={fontSize === '20px' ? 'selected' : ''} action={() => setFontSize('20px')}>A</Wrapper>
						</div>
					</div>
					<div className="FontSize__preview">
						<Text className="FontSize__preview__text" size="normal">{`${formatMessage(messages.preview)}:`}</Text>
						<div data-size={sizes[fontSize]} className="FontSize__preview__bubble">
							{formatMessage(messages.example)}
						</div>
					</div>
					<div className="FontSize__actions">
						<Button isDark click={onClose}>{formatMessage(messages.cancel)}</Button>
						<Button click={handleChange}>{formatMessage(messages.save)}</Button>
					</div>
				</div>
			</div>
		</Modal>
	);
};

const mapStateToProps = state => ({
	agent: state.agent.info
});


const mapActionsToProps = dispatch => ({
	updateSettings: requestInfo => dispatch(actions.updateSettings(requestInfo)),
	addNotification: notification => dispatch(actions.addNotification(notification))
});

FontSize.propTypes = {
	agent: PropTypes.shape({
		settings: PropTypes.shape({
			fontSize: PropTypes.string
		}),
		account: PropTypes.shape({
			id: PropTypes.number
		}),
		id: PropTypes.number
	}).isRequired,
	onClose: PropTypes.func.isRequired,
	updateSettings: PropTypes.func.isRequired,
	intl: PropTypes.shape({
		formatMessage: PropTypes.func.isRequired
	}).isRequired
};

export default connect(mapStateToProps, mapActionsToProps)(injectIntl(FontSize));
