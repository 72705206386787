import React from 'react';
import PropTypes from 'prop-types';

import './index.sass';
import Text from '../../Atoms/Text';
import Wrapper from '../../Atoms/Wrapper';

const SelectOptionsItem = ({ value, action }) => (
	<Wrapper
		className="SelectOptionsItem"
		action={() => action(value)}
	>
		<Text>{value}</Text>
	</Wrapper>
);

SelectOptionsItem.propTypes = {
	value: PropTypes.string.isRequired,
	action: PropTypes.func.isRequired
};

export default SelectOptionsItem;
