import React, {
	useState, useCallback, useRef, useEffect
} from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import './index.sass';
import messages from './translations';
import * as actions from '../../../store/actions';
import { useMountEffect } from '../../../shared/utility';
import Spinner from '../../Atoms/Spinner';
import InfiniteList from '../../Molecules/InfiniteList';
import Text from '../../Atoms/Text';
import Modal from '../../Molecules/Modal';
import Button from '../../Molecules/Buttons/Button';
import Wrapper from '../../Atoms/Wrapper';
import ImgSvg from '../../Atoms/ImgSvg';
import CommonInput from '../../Molecules/Inputs/Common';
import MultiSelect from '../../Molecules/Inputs/MultiSelect';

const expirationTimeOptions = [
	{ value: 172800, label: '48h' },
	{ value: 86400, label: '24h' },
	{ value: 43200, label: '12h' },
	{ value: 21600, label: '6h' },
	{ value: 3600, label: '1h' }
];

const WhatsAppTemplates = ({
	customerKey,
	channelWhatsapp,
	onClose,
	fetchTemplates,
	whatsAppTemplates,
	whatsAppTemplatesStatus,
	updateCreatingInteraction,
	senderId,
	intl,
	interactionHash
}) => {
	const [selectedTemplate, setSelectedTemplate] = useState({});
	const [searchValue, setSearchValue] = useState('');
	const [openSelect, setOpenSelect] = useState('');
	const [nextStep, setNextStep] = useState(false);
	const [expirationTime, setExpirationTime] = useState(86400);
	const [templatePreview, setTemplatePreview] = useState();
	const [formattedFields, setFormattedFields] = useState([]);

	const ref = useRef();

	useMountEffect(() => { fetchTemplates({ senderId }); });

	useEffect(() => {
		let parsedTextReference = selectedTemplate.textReference;
		formattedFields.forEach(({ name, value }) => {
			if (value !== '') parsedTextReference = parsedTextReference.replace(`{{${name}}}`, value);
		});

		setTemplatePreview(parsedTextReference);
	}, [formattedFields, selectedTemplate.textReference, setTemplatePreview]);

	const { loading } = whatsAppTemplatesStatus;
	const { formatMessage } = intl;

	const initWhatsAppTemplate = (fields, message, expiration) => {
		window.omzVish.newInteraction(interactionHash, 'WHATSAPP', customerKey, true, null, {
			id: selectedTemplate.id,
			senderId: selectedTemplate.senderId,
			whatsappId: channelWhatsapp,
			expirationTime: expiration,
			message,
			fields
		});
		onClose();
		updateCreatingInteraction({ type: 'WHATSAPP', info: channelWhatsapp });
	};

	const onSearch = useCallback((search) => {
		setTimeout(() => {
			if ((ref.current
					&& ref.current.input
					&& ref.current.input.current
					&& ref.current.input.current.value === search) || search === '') {
				fetchTemplates({ senderId, search });
			}
		}, 1200);
	}, [senderId, fetchTemplates]);

	const onChange = useCallback((e) => {
		const newValue = e.target.value;

		setSearchValue(newValue);
		onSearch(newValue);
	}, [onSearch]);

	const changeFields = (name, value) => setFormattedFields(formattedFields.map((field) => {
		if (field.name === name) return { ...field, value };
		return field;
	}));

	const activeButton = () => !!Object.keys(selectedTemplate).length && !formattedFields.some(({ value }) => value === '');

	return (
		<Modal size="half-screen" onClose={onClose} className="WhatsappTemplates">
			<div className="WhatsappTemplates__container">
				<Text size="larger">{formatMessage(messages.title)}</Text>
				{!nextStep ? (
					<>
						<div className="WhatsappTemplates__select">
							<div
								onClick={() => setOpenSelect(!openSelect)}
								role="presentation"
								className={`WhatsappTemplates__select__header ${openSelect ? 'WhatsappTemplates__select__header--active' : ''}`}
							>
								{selectedTemplate.name ? (
									<p className="WhatsappTemplates__select__header__selected">
										<span>{selectedTemplate.name}</span>
										{selectedTemplate.textReference}
									</p>
								) : (<span>{formatMessage(messages.select)}</span>)}
								<ImgSvg name="arrow" />
							</div>
							{openSelect && (
								<div className="WhatsappTemplates__select__body">
									<div
										onClick={() => setOpenSelect(false)}
										role="presentation"
										className="WhatsappTemplates__select__body__overlay"
									/>
									<div className="WhatsappTemplates__list">
										{loading ? <Spinner /> : (
											<>
												<div className="WhatsappTemplates__list__search">
													<CommonInput
														placeholder={formatMessage(messages.placeholder)}
														onChange={onChange}
														value={searchValue}
														loading={loading}
														ref={ref}
													/>
												</div>
												{whatsAppTemplates.length > 0 ? (
													<InfiniteList customClass="WhatsappTemplates__list__form">
														{whatsAppTemplates.map((template, index) => (
															<div style={{ position: 'relative' }} key={template.id}>
																<Wrapper
																	className="WhatsappTemplates__list__item"
																	action={() => {
																		setSelectedTemplate(template);
																		setFormattedFields(() => template.fields.map(field => ({ name: field, value: '' })));
																		setOpenSelect(false);
																	}}
																	key={template.id}
																>
																	<Text size="bigger">{template.name}</Text>
																	<Text size="bigger">{template.textReference}</Text>
																</Wrapper>
																{whatsAppTemplates.length > 1 && (
																	<div className={`WhatsappTemplates__list__item__tooltip ${index === whatsAppTemplates.length - 1 ? 'WhatsappTemplates__list__item__tooltip--last' : ''}`}>
																		<p>{template.textReference}</p>
																	</div>
																)}
															</div>
														))}
													</InfiniteList>
												) : <Text>{formatMessage(messages.noTemplates)}</Text>}
											</>
										)}
									</div>
								</div>
							)}
						</div>
						<div className="WhatsappTemplates__preview">
							<span className="WhatsappTemplates__preview__header">{formatMessage(messages.previewTitle)}</span>
							<div className="WhatsappTemplates__preview__content">{templatePreview || selectedTemplate.textReference || formatMessage(messages.previewPlaceholder)}</div>
							<span className="WhatsappTemplates__preview__footer">{formatMessage(messages.previewDescription)}</span>
						</div>
						{Object.keys(selectedTemplate).length > 0 && !!selectedTemplate.fields.length && (
							<div className="WhatsappTemplates__fields">
								<div className="WhatsappTemplates__fields__header">
									{formattedFields.length > 0 ? (
										<>
											<Text size="large">{formatMessage(messages.headerWithFields)}</Text>
											<Text size="bigger">{formatMessage(messages.headerWithFieldsDescription)}</Text>
										</>
									) : (
										<Text size="large">{formatMessage(messages.header)}</Text>
									)}
								</div>
								<div className="WhatsappTemplates__fields__content">
									{formattedFields.length > 0 && (
										<InfiniteList customClass="WhatsappTemplates__fields__content">
											{formattedFields.map(({ name, value }) => (
												<CommonInput
													placeholder={`{{${name}}}`}
													label={`${formatMessage(messages.fillVariables)} ${name}`}
													value={value}
													onChange={e => changeFields(name, e.target.value)}
													showIcon={false}
													key={name}
												/>
											))}
										</InfiniteList>
									)}
								</div>
							</div>
						)}
					</>
				) : (
					<div className="WhatsappTemplates__expirationTime">
						<Text size="large">{formatMessage(messages.headerExpirationTime)}</Text>
						<Text size="bigger">{formatMessage(messages.descriptionExpirationTime)}</Text>

						<div className="WhatsappTemplates__expirationTime__select">
							<MultiSelect
								options={expirationTimeOptions}
								onChange={() => {}}
								setFilter={setExpirationTime}
								defaultValue={expirationTimeOptions.find(opt => opt.value === expirationTime)}
								keyName="period"
								width={230}
								isMultiple={false}
							/>
						</div>
					</div>
				)}

				<div className="WhatsappTemplates__actions">
					{nextStep ? (
						<>
							<Button
								secondary
								click={() => setNextStep(false)}
							>
								{formatMessage(messages.back)}
							</Button>
							<Button
								active={activeButton()}
								click={() => initWhatsAppTemplate(formattedFields, templatePreview, expirationTime ? Number(expirationTime) : null)}
							>
								{formatMessage(messages.sendMessage)}
							</Button>
						</>
					) : (
						<>
							<Button
								secondary
								click={onClose}
							>
								{formatMessage(messages.cancel)}
							</Button>
							<Button
								active={activeButton()}
								click={() => setNextStep(true)}
							>
								{formatMessage(messages.next)}
							</Button>
						</>
					)}
				</div>
			</div>
		</Modal>
	);
};

WhatsAppTemplates.propTypes = {
	onClose: PropTypes.func.isRequired,
	fetchTemplates: PropTypes.func.isRequired,
	whatsAppTemplates: PropTypes.arrayOf(PropTypes.shape({
		id: PropTypes.string,
		name: PropTypes.string,
		textReference: PropTypes.string,
		updatedAt: PropTypes.string
	})).isRequired,
	whatsAppTemplatesStatus: PropTypes.shape({
		loading: PropTypes.bool
	}).isRequired,
	customerKey: PropTypes.string.isRequired,
	updateCreatingInteraction: PropTypes.func.isRequired,
	channelWhatsapp: PropTypes.string.isRequired,
	senderId: PropTypes.string.isRequired,
	interactionHash: PropTypes.string.isRequired,
	intl: PropTypes.shape({
		formatMessage: PropTypes.func
	}).isRequired
};

const mapStateToProps = ({ agent }) => ({
	whatsAppTemplates: agent.whatsAppTemplates,
	whatsAppTemplatesStatus: agent.whatsAppTemplatesStatus
});

const mapActionsToProps = dispatch => ({
	fetchTemplates: info => dispatch(actions.fetchWhatsAppTemplates(info)),
	updateCreatingInteraction: info => dispatch(actions.updateCreatingInteraction(info))
});

export default connect(mapStateToProps, mapActionsToProps)(injectIntl(WhatsAppTemplates));
