import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import './index.sass';

const AvatarName = ({ name, url, status = '' }) => {
	const [classes, setClasses] = useState('');
	useEffect(() => {
		const currentClass = ['AvatarName'];

		if (name) {
			currentClass.push('AvatarName--withName');
		}

		if (status) {
			currentClass.push('AvatarName--status');
			if (['REGISTERED', 'ONLINE'].includes(status)) {
				currentClass.push('AvatarName--online');
			} else if (['REGISTERING', 'UNREGISTERING'].includes(status)) {
				currentClass.push('AvatarName--connecting');
			} else if (status === 'PREPAUSED') {
				currentClass.push('AvatarName--prepause');
			} else if (status === 'PAUSED') {
				currentClass.push('AvatarName--pause');
			} else {
				currentClass.push('AvatarName--offline');
			}
		}

		setClasses(currentClass.join(' '));
	}, [name, status]);

	const [names, setNames] = useState([]);
	useEffect(() => {
		setNames(name ? name.split(' ') : []);
	}, [name]);


	let avatarContent = (
		<div>
			{names.slice(0, 2).map(word => word.charAt(0)).join('')}
		</div>
	);

	if (url) avatarContent = <img src={url} alt="Agent" />;

	return (
		<div className={classes}>
			<div className="AvatarName__info">
				{avatarContent}
			</div>
		</div>
	);
};

AvatarName.propTypes = {
	name: PropTypes.string,
	url: PropTypes.string,
	status: PropTypes.oneOf(['', 'NOT_REGISTERED', 'REGISTERING', 'REGISTERED', 'UNREGISTERING', 'PREPAUSED', 'PAUSED'])
};

export default AvatarName;
