import React from 'react';
import { connect } from 'react-redux';
import { injectIntl, defineMessages } from 'react-intl';
import PropTypes from 'prop-types';
import moment from 'moment';

import './index.sass';
import * as actions from '../../../store/actions';
import ImgSvg from '../../Atoms/ImgSvg';
import { getTaskCustomerName } from '../../../shared/utility';
import ActiveInteractions from '../ActiveInteractions';
import Button from '../../Molecules/Buttons/Button';

const TaskSidebarInfo = ({
	selectedTask,
	fetchAgentTask,
	defineTaskSidebar,
	clearEditTask,
	intl,
	taskExtras
}) => {
	const { formatMessage } = intl;
	const messages = defineMessages({
		createdBy: {
			id: 'taskSidebarInfo.createdBy',
			defaultMessage: 'Criado por'
		},
		customer: {
			id: 'taskSidebarInfo.customer',
			defaultMessage: 'Cliente'
		},
		edit: {
			id: 'taskSidebarInfo.edit',
			defaultMessage: 'Editar'
		},
		opened: {
			id: 'taskSidebarInfo.opened',
			defaultMessage: 'Pendente'
		},
		finished: {
			id: 'taskSidebarInfo.finished',
			defaultMessage: 'Completa'
		},
		expired: {
			id: 'taskSidebarInfo.expired',
			defaultMessage: 'Expirada'
		},
		activeContact: {
			id: 'taskSidebarInfo.activeContact',
			defaultMessage: 'Entrar em contato'
		},
		measure: {
			id: 'taskSidebarInfo.measure',
			defaultMessage: 'Medida'
		},
		projectName: {
			id: 'taskSidebarInfo.projectName',
			defaultMessage: 'Nome do Projeto'
		},
		responsibleEngineer: {
			id: 'taskSidebarInfo.responsibleEngineer',
			defaultMessage: 'Engenheiro Responsável'
		},
		uninformed: {
			id: 'taskSidebarInfo.uninformed',
			defaultMessage: 'Não informado'
		}
	});
	const taskStatus = {
		OPENED: {
			sufix: 'opened',
			label: formatMessage(messages.opened)
		},
		FINISHED: {
			sufix: 'finished',
			label: formatMessage(messages.finished)
		},
		EXPIRED: {
			sufix: 'expired',
			label: formatMessage(messages.expired)
		}
	};

	let { verifiedMedida, verifiedNomeDoProjeto, verifiedEngenheiroResponsavel } = '';
	if (selectedTask && selectedTask.extra && selectedTask.extra.filters) {
		verifiedMedida = selectedTask.extra.filters.medida;
		verifiedNomeDoProjeto = selectedTask.extra.filters.nomeDoProjeto;
		verifiedEngenheiroResponsavel = selectedTask.extra.filters.engenheiroResponsavel;
	}

	const agentsNames = [];
	if (selectedTask && selectedTask.agents) {
		selectedTask.agents.map(agent => agentsNames.push(agent.name));
	}

	return (
		<div className="TaskSidebarInfo">
			<div
				className="TaskSidebarInfo__close"
				onClick={() => fetchAgentTask({})}
				onKeyPress={() => fetchAgentTask({})}
				role="presentation"
			>
				<ImgSvg name="close" />
			</div>
			<h3>{selectedTask.name}</h3>
			<div className="TaskSidebarInfo__data">
				<div>
					<p className="TaskSidebarInfo__item">{moment.utc(selectedTask.expiresAt).format('DD/MM/YYYY - HH:mm')}</p>
					<p className="TaskSidebarInfo__item">
						{formatMessage(messages.createdBy)}
						{' '}
						<strong>{agentsNames.join(', ')}</strong>
					</p>
					<p className="TaskSidebarInfo__item">
						{formatMessage(messages.customer)}
						{' '}
						<strong>{getTaskCustomerName(selectedTask.customer)}</strong>
					</p>
					{taskExtras && (
						<>
							<p className="TaskSidebarInfo__item">
								{formatMessage(messages.measure)}
								{': '}
								<strong>{verifiedMedida || formatMessage(messages.uninformed)}</strong>
							</p>
							<p className="TaskSidebarInfo__item">
								{formatMessage(messages.projectName)}
								{': '}
								<strong>{verifiedNomeDoProjeto || formatMessage(messages.uninformed)}</strong>
							</p>
							<p className="TaskSidebarInfo__item">
								{formatMessage(messages.responsibleEngineer)}
								{': '}
								<strong>
									{verifiedEngenheiroResponsavel || formatMessage(messages.uninformed)}
								</strong>
							</p>
						</>
					)}
				</div>
				{((taskExtras && taskStatus[selectedTask.status].sufix !== 'expired') || !taskExtras) && (
					<div className={`TaskSidebarInfo__status TaskSidebarInfo__status--${taskStatus[selectedTask.status].sufix}`}>
						{taskStatus[selectedTask.status].label}
					</div>
				)}
			</div>
			<div className="TaskSidebarInfo__actives">
				<p>{formatMessage(messages.activeContact)}</p>
				<ActiveInteractions
					fields={selectedTask.customer.fields}
					taskId={selectedTask.id}
					customerKey={selectedTask.customer.customerKey}
					emailType="activeEmail"
				/>
			</div>
			<p className="TaskSidebarInfo__description">{selectedTask.description}</p>
			<Button
				click={() => {
					clearEditTask();
					defineTaskSidebar('edit');
				}}
			>
				{formatMessage(messages.edit)}
			</Button>
		</div>
	);
};

TaskSidebarInfo.propTypes = {
	intl: PropTypes.shape({
		formatMessage: PropTypes.func.isRequired
	}).isRequired,
	selectedTask: PropTypes.shape({
		name: PropTypes.string.isRequired,
		agents: PropTypes.arrayOf(PropTypes.shape({
			name: PropTypes.string.isRequired
		}).isRequired),
		description: PropTypes.string.isRequired,
		createdAt: PropTypes.string.isRequired,
		customer: PropTypes.shape({
			fields: PropTypes.arrayOf(
				PropTypes.shape({
					name: PropTypes.string.isRequired
				})
			).isRequired,
			customerKey: PropTypes.string
		}).isRequired,
		extra: PropTypes.shape({
			filters: PropTypes.shape({
				nomeDoProjeto: PropTypes.string,
				engenheiroResponsavel: PropTypes.string,
				medida: PropTypes.string
			})
		}),
		expiresAt: PropTypes.string,
		status: PropTypes.string,
		id: PropTypes.number
	}).isRequired,
	fetchAgentTask: PropTypes.func.isRequired,
	defineTaskSidebar: PropTypes.func.isRequired,
	clearEditTask: PropTypes.func.isRequired,
	taskExtras: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
	taskExtras: state.agent.taskExtras
});

const mapActionsToProps = dispatch => ({
	defineTaskSidebar: content => dispatch(actions.defineTaskSidebar(content)),
	clearEditTask: () => dispatch(actions.clearEditTask())
});

export default connect(mapStateToProps, mapActionsToProps)(injectIntl(TaskSidebarInfo));
