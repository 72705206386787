import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';

import './index.sass';

const SlaTimer = ({
	endedAt,
	startedAt,
	slaTime,
	changeSlaStatus,
	color = '#FFF'
}) => {
	const [time, setTime] = useState('--');

	const getDateTimeRange = useCallback((date) => {
		const oneSecond = 1000;
		const oneMinute = oneSecond * 60;
		const oneHour = oneMinute * 60;
		const startDate = moment(date).add({ seconds: slaTime });

		const now = endedAt ? moment(endedAt) : moment();
		const elapsed = startDate - now;
		const parts = [];

		parts[0] = String(Math.floor((elapsed > 0 ? elapsed : elapsed * -1) / oneHour));
		parts[1] = String(Math.floor(((elapsed > 0 ? elapsed : elapsed * -1) % oneHour) / oneMinute));
		parts[2] = String(Math.floor((((elapsed > 0 ? elapsed : elapsed * -1) % oneHour) % oneMinute) / oneSecond));

		parts[0] = parts[0].padStart(2, '0');
		parts[1] = parts[1].padStart(2, '0');
		parts[2] = parts[2].padStart(2, '0');

		if (parts[0] > 0) {
			setTime(`${elapsed < 0 ? '-' : ''}${parts[0]}h ${parts[1]}m`);
		} else if (parts[1] > 0) {
			setTime(`${elapsed < 0 ? '-' : ''}${parts[1]}m ${parts[2]}s`);
		} else {
			setTime(`${elapsed < -1000 ? '-' : ''}${parts[2]}s`);
		}
	}, [endedAt, slaTime]);

	useEffect(() => {
		getDateTimeRange(startedAt);

		const interval = setInterval(() => {
			getDateTimeRange(startedAt);
		}, 1000);
		return () => clearInterval(interval);
	}, [getDateTimeRange, startedAt]);

	useEffect(() => {
		if (changeSlaStatus) {
			const endedTime = endedAt ? moment(endedAt) : moment();
			const interval = moment(startedAt).add({ seconds: slaTime }).diff(endedTime, 'seconds');

			switch (true) {
			case interval < 0:
				changeSlaStatus({ status: 'expired', notification: true });
				break;
			case interval < 20:
				changeSlaStatus({ status: 'almostExpired', notification: false });
				break;
			default:
				changeSlaStatus({ status: 'notExpired', notification: false });
				break;
			}
		}
	}, [time, changeSlaStatus, slaTime, startedAt, endedAt]);

	return (
		<div style={endedAt ? { color, border: `solid ${color} 1px` } : { background: color }} className="SlaTimer">
			{time}
		</div>
	);
};

SlaTimer.propTypes = {
	changeSlaStatus: PropTypes.func,
	color: PropTypes.string,
	startedAt: PropTypes.string.isRequired,
	endedAt: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
	slaTime: PropTypes.number.isRequired
};

export default SlaTimer;
