import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, injectIntl } from 'react-intl';

import './index.sass';
import ImgSvg from '../../Atoms/ImgSvg';
import Wrapper from '../../Atoms/Wrapper';


const messages = defineMessages({
	sendMessage: {
		id: 'chatInput.sendMessage',
		defaultMessage: 'Enviar mensagem'
	}
});

const Send = ({ disabled, onSend, intl }) => (
	<Wrapper disableKeyPressEvent={disabled} ariaLabel={intl.formatMessage(messages.sendMessage)} className={`Send ${disabled ? 'disabled' : ''}`} action={onSend}>
		Enviar
		<ImgSvg name="send-on" />
	</Wrapper>
);

Send.propTypes = {
	onSend: PropTypes.func.isRequired,
	intl: PropTypes.shape({
		formatMessage: PropTypes.func.isRequired
	}).isRequired,
	disabled: PropTypes.bool.isRequired
};

export default injectIntl(Send);
