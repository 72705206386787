import React from 'react';
import PropTypes from 'prop-types';

import './index.sass';
import Wrapper from '../../Atoms/Wrapper';
import Text from '../../Atoms/Text';


const NavBarItem = ({
	active,
	children,
	click,
	dataTestId = ''
}) => (
	<Wrapper
		className={`NavBarItem ${active ? 'NavBarItem--active' : ''}`}
		action={click}
		data-testid={dataTestId}
	>
		<Text size="bigger">{children}</Text>
	</Wrapper>
);

NavBarItem.propTypes = {
	active: PropTypes.bool,
	click: PropTypes.func.isRequired,
	dataTestId: PropTypes.string
};

export default NavBarItem;
