/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { saveAs } from 'file-saver';
import { v4 as uuidv4 } from 'uuid';
import { defineMessages, injectIntl } from 'react-intl';

// Actions
import { addNotification as addNotificationAction } from '../../../store/actions';

// Helper
import {
	isFileMessage,
	isImageMessage,
	isVideoMessage
} from './Helper';

import './index.sass';

const messages = defineMessages({
	previewUnavailable: {
		id: 'attachmentModal.previewUnavailable',
		defaultMessage: 'Pré-visualização indisponível'
	},
	downloadError: {
		id: 'attachmentModal.downloadError',
		defaultMessage: 'Não foi possível realizar o download do arquivo'
	}
});

function AttachmentModal({
	url,
	mimeType,
	onClose,
	messageId,
	intl,
	addNotification
}) {
	const base64ToBlob = (base64, type) => {
		const byteCharacters = atob(base64);
		const byteNumbers = new Array(byteCharacters.length);
		for (let i = 0; i < byteCharacters.length; i += 1) {
			byteNumbers[i] = byteCharacters.charCodeAt(i);
		}
		const byteArray = new Uint8Array(byteNumbers);
		return new Blob([byteArray], { type });
	};

	const getFileExtension = (type) => {
		const parts = type.split('/');
		return parts[1];
	};

	const downloadAttachment = () => {
		axios.get(`${process.env.OMZ_INTERACTIONS}/messages/blob/${messageId}`)
			.then((response) => {
				const { data, type, filename } = response.data;

				const blob = base64ToBlob(data, type);

				saveAs(blob, filename || `${uuidv4()}.${getFileExtension(type)}`);
			})
			.catch(() => {
				addNotification({
					content: intl.formatMessage(messages.downloadError),
					type: 'error'
				});
			});
	};

	const handleContentClick = (e) => {
		e.stopPropagation();
	};

	return (
		<div id="modal-attachment" className="modal is-active" role="dialog">
			<div className="modal-background" />
			<div className="is-flex is-flex-direction-column is-fullwidth is-fullheight is-max-z">
				<header className="Header is-flex is-flex-0 is-justify-content-flex-end p-6">
					<span className="icon has-text-white is-clickable is-medium mr-2" onClick={() => downloadAttachment()} role="button" tabIndex={0}>
						<i className="fa-light fa-download" />
					</span>
					<span className="icon has-text-white is-clickable is-medium" onClick={() => onClose()} role="button" tabIndex={0}>
						<i className="fa-light fa-xmark" />
					</span>
				</header>
				<div className="is-flex is-flex-1 is-align-items-center is-justify-content-center" style={{ maxHeight: '85vh' }} onClick={() => onClose()}>
					{
						isVideoMessage(mimeType) && (
							<video className="Video" src={url} alt="media" controls onClick={handleContentClick}><track kind="captions" /></video>
						)
					}
					{
						isImageMessage(mimeType) && (
							<img className="Image" src={url} alt="media" onClick={handleContentClick} />
						)
					}
					{
						isFileMessage(mimeType) && (
							<object className="File has-text-centered" data={url} type={mimeType}>
								<span className="title is-2 has-text-white">{intl.formatMessage(messages.previewUnavailable)}</span>
							</object>
						)
					}
				</div>
			</div>
		</div>
	);
}

AttachmentModal.propTypes = {
	url: PropTypes.string.isRequired,
	mimeType: PropTypes.string.isRequired,
	onClose: PropTypes.func.isRequired,
	messageId: PropTypes.number.isRequired,
	intl: PropTypes.shape({
		formatMessage: PropTypes.func.isRequired
	}).isRequired,
	addNotification: PropTypes.func.isRequired
};

const mapStateToProps = () => ({});

const mapActionsToProps = dispatch => ({
	addNotification: info => dispatch(addNotificationAction(info))
});

export default connect(mapStateToProps, mapActionsToProps)(injectIntl(AttachmentModal));
