import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { defineMessages, injectIntl } from 'react-intl';

import './index.sass';
import * as actions from '../../../store/actions';
import InputText from '../../Molecules/Inputs/Form';
import { checkValidity, formatFormFields, parseFormItem } from '../../../shared/utility';
import Button from '../../Molecules/Buttons/Button';
import Text from '../../Atoms/Text';
import IconButton from '../../Molecules/Buttons/IconButton';
import Modal from '../../Molecules/Modal';

const messages = defineMessages({
	save: {
		id: 'addCustomer.save',
		defaultMessage: 'Adicionar'
	},
	main_identifier: {
		id: 'addCustomer.main_identifier',
		defaultMessage: 'Nome'
	},
	channel_phone: {
		id: 'addCustomer.channel_phone',
		defaultMessage: 'Telefone'
	},
	channel_email: {
		id: 'addCustomer.channel_email',
		defaultMessage: 'Email'
	},
	document: {
		id: 'addCustomer.document',
		defaultMessage: 'CPF'
	},
	addContact: {
		id: 'addCustomer.callToAction',
		defaultMessage: 'Adicionar Contato'
	},
	info: {
		id: 'addCustomer.info',
		defaultMessage: 'Preencha abaixo os dados do novo cadastro:'
	},
	alreadyRegistered: {
		id: 'addCustomer.alreadyRegistered',
		defaultMessage: 'já cadastrado'
	},
	addCustomer: {
		id: 'addCustomer.addCustomer',
		defaultMessage: 'Cliente adicionado'
	},
	addCustomerContent: {
		id: 'addCustomer.addCustomerContent',
		defaultMessage: 'Dados inseridos'
	},
	notAddCustomer: {
		id: 'addCustomer.notAddCustomer',
		defaultMessage: 'Cliente não adicionado'
	},
	notAddCustomerContent: {
		id: 'addCustomer.notAddCustomerContent',
		defaultMessage: 'Verifique os dados do formulário'
	}
});

class AddCustomer extends Component {
	state = { formControls: [], isFormValid: false }

	componentDidMount() {
		const { formFields } = this.props;
		if (formFields) this.setState({ formControls: formatFormFields(formFields) });
	}

	componentDidUpdate() {
		const { addCustomerStatus } = this.props;
		if (addCustomerStatus.success) this.closeAddCustomer();
	}

	static getDerivedStateFromProps(props, state) {
		const {
			addCustomerStatus,
			intl,
			addNotification,
			addCustomerClear
		} = props;
		const { formatMessage } = intl;
		const { errors, success } = addCustomerStatus;

		if (errors) {
			const updatedFormControls = state.formControls.map((field) => {
				const { label, name } = field;
				const updatedField = { ...field };
				const hasError = errors[name];
				if (hasError === 'taken') {
					updatedField.validationMessage = `${label} ${formatMessage(messages.alreadyRegistered)}`;
					updatedField.isValid = false;
				}
				return updatedField;
			});

			addNotification({
				title: formatMessage(messages.notAddCustomer),
				content: formatMessage(messages.notAddCustomerContent),
				type: 'error'
			});

			addCustomerClear();

			return { formControls: updatedFormControls, isFormValid: false };
		}

		if (success) {
			addNotification({
				title: formatMessage(messages.addCustomer),
				content: formatMessage(messages.addCustomerContent),
				type: 'success'
			});

			addCustomerClear();
		}

		return null;
	}

	inputChangedHandler = (event, inputIdentifier) => {
		const { intl } = this.props;
		const { formControls } = this.state;
		const updatedControls = formControls.map((formItem) => {
			if (formItem.id === inputIdentifier) {
				const { isValid, validationMessage } = checkValidity(
					event.target.value,
					formItem.validation,
					formItem.label
				);

				let formattedMessage = '';
				if (Object.keys(validationMessage).length > 0) {
					const { label, intlObject } = validationMessage;
					formattedMessage = `${label} ${intl.formatMessage(intlObject)}`;
				}

				return {
					...formItem,
					value: event.target.value,
					isValid,
					validationMessage: formattedMessage,
					wasTouched: true
				};
			}
			return formItem;
		});

		let isFormValid = true;
		updatedControls.forEach(({
			validation,
			wasTouched,
			value,
			isValid
		}) => {
			if (validation.isRequired && !wasTouched && value !== '') {
				isFormValid = !isValid && isFormValid;
			} else if (validation.isRequired || wasTouched) {
				isFormValid = isValid && isFormValid;
			}
		});

		this.setState({ formControls: updatedControls, isFormValid });
	}

	onAddCustomer = () => {
		const { isFormValid, formControls } = this.state;
		const { addCustomer, accountId } = this.props;

		const fields = {};
		formControls.forEach((formItem) => {
			fields[formItem.name] = parseFormItem(formControls, formItem, false);
		});

		if (isFormValid) addCustomer({ accountId, fields });
	}

	addMoreFields = (fieldName) => {
		const { formControls } = this.state;
		let field = {};
		let index = 0;
		const updatedContols = formControls.map((control, controlIndex) => {
			if (fieldName === control.name) {
				field = {
					...control,
					id: new Date().getTime(),
					value: '',
					createdField: true
				};
				index = controlIndex;
				return control;
			}
			return control;
		});

		updatedContols.splice(index + 1, 0, field);

		this.setState({
			formControls: updatedContols,
			isFormValid: false
		});
	}

	removeField = (fieldId) => {
		const { formControls } = this.state;
		this.setState({ formControls: formControls.filter(({ id }) => id !== fieldId) });
	};

	closeAddCustomer = () => {
		const { addCustomerClear, hideAddCustomer } = this.props;
		addCustomerClear();
		hideAddCustomer();
	}

	render() {
		const { formControls, isFormValid } = this.state;
		const { intl, addCustomerStatus, onClose } = this.props;
		const { loading } = addCustomerStatus;
		const { formatMessage } = intl;

		return (
			<Modal className="AddCustomer" onClose={onClose}>
				<div className="AddCustomer__info">
					<Text size="biggest">{formatMessage(messages.addContact)}</Text>
					<Text>{formatMessage(messages.info)}</Text>
				</div>
				<div className="AddCustomer__form">
					{formControls && formControls.map(({
						value,
						wasTouched,
						id,
						validationMessage,
						isValid,
						name,
						label,
						validation,
						isMultiple,
						createdField
					}) => {
						const { isRequired } = validation;
						const defaultLocale = localStorage.getItem('locale') === 'pt';
						const translateFields = ['main_identifier', 'document', 'channel_phone', 'channel_email'];
						const translatedLabel = translateFields.includes(name) && !defaultLocale
							? formatMessage(messages[name])
							: label;

						return (
							<div className="AddCustomer__form__item" key={id}>
								<InputText
									label={isRequired ? `${translatedLabel}*` : translatedLabel}
									validationMessage={validationMessage}
									value={value || ''}
									onChange={event => this.inputChangedHandler(event, id)}
									touched={wasTouched}
									valid={isValid}
								/>
								{isMultiple && !createdField && (
									<IconButton
										square
										name="plus"
										fill="green"
										click={() => this.addMoreFields(name)}
									/>
								)}
								{createdField && (
									<IconButton
										square
										name="cross"
										fill="red"
										click={() => this.removeField(id)}
									/>
								)}
							</div>
						);
					})}
					<Button
						active={isFormValid && !loading}
						click={this.onAddCustomer}
						disabled={loading}
					>
						<Text size="bigger">{formatMessage(messages.save)}</Text>
					</Button>
				</div>
			</Modal>
		);
	}
}
const mapStateToProps = state => ({
	formFields: state.formFields.fields,
	accountId: state.agent.info.account.id,
	showingAddCustomer: state.interface.showingAddCustomer,
	addCustomerStatus: state.agent.addCustomerStatus
});

const mapActionsToProps = dispatch => ({
	updateCustomer: customerInfo => dispatch(actions.updateCustomer(customerInfo)),
	addCustomer: info => dispatch(actions.addCustomer(info)),
	addCustomerClear: () => dispatch(actions.addCustomerClear()),
	hideAddCustomer: () => dispatch(actions.hideAddCustomer()),
	addNotification: notification => dispatch(actions.addNotification(notification))
});

AddCustomer.propTypes = {
	formFields: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
	accountId: PropTypes.number.isRequired,
	intl: PropTypes.shape({
		formatMessage: PropTypes.func.isRequired
	}).isRequired,
	addCustomer: PropTypes.func.isRequired,
	addCustomerStatus: PropTypes.shape({
		loading: PropTypes.bool,
		errors: PropTypes.oneOfType([PropTypes.bool, PropTypes.shape({})]),
		success: PropTypes.bool
	}).isRequired,
	addCustomerClear: PropTypes.func.isRequired,
	hideAddCustomer: PropTypes.func.isRequired,
	onClose: PropTypes.func.isRequired
};

export default connect(mapStateToProps, mapActionsToProps)(injectIntl(AddCustomer));
