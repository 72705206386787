import React, { useState } from 'react';
import PropTypes from 'prop-types';

import './index.sass';
import Bubble from '../../../Atoms/Bubble';
import AttachmentModal from '../../../Sirius/AttachmentModal';

const MessageVideo = ({ messageInfo, children }) => {
	const [attachmentIsOpen, setAttachmentIsOpen] = useState(false);

	return (
		<>
			<Bubble className="MessageVideo" timeOnBottom messageInfo={messageInfo}>
				{children}
				<div
					className="ExpandFile is-flex is-align-items-center is-justify-content-center has-background-white is-clickable"
					onClick={() => setAttachmentIsOpen(true)}
					role="button"
					tabIndex={0}
				>
					<span className="icon is-medium has-text-dark">
						<i className="fa-light fa-film" />
					</span>
				</div>
				<video src={messageInfo.url} controls><track kind="captions" /></video>
				{messageInfo.content}
			</Bubble>
			{
				attachmentIsOpen && (
					<AttachmentModal
						url={messageInfo.url}
						mimeType={messageInfo.contentType}
						messageId={messageInfo.messageId}
						onClose={() => setAttachmentIsOpen(false)}
					/>
				)
			}
		</>
	);
};

MessageVideo.propTypes = {
	messageInfo: PropTypes.shape({
		url: PropTypes.string,
		content: PropTypes.string,
		contentType: PropTypes.string,
		messageId: PropTypes.number
	}).isRequired
};

export default MessageVideo;
