import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import { defineMessages, injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import { QUEUE_INTERACTION_STATES, ACTIVE_INTERACTION_STATES } from '../../../shared/consts';
import { sortInteractionsBySla } from '../../../shared/utility';
import * as actions from '../../../store/actions';
import Alert from '../../Atoms/Alert';
import ImgSvg from '../../Atoms/ImgSvg';
import Spinner from '../../Atoms/Spinner';
import Text from '../../Atoms/Text';
import InfiniteList from '../../Molecules/InfiniteList';
import ClientMessage from '../ClientMessage';
import './index.sass';

const messages = defineMessages({
	headerQueue: {
		id: 'interactionList.headerQueue',
		defaultMessage: 'Em fila'
	},
	headerInbox: {
		id: 'interactionList.headerInbox',
		defaultMessage: 'Pendentes'
	},
	talkingEmpty: {
		id: 'interactionList.talkingEmpty',
		defaultMessage: 'Sem conversas em andamento'
	},
	queueEmpty: {
		id: 'interactionList.queueEmpty',
		defaultMessage: 'Você não tem conversas na fila'
	},
	talkingEmptyDescription: {
		id: 'interactionList.talkingEmptyDescription',
		defaultMessage: 'Quando um cliente entrar em contato, a conversa ficará ativa e aparecerá aqui automaticamente para você iniciar o atendimento.'
	},
	queueEmptyDescription: {
		id: 'interactionList.queueEmptyDescription',
		defaultMessage: 'Por enquanto, todos os clientes já foram atendidos.'
	},
	inboxEmpty: {
		id: 'interactionList.inboxEmpty',
		defaultMessage: 'Você não tem conversas pendentes'
	},
	inboxEmptyDescription: {
		id: 'interactionList.inboxEmptyDescription',
		defaultMessage: 'Conversas iniciadas e que foram selecionadas como pendente por você ou outra pessoa, aparecerão aqui.'
	},
	missedEmpty: {
		id: 'interactionList.missedEmpty',
		defaultMessage: 'Você não tem conversas inativas'
	},
	missedEmptyDescription: {
		id: 'interactionList.missedEmptyDescription',
		defaultMessage: 'Conversas não atendidas, fora do horário de atendimento ou expiradas, aparecerão aqui.'
	},
	newInteraction: {
		id: 'interactionList.newInteraction',
		defaultMessage: 'Nova Interação'
	},
	whereWillBeShown: {
		id: 'interactionList.whereWillBeShown',
		defaultMessage: 'Suas interações serão exibidas aqui'
	},
	showing: {
		id: 'interactionList.showing',
		defaultMessage: 'Exibindo'
	},
	of: {
		id: 'interactionList.of',
		defaultMessage: 'de'
	},
	conversations: {
		id: 'interactionList.conversations',
		defaultMessage: 'conversas'
	},
	dynamicList: {
		id: 'interactionList.dynamicList',
		defaultMessage: 'Lista dinâmica'
	},
	slaServiceTime: {
		id: 'interactionList.slaServiceTime',
		defaultMessage: 'Atendimento'
	},
	slaQueuedTime: {
		id: 'interactionList.slaQueuedTime',
		defaultMessage: 'Espera'
	},
	slaFirstReplyTime: {
		id: 'interactionList.slaFirstReplyTime',
		defaultMessage: 'Primeira resposta'
	},
	slaMessageTime: {
		id: 'interactionList.slaMessageTime',
		defaultMessage: 'Mensagem'
	}
});

const InteractionList = ({
	currentInteractionHash,
	talkingInteractions,
	interactions,
	pendingInteractions,
	missedInteractions,
	filteredInteractions,
	filteredPendingInteractions,
	filteredMissedInteractions,
	interactionList,
	fetchInboxInteractionsStatus = {},
	fetchQueueInteractionsStatus = {},
	fetchMissedInteractionsStatus = {},
	searchInteractionsStatus = {},
	loadMoreInteractions,
	isRingingInteraction,
	shouldNotify,
	agent,
	intl,
	defineSelectedInteraction,
	setCurrentInteraction,
	clearPendingMessages,
	searchValue
}) => {
	const { formatMessage } = intl;
	const interactionLists = {
		talking: { value: talkingInteractions, status: fetchQueueInteractionsStatus },
		queue: { value: interactions, status: fetchQueueInteractionsStatus },
		inbox: { value: pendingInteractions, status: fetchInboxInteractionsStatus },
		missed: { value: missedInteractions, status: fetchMissedInteractionsStatus }
	};
	const filteredInteractionsLists = {
		queue: { value: filteredInteractions, status: searchInteractionsStatus },
		inbox: { value: filteredPendingInteractions, status: searchInteractionsStatus },
		missed: { value: filteredMissedInteractions, status: searchInteractionsStatus }
	};

	const currentList = useMemo(() => {
		const currentInteractionList = searchValue ? filteredInteractionsLists : interactionLists;

		return sortInteractionsBySla(currentInteractionList[interactionList].value, interactionList);
	}, [interactionLists, interactionList, searchValue, filteredInteractionsLists]);

	const hideInteractionFeature = agent.features.some(({ name }) => name === 'hide_messages_preview');

	const onSelectClient = useCallback((interaction) => {
		const { interactionHash } = interaction;

		if (hideInteractionFeature) return () => {};
		if (currentInteractionHash && interactionHash === currentInteractionHash) return defineSelectedInteraction('');
		clearPendingMessages(interactionHash);
		setCurrentInteraction(interaction);
		return defineSelectedInteraction(interactionHash);
	}, [hideInteractionFeature, clearPendingMessages, currentInteractionHash, defineSelectedInteraction, setCurrentInteraction]);

	return (
		<InfiniteList customClass="InteractionList" onBottom={() => loadMoreInteractions(interactionList)}>
			<Alert
				notify={isRingingInteraction && shouldNotify}
				type="ringing"
				content={formatMessage(messages.newInteraction)}
				loop
			/>
			{currentList.length > 0 ? (
				<div className="InteractionList__section" data-testid="component-interaction-list-current-list">
					{currentList.map((interaction, index) => (
						<ClientMessage
							key={interaction.id}
							interactionInfo={interaction}
							onSelect={onSelectClient}
							selected={currentInteractionHash === interaction.interactionHash}
							data-testid="component-interaction-list-current-list-item"
							messageIndex={index}
						/>
					))}
				</div>
			) : (
				<div className="InteractionList__empty" data-testid="component-interaction-list-empty">
					{Object.values(interactionLists).some(({ status }) => status.loading) ? <Spinner /> : (
						<>
							<div className="InteractionList__empty__image" data-testid="component-interaction-list-empty-image">
								<ImgSvg name="empty" />
							</div>
							<span className="InteractionList__empty__info" data-testid="component-interaction-list-empty-info">
								<Text data-testid="component-interaction-list-empty-info">{formatMessage(messages[`${interactionList}Empty`])}</Text>
								<Text size="bigger" data-testid="component-interaction-list-empty-info">{formatMessage(messages[`${interactionList}EmptyDescription`])}</Text>
							</span>
						</>
					)}
				</div>
			)}
		</InfiniteList>
	);
};

const mapStateToProps = state => ({
	agent: state.agent.info,
	currentInteractionHash: state.interaction.currentInteractionHash,
	talkingInteractions: state.interaction.interactions.filter(({ currentState, rejected = false }) => (
		ACTIVE_INTERACTION_STATES.includes(currentState) || rejected
	)),
	interactions: state.interaction.interactions.filter(({ currentState }) => (
		QUEUE_INTERACTION_STATES.includes(currentState)
	)),
	pendingInteractions: state.interaction.pendingInteractions,
	missedInteractions: state.interaction.missedInteractions,
	filteredInteractions: state.interaction.filteredInteractions,
	filteredPendingInteractions: state.interaction.filteredPendingInteractions,
	filteredMissedInteractions: state.interaction.filteredMissedInteractions,
	fetchInboxInteractionsStatus: state.interaction.fetchInboxInteractionsStatus,
	fetchQueueInteractionsStatus: state.interaction.fetchQueueInteractionsStatus,
	fetchMissedInteractionsStatus: state.interaction.fetchMissedInteractionsStatus,
	searchInteractionsStatus: state.interaction.searchInteractionsStatus,
	isRingingInteraction: state.interaction.interactions.concat(state.interaction.pendingInteractions)
		.some(interaction => interaction.currentState === 'RINGING'),
	shouldNotify: state.agent.notify
});

const mapActionsToProps = dispatch => ({
	defineSelectedInteraction: (interactionHash) => {
		dispatch(actions.defineSelectedInteraction(interactionHash));
	},
	setCurrentInteraction: (interaction) => {
		dispatch(actions.setCurrentInteraction(interaction));
	},
	clearPendingMessages: interactionHash => dispatch(actions.clearPendingMessages(interactionHash))
});

InteractionList.propTypes = {
	agent: PropTypes.shape({
		features: PropTypes.arrayOf(PropTypes.shape({
			status: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
		}))
	}).isRequired,
	talkingInteractions: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.number
		})
	).isRequired,
	interactions: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.number
		})
	),
	pendingInteractions: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.number
		})
	),
	missedInteractions: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.number
		})
	),
	filteredInteractions: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.number
		})
	),
	filteredPendingInteractions: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.number
		})
	),
	filteredMissedInteractions: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.number
		})
	),
	fetchInboxInteractionsStatus: PropTypes.shape({
		loading: PropTypes.bool,
		error: PropTypes.bool
	}),
	fetchQueueInteractionsStatus: PropTypes.shape({
		loading: PropTypes.bool,
		error: PropTypes.bool
	}),
	fetchMissedInteractionsStatus: PropTypes.shape({
		loading: PropTypes.bool,
		error: PropTypes.bool
	}),
	searchInteractionsStatus: PropTypes.shape({
		loading: PropTypes.bool,
		error: PropTypes.bool
	}),
	defineSelectedInteraction: PropTypes.func.isRequired,
	setCurrentInteraction: PropTypes.func.isRequired,
	interactionList: PropTypes.oneOf(['talking', 'queue', 'inbox', 'missed']).isRequired,
	loadMoreInteractions: PropTypes.func,
	currentInteractionHash: PropTypes.string.isRequired,
	isRingingInteraction: PropTypes.bool.isRequired,
	clearPendingMessages: PropTypes.func.isRequired,
	shouldNotify: PropTypes.bool.isRequired,
	searchValue: PropTypes.string,
	intl: PropTypes.shape({
		formatMessage: PropTypes.func.isRequired
	}).isRequired
};

export default connect(mapStateToProps, mapActionsToProps)(injectIntl(InteractionList));
