import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Sound from 'react-sound';
import { defineMessages, injectIntl } from 'react-intl';

import { newNotification } from '../../../shared/utility';

const messages = defineMessages({
	newNotification: {
		id: 'alert.newNotification',
		defaultMessage: 'Nova notificação'
	},
	newInteraction: {
		id: 'alert.newInteraction',
		defaultMessage: 'Nova Interação'
	},
	newMessage: {
		id: 'alert.newMessage',
		defaultMessage: 'Nova Mensagem'
	}
});

const Alert = ({
	notify,
	type,
	content = '',
	onCancelNotify = () => {},
	onCancelBrowserNotify = () => {},
	showBrowserNotification,
	loop,
	intl
}) => {
	const autoLoad = true;
	const [title, setTitle] = useState('newNotification');
	const [loaded, setLoaded] = useState(false);

	useEffect(() => {
		if (notify && type !== 'ringing') {
			if (type === 'new_message') setTitle('newMessage');
			if (type === 'new_interaction') setTitle('newInteraction');
		}
	}, [notify, type]);

	useEffect(() => {
		if (notify && type !== 'ringing') {
			if (showBrowserNotification) {
				newNotification(intl.formatMessage(messages[title]), content);
				onCancelBrowserNotify();
			}

			if (!notify) onCancelNotify();
		}
	}, [
		notify,
		type,
		showBrowserNotification,
		content,
		onCancelBrowserNotify,
		onCancelNotify,
		intl,
		title
	]);

	const onLoad = () => {
		setLoaded(true);
	};

	const sounds = {
		autoAcceptInteraction: 'https://zchat-customer-audios-prd.s3.amazonaws.com/Omnize/notification_new.aac',
		ringing: 'https://zchat-customer-audios-prd.s3.amazonaws.com/Omnize/OpeningAudio.mp3',
		newMessage: 'https://zchat-customer-audios-prd.s3.amazonaws.com/Omnize/Notification.mp3'
	};

	return type !== 'new_interaction' && (
		<Sound
			url={sounds[type] || ''}
			playStatus={(notify && loaded) ? Sound.status.PLAYING : Sound.status.STOPPED}
			onFinishedPlaying={() => onCancelNotify()}
			onLoad={() => onLoad()}
			autoLoad={autoLoad}
			loop={loop}
		/>
	);
};

Alert.propTypes = {
	notify: PropTypes.bool.isRequired,
	type: PropTypes.oneOf(['newMessage', 'new_interaction', 'new_message', 'ringing', '']).isRequired,
	content: PropTypes.string,
	onCancelNotify: PropTypes.func,
	loop: PropTypes.bool,
	onCancelBrowserNotify: PropTypes.func,
	showBrowserNotification: PropTypes.bool,
	intl: PropTypes.shape({
		formatMessage: PropTypes.func.isRequired
	}).isRequired
};

export default injectIntl(Alert);
