/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import SelectDropdown from '../SelectDropdown';

function SelectCreatable({
	initialValues,
	initialSelected,
	label,
	placeholder,
	helper,
	sufix,
	onSelectItem,
	position = 'bottom',
	hasItemDefault = true,
	isReadonly,
	isCreatable,
	menuIsFullwidth = false,
	className,
	allowOnlyNumbers,
	selectItem
}) {
	const [createdValues, setCreatedValues] = useState([]);
	const [values, setValues] = useState([]);

	useEffect(() => {
		const sortedValues = [...initialValues, ...createdValues].sort((a, b) => a.value - b.value);

		setValues(sortedValues);
	}, [initialValues, createdValues]);

	const handleOnAddNewValue = (newValue) => {
		setCreatedValues(prevItens => [...prevItens, newValue]);
	};


	return (
		<div>
			<SelectDropdown.Root className={className} initialSelected={initialSelected}>
				<SelectDropdown.Trigger>
					{
						label && (
							<SelectDropdown.Label>
								{label}
							</SelectDropdown.Label>
						)
					}
					<SelectDropdown.ContentCreatable
						placeholder={placeholder}
						isReadonly={isReadonly}
						isCreatable={isCreatable}
						onAddNewOption={e => handleOnAddNewValue(e)}
						allowOnlyNumbers={allowOnlyNumbers}
						selectItem={selectItem}
					/>
				</SelectDropdown.Trigger>
				<SelectDropdown.MenuCreatable
					options={values}
					sufix={sufix}
					position={position}
					hasItemDefault={hasItemDefault}
					onAddNewOption={e => handleOnAddNewValue(e)}
					onSelectItem={option => onSelectItem(option)}
					menuIsFullwidth={menuIsFullwidth}
				/>
			</SelectDropdown.Root>
			{
				helper && (
					<SelectDropdown.Help>
						{helper}
					</SelectDropdown.Help>
				)
			}
		</div>
	);
}

export default SelectCreatable;
