import React, {
	useCallback
} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl, defineMessages } from 'react-intl';

import './index.sass';
import * as actions from '../../../store/actions';

import IconButton from '../../Molecules/Buttons/IconButton';
import ReferenceContent from '../../Molecules/Messages/ReferenceContent';

const messages = defineMessages({
	cancel: {
		id: 'replyMessage.cancel',
		defaultMessage: 'Cancelar'
	}
});

const ReplyMessage = ({
	selectedMessage = {},
	onHideReplyMessage,
	currentInteractionHash,
	intl
}) => {
	const onCloseReplyMessage = useCallback(() => onHideReplyMessage(currentInteractionHash), [onHideReplyMessage, currentInteractionHash]);

	return (
		<div className="ReplyMessage">
			<div className={`ReplyMessage__message ${selectedMessage.origin === 'INT_ADMIN' ? 'ReplyMessage__message--internal-message' : ''}`}>
				<ReferenceContent messageInfo={selectedMessage} />
			</div>
			<div className="ReplyMessage__closeReply">
				<IconButton
					tooltipPosition="top"
					name="close"
					fill="color-light"
					click={onCloseReplyMessage}
					info={intl.formatMessage(messages.cancel)}
				/>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => {
	const { interaction } = state;
	const { currentInteractionHash } = interaction;

	return ({
		currentInteractionHash
	});
};

const mapActionsToProps = dispatch => ({
	onHideReplyMessage: interactionHash => dispatch(actions.hideReplyMessage(interactionHash))
});

ReplyMessage.propTypes = {
	selectedMessage: PropTypes.shape({
		interactionType: PropTypes.string,
		contentType: PropTypes.string,
		isFromAgent: PropTypes.bool,
		agentPhoto: PropTypes.string,
		agentName: PropTypes.string,
		content: PropTypes.string,
		origin: PropTypes.string
	}),
	onHideReplyMessage: PropTypes.func.isRequired,
	currentInteractionHash: PropTypes.string.isRequired,
	intl: PropTypes.shape({
		formatMessage: PropTypes.func
	}).isRequired
};

export default connect(mapStateToProps, mapActionsToProps)(injectIntl(ReplyMessage));
