import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import './index.sass';
import * as actions from '../../../store/actions';
import ImgSvg from '../../Atoms/ImgSvg';
import Text from '../../Atoms/Text';
import Card from '../Card';
import Wrapper from '../../Atoms/Wrapper';

const iconNames = { warning: 'exclamation', error: 'close', success: 'checked' };

const Notification = ({ notification, onRemoveNotification, milisecondsToClose = 5000 }) => {
	const {
		type,
		title,
		content,
		id,
		automaticClose
	} = notification;

	if (automaticClose) setTimeout(() => onRemoveNotification(id), milisecondsToClose);

	return (
		<Card>
			<div className="Notification">
				<div className={`Notification__icon Notification__icon--${type}`}>
					<ImgSvg name={iconNames[type]} />
				</div>
				<div className="Notification__content">
					<Text size="big" className="Notification__content--responsive">{title}</Text>
					<Text size="big" className="Notification__content--responsive">{content}</Text>
				</div>
				<Wrapper className="Notification__close" action={() => onRemoveNotification(id)} data-test-id="closeNotification">
					<ImgSvg name="close" />
				</Wrapper>
			</div>
		</Card>
	);
};

Notification.propTypes = {
	notification: PropTypes.shape({
		type: PropTypes.oneOf(['success', 'warning', 'error']).isRequired,
		title: PropTypes.string.isRequired,
		content: PropTypes.string.isRequired,
		id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
		automaticClose: PropTypes.bool
	}).isRequired,
	onRemoveNotification: PropTypes.func.isRequired,
	milisecondsToClose: PropTypes.number
};

const mapActionsToProps = dispatch => ({
	onRemoveNotification: id => dispatch(actions.removeNotification(id))
});

export default connect(null, mapActionsToProps)(Notification);
