import axios from 'axios';
import * as actionTypes from './actionTypes';

export const fetchWidgetAndIntegrationsBegin = () => ({
	type: actionTypes.FETCH_WIDGET_AND_INTEGRATIONS_BEGIN
});
export const fetchWidgetAndIntegrationsSuccess = widgets => ({
	type: actionTypes.FETCH_WIDGET_AND_INTEGRATIONS_SUCCESS,
	payload: widgets
});
export const fetchWidgetAndIntegrationsFailed = () => ({
	type: actionTypes.FETCH_WIDGET_AND_INTEGRATIONS_FAILED
});
export const fetchWidgetAndIntegrations = () => (
	(dispatch) => {
		dispatch(fetchWidgetAndIntegrationsBegin());
		return axios.get(`${process.env.OMZ_ZAPI}/admin/widgets`, { dispatch })
			.then(({ data }) => {
				const { errorMessage, widgets } = data;
				if (errorMessage) {
					dispatch(fetchWidgetAndIntegrationsFailed(errorMessage));
				} else {
					dispatch(fetchWidgetAndIntegrationsSuccess(widgets));
				}
			}).catch(() => {});
	}
);
