import React from 'react';
import PropTypes from 'prop-types';
import { Picker } from 'emoji-mart';
import 'emoji-mart/css/emoji-mart.css';

import Backdrop from '../Backdrop';

const Emoji = ({ onSelectEmoji, onClose }) => (
	<>
		<Picker
			onSelect={onSelectEmoji}
			showPreview={false}
			showSkinTones={false}
		/>
		<Backdrop closeAction={onClose} clear higher />
	</>
);

Emoji.propTypes = {
	onSelectEmoji: PropTypes.func.isRequired,
	onClose: PropTypes.func.isRequired
};

export default Emoji;
