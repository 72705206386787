/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/forbid-prop-types */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ReactSelect, { components } from 'react-select';
import { defineMessages, injectIntl } from 'react-intl';
import { isEqual } from 'lodash';

import Button from '../../Buttons/Button';
import ImgSvg from '../../../Atoms/ImgSvg';

import './index.sass';


const messages = defineMessages({
	applyFilter: {
		id: 'multiSelect.applyFilter',
		defaultMessage: 'Aplicar Filtros'
	}
});

const MenuList = ({ ...data }) => (
	<div>
		{data.isMulti ? (
			<>
				<div className="MultiSelect__wrapper__options">
					<components.MenuList {...data} />
				</div>
				<div className="MultiSelect__wrapper__footer">
					<Button active={data.selectProps.applyFilterDisabled} click={data.selectProps.applyFilter}>{data.selectProps.applyFilterLabel}</Button>
				</div>
			</>
		) : <components.MenuList {...data} />}
	</div>
);

const ValueContainer = ({
	children,
	...data
}) => {
	if (!data.selectProps) return null;

	const { icon } = data.selectProps;

	return (
		<components.ValueContainer {...data} className="css-1fdsijx-ValueContainer">
			{icon && (
				<div className="MultiSelect__wrapper__icon">
					<ImgSvg color="#2c4fd8" name={icon} />
				</div>
			)}
			{' '}
			{children}
		</components.ValueContainer>
	);
};

const MultiSelect = ({
	isMultiple = true,
	onChange,
	options,
	keyName,
	defaultValue,
	placeholder,
	setFilter = () => {},
	width = 440,
	icon,
	intl
}) => {
	const [optionSelected, setOptionSelected] = useState(defaultValue);
	const [menuIsOpen, setMenuIsOpen] = useState(false);

	const { formatMessage } = intl;

	const applyFilter = (opts = optionSelected) => {
		if (isMultiple) {
			const selecteds = opts.map(opt => (opt.value)).toString() || undefined;
			setMenuIsOpen(false);
			setFilter(selecteds);
			onChange({ [keyName]: selecteds });
		} else {
			setFilter(opts.value);
			onChange({ [keyName]: opts.value });
		}
	};

	const handleChange = (opts, triggeredAction) => {
		if (!isMultiple || triggeredAction.action === 'clear' || triggeredAction.action === 'remove-value') {
			applyFilter(opts);
		}
		setOptionSelected(opts);
	};

	const Option = data => (
		<div className="MultiSelect__wrapper">
			<components.Option className={isMultiple ? 'isMultiple' : ''} {...data}>
				{/* eslint-disable-next-line jsx-a11y/label-has-for */}
				<label className="MultiSelect__wrapper__option" htmlFor={data.value}>
					{isMultiple && (
						<input
							id={data.value}
							type="checkbox"
							checked={data.isSelected}
							readOnly
						/>
					)}
					{data.label}
				</label>
			</components.Option>
		</div>
	);

	const MultiValue = (data) => {
		const maxToShow = 2;
		const overflow = data.getValue().slice(maxToShow);

		// eslint-disable-next-line no-nested-ternary
		return data.index < maxToShow ? (
			<components.MultiValue {...data} />
		) : data.index === maxToShow ? (
			<div className="MultiSelect__wrapper__length-info">
				+
				{overflow.length}
			</div>
		) : null;
	};

	return (
		<div style={{ width }} className="MultiSelect">
			<ReactSelect
				onMenuClose={() => {
					setMenuIsOpen(false);
					setOptionSelected(defaultValue);
				}}
				onMenuOpen={() => setMenuIsOpen(true)}
				menuIsOpen={menuIsOpen}
				options={options}
				isMulti={isMultiple}
				closeMenuOnSelect={!isMultiple}
				hideSelectedOptions={false}
				components={{
					Option,
					MultiValue,
					MenuList,
					ValueContainer
				}}
				isSearchable
				applyFilter={applyFilter}
				applyFilterLabel={formatMessage(messages.applyFilter)}
				applyFilterDisabled={!isEqual(defaultValue, optionSelected)}
				onChange={handleChange}
				value={optionSelected}
				defaultValue={defaultValue}
				placeholder={placeholder}
				icon={icon}
			/>
		</div>
	);
};

MultiSelect.propTypes = {
	options: PropTypes.arrayOf(PropTypes.shape({ value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]), label: PropTypes.string })).isRequired,
	isMultiple: PropTypes.bool,
	onChange: PropTypes.func.isRequired,
	setFilter: PropTypes.func,
	keyName: PropTypes.string.isRequired,
	defaultValue: PropTypes.oneOfType([
		PropTypes.shape({ value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]), label: PropTypes.string }),
		PropTypes.arrayOf(PropTypes.shape({ value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]), label: PropTypes.string }))
	]),
	placeholder: PropTypes.string,
	width: PropTypes.number,
	icon: PropTypes.string,
	intl: PropTypes.shape({
		formatMessage: PropTypes.func.isRequired
	}).isRequired
};

export default injectIntl(MultiSelect);
