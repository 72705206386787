import './index.sass';

import React, { useRef } from 'react';
import { defineMessages, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';

import Wrapper from '../Wrapper';
import ImgSvg from '../ImgSvg';

const messages = defineMessages({
	placeholder: {
		id: 'inputFile.placeholder',
		defaultMessage: 'Escolha um arquivo...'
	}
});

const InputFile = ({
	disabled,
	attachment = {},
	onAddAttachment,
	onDelAttachment,
	intl
}) => {
	const input = useRef();

	const handleAddAttachment = () => {
		const file = input.current.files[0];
		onAddAttachment(file);
	};

	const handleDelAttachment = () => {
		if (disabled) return;

		input.current.value = '';
		onDelAttachment();
	};

	return (
		<div className={disabled ? 'InputFile InputFile--disabled' : 'InputFile'}>
			<label className={attachment.url ? 'InputFile__label' : 'InputFile__label InputFile__label--rounded'} htmlFor="InputFile">
				<span>{intl.formatMessage(messages.placeholder)}</span>
				<input
					ref={input}
					type="file"
					id="InputFile"
					className="InputFile__input"
					onChange={handleAddAttachment}
					disabled={disabled}
				/>
			</label>
			{attachment.url && (
				<div className="InputFile__file">
					<a href={attachment.url} target="_blank" rel="noopener noreferrer" download>
						{attachment.filename}
					</a>
					<Wrapper action={handleDelAttachment}>
						<ImgSvg name="close" />
					</Wrapper>
				</div>
			)}
		</div>
	);
};


InputFile.propTypes = {
	disabled: PropTypes.bool,
	attachment: PropTypes.shape({
		filename: PropTypes.string,
		url: PropTypes.string
	}),
	onAddAttachment: PropTypes.func.isRequired,
	onDelAttachment: PropTypes.func.isRequired,
	intl: PropTypes.shape({
		formatMessage: PropTypes.func.isRequired
	}).isRequired
};

export default injectIntl(InputFile);
