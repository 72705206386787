import React from 'react';
import { connect } from 'react-redux';
import DashboardBox from '../../Organism/DashboardBox';

const DashboardTemplate = () => (
	<div className="Template__dashboard">
		<DashboardBox />
	</div>
);

const mapStateToProps = () => ({});

export default connect(mapStateToProps)(DashboardTemplate);
