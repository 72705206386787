import PropTypes from 'prop-types';
import React from 'react';
import { SvgLoader, SvgProxy } from 'react-svgmt';

const ImgSvg = ({ color = '#000', name }) => {
	const svgFile = require(`../../../assets/images/svg/${name}.svg`);

	return (
		<SvgLoader path={svgFile} data-testid={`component-imgsvg-${name}`}>
			<SvgProxy fill={color !== 'default' ? color : false} selector="path" />
		</SvgLoader>
	);
};

ImgSvg.propTypes = {
	name: PropTypes.string.isRequired,
	color: PropTypes.string
};

export default ImgSvg;
