import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import './index.sass';
import ImgSvg from '../../../Atoms/ImgSvg';
import Wrapper from '../../../Atoms/Wrapper';

const IconButton = ({
	name,
	info,
	square,
	rounded,
	fill = 'grey',
	className = '',
	ariaLabel = '',
	click = () => {},
	tooltipPosition = 'center',
	'data-testid': dataTestId
}) => {
	const [classes, setClasses] = useState('');
	useEffect(() => {
		const currentClasses = ['IconButton', `IconButton--${fill}`, className];

		if (square) currentClasses.push('IconButton--square');
		if (rounded) currentClasses.push('IconButton--rounded');
		setClasses(currentClasses.join(' '));
	}, [fill, square, rounded, className]);

	return (
		<Wrapper
			tooltipPosition={tooltipPosition}
			ariaLabel={ariaLabel}
			className={classes}
			action={click}
			info={info}
			data-testid={dataTestId}
		>
			<ImgSvg name={name} />
		</Wrapper>
	);
};

IconButton.propTypes = {
	click: PropTypes.func,
	fill: PropTypes.oneOf(['grey', 'red', 'white', 'green', 'blue', 'grey-light', 'color-light']),
	info: PropTypes.string,
	name: PropTypes.string.isRequired,
	square: PropTypes.bool,
	rounded: PropTypes.bool,
	className: PropTypes.string,
	ariaLabel: PropTypes.string,
	tooltipPosition: PropTypes.oneOf(['left', 'right', 'center', 'top']),
	'data-testid': PropTypes.string
};

export default IconButton;
