import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
	agentDashboard: {
		adherence: 0,
		averageServiceTime: '',
		productiveTime: '',
		channels: {
			ended: 0,
			transferred: 0,
			total: 0
		},
		voice: {
			ended: 0,
			transferred: 0,
			total: 0
		},
		mood: {
			npsDetractors: 0,
			npsNeutrals: 0,
			npsPromoters: 0,
			npsRate: 0,
			npsAverage: 0,
			starsAverage: 0,
			thumbsAverage: 0,
			thumbsPositive: 0,
			thumbsNegative: 0
		}
	},
	peersDashboard: {
		agents: {
			online: 0,
			preBreak: 0,
			break: 0,
			offline: 0
		},
		departments: {
			data: [],
			total: 0
		}
	},
	fetchAgentDashboardStatus: {
		loading: true,
		error: ''
	},
	fetchPeersDashboardStatus: {
		loading: true,
		error: ''
	}
};

const fetchAgentDashboard = (state, action) => {
	const {
		adherence,
		productiveTime,
		averageServiceTime,
		channels,
		voice,
		mood
	} = action.payload;

	return updateObject(state, {
		fetchAgentDashboardStatus: {
			loading: false
		},
		agentDashboard: {
			adherence,
			productiveTime,
			averageServiceTime,
			channels,
			voice,
			mood
		}
	});
};

const fetchPeersDashboard = (state, action) => {
	const {
		agents,
		departments
	} = action.payload;

	return updateObject(state, {
		fetchPeersDashboardStatus: {
			loading: false
		},
		peersDashboard: {
			agents,
			departments
		}
	});
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
	case actionTypes.FETCH_AGENT_DASHBOARD_BEGIN:
		return updateObject(state, {
			fetchAgentDashboardStatus: {
				loading: true
			}
		});
	case actionTypes.FETCH_AGENT_DASHBOARD_SUCCESS:
		return fetchAgentDashboard(state, action);
	case actionTypes.FETCH_AGENT_DASHBOARD_FAILED:
		return updateObject(state, {
			fetchAgentDashboardStatus: {
				loading: false
			}
		});
	case actionTypes.FETCH_PEERS_DASHBOARD_BEGIN:
		return updateObject(state, {
			fetchPeersDashboardStatus: {
				loading: true
			}
		});
	case actionTypes.FETCH_PEERS_DASHBOARD_SUCCESS:
		return fetchPeersDashboard(state, action);
	case actionTypes.FETCH_PEERS_DASHBOARD_FAILED:
		return updateObject(state, {
			fetchPeersDashboardStatus: {
				loading: false
			}
		});
	default:
		return state;
	}
};

export default reducer;
