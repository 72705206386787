import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';

import './index.sass';
import Spinner from '../../Atoms/Spinner';
import ImgSvg from '../../Atoms/ImgSvg';

const TaskCheckbox = ({
	loading,
	checked,
	onChecked,
	blockReopenTasks
}) => {
	const [isChecked, setIsChecked] = useState(false);
	useEffect(() => {
		setIsChecked(checked);
	}, [checked]);

	const handleCheck = useCallback(() => {
		if (!blockReopenTasks || !checked) onChecked(!checked);
	}, [blockReopenTasks, checked, onChecked]);

	return (
		<>
			{loading ? (
				<Spinner />
			) : (
				<div
					className={`TaskCheckbox${isChecked ? ' TaskCheckbox--active' : ''}`}
					onClick={() => handleCheck()}
					onKeyPress={() => handleCheck()}
					role="checkbox"
					aria-hidden="true"
					aria-checked={isChecked}
				>
					<ImgSvg name="checked" />
				</div>
			)}
		</>
	);
};

TaskCheckbox.propTypes = {
	onChecked: PropTypes.func.isRequired,
	checked: PropTypes.bool,
	blockReopenTasks: PropTypes.bool,
	loading: PropTypes.bool
};

export default TaskCheckbox;
