import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import './index.sass';

const CircleDisplay = ({
	small,
	animate,
	children,
	color = 'grey',
	className = ''
}) => {
	const [classes, setClasses] = useState('');
	useEffect(() => {
		const currentClasses = ['CircleDisplay'];

		if (color) currentClasses.push(`CircleDisplay--${color}`);
		if (small) currentClasses.push('CircleDisplay--small');
		if (animate) currentClasses.push('CircleDisplay--animate');
		setClasses(currentClasses.join(' '));
	}, [color, small, animate]);

	return <div className={`${classes} ${className}`}>{children}</div>;
};

CircleDisplay.propTypes = {
	color: PropTypes.oneOf(['green', 'red', 'grey', 'blue', 'white', 'primary']),
	small: PropTypes.bool,
	animate: PropTypes.bool,
	className: PropTypes.string
};

export default CircleDisplay;
