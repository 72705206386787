import React from 'react';
import PropTypes from 'prop-types';

import './index.sass';
import IconButton from '../../Buttons/IconButton';

const CallControls = ({
	enabledMic = true,
	enabledVideo = true,
	onToggleMic = () => {},
	onToggleVideo = () => {},
	onEnd = () => {}
}) => (
	<div className="CallControls">
		<div className="CallControls__buttons">
			<IconButton
				fill={enabledMic ? 'grey' : 'white'}
				name={enabledMic ? 'microphone-off' : 'microphone-off-off'}
				click={onToggleMic}
			/>
			<IconButton
				fill={enabledVideo ? 'grey' : 'white'}
				name={enabledVideo ? 'video-off' : 'video-turnoff-off'}
				click={onToggleVideo}
			/>
			<IconButton
				fill="red"
				name="hangup-off"
				click={onEnd}
			/>
		</div>
	</div>
);

CallControls.propTypes = {
	enabledMic: PropTypes.bool,
	enabledVideo: PropTypes.bool,
	onToggleMic: PropTypes.func,
	onToggleVideo: PropTypes.func,
	onEnd: PropTypes.func
};

export default CallControls;
