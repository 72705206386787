/* eslint-disable react/no-unknown-property */
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl, defineMessages } from 'react-intl';

import * as actions from '../../../store/actions';

import fullstory from '../../../shared/fullstory';

import './index.sass';
import ImgSvg from '../ImgSvg';
import Wrapper from '../Wrapper';

const messages = defineMessages({
	internalMessage: {
		id: 'internalMessageBubble.internalMessage',
		defaultMessage: 'Mensagem interna'
	},
	viewAll: {
		id: 'internalMessageBubble.viewAll',
		defaultMessage: 'Ver todas as mensagens privadas'
	},
	reply: {
		id: 'internalMessageBubble.reply',
		defaultMessage: 'Responder'
	}
});

const InternalMessageBubble = ({
	children,
	messageInfo,
	settings,
	showReplyMessage,
	currentInteractionHash,
	onHideReplyMessage,
	onShowReplyMessage,
	showInternalMessages,
	intl
}) => {
	const {
		isFromInternalAdmin,
		createdAt,
		messageId,
		externalId,
		isChatHistory
	} = messageInfo;

	const { fontSize = '14px' } = settings;
	const { formatMessage } = intl;

	const onSelectReplyMessage = useCallback(() => {
		if (showReplyMessage) onHideReplyMessage(currentInteractionHash, messageInfo);
		fullstory('[Agent > Messages > Internal messages] Clicked on the reply button to an internal message');
		return onShowReplyMessage(currentInteractionHash, messageInfo);
	}, [currentInteractionHash, showReplyMessage, onHideReplyMessage, onShowReplyMessage, messageInfo]);

	return (
		<div className={`InternalMessageBubble ${isChatHistory ? 'InternalMessageBubble--isChatHistory' : ''}`} externalid={externalId} messageid={messageId}>
			<div className="InternalMessageBubble__header">
				<span className="InternalMessageBubble__header__title">
					<ImgSvg name="lock" />
					{formatMessage(messages.internalMessage)}
				</span>
				<span className="InternalMessageBubble__header__time">{createdAt}</span>
			</div>
			<div style={{ fontSize }} className="InternalMessageBubble__content">
				{children}
			</div>
			{!isChatHistory && (
				<div className="InternalMessageBubble__footer">
					<Wrapper
						className="InternalMessageBubble__footer__button"
						action={() => {
							showInternalMessages();
							fullstory('[Agent > Messages > Internal messages] Clicked the button to see all internal messages');
						}}
					>
						{formatMessage(messages.viewAll)}
					</Wrapper>
					{isFromInternalAdmin && (
						<Wrapper
							className="InternalMessageBubble__footer__button"
							action={onSelectReplyMessage}
						>
							{formatMessage(messages.reply)}
							<ImgSvg name="reply" />
						</Wrapper>
					)}
				</div>
			)}
		</div>
	);
};

InternalMessageBubble.propTypes = {
	settings: PropTypes.shape({
		fontSize: PropTypes.string
	}).isRequired,
	messageInfo: PropTypes.shape({
		isFromAgent: PropTypes.bool,
		isFromInternalAdmin: PropTypes.bool,
		createdAt: PropTypes.string,
		status: PropTypes.string,
		attachments: PropTypes.arrayOf(PropTypes.string),
		interactionType: PropTypes.string,
		messageId: PropTypes.number,
		externalId: PropTypes.string,
		rejected: PropTypes.bool,
		isChatHistory: PropTypes.bool
	}).isRequired,
	showInternalMessages: PropTypes.func.isRequired,
	onShowReplyMessage: PropTypes.func.isRequired,
	onHideReplyMessage: PropTypes.func.isRequired,
	currentInteractionHash: PropTypes.string.isRequired,
	showReplyMessage: PropTypes.bool.isRequired,
	intl: PropTypes.shape({
		formatMessage: PropTypes.func.isRequired
	}).isRequired
};

const MapStateToProps = (state) => {
	const { interaction } = state;
	const { currentInteractionHash } = interaction;

	return ({
		currentInteractionHash,
		showReplyMessage: state.interface.showingReplyMessage.some(item => item.hash === currentInteractionHash),
		settings: state.agent.info.settings

	});
};

const MapActionsToProps = dispatch => ({
	showInternalMessages: () => dispatch(actions.showInternalMessages()),
	onShowReplyMessage: (interactionHash, messageId) => dispatch(actions.showReplyMessage(interactionHash, messageId)),
	onHideReplyMessage: interactionHash => dispatch(actions.hideReplyMessage(interactionHash))
});

export default connect(MapStateToProps, MapActionsToProps)(injectIntl(InternalMessageBubble));
