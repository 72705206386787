/* eslint-disable react/forbid-prop-types */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Pagination from '../Pagination';
import DataTable from '../DataTable';
import './index.sass';

const Table = (props) => {
	const [params, setParams] = useState({});
	const {
		pagination = { total: 0 },
		loading,
		onChange = () => {},
		...rest
	} = props;
	const handlePageChange = ({ page, limit }) => {
		onChange({ page, limit });
	};
	const handleTableChange = ({ field, order }) => {
		setParams({ ...params, sorter: { field, order } });
	};

	return (
		<div>
			{loading && (
				<div className="mask">
					<div className="spinner">
						<div className="bounce1" />
						<div className="bounce2" />
						<div className="bounce3" />
					</div>
				</div>
			)}
			<DataTable onChange={handleTableChange} {...rest} />
			<div className="pagination">
				<Pagination
					{...pagination}
					onClick={handlePageChange}
				/>
			</div>
		</div>
	);
};

Table.propTypes = {
	dataSource: PropTypes.arrayOf(PropTypes.object),
	columns: PropTypes.arrayOf(
		PropTypes.shape({ key: PropTypes.string, value: PropTypes.string })
	),
	pagination: PropTypes.shape({ total: PropTypes.number }),
	onChange: PropTypes.func,
	loading: PropTypes.bool
};

export default Table;
