import React, { useContext, createContext } from 'react';

import store from '../store';

const StoreContext = createContext();

export const StoreProvider = ({ children }) => (
	<StoreContext.Provider
		value={{
			currentInteraction: store.getState().interaction.currentInteraction
		}}
	>
		{children}
	</StoreContext.Provider>
);

export const useStore = () => useContext(StoreContext);
