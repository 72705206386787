import React, { useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { defineMessages, injectIntl } from 'react-intl';

import './index.sass';
import AvatarName from '../../Atoms/AvatarName';
import IconButton from '../Buttons/IconButton';

const messages = defineMessages({
	removeAvatar: {
		id: 'updateAvatar.removeAvatar',
		defaultMessage: 'Remover foto do perfil'
	},
	changeAvatar: {
		id: 'updateAvatar.changeAvatar',
		defaultMessage: 'Alterar foto do perfil'
	}
});

const UpdateAvatar = ({
	image = '',
	updated,
	onUpdateAvatar,
	name,
	intl
}) => {
	const inputFile = useRef();

	const onChangeImage = useCallback(() => {
		const reader = new FileReader();
		const file = inputFile.current.files[0];

		if (file && file.type && file.type.constructor === String && file.type.includes('image')) {
			reader.onload = event => onUpdateAvatar(event.target.result);
			reader.readAsDataURL(inputFile.current.files[0]);
		}
	}, [onUpdateAvatar]);

	const onRemoveImage = useCallback(() => {
		inputFile.current.value = '';
		onUpdateAvatar('');
	}, [onUpdateAvatar]);

	return (
		<div className="UpdateAvatar">
			{updated && (
				<div className="UpdateAvatar__icon">
					<IconButton
						click={onRemoveImage}
						name="trash"
						fill="red"
						ariaLabel={intl.formatMessage(messages.removeAvatar)}
					/>
				</div>
			)}
			<label htmlFor="files" className="UpdateAvatar__add">
				{!updated && (
					<div className="UpdateAvatar__icon">
						<IconButton
							click={() => {}}
							name="camera"
							fill="green"
							ariaLabel={intl.formatMessage(messages.changeAvatar)}
						/>
					</div>
				)}
				<input
					type="file"
					id="files"
					onChange={onChangeImage}
					ref={inputFile}
					accept="image/*"
				/>
				<AvatarName url={image} name={name} />
			</label>
		</div>
	);
};

UpdateAvatar.propTypes = {
	image: PropTypes.string,
	onUpdateAvatar: PropTypes.func.isRequired,
	updated: PropTypes.bool,
	name: PropTypes.string,
	intl: PropTypes.shape({
		formatMessage: PropTypes.func.isRequired
	}).isRequired
};

export default injectIntl(UpdateAvatar);
