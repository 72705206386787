import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { defineMessages, injectIntl } from 'react-intl';

import './index.sass';
import * as actions from '../../../store/actions';
import InputText from '../../Molecules/Inputs/Mask';
import { checkValidity, fillFormFieldsWithCustomer, parseFormItem } from '../../../shared/utility';
import Button from '../../Molecules/Buttons/Button';
import Text from '../../Atoms/Text';
import Textarea from '../../Molecules/Inputs/Textarea';
import IconButton from '../../Molecules/Buttons/IconButton';

const messages = defineMessages({
	alreadyRegistered: {
		id: 'clientForm.alreadyRegistered',
		defaultMessage: 'já cadastrado'
	},
	updated: {
		id: 'clientForm.updated',
		defaultMessage: 'Cliente alterado'
	},
	updatedContent: {
		id: 'clientForm.updatedContent',
		defaultMessage: 'Os dados foram alterados com sucesso'
	},
	notUpdated: {
		id: 'clientForm.notUpdated',
		defaultMessage: 'Cliente não alterado'
	},
	notUpdatedContent: {
		id: 'clientForm.notUpdatedContent',
		defaultMessage: 'Verifique os campos do formulário'
	},
	save: {
		id: 'clientForm.save',
		defaultMessage: 'Salvar alterações'
	},
	cancel: {
		id: 'clientForm.cancel',
		defaultMessage: 'Cancelar edição'
	},
	main_identifier: {
		id: 'clientForm.main_identifier',
		defaultMessage: 'Nome'
	},
	channel_phone: {
		id: 'clientForm.channel_phone',
		defaultMessage: 'Telefone'
	},
	channel_email: {
		id: 'clientForm.channel_email',
		defaultMessage: 'Email'
	},
	document: {
		id: 'clientForm.document',
		defaultMessage: 'CPF'
	},
	internalNote: {
		id: 'clientForm.internalNote',
		defaultMessage: 'Esta é uma nota interna.'
	},
	internalNotePlaceholder: {
		id: 'clientForm.internalNotePlaceholder',
		defaultMessage: 'Escreva uma nota sobre o cliente.'
	}
});

const ClientForm = ({
	updateCustomerStatus = {},
	updateCustomerClear,
	intl,
	formFields,
	isHistoryInteraction,
	updateCustomer,
	accountId,
	currentInteraction,
	addNotification,
	inactiveEdit,
	isEditing,
	customerInfo
}) => {
	const [formControls, setFormControls] = useState([]);
	const [note, setNote] = useState('');
	const [isFormValid, setIsFormValid] = useState(false);
	const [removedField, setRemovedField] = useState(false);
	const [previousUpdateCustomerStatus, setPreviousUpdateCustomerStatus] = useState(updateCustomerStatus);

	useEffect(() => {
		if (formFields && customerInfo) {
			setFormControls(fillFormFieldsWithCustomer(formFields, customerInfo));
			setNote(customerInfo.note);
		}
	}, [customerInfo, formFields]);

	const { formatMessage } = intl;

	useEffect(() => {
		const { error, success } = updateCustomerStatus;

		if (error !== previousUpdateCustomerStatus.error || success !== previousUpdateCustomerStatus.success) {
			if (error) {
				addNotification({
					title: formatMessage(messages.notUpdated),
					content: formatMessage(messages.notUpdatedContent),
					type: 'error'
				});

				updateCustomerClear();
			}

			if (success) {
				addNotification({
					title: formatMessage(messages.updated),
					content: formatMessage(messages.updatedContent),
					type: 'success'
				});

				updateCustomerClear();
			}
			setPreviousUpdateCustomerStatus(updateCustomerStatus);
		}
	}, [updateCustomerStatus, addNotification, updateCustomerClear, intl, formControls, formatMessage, previousUpdateCustomerStatus.error, previousUpdateCustomerStatus.success]);

	const inputChangedHandler = (event, inputIdentifier) => {
		const { error } = updateCustomerStatus;
		const updatedControls = formControls.map((formItem) => {
			if (formItem.id === inputIdentifier) {
				const { isValid, validationMessage } = checkValidity(
					event.target.value, formItem.validation, formItem.label
				);

				let formattedMessage = '';
				if (Object.keys(validationMessage).length > 0) {
					const { label, intlObject } = validationMessage;
					formattedMessage = `${label} ${formatMessage(intlObject)}`;
				}

				if (error) updateCustomerClear();
				return {
					...formItem,
					value: event.target.value,
					isValid,
					validationMessage: formattedMessage,
					wasTouched: true
				};
			}
			return formItem;
		});

		let isValid = true;
		updatedControls.forEach((field, controlsIndex) => {
			const { validation, wasTouched, value } = field;
			const formField = updatedControls[controlsIndex];
			if (validation.isRequired && !wasTouched && value !== '') {
				isValid = !formField.isValid && isValid;
			} else if (validation.isRequired || wasTouched) {
				isValid = formField.isValid && isValid;
			}
		});

		setFormControls(updatedControls);
		setIsFormValid(isValid);
		setRemovedField(false);
	};

	const onCancelEdit = () => {
		inactiveEdit();
		setFormControls(fillFormFieldsWithCustomer(formFields, customerInfo));
		updateCustomerClear();
	};

	const onUpdateCustomer = () => {
		const { customerKey } = currentInteraction.customerInfo;

		const fields = {};
		formControls.forEach((formItem) => {
			const { name, wasTouched } = formItem;
			if (wasTouched) fields[name] = parseFormItem(formControls, formItem, removedField);
		});

		if (isFormValid || removedField || note !== customerInfo.note) {
			updateCustomer({
				customerKey, accountId, fields, note
			});
			onCancelEdit();
		}
		setRemovedField(false);
	};

	const addMoreFields = (fieldName) => {
		let field = {};
		let index = 0;
		const updatedContols = formControls.map((control, controlIndex) => {
			if (fieldName === control.name) {
				field = {
					...control,
					id: new Date().getTime(),
					value: '',
					createdField: true
				};
				index = controlIndex;
				return control;
			}
			return control;
		});

		updatedContols.splice(index + 1, 0, field);

		setFormControls(updatedContols);
		setIsFormValid(false);
	};

	const removeField = (id) => {
		setFormControls(formControls.filter(control => control.id !== id));
		setRemovedField(true);
	};

	const { loading } = updateCustomerStatus;

	return (
		<div className="ClientForm__infos">
			<div className="ClientForm__form">
				{
					formControls ? formControls.map((field) => {
						const {
							value,
							wasTouched,
							id,
							validationMessage,
							isValid,
							name,
							label,
							validation,
							isMultiple,
							createdField
						} = field;
						const { isRequired } = validation;
						const locale = localStorage.getItem('locale');
						const translatedFields = ['main_identifier', 'document', 'channel_phone', 'channel_email'];
						let translatedLabel = label;

						if (translatedFields.includes(name)) {
							translatedLabel = locale === 'es' || locale === 'en' ? formatMessage(messages[name]) : label;
						}

						return (
							<div className="ClientForm__form__item" key={id}>
								{(isEditing || !(Array.isArray(value) && !value.length)) && (
									<InputText
										label={isRequired ? `${translatedLabel}*` : translatedLabel}
										validationMessage={validationMessage}
										value={value || ''}
										isEditing={isEditing}
										onChange={event => inputChangedHandler(event, id)}
										touched={wasTouched}
										valid={isValid}
										disabled={isHistoryInteraction}
									/>
								)}
								{isEditing && isMultiple && !createdField && !isHistoryInteraction && (
									<IconButton
										square
										name="plus"
										fill="green"
										click={() => addMoreFields(name)}
									/>
								)}
								{isEditing && createdField && !isHistoryInteraction && (
									<IconButton
										square
										name="cross"
										fill="red"
										click={() => removeField(id)}
									/>
								)}
							</div>
						);
					}) : null
				}

				<div className="ClientForm__form__note">
					<Textarea
						placeholder={formatMessage(messages.internalNotePlaceholder)}
						value={note || ''}
						onChange={e => setNote(e.target.value)}
						disabled={!isEditing}
					/>
					<Text size="bigger">{formatMessage(messages.internalNote)}</Text>
				</div>

				{isEditing && (
					<div className="ClientForm__form__actions">
						<Button
							disabled={loading}
							click={onCancelEdit}
							selected
							secondary
						>
							<Text size="bigger">{formatMessage(messages.cancel)}</Text>
						</Button>
						<Button
							disabled={loading}
							active={(isFormValid || removedField || (note !== customerInfo.note)) && !loading}
							click={onUpdateCustomer}
							selected
						>
							<Text size="bigger">{formatMessage(messages.save)}</Text>
						</Button>
					</div>
				)}
			</div>
		</div>
	);
};

const mapStateToProps = ({ agent, formFields, interaction }) => {
	const {
		currentInteractionHash,
		interactions,
		historyInteractions,
		pendingInteractions,
		missedInteractions,
		updateCustomerStatus
	} = interaction;
	const allInteractions = interactions.concat(pendingInteractions).concat(historyInteractions).concat(missedInteractions);

	return ({
		formFields: formFields.fields,
		accountId: agent.info.account.id,
		updateCustomerStatus: updateCustomerStatus.find(({ hash }) => hash === currentInteractionHash),
		currentInteraction: allInteractions.find(({ interactionHash }) => interactionHash === currentInteractionHash) || {}
	});
};

const mapActionsToProps = dispatch => ({
	updateCustomer: customerInfo => dispatch(actions.updateCustomer(customerInfo)),
	updateCustomerClear: () => dispatch(actions.updateCustomerClear()),
	addNotification: notification => dispatch(actions.addNotification(notification))
});

ClientForm.propTypes = {
	customerInfo: PropTypes.shape({
		customerKey: PropTypes.string,
		note: PropTypes.string
	}).isRequired,
	formFields: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
	addNotification: PropTypes.func.isRequired,
	updateCustomer: PropTypes.func.isRequired,
	inactiveEdit: PropTypes.func.isRequired,
	updateCustomerClear: PropTypes.func.isRequired,
	accountId: PropTypes.number.isRequired,
	isEditing: PropTypes.bool,
	currentInteraction: PropTypes.shape({
		customerInfo: PropTypes.shape({
			customerKey: PropTypes.string
		}),
		currentInteractionHash: PropTypes.string
	}).isRequired,
	updateCustomerStatus: PropTypes.shape({
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.bool, PropTypes.shape({})]),
		success: PropTypes.oneOfType([PropTypes.bool, PropTypes.shape({})])
	}),
	intl: PropTypes.shape({
		formatMessage: PropTypes.func.isRequired
	}).isRequired,
	isHistoryInteraction: PropTypes.bool
};

export default connect(mapStateToProps, mapActionsToProps)(injectIntl(ClientForm));
