import React, { useRef, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl, defineMessages } from 'react-intl';

import './index.sass';
import * as actions from '../../../store/actions';
import { useMountEffect, letSendAttachment } from '../../../shared/utility';
import ActionsHeader from '../ActionsHeader';
import Modal from '../Modal';
import Textarea from '../Inputs/Textarea';
import InputFile from '../../Atoms/InputFile';

const messages = defineMessages({
	title: {
		id: 'annotation.title',
		defaultMessage: 'Anotação'
	},
	type: {
		id: 'annotation.type',
		defaultMessage: 'Digite aqui sua anotação'
	}
});

const Annotation = ({
	agent,
	currentInteraction,
	currentInteractionHash,
	onChangeInputAnnotation,
	onClearNoteAttachment,
	onSendNoteAttachment,
	inputAnnotation = { value: '' },
	onUpdateNoteChanged,
	updateNote,
	updateNoteStatus = {
		loading: false,
		error: null
	},
	intl,
	onlyShowValue,
	onClose
}) => {
	const { value } = inputAnnotation;
	const valueRef = useRef();
	const { formatMessage } = intl;
	const { success, error, loading } = updateNoteStatus;
	const noteAttachment = {
		url: currentInteraction && currentInteraction.note && currentInteraction.note.url,
		filename: currentInteraction && currentInteraction.note && currentInteraction.note.s3 && currentInteraction.note.s3.filename
	};

	useMountEffect(() => {
		const body = currentInteraction && currentInteraction.note && currentInteraction.note.body;

		if (body && body.length > 0 && value === '') {
			onChangeInputAnnotation({
				hash: currentInteractionHash,
				value: body
			});
		}

		document.addEventListener('keyup', (e) => {
			if (e.which === 27) onClose();
		});
	});

	useEffect(() => {
		if (success || error) onUpdateNoteChanged();
	}, [success, error, onUpdateNoteChanged]);

	const dynamicNoteUpdate = useCallback((searchValue) => {
		setTimeout(() => {
			if (searchValue === valueRef.current) {
				const { note } = currentInteraction;

				updateNote({
					agentId: agent.info.id,
					interactionHash: currentInteractionHash,
					accountId: agent.info.account.id,
					note: {
						...note,
						body: searchValue
					}
				});
			}
		}, 1000);
	}, [agent, currentInteraction, currentInteractionHash, updateNote]);

	const onType = useCallback((event) => {
		onChangeInputAnnotation({
			hash: currentInteractionHash,
			value: event.target.value
		});
		valueRef.current = event.target.value;
		onUpdateNoteChanged(currentInteractionHash);
		dynamicNoteUpdate(event.target.value);
	}, [onChangeInputAnnotation, onUpdateNoteChanged, currentInteractionHash, dynamicNoteUpdate]);

	const onAddAttachment = useCallback((file) => {
		const { size, type } = file;
		const { approved } = letSendAttachment(size, type);

		if (approved) {
			const { note } = currentInteraction;
			const formData = new FormData();

			formData.append('files[]', file);

			onSendNoteAttachment({
				formData,
				agentId: agent.info.id,
				interactionHash: currentInteractionHash,
				note
			});
		}
	}, [agent, currentInteraction, currentInteractionHash, onSendNoteAttachment]);

	const onDelAttachment = useCallback(() => {
		const { note } = currentInteraction;

		onClearNoteAttachment({
			agentId: agent.info.id,
			interactionHash: currentInteractionHash,
			note
		});
	}, [agent, currentInteraction, currentInteractionHash, onClearNoteAttachment]);

	return (
		<Modal onClose={onClose}>
			<div className="Annotation">
				<ActionsHeader
					type="annotation"
					title={formatMessage(messages.title)}
					success={success}
					error={error}
					clear
				/>
				<Textarea
					placeholder={formatMessage(messages.type)}
					value={value}
					onChange={onType}
					disabled={loading || onlyShowValue}
					shouldFocus={success || error}
				/>
				<InputFile
					disabled={loading || onlyShowValue}
					attachment={noteAttachment}
					onAddAttachment={onAddAttachment}
					onDelAttachment={onDelAttachment}
				/>
			</div>
		</Modal>
	);
};

const mapStateToProps = (state) => {
	const { interaction, agent } = state;
	const {
		currentInteractionHash,
		interactions,
		pendingInteractions,
		historyInteractions,
		missedInteractions,
		updateNoteStatus
	} = interaction;

	const allInteractions = interactions.concat(pendingInteractions).concat(historyInteractions).concat(missedInteractions);

	return ({
		agent,
		currentInteractionHash,
		currentInteraction: allInteractions.find(({ interactionHash }) => interactionHash === currentInteractionHash) || {},
		inputAnnotation: state.interface.inputAnnotation.find(({ hash }) => hash === currentInteractionHash) || { value: '' },
		updateNoteStatus: updateNoteStatus.find(({ hash }) => hash === currentInteractionHash) || {}
	});
};

const mapActionsToProps = dispatch => ({
	updateNote: interactionInfo => dispatch(actions.updateNote(interactionInfo)),
	onChangeInputAnnotation: inputInfo => dispatch(actions.changeInputAnnotation(inputInfo)),
	onSendNoteAttachment: interactionInfo => dispatch(actions.sendNoteAttachment(interactionInfo)),
	onClearNoteAttachment: interactionInfo => dispatch(actions.clearNoteAttachment(interactionInfo)),
	onUpdateNoteChanged: () => dispatch(actions.updateNoteChanged())
});

Annotation.propTypes = {
	currentInteraction: PropTypes.shape({
		note: PropTypes.shape({
			body: PropTypes.string,
			url: PropTypes.string,
			s3: PropTypes.shape({
				key: PropTypes.string,
				bucket: PropTypes.string,
				filename: PropTypes.string
			})
		})
	}).isRequired,
	agent: PropTypes.shape({
		info: PropTypes.shape({
			id: PropTypes.number.isRequired,
			account: PropTypes.shape({
				id: PropTypes.number
			})
		}),
		account: PropTypes.shape({
			id: PropTypes.number
		})
	}).isRequired,
	updateNote: PropTypes.func.isRequired,
	updateNoteStatus: PropTypes.shape({
		loading: PropTypes.bool,
		success: PropTypes.bool,
		error: PropTypes.bool
	}),
	inputAnnotation: PropTypes.shape({
		value: PropTypes.string
	}),
	currentInteractionHash: PropTypes.string.isRequired,
	onChangeInputAnnotation: PropTypes.func.isRequired,
	onUpdateNoteChanged: PropTypes.func.isRequired,
	onSendNoteAttachment: PropTypes.func.isRequired,
	onClearNoteAttachment: PropTypes.func.isRequired,
	intl: PropTypes.shape({
		formatMessage: PropTypes.func.isRequired
	}).isRequired,
	onlyShowValue: PropTypes.bool,
	onClose: PropTypes.func.isRequired
};

export default connect(mapStateToProps, mapActionsToProps)(injectIntl(Annotation));
