import React from 'react';
import PropTypes from 'prop-types';
import Spinner from '../../Atoms/Spinner';

import './index.sass';

const InteractionListTitle = ({ title, loading, interactionsCount }) => (
	<div className="InteractionListTitle">
		<p>{title}</p>
		{!!interactionsCount && (
			<span className="InteractionListTitle__count">{interactionsCount}</span>
		)}
		<div className="InteractionList__section__status">
			{ loading && (<Spinner />) }
		</div>
	</div>
);

InteractionListTitle.propTypes = {
	title: PropTypes.string.isRequired,
	loading: PropTypes.bool,
	interactionsCount: PropTypes.number.isRequired
};


export default InteractionListTitle;
