import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { defineMessages, injectIntl } from 'react-intl';

import './index.sass';
import * as actions from '../../../store/actions';
import AgentTaskGroup from '../../Molecules/AgentTaskGroup';
import AgentTaskItem from '../AgentTaskItem';
import Spinner from '../../Atoms/Spinner';
import InfiniteList from '../../Molecules/InfiniteList';
import Text from '../../Atoms/Text';

const messages = defineMessages({
	empty: {
		id: 'agentTasks.empty',
		defaultMessage: 'Não há tasks'
	},
	task: {
		id: 'agentTasks.task',
		defaultMessage: 'Tarefas'
	},
	taskContent: {
		id: 'agentTasks.taskContent',
		defaultMessage: 'A ação foi realizada'
	}
});

const AgentTasks = ({
	fetchAgentTasks,
	finishedTask,
	createOrUpdateInteractionTaskClear,
	taskSidebar,
	creatingOrUpdatingSuccess,
	addNotification,
	intl,
	fetchAgentTasksStatus = { loading: false },
	tasks,
	tasksNearToExpire = []
}) => {
	const { formatMessage } = intl;
	const { loading } = fetchAgentTasksStatus;
	const [finishedTaskState, setFinishedTaskState] = useState(0);
	useEffect(() => {
		if (finishedTask !== finishedTaskState) setFinishedTaskState(finishedTask);
	}, [finishedTask, finishedTaskState]);

	useEffect(() => {
		if (creatingOrUpdatingSuccess && taskSidebar === 'default') {
			createOrUpdateInteractionTaskClear();
			addNotification({
				title: formatMessage(messages.task),
				content: formatMessage(messages.taskContent),
				type: 'success'
			});
		}
	}, [
		addNotification,
		createOrUpdateInteractionTaskClear,
		creatingOrUpdatingSuccess,
		formatMessage,
		taskSidebar
	]);

	const loadMoreTasks = useCallback(() => {
		const {
			count,
			ended,
			failed,
			filter,
			status
		} = fetchAgentTasksStatus;
		const requestObject = { page: count };
		if (filter) requestObject.filter = filter;
		if (status) requestObject.status = status;
		if (!ended && !loading && !failed) fetchAgentTasks(requestObject);
	}, [fetchAgentTasks, fetchAgentTasksStatus, loading]);

	const groups = Object.keys(tasks);
	let content = groups.map(group => (
		<AgentTaskGroup date={group} tasks={tasks[group]} key={group} />
	));

	if (!loading && groups.length === 0) content = <Text size="bigger">{intl.formatMessage(messages.empty)}</Text>;

	return (
		<div className="AgentTasks_wrapper">
			<InfiniteList
				onBottom={loadMoreTasks}
				customClass="AgentTasks"
			>
				{tasksNearToExpire.map(task => <AgentTaskItem isExpiring task={task} key={task.id} />)}
				{content}
				{loading && <Spinner />}
			</InfiniteList>
		</div>
	);
};

const mapStateToProps = state => ({
	tasks: state.agent.tasks,
	fetchAgentTasksStatus: state.agent.fetchAgentTasksStatus,
	finishedTask: state.agent.finishedTask,
	tasksNearToExpire: state.agent.tasksNearToExpire,
	creatingOrUpdatingSuccess: state.agent.creatingOrUpdatingSuccess,
	taskSidebar: state.interface.taskSidebar
});

const mapActionsToProps = dispatch => ({
	fetchAgentTasks: requestInfo => dispatch(actions.fetchAgentTasks(requestInfo)),
	createOrUpdateInteractionTaskClear: () => dispatch(actions.createOrUpdateInteractionTaskClear()),
	addNotification: notification => dispatch(actions.addNotification(notification))
});

AgentTasks.propTypes = {
	fetchAgentTasks: PropTypes.func.isRequired,
	fetchAgentTasksStatus: PropTypes.shape({
		loading: PropTypes.bool,
		count: PropTypes.number,
		ended: PropTypes.bool,
		failed: PropTypes.bool,
		filter: PropTypes.string,
		status: PropTypes.string
	}),
	tasks: PropTypes.shape().isRequired,
	intl: PropTypes.shape({
		formatMessage: PropTypes.func
	}).isRequired,
	finishedTask: PropTypes.number.isRequired,
	tasksNearToExpire: PropTypes.arrayOf(PropTypes.shape({
		id: PropTypes.number
	})),
	creatingOrUpdatingSuccess: PropTypes.bool.isRequired,
	createOrUpdateInteractionTaskClear: PropTypes.func.isRequired,
	taskSidebar: PropTypes.string.isRequired,
	addNotification: PropTypes.func.isRequired
};

export default connect(mapStateToProps, mapActionsToProps)(injectIntl(AgentTasks));
