/* eslint-disable no-param-reassign */
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { IntlProvider } from 'react-intl';
import axios from 'axios';

import store from './store';

import './index.css';
import AppAuth from './AppAuth';
import * as serviceWorker from './serviceWorker';
import { decodeJWT } from './shared/utility';
import * as actionTypes from './store/actions/actionTypes';

if (!Intl.PluralRules) {
	require('intl-pluralrules/polyfill');
	require('@formatjs/intl-relativetimeformat/dist/locale-data/pt'); // Add locale data for de
}

if (!Intl.RelativeTimeFormat) {
	require('@formatjs/intl-relativetimeformat/polyfill');
	require('@formatjs/intl-relativetimeformat/dist/locale-data/pt'); // Add locale data for de
}

/* Configure axios */
// Do something before request is sent
axios.interceptors.request.use(async (config) => {
	if (config.method !== 'get') {
		return config;
	}

	const token = sessionStorage.getItem('omz_token');
	const tokenData = decodeJWT(token);
	const isExpired = (tokenData.exp * 1000) - Date.now() < 0;

	if (!isExpired) {
		config.headers.Authorization = token; // Add token to Authorization header
	} else {
		try {
			const response = await axios.get(`${process.env.OMZ_ZAPI}/external/authentication/refresh`, {
				headers: {
					Authorization: token
				}
			});

			sessionStorage.setItem('omz_token', response.data.token); // Store refreshed token
			config.headers.Authorization = `Bearer ${response.data.token}`; // Update Authorization header with refreshed token
		} catch (error) {
			throw error; // Rethrow the error to propagate it
		}
	}

	config.headers['Content-type'] = 'application/json'; // Set Content-Type header
	return config;
}, error => Promise.reject(error)); // Reject request with error

// Do something after response is received
axios.interceptors.response.use((response) => {
	if (response.data && response.config.dispatch) {
		const { status, error } = response.data;
		if ([401, 403].includes(status) || (error && error.toString().toLowerCase() === 'invalid token')) {
			response.config.dispatch({ type: actionTypes.TOKEN_EXPIRED }); // Dispatch action for token expiration
		}
	}
	return response;
}, error => Promise.reject(error)); // Reject response with error


let messages;
const language = localStorage.getItem('locale') || 'pt';

try {
	/* global window */
	messages = require(`./locales/${window.location.host.replace('.omnize.com.br', '')}.json`);
	if (messages) {
		messages = messages[language];
	}
} catch (e) {
	messages = require(`./locales/${language}.json`);
}

const container = document.getElementById('root');
const root = createRoot(container);
const app = (
	<IntlProvider locale="pt" messages={messages}>
		<Provider store={store}>
			<AppAuth />
		</Provider>
	</IntlProvider>
);

root.render(app);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

export default axios;
