import './index.css';

import React from 'react';
import PropTypes from 'prop-types';

import DashboardTemplate from './Dashboard';
import TasksTemplate from './Tasks';
import DefaultTemplate from './Default';
import NavBar from '../Organism/NavBar';

const Template = ({ template }) => (
	<div className="Template">
		<NavBar />
		{template === 'default' && <DefaultTemplate />}
		{template === 'tasks' && <TasksTemplate />}
		{template === 'dashboard' && <DashboardTemplate />}
	</div>
);

Template.propTypes = {
	template: PropTypes.string.isRequired
};

export default Template;
