import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { injectIntl, defineMessages } from 'react-intl';

import MessageList from '../../Organism/MessageList';
import ActionsHeader from '../ActionsHeader';
import Button from '../Buttons/Button';

import * as actions from '../../../store/actions';

import './index.sass';
import Modal from '../Modal';

const messages = defineMessages({
	title: {
		id: 'internalMessages.title',
		defaultMessage: 'Mensagens internas'
	},
	back: {
		id: 'internalMessages.back',
		defaultMessage: 'Voltar para o chat'
	}
});

const InternalMessages = ({
	currentInteraction,
	hideInternalMessages,
	intl
}) => {
	const { formatMessage } = intl;
	const internalMessages = currentInteraction.messages.filter(message => message.origin === 'INT_AGENT' || message.origin === 'INT_ADMIN');

	return (
		<Modal size="bg" onClose={hideInternalMessages}>
			<>
				<ActionsHeader
					title={formatMessage(messages.title)}
					clear
				/>
				<div className="InternalMessages">
					<MessageList isInternalHistory messages={internalMessages} />
				</div>
				<div className="InternalMessages__actions">
					<Button
						click={hideInternalMessages}
						secondary
					>
						{formatMessage(messages.back)}
					</Button>
				</div>
			</>
		</Modal>
	);
};

InternalMessages.propTypes = {
	intl: PropTypes.shape({
		formatMessage: PropTypes.func.isRequired
	}).isRequired,
	hideInternalMessages: PropTypes.func.isRequired,
	currentInteraction: PropTypes.shape({
		messages: PropTypes.arrayOf(PropTypes.shape({})),
		origin: PropTypes.string
	}).isRequired
};

const MapActionsToProps = dispatch => ({
	hideInternalMessages: () => dispatch(actions.hideInternalMessages())
});

const mapStateToProps = ({ interaction }) => ({
	currentInteraction: interaction.currentInteraction
});

export default connect(mapStateToProps, MapActionsToProps)(injectIntl(InternalMessages));
