/* eslint-disable consistent-return */
import React, { useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

const ONE_SECOND = 1000;
const ONE_MINUTE = ONE_SECOND * 60;
const ONE_HOUR = ONE_MINUTE * 60;

const BreakStatusTimer = ({
	className = '', endedAt, isTimerEnded
}) => {
	const [breakStatusTime, setBreakStatusTime] = useState('--');
	const [isYellow, setIsYellow] = useState(false);

	const timer = endedAt ? JSON.parse(window.localStorage.getItem('breakTimer')).endedAt : JSON.parse(window.localStorage.getItem('breakTimer')).startedAt;

	const getBreakStatusTime = useCallback((breakStatusDate) => {
		const timeDifference = Math.abs(Date.now() - breakStatusDate);

		const restHours = timeDifference % ONE_HOUR;
		const restMinutes = restHours % ONE_MINUTE;

		const hours = Math.floor(timeDifference / ONE_HOUR).toString().padStart(2, '0');
		const minutes = Math.floor(restHours / ONE_MINUTE).toString().padStart(2, '0');
		const seconds = Math.floor(restMinutes / ONE_SECOND).toString().padStart(2, '0');

		if (hours > 0) {
			setBreakStatusTime(`${hours}h ${minutes}m ${seconds}s`);
		} else if (minutes > 0) {
			setBreakStatusTime(`${minutes}m ${seconds}s`);
		} else {
			setBreakStatusTime(`${seconds}s`);
		}

		if (endedAt && timeDifference < ONE_MINUTE) {
			setIsYellow(true);
		} else {
			setIsYellow(false);
		}
	}, [endedAt]);

	useEffect(() => {
		if (endedAt && isTimerEnded) {
			setBreakStatusTime('00:00');
		} else if (!endedAt || !isTimerEnded) {
			if (timer) {
				getBreakStatusTime(timer);

				const interval = setInterval(() => {
					getBreakStatusTime(timer);
				}, ONE_SECOND);

				return () => clearInterval(interval);
			}
		}
	}, [timer, endedAt, isTimerEnded, getBreakStatusTime, setBreakStatusTime]);

	return <span className={(isYellow && 'color-yellow') || (breakStatusTime === '00:00' && 'color-red') || className}>{breakStatusTime}</span>;
};

const mapStateToProps = ({ agent }) => ({
	agentBreakStatus: agent.agentBreakStatus
});

BreakStatusTimer.propTypes = {
	className: PropTypes.string,
	agentBreakStatus: PropTypes.shape({
		startedAt: PropTypes.number
	}).isRequired,
	endedAt: PropTypes.bool,
	isTimerEnded: PropTypes.bool
};

export default connect(mapStateToProps)(BreakStatusTimer);
