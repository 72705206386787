import * as actionTypes from '../actions/actionTypes';
import { updateObject, setRequestStatus, checkFeature } from '../../shared/utility';

const initialState = {
	info: {},
	isFullAutomatic: false,
	isAutoAcceptInteraction: false,
	loading: true,
	error: '',
	registerState: 'NOT_REGISTERED',
	notify: false,
	unauthorized: false,
	authenticated: false,
	authenticating: false,
	authUnauthorized: false,
	registeredPhone: false,
	registeringPhone: false,
	contactsInfo: {},
	agentBreakStatus: {},
	breakStatus: {
		data: [],
		loading: false,
		page: 1,
		blockInteractionsOnBreak: false
	},
	agentDashboard: {},
	contactsSearch: [],
	tasks: {},
	selectedTask: {},
	taskCreated: false,
	iteractionTaskCreated: false,
	taskCount: 0,
	taskCountSuccess: false,
	phoneData: {},
	fetchAgentContactsStatus: {
		ended: false,
		loading: false,
		count: 1
	},
	searchAgentContactsStatus: {
		ended: false,
		loading: false,
		count: 1
	},
	isSearchingContacts: false,
	creatingInteraction: {},
	addCustomerStatus: {},
	creatingOrUpdatingTask: false,
	creatingOrUpdatingSuccess: false,
	taskExtras: false,
	blockReopenTasks: true,
	fetchAgentTasksStatus: {},
	fetchAgentDashboardStatus: {},
	tagRequired: false,
	whatsappVoice: false,
	finishedTask: 0,
	informExpiringSoon: false,
	informedExpiringSoonAfterLogin: false,
	updatePhotoStatus: {
		loading: false,
		success: false,
		error: false
	},
	updateSettingsStatus: {
		loading: false,
		success: false,
		error: false
	},
	updatePasswordStatus: {
		loading: false,
		success: false,
		error: false
	},
	answersStatus: {
		loading: false,
		success: false,
		error: false
	},
	answers: [],
	whatsAppTemplates: [],
	whatsAppTemplatesStatus: {},
	agentBlocked: {
		blocked: false,
		blockModalViewed: false
	}
};

const updateCallStatus = (state, action) => {
	const { payload } = action;
	const { phoneData } = state;

	let initialTime = '';
	if (payload === 'conversando') initialTime = new Date();

	const updatedPhoneData = {
		...phoneData,
		status: action.payload,
		initialTime
	};

	return updateObject(state, {
		phoneData: updatedPhoneData
	});
};

const updateConferenceUsers = (state, action) => {
	const { payload } = action;
	const { phoneData } = state;

	const updatedConferenceUsers = phoneData.conferenceUsers ? [...phoneData.conferenceUsers] : [];

	const existThisNumber = updatedConferenceUsers.some(user => user.number === payload.number);
	if (!existThisNumber) updatedConferenceUsers.push(payload);

	return updateObject(state, {
		phoneData: {
			...phoneData,
			conferenceUsers: updatedConferenceUsers
		}
	});
};

const updateAgentBreakStatus = (state, action) => {
	const { breakStatus } = action.payload;
	const { blockInteractionsOnBreak = state.breakStatus.blockInteractionsOnBreak } = breakStatus;

	return updateObject(state, {
		agentBreakStatus: { ...breakStatus, startedAt: Date.now() },
		breakStatus: { ...state.breakStatus, blockInteractionsOnBreak }
	});
};

const fetchBreakStatus = (state, action) => {
	const { blockInteractionsOnBreak = false, fetchedBreakStatus, page } = action.payload;
	const { data } = state.breakStatus;

	let breakStatus = [];
	if (page === 1) {
		breakStatus = fetchedBreakStatus;
	} else {
		breakStatus = [...data, ...fetchedBreakStatus];
	}


	return updateObject(state, {
		breakStatus: {
			data: breakStatus,
			loading: false,
			page: page + 1,
			ended: fetchedBreakStatus.length < 20,
			blockInteractionsOnBreak
		}
	});
};

const fetchAgentDashboard = (state, action) => {
	const {
		channels,
		averageServiceTime,
		voice,
		departments
	} = action.payload;

	return updateObject(state, {
		fetchAgentDashboardStatus: {
			loading: false
		},
		agentDashboard: {
			channels,
			averageServiceTime,
			voice,
			departments
		}
	});
};

const fetchAgentContacts = (state, action) => {
	const { contactsInfo } = state;
	const { page, customers } = action.payload;
	const fetchedCustomers = customers;

	const updatedCustomers = contactsInfo.customers && page !== 1 ? [...contactsInfo.customers] : [];
	fetchedCustomers.forEach((customer) => {
		const { id } = customer;
		const existingCustomerIndex = updatedCustomers.findIndex(
			customerItem => customerItem.id === id
		);

		if (existingCustomerIndex === -1) {
			updatedCustomers.push(customer);
		} else {
			updatedCustomers[existingCustomerIndex] = customer;
		}
	});

	return updateObject(state, {
		contactsInfo: {
			...action.payload,
			customers: updatedCustomers
		},
		fetchAgentContactsStatus: {
			...state.fetchAgentContactsStatus,
			count: action.payload.page + 1,
			loading: false,
			ended: action.payload.ended
		},
		isSearchingContacts: false
	});
};

const searchAgentContacts = (state, action) => {
	const { contactsInfo } = state;
	const { customers, page } = action.payload;

	const updatedCustomers = contactsInfo.customers && page !== 1 ? [...contactsInfo.customers] : [];

	customers.forEach((customer) => {
		const { id } = customer;
		const existingCustomerIndex = updatedCustomers.findIndex(
			customerItem => customerItem.id === id
		);

		if (existingCustomerIndex === -1) {
			updatedCustomers.push(customer);
		} else {
			updatedCustomers[existingCustomerIndex] = customer;
		}
	});

	return updateObject(state, {
		contactsInfo: {
			...action.payload,
			customers: updatedCustomers
		},
		searchAgentContactsStatus: {
			...state.searchAgentContactsStatus,
			count: action.payload.page + 1,
			loading: false,
			ended: action.payload.ended
		},
		isSearchingContacts: true
	});
};

const loginSuccess = (state, { payload }) => updateObject(state, {
	loading: false,
	error: '',
	info: payload.user,
	isFullAutomatic: !!payload.user.account.fullAutomatic,
	isAutoAcceptInteraction: checkFeature(payload.user.features, 'auto_accept_interaction'),
	showTasks: checkFeature(payload.user.features, 'show_tasks'),
	taskExtras: checkFeature(payload.user.features, 'comgas_tasks'),
	tagRequired: checkFeature(payload.user.features, 'tag_required'),
	whatsappVoice: checkFeature(payload.user.features, 'whatsapp_voice'),
	blockReopenTasks: checkFeature(payload.user.features, 'block_reopen_tasks'),
	hasAccountName: checkFeature(payload.user.features, 'has_account_name'),
	shortcutWithHtml: checkFeature(payload.user.features, 'shortcut_with_html'),
	altuWappTemplates: checkFeature(payload.user.features, 'altu_wapp_templates'),
	blockEditCustomerInfo: checkFeature(payload.user.features, 'block_edit_customer_info'),
	blockSlaNotification: checkFeature(payload.user.features, 'block_sla_notification'),
	blockRemoveTag: checkFeature(payload.user.features, 'block_remove_tag')
});

const fetchAgentTasks = (state, action) => {
	const {
		tasks,
		page,
		filter,
		status,
		tasksNearToExpire
	} = action.payload;
	const { fetchAgentTasksStatus } = state;
	const { isSearching = false, isFiltering = false } = fetchAgentTasksStatus;
	const currentTasks = page !== 1 ? state.tasks : {};

	Object.entries(tasks).forEach(([key, value]) => {
		currentTasks[key] = value;
	});

	const updatedObject = {
		tasks: currentTasks,
		fetchAgentTasksStatus: {
			...state.fetchAgentTasksStatus,
			count: page + 1,
			ended: Object.keys(tasks).length === 0,
			loading: false,
			failed: false,
			filter,
			status
		},
		tasksNearToExpire
	};

	if (isSearching) updatedObject.fetchAgentTasksStatus.isSearching = false;
	if (isFiltering) updatedObject.fetchAgentTasksStatus.isFiltering = false;

	return updateObject(state, updatedObject);
};

const finishTask = (state, action) => {
	const { tasks } = state;
	const { payload } = action;
	const updatedTasks = tasks;


	Object.entries(tasks).forEach(([key, value]) => {
		updatedTasks[key] = value.map((task) => {
			if (task.id === payload) {
				return {
					...task,
					status: 'FINISHED',
					updatedTime: new Date().getTime()
				};
			}

			return task;
		});
	});

	return updateObject(state, {
		tasks: updatedTasks,
		finishedTask: payload
	});
};

const informExpiringSoonTasks = (state, action) => {
	const { isSocket, inform } = action.payload;
	const { informedExpiringSoonAfterLogin } = state;
	const firstTimeInforming = informedExpiringSoonAfterLogin ? false : inform;

	return updateObject(state, {
		informExpiringSoon: isSocket || firstTimeInforming,
		informedExpiringSoonAfterLogin: true
	});
};

const updatePhoto = (state, action) => updateObject(state, {
	...state,
	info: {
		...state.info,
		photo: `${action.payload}?timestamp=${new Date().getTime()}`
	},
	updatePhotoStatus: setRequestStatus('success')
});

const updateSettings = (state, action) => updateObject(state, {
	...state,
	info: {
		...state.info,
		settings: action.payload
	},
	updateSettingsStatus: setRequestStatus('success')
});

const reducer = (state = initialState, action) => {
	switch (action.type) {
	case actionTypes.AUTH_AGENT_BEGIN:
		return updateObject(state, {
			authenticating: true
		});
	case actionTypes.AUTH_AGENT_SUCCESS:
		return updateObject(state, {
			authenticating: false,
			authenticated: true
		});
	case actionTypes.AUTH_AGENT_FAILED:
		return updateObject(state, {
			authenticating: false,
			authUnauthorized: true
		});
	case actionTypes.SET_NOTIFY:
		return updateObject(state, {
			notify: !state.notify
		});
	case actionTypes.SET_UNAUTHORIZED:
		return updateObject(state, {
			unauthorized: true
		});
	case actionTypes.LOGIN_AGENT_BEGIN:
		return updateObject(state, {
			loading: true
		});
	case actionTypes.LOGIN_AGENT_FAILED:
		return updateObject(state, {
			loading: false,
			error: action.payload
		});
	case actionTypes.LOGIN_AGENT_SUCCESS:
		return loginSuccess(state, action);
	case actionTypes.REGISTER_AGENT_BEGIN:
		return updateObject(state, {
			registerState: 'REGISTERING'
		});
	case actionTypes.REGISTER_AGENT_SUCCESS:
		return updateObject(state, {
			registerState: 'REGISTERED',
			agentBreakStatus: {}
		});
	case actionTypes.REGISTER_PHONE_BEGIN:
		return updateObject(state, {
			registeringPhone: true
		});
	case actionTypes.REGISTER_PHONE_SUCCESS:
		return updateObject(state, {
			registeredPhone: true,
			registeringPhone: false
		});
	case actionTypes.UNREGISTER_PHONE:
		return updateObject(state, {
			registeredPhone: false
		});
	case actionTypes.UNREGISTER_AGENT_BEGIN:
		return updateObject(state, {
			registerState: 'UNREGISTERING'
		});
	case actionTypes.UNREGISTER_AGENT_FAILED:
		return updateObject(state, {
			registerState: 'REGISTERED'
		});
	case actionTypes.UNREGISTER_AGENT_SUCCESS:
		return updateObject(state, {
			registerState: 'NOT_REGISTERED'
		});
	case actionTypes.AGENT_BREAK_STATUS_SUCCESS:
		return updateAgentBreakStatus(state, action);
	case actionTypes.FETCH_BREAK_STATUS_BEGIN:
		return updateObject(state, {
			breakStatus: {
				...state.breakStatus,
				loading: true
			}
		});
	case actionTypes.FETCH_BREAK_STATUS_SUCCESS:
		return fetchBreakStatus(state, action);
	case actionTypes.FETCH_BREAK_STATUS_FAILED:
		return updateObject(state, {
			breakStatus: {
				...state.breakStatus,
				loading: false
			}
		});
	case actionTypes.FETCH_AGENT_DASHBOARD_BEGIN:
		return updateObject(state, {
			fetchAgentDashboardStatus: {
				loading: true
			}
		});
	case actionTypes.FETCH_AGENT_DASHBOARD_SUCCESS:
		return fetchAgentDashboard(state, action);
	case actionTypes.FETCH_AGENT_DASHBOARD_FAILED:
		return updateObject(state, {
			fetchAgentDashboardStatus: {
				loading: false
			}
		});
	case actionTypes.FETCH_AGENT_CONTACTS_BEGIN:
		return updateObject(state, {
			fetchAgentContactsStatus: {
				...state.fetchAgentContactsStatus,
				loading: true
			}
		});
	case actionTypes.FETCH_AGENT_CONTACTS_SUCCESS:
		return fetchAgentContacts(state, action);
	case actionTypes.FETCH_AGENT_CONTACTS_FAILED:
		return updateObject(state, {
			fetchAgentContactsStatus: {
				...state.fetchAgentContactsStatus,
				loading: false
			}
		});
	case actionTypes.FETCH_AGENTS_CONTACTS_SEARCH:
		return updateObject(state, {
			contactsSearch: action.payload
		});
	case actionTypes.FETCH_AGENT_TASKS_BEGIN:
		return updateObject(state, {
			fetchAgentTasksStatus: {
				...state.fetchAgentTasksStatus,
				loading: true,
				isSearching: action.payload.search !== '',
				isFiltering: action.payload.filter !== '' || action.payload.status !== ''
			}
		});
	case actionTypes.FETCH_AGENT_TASKS_SUCCESS:
		return fetchAgentTasks(state, action);
	case actionTypes.FETCH_AGENT_TASKS_FAILED:
		return updateObject(state, {
			fetchAgentTasksStatus: {
				...state.fetchAgentTasksStatus,
				loading: false,
				failed: true,
				isSearching: false,
				isFiltering: false
			}
		});
	case actionTypes.FETCH_AGENT_TASK:
		return updateObject(state, {
			selectedTask: action.payload
		});
	case actionTypes.CLEAR_NEW_TASK:
		return updateObject(state, {
			taskCreated: false,
			selectedTask: {}
		});
	case actionTypes.CLEAR_EDIT_TASK:
		return updateObject(state, {
			taskCreated: false
		});
	case actionTypes.CREATE_TASK_SUCCESS:
		return updateObject(state, {
			taskCreated: true
		});
	case actionTypes.CREATE_OR_UPDATE_INTERACTION_TASK_BEGIN:
		return updateObject(state, {
			iteractionTaskCreated: true,
			creatingOrUpdatingTask: true,
			creatingOrUpdatingSuccess: false
		});
	case actionTypes.CREATE_OR_UPDATE_INTERACTION_TASK_SUCCESS:
		return updateObject(state, {
			iteractionTaskCreated: true,
			creatingOrUpdatingTask: false,
			creatingOrUpdatingSuccess: true
		});
	case actionTypes.CREATE_OR_UPDATE_INTERACTION_TASK_FAILED:
		return updateObject(state, {
			creatingOrUpdatingTask: false,
			creatingOrUpdatingSuccess: false
		});
	case actionTypes.CREATE_OR_UPDATE_INTERACTION_TASK_CLEAR:
		return updateObject(state, {
			iteractionTaskCreated: false,
			creatingOrUpdatingTask: false,
			creatingOrUpdatingSuccess: false
		});
	case actionTypes.CLEAR_INTERACTION_NEW_TASK:
		return updateObject(state, {
			iteractionTaskCreated: false
		});
	case actionTypes.FETCH_AGENT_COUNT_SUCCESS:
		return updateObject(state, {
			taskCount: action.payload,
			informExpiringSoon: action.payload > state.taskCount,
			taskCountSuccess: true
		});
	case actionTypes.FETCH_AGENT_COUNT_FAILED:
		return updateObject(state, {
			taskCount: 0,
			taskCountSuccess: false
		});
	case actionTypes.DEFINE_PHONE_CONNECTION_DATA:
		return updateObject(state, {
			phoneData: action.payload
		});
	case actionTypes.UPDATE_CALL_STATUS:
		return updateCallStatus(state, action);
	case actionTypes.UPDATE_CONFERENCE_USERS:
		return updateConferenceUsers(state, action);
	case actionTypes.CLEAR_CONFERENCE_USERS:
		return updateObject(state, {
			phoneData: {
				...state.phoneData,
				conferenceUsers: []
			}
		});
	case actionTypes.RESET_PASSWORD_BEGIN:
		return updateObject(state, {
			resetingPassword: true
		});
	case actionTypes.RESET_PASSWORD_SUCCESS:
		return updateObject(state, {
			resetingPassword: false,
			resetedPassword: true
		});
	case actionTypes.RESET_PASSWORD_FAILED:
		return updateObject(state, {
			resetingPassword: false
		});
	case actionTypes.RESET_PASSWORD_CLEAR:
		return updateObject(state, {
			resetingPassword: false,
			resetedPassword: false
		});
	case actionTypes.SEARCH_AGENT_CONTACTS_BEGIN:
		return updateObject(state, {
			searchAgentContactsStatus: {
				...state.searchAgentContactsStatus,
				loading: true
			}
		});
	case actionTypes.SEARCH_AGENT_CONTACTS_SUCCESS:
		return searchAgentContacts(state, action);
	case actionTypes.SEARCH_AGENT_CONTACTS_FAILED:
		return updateObject(state, {
			searchAgentContactsStatus: {
				...state.searchAgentContactsStatus,
				loading: false
			}
		});
	case actionTypes.CREATING_INTERACTION:
		return updateObject(state, {
			creatingInteraction: action.payload
		});
	case actionTypes.ADD_CUSTOMER_BEGIN:
		return updateObject(state, {
			addCustomerStatus: {
				...state.addCustomerStatus,
				loading: true,
				failed: false
			}
		});
	case actionTypes.ADD_CUSTOMER_SUCCESS:
		return updateObject(state, {
			addCustomerStatus: {
				...state.addCustomerStatus,
				loading: false,
				success: true
			}
		});
	case actionTypes.ADD_CUSTOMER_FAILED:
		return updateObject(state, {
			addCustomerStatus: {
				...state.addCustomerStatus,
				loading: false,
				errors: action.payload
			}
		});
	case actionTypes.ADD_CUSTOMER_CLEAR:
		return updateObject(state, {
			addCustomerStatus: {}
		});
	case actionTypes.FINISH_TASK:
		return finishTask(state, action);
	case actionTypes.INFORM_EXPIRING_SOON_TASKS:
		return informExpiringSoonTasks(state, action);
	case actionTypes.UPDATE_PHOTO_BEGIN:
		return updateObject(state, { updatePhotoStatus: setRequestStatus('begin') });
	case actionTypes.UPDATE_PHOTO_SUCCESS:
		return updatePhoto(state, action);
	case actionTypes.UPDATE_PHOTO_FAILED:
		return updateObject(state, { updatePhotoStatus: setRequestStatus('failed', '', true) });
	case actionTypes.UPDATE_SETTINGS_BEGIN:
		return updateObject(state, { updateSettingsStatus: setRequestStatus('begin') });
	case actionTypes.UPDATE_SETTINGS_SUCCESS:
		return updateSettings(state, action);
	case actionTypes.UPDATE_SETTINGS_FAILED:
		return updateObject(state, { updateSettingsStatus: setRequestStatus('failed', '', true) });
	case actionTypes.UPDATE_PHOTO_CLEAR:
		return updateObject(state, { updatePhotoStatus: setRequestStatus('clear') });
	case actionTypes.UPDATE_PASSWORD_BEGIN:
		return updateObject(state, { updatePasswordStatus: setRequestStatus('begin') });
	case actionTypes.UPDATE_PASSWORD_SUCCESS:
		return updateObject(state, { updatePasswordStatus: setRequestStatus('success') });
	case actionTypes.UPDATE_PASSWORD_FAILED:
		return updateObject(state, { updatePasswordStatus: setRequestStatus('failed', '', action.payload || true) });
	case actionTypes.UPDATE_PASSWORD_CLEAR:
		return updateObject(state, { updatePasswordStatus: setRequestStatus('clear') });
	case actionTypes.FETCH_WHATSAPP_TEMPLATES_BEGIN:
		return updateObject(state, { answersStatus: setRequestStatus('begin') });
	case actionTypes.FETCH_WHATSAPP_TEMPLATES_SUCCESS:
		return updateObject(state, {
			whatsAppTemplatesStatus: setRequestStatus('success'),
			whatsAppTemplates: action.payload.templates
		});
	case actionTypes.FETCH_WHATSAPP_TEMPLATES_FAILED:
		return updateObject(state, { whatsAppTemplatesStatus: setRequestStatus('failed') });
	case actionTypes.UPDATE_USERID:
		return updateObject(state, { info: { ...state.info, auth0UserID: action.payload } });
	case actionTypes.ON_AGENT_BLOCKED:
		return updateObject(state, { agentBlocked: action.payload });
	default:
		return state;
	}
};

export default reducer;
