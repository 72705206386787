import React from 'react';
import PropTypes from 'prop-types';

import './index.sass';
import ImgSvg from '../../Atoms/ImgSvg';
import Wrapper from '../../Atoms/Wrapper';

const AttachmentItem = ({ onClearAttachment, attachment }) => (
	<div className="Attachment">
		<a
			href={attachment.url}
			target="_blank"
			download
			rel="noopener noreferrer"
		>
			{attachment.name}
		</a>
		<Wrapper action={() => onClearAttachment(attachment.name)}>
			<ImgSvg name="close" />
		</Wrapper>
	</div>
);

AttachmentItem.propTypes = {
	onClearAttachment: PropTypes.func.isRequired,
	attachment: PropTypes.shape({
		name: PropTypes.string,
		url: PropTypes.string
	}).isRequired
};

export default AttachmentItem;
