export const LOGIN_AGENT_BEGIN = 'LOGIN_AGENT_BEGIN';
export const LOGIN_AGENT_SUCCESS = 'LOGIN_AGENT_SUCCESS';
export const LOGIN_AGENT_FAILED = 'LOGIN_AGENT_FAILED';

export const AUTH_AGENT_BEGIN = 'AUTH_AGENT_BEGIN';
export const AUTH_AGENT_SUCCESS = 'AUTH_AGENT_SUCCESS';
export const AUTH_AGENT_FAILED = 'AUTH_AGENT_FAILED';

export const REGISTER_AGENT_BEGIN = 'REGISTER_AGENT_BEGIN';
export const REGISTER_AGENT_SUCCESS = 'REGISTER_AGENT_SUCCESS';
export const REGISTER_AGENT_FAILED = 'REGISTER_AGENT_FAILED';

export const REGISTER_PHONE_BEGIN = 'REGISTER_PHONE_BEGIN';
export const REGISTER_PHONE_SUCCESS = 'REGISTER_PHONE_SUCCESS';

export const UNREGISTER_PHONE = 'UNREGISTER_PHONE';

export const UNREGISTER_AGENT_BEGIN = 'UNREGISTER_AGENT_BEGIN';
export const UNREGISTER_AGENT_SUCCESS = 'UNREGISTER_AGENT_SUCCESS';
export const UNREGISTER_AGENT_FAILED = 'UNREGISTER_AGENT_FAILED';

export const AGENT_BREAK_STATUS_BEGIN = 'AGENT_BREAK_STATUS_BEGIN';
export const AGENT_BREAK_STATUS_SUCCESS = 'AGENT_BREAK_STATUS_SUCCESS';

export const FETCH_BREAK_STATUS_BEGIN = 'FETCH_BREAK_STATUS_BEGIN';
export const FETCH_BREAK_STATUS_SUCCESS = 'FETCH_BREAK_STATUS_SUCCESS';
export const FETCH_BREAK_STATUS_FAILED = 'FETCH_BREAK_STATUS_FAILED';

export const FETCH_FORM_FIELDS_BEGIN = 'FETCH_FORM_FIELDS_BEGIN';
export const FETCH_FORM_FIELDS_SUCCESS = 'FETCH_FORM_FIELDS_SUCCESS';
export const FETCH_FORM_FIELDS_FAILED = 'FETCH_FORM_FIELDS_FAILED';

export const FETCH_CUSTOM_CHANNELS_BEGIN = 'FETCH_CUSTOM_CHANNELS_BEGIN';
export const FETCH_CUSTOM_CHANNELS_SUCCESS = 'FETCH_CUSTOM_CHANNELS_SUCCESS';
export const FETCH_CUSTOM_CHANNELS_FAILED = 'FETCH_CUSTOM_CHANNELS_FAILED';

export const FETCH_INBOX_INTERACTIONS_BEGIN = 'FETCH_INBOX_INTERACTIONS_BEGIN';
export const FETCH_INBOX_INTERACTIONS_SUCCESS = 'FETCH_INBOX_INTERACTIONS_SUCCESS';
export const FETCH_INBOX_INTERACTIONS_FAILED = 'FETCH_INBOX_INTERACTIONS_FAILED';
export const RESET_INBOX_INTERACTION_PAGE_COUNT = 'RESET_INBOX_INTERACTION_PAGE_COUNT';

export const FETCH_QUEUE_INTERACTIONS_BEGIN = 'FETCH_QUEUE_INTERACTIONS_BEGIN';
export const FETCH_QUEUE_INTERACTIONS_SUCCESS = 'FETCH_QUEUE_INTERACTIONS_SUCCESS';
export const FETCH_QUEUE_INTERACTIONS_FAILED = 'FETCH_QUEUE_INTERACTIONS_FAILED';
export const RESET_QUEUE_INTERACTION_PAGE_COUNT = 'RESET_QUEUE_INTERACTION_PAGE_COUNT';

export const FETCH_MISSED_INTERACTIONS_BEGIN = 'FETCH_MISSED_INTERACTIONS_BEGIN';
export const FETCH_MISSED_INTERACTIONS_SUCCESS = 'FETCH_MISSED_INTERACTIONS_SUCCESS';
export const FETCH_MISSED_INTERACTIONS_FAILED = 'FETCH_MISSED_INTERACTIONS_FAILED';
export const RESET_MISSED_INTERACTION_PAGE_COUNT = 'RESET_MISSED_INTERACTION_PAGE_COUNT';

export const FETCH_INTERACTIONS_COUNT_BEGIN = 'FETCH_INTERACTIONS_COUNT_BEGIN';
export const FETCH_INTERACTIONS_COUNT_SUCCESS = 'FETCH_INTERACTIONS_COUNT_SUCCESS';
export const FETCH_INTERACTIONS_COUNT_FAILED = 'FETCH_INTERACTIONS_COUNT_FAILED';

export const SEARCH_INBOX_INTERACTIONS_BEGIN = 'SEARCH_INBOX_INTERACTIONS_BEGIN';
export const SEARCH_INBOX_INTERACTIONS_SUCCESS = 'SEARCH_INBOX_INTERACTIONS_SUCCESS';
export const SEARCH_INBOX_INTERACTIONS_FAILED = 'SEARCH_INBOX_INTERACTIONS_FAILED';

export const SEARCH_QUEUE_INTERACTIONS_BEGIN = 'SEARCH_QUEUE_INTERACTIONS_BEGIN';
export const SEARCH_QUEUE_INTERACTIONS_SUCCESS = 'SEARCH_QUEUE_INTERACTIONS_SUCCESS';
export const SEARCH_QUEUE_INTERACTIONS_FAILED = 'SEARCH_QUEUE_INTERACTIONS_FAILED';

export const SEARCH_MISSED_INTERACTIONS_BEGIN = 'SEARCH_MISSED_INTERACTIONS_BEGIN';
export const SEARCH_MISSED_INTERACTIONS_SUCCESS = 'SEARCH_MISSED_INTERACTIONS_SUCCESS';
export const SEARCH_MISSED_INTERACTIONS_FAILED = 'SEARCH_MISSED_INTERACTIONS_FAILED';

export const UPDATE_CUSTOMER_BEGIN = 'UPDATE_CUSTOMER_BEGIN';
export const UPDATE_CUSTOMER_SUCCESS = 'UPDATE_CUSTOMER_SUCCESS';
export const UPDATE_CUSTOMER_FAILED = 'UPDATE_CUSTOMER_FAILED';
export const UPDATE_CUSTOMER_CLEAR = 'UPDATE_CUSTOMER_CLEAR';

export const FETCH_TRANSFER_DATA_BEGIN = 'FETCH_TRANSFER_DATA_BEGIN';
export const FETCH_TRANSFER_DATA_SUCCESS = 'FETCH_TRANSFER_DATA_SUCCESS';
export const FETCH_TRANSFER_DATA_FAILED = 'FETCH_TRANSFER_DATA_FAILED';

export const UPDATE_NOTE_BEGIN = 'UPDATE_NOTE_BEGIN';
export const UPDATE_NOTE_SUCCESS = 'UPDATE_NOTE_SUCCESS';
export const UPDATE_NOTE_FAILED = 'UPDATE_NOTE_FAILED';
export const UPDATE_NOTE_CHANGED = 'UPDATE_NOTE_CHANGED';

export const UPDATE_TAGS_BEGIN = 'UPDATE_TAGS_BEGIN';
export const UPDATE_TAGS_SUCCESS = 'UPDATE_TAGS_SUCCESS';
export const UPDATE_TAGS_FAILED = 'UPDATE_TAGS_FAILED';
export const UPDATE_TAGS_CHANGED = 'UPDATE_TAGS_CHANGED';

export const FETCH_HISTORY_BEGIN = 'FETCH_HISTORY_BEGIN';
export const FETCH_HISTORY_SUCCESS = 'FETCH_HISTORY_SUCCESS';
export const FETCH_HISTORY_FAILED = 'FETCH_HISTORY_FAILED';

export const FETCH_INTERACTION_HISTORY_BEGIN = 'FETCH_INTERACTION_HISTORY_BEGIN';
export const FETCH_INTERACTION_HISTORY_SUCCESS = 'FETCH_INTERACTION_HISTORY_SUCCESS';
export const FETCH_INTERACTION_HISTORY_FAILED = 'FETCH_INTERACTION_HISTORY_FAILED';

export const UPDATE_INTERACTION_CUSTOMER_BEGIN = 'UPDATE_INTERACTION_CUSTOMER_BEGIN';
export const UPDATE_INTERACTION_CUSTOMER_SUCCESS = 'UPDATE_INTERACTION_CUSTOMER_SUCCESS';
export const UPDATE_INTERACTION_CUSTOMER_FAILED = 'UPDATE_INTERACTION_CUSTOMER_FAILED';

export const FETCH_ALL_TAGS_BEGIN = 'FETCH_ALL_TAGS_BEGIN';
export const FETCH_ALL_TAGS_SUCCESS = 'FETCH_ALL_TAGS_SUCCESS';
export const FETCH_ALL_TAGS_FAILED = 'FETCH_ALL_TAGS_FAILED';

export const FETCH_TAGS_BEGIN = 'FETCH_TAGS_BEGIN';
export const FETCH_TAGS_SUCCESS = 'FETCH_TAGS_SUCCESS';
export const FETCH_TAGS_FAILED = 'FETCH_TAGS_FAILED';

export const FETCH_SUBTAGS_BEGIN = 'FETCH_SUBTAGS_BEGIN';
export const FETCH_SUBTAGS_SUCCESS = 'FETCH_SUBTAGS_SUCCESS';
export const FETCH_SUBTAGS_FAILED = 'FETCH_SUBTAGS_FAILED';

export const FETCH_ANSWERS_BEGIN = 'FETCH_ANSWERS_BEGIN';
export const FETCH_ANSWERS_SUCCESS = 'FETCH_ANSWERS_SUCCESS';
export const FETCH_ANSWERS_FAILED = 'FETCH_ANSWERS_FAILED';

export const FETCH_CUSTOMER_BEGIN = 'FETCH_CUSTOMER_BEGIN';
export const FETCH_CUSTOMER_SUCCESS = 'FETCH_CUSTOMER_SUCCESS';
export const FETCH_CUSTOMER_FAILED = 'FETCH_CUSTOMER_FAILED';

export const ON_NEW_INTERACTION_SUCCESS = 'ON_NEW_INTERACTION_SUCCESS';
export const ON_UPDATE_INTERACTION_SUCCESS = 'ON_UPDATE_INTERACTION_SUCCESS';

export const ON_NEW_MESSAGE_SUCCESS = 'ON_NEW_MESSAGE_SUCCESS';
export const ON_SEND_MESSAGE_SUCCESS = 'ON_SEND_MESSAGE_SUCCESS';
export const ON_DELIVERED_MESSAGE_SUCCESS = 'ON_DELIVERED_MESSAGE_SUCCESS';
export const ON_RECEIVED_MESSAGE_SUCCESS = 'ON_RECEIVED_MESSAGE_SUCCESS';

export const ON_TYPING_SUCCESS = 'ON_TYPING_SUCCESS';
export const ON_CLEARED_SUCCESS = 'ON_CLEARED_SUCCESS';
export const ON_TRANSFERRED_SUCCESS = 'ON_TRANSFERRED_SUCCESS';
export const ON_CLOSING_INTERACTION = 'ON_CLOSING_INTERACTION';
export const ON_CLOSED_INTERACTION = 'ON_CLOSED_INTERACTION';
export const ON_FINISHING = 'ON_FINISHING';
export const ON_FINISHED_SUCCESS = 'ON_FINISHED_SUCCESS';

export const DEFINE_SELECTED_INTERACTION = 'DEFINE_SELECTED_INTERACTION';

export const SET_CURRENT_INTERACTION = 'SET_CURRENT_INTERACTION';

export const ON_ACCEPT_INTERACTION_BEGIN = 'ON_ACCEPT_INTERACTION_BEGIN';
export const ON_ACCEPT_INTERACTION_SUCCESS = 'ON_ACCEPT_INTERACTION_SUCCESS';

export const ON_REPLY_INTERACTION_SUCCESS = 'ON_REPLY_INTERACTION_SUCCESS';

export const LOAD_INTERACTION_INFO_BEGIN = 'LOAD_INTERACTION_INFO_BEGIN';
export const LOAD_INTERACTION_INFO_SUCCESS = 'LOAD_INTERACTION_INFO_SUCCESS';
export const LOAD_INTERACTION_INFO_FAILED = 'LOAD_INTERACTION_INFO_FAILED';

export const FETCH_CLIENT_HISTORY = 'FETCH_CLIENT_HISTORY_SUCCESS';

export const REMOVE_INTERACTION = 'REMOVE_INTERACTION';

export const SET_NOTIFY = 'SET_NOTIFY';
export const SET_UNAUTHORIZED = 'SET_UNAUTHORIZED';

export const MERGE_CUSTOMER_FAILED = 'MERGE_CUSTOMER_FAILED';
export const MERGE_CUSTOMER_SUCCESS = 'MERGE_CUSTOMER_SUCCESS';
export const MERGE_CUSTOMER_BEGIN = 'MERGE_CUSTOMER_BEGIN';
export const MERGE_CUSTOMER_CLEAR = 'MERGE_CUSTOMER_CLEAR';

export const FETCH_AGENT_HISTORY_FAILED = 'FETCH_AGENT_HISTORY_FAILED';
export const FETCH_AGENT_HISTORY_SUCCESS = 'FETCH_AGENT_HISTORY_SUCCESS';
export const FETCH_AGENT_HISTORY_BEGIN = 'FETCH_AGENT_HISTORY_BEGIN';

export const FETCH_AGENT_DASHBOARD_FAILED = 'FETCH_AGENT_DASHBOARD_FAILED';
export const FETCH_AGENT_DASHBOARD_SUCCESS = 'FETCH_AGENT_DASHBOARD_SUCCESS';
export const FETCH_AGENT_DASHBOARD_BEGIN = 'FETCH_AGENT_DASHBOARD_BEGIN';

export const FETCH_PEERS_DASHBOARD_FAILED = 'FETCH_PEERS_DASHBOARD_FAILED';
export const FETCH_PEERS_DASHBOARD_SUCCESS = 'FETCH_PEERS_DASHBOARD_SUCCESS';
export const FETCH_PEERS_DASHBOARD_BEGIN = 'FETCH_PEERS_DASHBOARD_BEGIN';

export const FETCH_WIDGET_AND_INTEGRATIONS_BEGIN = 'FETCH_WIDGET_AND_INTEGRATIONS_BEGIN';
export const FETCH_WIDGET_AND_INTEGRATIONS_SUCCESS = 'FETCH_WIDGET_AND_INTEGRATIONS_SUCCESS';
export const FETCH_WIDGET_AND_INTEGRATIONS_FAILED = 'FETCH_WIDGET_AND_INTEGRATIONS_FAILED';

export const SEARCH_AGENT_HISTORY_FAILED = 'SEARCH_AGENT_HISTORY_FAILED';
export const SEARCH_AGENT_HISTORY_SUCCESS = 'SEARCH_AGENT_HISTORY_SUCCESS';
export const SEARCH_AGENT_HISTORY_BEGIN = 'SEARCH_AGENT_HISTORY_BEGIN';

export const FETCH_AGENT_CONTACTS_FAILED = 'FETCH_AGENT_CONTACTS_FAILED';
export const FETCH_AGENT_CONTACTS_SUCCESS = 'FETCH_AGENT_CONTACTS_SUCCESS';
export const FETCH_AGENT_CONTACTS_BEGIN = 'FETCH_AGENT_CONTACTS_BEGIN';
export const FETCH_AGENTS_CONTACTS_SEARCH = 'FETCH_AGENTS_CONTACTS_SEARCH';

export const FETCH_AGENT_TASKS_FAILED = 'FETCH_AGENT_TASKS_FAILED';
export const FETCH_AGENT_TASKS_SUCCESS = 'FETCH_AGENT_TASKS_SUCCESS';
export const FETCH_AGENT_TASKS_BEGIN = 'FETCH_AGENT_TASKS_BEGIN';
export const FETCH_AGENT_TASK = 'FETCH_AGENT_TASK';
export const CREATE_TASK_SUCCESS = 'CREATE_TASK_SUCCESS';
export const CREATE_OR_UPDATE_INTERACTION_TASK_SUCCESS = 'CREATE_OR_UPDATE_INTERACTION_TASK_SUCCESS';
export const CREATE_OR_UPDATE_INTERACTION_TASK_BEGIN = 'CREATE_OR_UPDATE_INTERACTION_TASK_BEGIN';
export const CREATE_OR_UPDATE_INTERACTION_TASK_FAILED = 'CREATE_OR_UPDATE_INTERACTION_TASK_FAILED';
export const CREATE_OR_UPDATE_INTERACTION_TASK_CLEAR = 'CREATE_OR_UPDATE_INTERACTION_TASK_CLEAR';
export const CLEAR_NEW_TASK = 'CLEAR_NEW_TASK';
export const CLEAR_INTERACTION_NEW_TASK = 'CLEAR_INTERACTION_NEW_TASK';
export const CLEAR_EDIT_TASK = 'CLEAR_EDIT_TASK';
export const FETCH_AGENT_COUNT_SUCCESS = 'FETCH_AGENT_COUNT_SUCCESS';
export const FETCH_AGENT_COUNT_FAILED = 'FETCH_AGENT_COUNT_FAILED';

export const DEFINE_PHONE_CONNECTION_DATA = 'DEFINE_PHONE_CONNECTION_DATA';

export const UPDATE_CALL_STATUS = 'UPDATE_CALL_STATUS';

export const UPDATE_CONFERENCE_USERS = 'UPDATE_CONFERENCE_USERS';
export const CLEAR_CONFERENCE_USERS = 'CLEAR_CONFERENCE_USERS';

export const RESET_PASSWORD_FAILED = 'RESET_PASSWORD_FAILED';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_BEGIN = 'RESET_PASSWORD_BEGIN';
export const RESET_PASSWORD_CLEAR = 'RESET_PASSWORD_CLEAR';

export const SEARCH_AGENT_CONTACTS_FAILED = 'SEARCH_AGENT_CONTACTS_FAILED';
export const SEARCH_AGENT_CONTACTS_SUCCESS = 'SEARCH_AGENT_CONTACTS_SUCCESS';
export const SEARCH_AGENT_CONTACTS_BEGIN = 'SEARCH_AGENT_CONTACTS_BEGIN';

export const CREATING_INTERACTION = 'CREATING_INTERACTION';

export const ADD_CUSTOMER_BEGIN = 'ADD_CUSTOMER_BEGIN';
export const ADD_CUSTOMER_SUCCESS = 'ADD_CUSTOMER_SUCCESS';
export const ADD_CUSTOMER_FAILED = 'ADD_CUSTOMER_FAILED';
export const ADD_CUSTOMER_CLEAR = 'ADD_CUSTOMER_CLEAR';

export const SEND_EMAIL_ATTACHMENT_BEGIN = 'SEND_EMAIL_ATTACHMENT_BEGIN';
export const SEND_EMAIL_ATTACHMENT_SUCCESS = 'SEND_EMAIL_ATTACHMENT_SUCCESS';
export const SEND_EMAIL_ATTACHMENT_FAILED = 'SEND_EMAIL_ATTACHMENT_FAILED';
export const SEND_EMAIL_ATTACHMENT_CLEAR = 'SEND_EMAIL_ATTACHMENT_CLEAR';

export const ADD_ATTACHMENT = 'ADD_ATTACHMENT';

export const CREATED_INTERACTION_TASK = 'CREATED_INTERACTION_TASK';
export const CREATED_INTERACTION_TASK_CLEAR = 'CREATED_INTERACTION_TASK_CLEAR';

export const SET_ONLY_CHAT_OR_PHONE = 'SET_ONLY_CHAT_OR_PHONE';

export const FINISH_TASK = 'FINISH_TASK';

export const FINISH_INTERACTION_TASK = 'FINISH_INTERACTION_TASK';

export const INFORM_EXPIRING_SOON_TASKS = 'INFORM_EXPIRING_SOON_TASKS';

export const UPDATE_PHOTO_BEGIN = 'UPDATE_PHOTO_BEGIN';
export const UPDATE_PHOTO_SUCCESS = 'UPDATE_PHOTO_SUCCESS';
export const UPDATE_PHOTO_FAILED = 'UPDATE_PHOTO_FAILED';
export const UPDATE_PHOTO_CLEAR = 'UPDATE_PHOTO_CLEAR';

export const UPDATE_SETTINGS_BEGIN = 'UPDATE_SETTINGS_BEGIN';
export const UPDATE_SETTINGS_SUCCESS = 'UPDATE_SETTINGS_SUCCESS';
export const UPDATE_SETTINGS_FAILED = 'UPDATE_SETTINGS_FAILED';

export const UPDATE_PASSWORD_BEGIN = 'UPDATE_PASSWORD_BEGIN';
export const UPDATE_PASSWORD_SUCCESS = 'UPDATE_PASSWORD_SUCCESS';
export const UPDATE_PASSWORD_FAILED = 'UPDATE_PASSWORD_FAILED';
export const UPDATE_PASSWORD_CLEAR = 'UPDATE_PASSWORD_CLEAR';

export const FETCH_WHATSAPP_TEMPLATES_BEGIN = 'FETCH_WHATSAPP_TEMPLATES_BEGIN';
export const FETCH_WHATSAPP_TEMPLATES_SUCCESS = 'FETCH_WHATSAPP_TEMPLATES_SUCCESS';
export const FETCH_WHATSAPP_TEMPLATES_FAILED = 'FETCH_WHATSAPP_TEMPLATES_FAILED';

// User Interface actions
export const SET_INTERACTION_LIST = 'SET_INTERACTION_LIST';
export const SET_CUSTOMER_SECTION = 'SET_CUSTOMER_SECTION';
export const SET_EDIT_CUSTOMER = 'SET_EDIT_CUSTOMER';

export const SET_PENDING_MESSAGES = 'SET_PENDING_MESSAGES';
export const CLEAR_PENDING_MESSAGES = 'CLEAR_PENDING_MESSAGES';

export const CLEAR_ACTIONS = 'CLEAR_ACTIONS';

export const CLEAR_INPUT_ANNOTATION = 'CLEAR_INPUT_ANNOTATION';

export const CHANGE_INPUT_TEXT = 'CHANGE_INPUT_TEXT';
export const CHANGE_INPUT_ANNOTATION = 'CHANGE_INPUT_ANNOTATION';
export const CHANGE_INPUT_SEARCH = 'CHANGE_INPUT_SEARCH';
export const CHANGE_INPUT_PHONE = 'CHANGE_INPUT_PHONE';

export const CHANGE_INPUT_TAGS = 'CHANGE_INPUT_TAGS';
export const SET_TAG = 'SET_TAG';
export const REMOVE_TAG = 'REMOVE_TAG';

export const SHOW_TRANSFER = 'SHOW_TRANSFER';
export const HIDE_TRANSFER = 'HIDE_TRANSFER';

export const SHOW_ANSWER = 'SHOW_ANSWER';
export const HIDE_ANSWER = 'HIDE_ANSWER';

export const SHOW_WHATSAPP_TEMPLATE = 'SHOW_WHATSAPP_TEMPLATE';
export const HIDE_WHATSAPP_TEMPLATE = 'HIDE_WHATSAPP_TEMPLATE';

export const SHOW_EMOJI = 'SHOW_EMOJI';
export const HIDE_EMOJI = 'HIDE_EMOJI';

export const SHOW_ANNOTATION = 'SHOW_ANNOTATION';
export const HIDE_ANNOTATION = 'HIDE_ANNOTATION';

export const SHOW_TAG = 'SHOW_TAG';
export const HIDE_TAG = 'HIDE_TAG';

export const SHOW_INTERNAL_MESSAGES = 'SHOW_INTERNAL_MESSAGES';
export const HIDE_INTERNAL_MESSAGES = 'HIDE_INTERNAL_MESSAGES';

export const SHOW_REPLY_MESSAGE = 'SHOW_REPLY_MESSAGE';
export const HIDE_REPLY_MESSAGE = 'HIDE_REPLY_MESSAGE';

export const SHOW_VOICE_RECORDING = 'SHOW_VOICE_RECORDING';
export const HIDE_VOICE_RECORDING = 'HIDE_VOICE_RECORDING';

export const SHOW_CALL = 'SHOW_CALL';
export const HIDE_CALL = 'HIDE_CALL';

export const SHOW_CREATE_TASK = 'SHOW_CREATE_TASK';
export const HIDE_CREATE_TASK = 'HIDE_CREATE_TASK';

export const FETCH_IDENTITY_BEGIN = 'FETCH_IDENTITY_BEGIN';
export const FETCH_IDENTITY_SUCCESS = 'FETCH_IDENTITY_SUCCESS';
export const FETCH_IDENTITY_FAILED = 'FETCH_IDENTITY_FAILED';

export const DEFINE_DEFAULT_IDENTITY = 'DEFINE_DEFAULT_IDENTITY';

export const DEFINE_MAINBOX_SECTION = 'DEFINE_MAINBOX_SECTION';
export const DEFINE_TEMPLATE = 'DEFINE_TEMPLATE';
export const DEFINE_TASK_SIDEBAR = 'DEFINE_TASK_SIDEBAR';

export const ACTIVE_PHONE_INTERFACE = 'ACTIVE_PHONE_INTERFACE';

export const SHOW_RECEPTIVE_MODAL = 'SHOW_RECEPTIVE_MODAL';
export const HIDE_RECEPTIVE_MODAL = 'HIDE_RECEPTIVE_MODAL';

export const SHOW_DIAL = 'SHOW_DIAL';
export const HIDE_DIAL = 'HIDE_DIAL';

export const SHOW_CONFERENCE = 'SHOW_CONFERENCE';
export const HIDE_CONFERENCE = 'HIDE_CONFERENCE';

export const SHOW_ADD_CUSTOMER = 'SHOW_ADD_CUSTOMER';
export const HIDE_ADD_CUSTOMER = 'HIDE_ADD_CUSTOMER';

export const SHOW_EDIT_CUSTOMER = 'SHOW_EDIT_CUSTOMER';
export const HIDE_EDIT_CUSTOMER = 'HIDE_EDIT_CUSTOMER';

export const CLEAR_UPDATE_INTERFACE = 'CLEAR_UPDATE_INTERFACE';

export const UPDATE_INTERACTION_STATUS = 'UPDATE_INTERACTION_STATUS';

export const UPDATE_TASK_SEARCH = 'UPDATE_TASK_SEARCH';

export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
export const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION';

export const SHOW_EDIT_AGENT = 'SHOW_EDIT_AGENT';
export const HIDE_EDIT_AGENT = 'HIDE_EDIT_AGENT';

export const SHOW_BREAK_STATUS = 'SHOW_BREAK_STATUS';
export const HIDE_BREAK_STATUS = 'HIDE_BREAK_STATUS';

export const TOKEN_EXPIRED = 'TOKEN_EXPIRED';

export const UPDATE_USERID = 'UPDATE_USERID';

export const FETCH_DEPARTMENTS_BEGIN = 'FETCH_DEPARTMENTS_BEGIN';
export const FETCH_DEPARTMENTS_SUCCESS = 'FETCH_DEPARTMENTS_SUCCESS';
export const FETCH_DEPARTMENTS_FAILED = 'FETCH_DEPARTMENTS_FAILED';

export const ON_NEW_WHATSAPP_VOICE_CALL_SUCCESS = 'ON_NEW_WHATSAPP_VOICE_CALL_SUCCESS';
export const ON_NEW_WHATSAPP_MEDIA_STREAM = 'ON_NEW_WHATSAPP_MEDIA_STREAM';
export const ON_WHATSAPP_CALL_DURATION_SUCCESS = 'ON_WHATSAPP_CALL_DURATION_SUCCESS';
export const UPDATE_CALL_DURATION = 'UPDATE_CALL_DURATION';

export const ON_TERMINATE_WHATSAPP_VOICE_CALL_SUCCESS = 'ON_TERMINATE_WHATSAPP_VOICE_CALL_SUCCESS';

export const ON_AGENT_BLOCKED = 'ON_AGENT_BLOCKED';
