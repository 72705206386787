import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { injectIntl, defineMessages } from 'react-intl';

import './index.sass';
import ImgSvg from '../../../Atoms/ImgSvg';
import { MESSAGE_CONTENT_TYPE } from '../../../../shared/consts';
import { mapNewCustomerItem, getTaskCustomerName } from '../../../../shared/utility';

const messages = defineMessages({
	image: {
		id: 'messageContent.image',
		defaultMessage: 'Imagem'
	},
	file: {
		id: 'messageContent.file',
		defaultMessage: 'Arquivo'
	},
	referenceImage: {
		id: 'messageContent.referenceImage',
		defaultMessage: 'imagem da mensagem de referência'
	}
});

const ReferenceContent = ({ messageInfo, iconColor, intl }) => {
	const elementRef = useRef(null);
	const [duration, setDuration] = useState();

	const customerName = useSelector(
		(state) => {
			const {
				interactions,
				pendingInteractions,
				historyInteractions,
				missedInteractions,
				currentInteractionHash
			} = state.interaction;
			const allInteractions = (
				interactions.concat(pendingInteractions).concat(historyInteractions).concat(missedInteractions)
			);

			const currentInteraction = (
				allInteractions.find(({ interactionHash }) => interactionHash === currentInteractionHash) || {}
			);
			const currentCostumer = mapNewCustomerItem(currentInteraction.customerInfo);
			return getTaskCustomerName(currentCostumer);
		}
	);

	const secondsToTime = time => `${String(Math.floor(time / 60)).padStart(2, '0')}:${String(Math.floor(time % 60)).padStart(2, '0')}`;

	const handleLoadedMetadata = () => {
		const element = elementRef.current;
		if (!element) return;
		setDuration(secondsToTime(element.duration));
	};

	const getVerifiedContentType = (contentType) => {
		let result = contentType;

		if (result.match(MESSAGE_CONTENT_TYPE.IMAGE)) {
			result = MESSAGE_CONTENT_TYPE.IMAGE;
		} else if (result.match(MESSAGE_CONTENT_TYPE.AUDIO)) {
			result = MESSAGE_CONTENT_TYPE.AUDIO;
		} else if (result.match(MESSAGE_CONTENT_TYPE.VIDEO)) {
			result = MESSAGE_CONTENT_TYPE.VIDEO;
		}

		return result;
	};

	const renderIcon = () => {
		const verifiedContentType = getVerifiedContentType(messageInfo.contentType);

		switch (verifiedContentType) {
		case MESSAGE_CONTENT_TYPE.TEXT:
		case MESSAGE_CONTENT_TYPE.EMAIL:
		case MESSAGE_CONTENT_TYPE.STATE:
			return null;
		case MESSAGE_CONTENT_TYPE.IMAGE:
			return 'image';
		case MESSAGE_CONTENT_TYPE.AUDIO:
			return 'audio';
		case MESSAGE_CONTENT_TYPE.VIDEO:
			return 'video';
		case MESSAGE_CONTENT_TYPE.LOCATION:
			return 'location';
		default:
			return 'file';
		}
	};

	const renderPreview = () => {
		const verifiedContentType = getVerifiedContentType(messageInfo.contentType);

		switch (verifiedContentType) {
		case MESSAGE_CONTENT_TYPE.AUDIO:
			return (
				<audio
					ref={elementRef}
					onLoadedMetadata={handleLoadedMetadata}
					src={messageInfo.url}
				>
					<track kind="captions" />
				</audio>
			);
		case MESSAGE_CONTENT_TYPE.IMAGE:
			return <img src={messageInfo.url} alt={intl.formatMessage(messages.referenceImage)} />;
		case MESSAGE_CONTENT_TYPE.VIDEO:
			return (
				<video
					ref={elementRef}
					onLoadedMetadata={handleLoadedMetadata}
					preload="metadata"
					src={messageInfo.url}
				>
					<track kind="captions" />
				</video>
			);
		default:
			return null;
		}
	};

	const renderContent = () => {
		const { content } = messageInfo;

		const defaultContentByType = {
			image: intl.formatMessage(messages.image),
			audio: duration,
			video: duration
		};

		return content || defaultContentByType[messageInfo.contentType] || intl.formatMessage(messages.file);
	};

	return (
		<div className="ReferenceContent">
			<div className="ReferenceContent__wrapper">
				<div className="ReferenceContent__wrapper__info">
					<span className="ReferenceContent__wrapper__info__name">{messageInfo.agentName || customerName}</span>
					<span className="ReferenceContent__wrapper__info__content">
						{renderIcon() && <ImgSvg name={renderIcon()} color={iconColor} />}
						<p>{renderContent()}</p>
					</span>
				</div>
			</div>
			{renderPreview() && (
				<div className="ReferenceContent__preview">
					{renderPreview()}
				</div>
			)}
		</div>
	);
};

ReferenceContent.propTypes = {
	messageInfo: PropTypes.shape({
		content: PropTypes.string,
		url: PropTypes.string,
		agentName: PropTypes.string,
		interactionType: PropTypes.string,
		contentType: PropTypes.string
	}).isRequired,
	iconColor: PropTypes.string,
	intl: PropTypes.shape({
		formatMessage: PropTypes.func.isRequired
	}).isRequired
};

export default injectIntl(ReferenceContent);
