import React from 'react';
import PropTypes from 'prop-types';

const NluBot = ({ script, agentId, protocol }) => (
	<div className="nlu">
		<iframe
			title="nlu"
			width="100%"
			style={{ height: 'calc(100vh - 109px)' }}
			frameBorder="none"
			src={`${script}?agentId=${agentId}&protocol=${protocol}`}
		/>
	</div>
);

NluBot.propTypes = {
	script: PropTypes.string.isRequired,
	agentId: PropTypes.number.isRequired,
	protocol: PropTypes.number.isRequired
};

export default NluBot;
