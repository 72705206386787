import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { defineMessages, injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import {
	getCustomerFieldValue,
	getLastMessage,
	parseCustomerName,
	timeInfoFormat5,
	interactionTypeLabel
} from '../../../shared/utility';
import { useBan } from '../../../context/bannedContent';
import * as actions from '../../../store/actions';
import CircleDisplay from '../../Atoms/CircleDisplay';
import ImgSvg from '../../Atoms/ImgSvg';
import SlaTimer from '../../Atoms/SlaTimer';
import Text from '../../Atoms/Text';
import Wrapper from '../../Atoms/Wrapper';
import ChannelImage from '../../Atoms/ChannelImage';
import VideoNotification from '../../Molecules/VideoNotification';
import './index.sass';
import UnavailableModal from '../UnavailableModal';

const messagesIntl = defineMessages({
	notRegistered: {
		id: 'clientMessage.notRegistered',
		defaultMessage: 'Não identificado'
	},
	audio: {
		id: 'clientMessage.audio',
		defaultMessage: 'Áudio'
	},
	video: {
		id: 'clientMessage.video',
		defaultMessage: 'Vídeo'
	},
	attachment: {
		id: 'clientMessage.attachment',
		defaultMessage: 'Anexo'
	},
	image: {
		id: 'clientMessage.image',
		defaultMessage: 'Imagem'
	},
	tagNotification: {
		id: 'clientMessage.tagNotification',
		defaultMessage: 'Tags obrigatórias'
	},
	tagNotificationContent: {
		id: 'clientMessage.tagNotificationContent',
		defaultMessage: 'Adicione tags a interação'
	},
	slaFirstReplyTimeInteractionTitle: {
		id: 'app.notification.slaFirstReplyTimeInteractionTitle',
		defaultMessage: 'SLA de primeira resposta'
	},
	slaFirstReplyTimeInteraction: {
		id: 'app.notification.slaFirstReplyTimeInteraction',
		defaultMessage: 'O atendimento ({protocol}) excedeu o tempo de SLA de primeira resposta'
	},
	slaServiceTimeInteractionTitle: {
		id: 'app.notification.slaServiceTimeInteractionTitle',
		defaultMessage: 'SLA de atendimento'
	},
	slaServiceTimeInteraction: {
		id: 'app.notification.slaServiceTimeInteraction',
		defaultMessage: 'O atendimento ({protocol}) excedeu o tempo de SLA de atendimento'
	},
	slaQueuedTimeInteractionTitle: {
		id: 'app.notification.slaQueuedTimeInteractionTitle',
		defaultMessage: 'SLA de espera'
	},
	slaQueuedTimeInteraction: {
		id: 'app.notification.slaQueuedTimeInteraction',
		defaultMessage: 'O atendimento ({protocol}) excedeu o tempo de SLA de espera'
	},
	slaMessageTimeInteractionTitle: {
		id: 'app.notification.slaMessageTimeInteractionTitle',
		defaultMessage: 'SLA de mensagem'
	},
	slaMessageTimeInteraction: {
		id: 'app.notification.slaMessageTimeInteraction',
		defaultMessage: 'O atendimento ({protocol}) excedeu o tempo de SLA de mensagem'
	},
	slaStatus: {
		id: 'clientMessage.slaStatus',
		defaultMessage: 'Status SLA'
	},
	notExpired: {
		id: 'clientMessage.notExpired',
		defaultMessage: 'no prazo'
	},
	almostExpired: {
		id: 'clientMessage.almostExpired',
		defaultMessage: 'prestes a expirar'
	},
	expired: {
		id: 'clientMessage.expired',
		defaultMessage: 'expirado'
	},
	expiredSlaTitle: {
		id: 'clientMessage.expiredSlaTitle',
		defaultMessage: 'SLA Expirado'
	},
	slaServiceTime: {
		id: 'clientMessage.slaServiceTime',
		defaultMessage: 'Atendimento'
	},
	slaQueuedTime: {
		id: 'clientMessage.slaQueuedTime',
		defaultMessage: 'Espera'
	},
	slaFirstReplyTime: {
		id: 'clientMessage.slaFirstReplyTime',
		defaultMessage: 'Primeira resposta'
	},
	slaMessageTime: {
		id: 'clientMessage.slaMessageTime',
		defaultMessage: 'Mensagem'
	},
	slaNotExpiredTitle: {
		id: 'clientMessage.slaNotExpiredTitle',
		defaultMessage: 'Ótimo, seu SLA está'
	},
	slaExpiredTitle: {
		id: 'clientMessage.slaExpiredTitle',
		defaultMessage: 'Atenção, seu SLA está'
	},
	slaInfo: {
		id: 'clientMessage.slaInfo',
		defineMessages: 'SLA é o acordo que define o tempo em que um atendimento precisa ser realizado.'
	},
	activate: {
		id: 'clientMessage.activate',
		defineMessages: 'Atender'
	},
	close: {
		id: 'clientMessage.close',
		defineMessages: 'Fechar'
	},
	emptyMessages: {
		id: 'clientMessage.emptyMessages',
		defineMessages: 'Essa conversa ainda não tem mensagens'
	}
});

const ClientMessage = ({
	selected,
	onSelect,
	interactionInfo,
	onAcceptInteraction,
	onRemoveInteraction,
	intl,
	defaultIdentity,
	phoneData,
	registeredPhone,
	hasPhoneInteraction,
	tagRequired,
	onShowTag,
	onlyChatOrPhone,
	interactionsInCall,
	replyInteraction,
	addNotification,
	defineSelectedInteraction,
	agent,
	agentBlocked,
	messageIndex
}) => {
	const { formatMessage } = intl;
	const { isFullAutomatic } = agent;
	const {
		customerInfo,
		messages,
		interactionHash,
		currentState,
		pendingMessages,
		interactionTags,
		interactionType,
		media,
		slas = []
	} = interactionInfo;

	const [name, setName] = useState('');
	useEffect(() => {
		if (customerInfo && customerInfo.fields) {
			setName(parseCustomerName(getCustomerFieldValue('main_identifier', customerInfo.fields) || formatMessage(messagesIntl.notRegistered)));
		}
	}, [customerInfo, formatMessage]);

	const [isRinging, setIsRinging] = useState(false);
	const [isNotAnswer, setIsNotAnswer] = useState(false);
	const [isQueued, setIsQueued] = useState(false);
	const [isEnded, setIsEnded] = useState(false);
	const [isUnavailable, setIsUnavailable] = useState(false);
	const [isPending, setIsPending] = useState(false);
	const [isMissed, setIsMissed] = useState(false);
	const [slaFirstReplyStatus, setSlaFirstReplyStatus] = useState({ status: 'notExpired', notification: true });
	const [slaServiceStatus, setSlaServiceStatus] = useState({ status: 'notExpired', notification: true });
	const [slaQueuedStatus, setSlaQueuedStatus] = useState({ status: 'notExpired', notification: true });
	const [slaMessageStatus, setSlaMessageStatus] = useState({ status: 'notExpired', notification: true });
	const [classes, setClasses] = useState('');
	const [actionClasses, setActionClasses] = useState('');
	const [lastMessageTime, setLastMessageTime] = useState('');
	const [showBlockModal, setBlockModal] = useState(false);

	const { cleanBadWords } = useBan();

	const colors = {
		expired: '#800013',
		almostExpired: '#CF620D',
		notExpired: '#53881F'
	};

	useEffect(() => {
		setIsRinging(currentState === 'RINGING');
		setIsNotAnswer(currentState === 'NOT_ANSWER');
		setIsQueued(currentState === 'QUEUED');
		setIsEnded(currentState === 'ENDED');
		setIsUnavailable(currentState === 'UNAVAILABLE');
		setIsPending(['POSTPONED', 'PENDING'].includes(currentState));
		setIsMissed(currentState === 'MISSED');
	}, [currentState]);

	useEffect(() => {
		const currentActionClasses = ['ClientMessage__action'];
		if (isRinging) currentActionClasses.push('ClientMessage__action--ringing');
		if (isNotAnswer || isQueued || isMissed || isPending) currentActionClasses.push('ClientMessage__action--queued');
		if (isUnavailable || isEnded) currentActionClasses.push('ClientMessage__action--ended');
		setActionClasses(currentActionClasses.join(' '));
	}, [isEnded, isMissed, isNotAnswer, isPending, isQueued, isRinging, isUnavailable]);

	const lastMessage = getLastMessage([...messages]);
	const {
		content,
		origin,
		createdAt,
		contentType,
		type
	} = lastMessage;
	const hasLastMessage = Object.keys(lastMessage).length > 0;
	const parsedOrigin = origin ? origin.toLowerCase() : '';
	const isAgentMessage = parsedOrigin === 'agent';

	let verifiedContent = content;
	const verifiedContentType = contentType || media || type;
	const ignoreLastMessageTypes = ['text', 'date', 'state'].includes(verifiedContentType);
	const canAcceptQueue = onlyChatOrPhone ? !hasPhoneInteraction : true;
	const canReply = interactionInfo.agent && agent ? interactionInfo.agent.id === agent.info.id : true;
	const activeCall = interactionsInCall.find(hash => hash === interactionHash);


	const verifyBlocked = () => {
		const blockAgent = agentBlocked.blocked;

		if (blockAgent) {
			setBlockModal(true);
			return true;
		}

		return false;
	};

	const acceptOrReplyAction = () => {
		if (verifyBlocked()) {
			return;
		}
		if (isMissed) {
			replyInteraction(interactionHash);
		} else {
			onAcceptInteraction(interactionHash);
		}
	};

	if (verifiedContentType && !ignoreLastMessageTypes) {
		if (content && content !== '') {
			verifiedContent = content;
		} else if (verifiedContentType.match('image')) {
			verifiedContent = formatMessage(messagesIntl.image);
		} else if (verifiedContentType.match('audio')) {
			verifiedContent = formatMessage(messagesIntl.audio);
		} else if (verifiedContentType.match('video')) {
			verifiedContent = formatMessage(messagesIntl.video);
		} else {
			verifiedContent = formatMessage(messagesIntl.attachment);
		}
	}

	const closeInteraction = useCallback(() => {
		if ((!interactionTags && tagRequired)
			|| (interactionTags && interactionTags.length === 0 && tagRequired)) {
			addNotification({
				title: formatMessage(messagesIntl.tagNotification),
				content: formatMessage(messagesIntl.tagNotificationContent),
				type: 'warning'
			});
			onShowTag(interactionHash);
			defineSelectedInteraction(interactionHash);
		} else {
			onRemoveInteraction({ interactionHash, registeredPhone, phoneData });
		}
	}, [
		addNotification,
		formatMessage,
		interactionHash,
		interactionTags,
		onRemoveInteraction,
		onShowTag,
		phoneData,
		registeredPhone,
		tagRequired,
		defineSelectedInteraction
	]);

	const renderSlaIcon = (data, isChecked) => <ImgSvg color={isChecked && data === 'almostExpired' ? colors.notExpired : colors[data]} name={isChecked ? 'checked' : `sla-${data}`} />;

	const renderSlaAlert = (data, isChecked) => {
		if (isChecked && data === 'almostExpired') return '';
		const alertMessages = {
			expired: formatMessage(messagesIntl.expiredSlaTitle),
			almostExpired: formatMessage(messagesIntl.almostExpired)
		};

		return data !== 'notExpired' && <span style={{ color: colors[data] }}>{alertMessages[data]}</span>;
	};

	const handleSlaStatus = (slaName) => {
		const statusSlas = {
			sla_queued_time: {
				name: 'slaQueuedStatus',
				status: slaQueuedStatus.status,
				setStatus: setSlaQueuedStatus
			},
			sla_first_reply_time: {
				name: 'slaFirstReplyStatus',
				status: slaFirstReplyStatus.status,
				setStatus: setSlaFirstReplyStatus
			},
			sla_service_time: {
				name: 'slaServiceStatus',
				status: slaServiceStatus.status,
				setStatus: setSlaServiceStatus
			},
			sla_message_time: {
				name: 'slaMessageStatus',
				status: slaMessageStatus.status,
				setStatus: setSlaMessageStatus
			}
		};

		return statusSlas[slaName];
	};


	const getMainSla = useCallback(() => {
		if (slas.find(sla => !sla.slaEndedAt)) {
			return slas.find(sla => !sla.slaEndedAt);
		} if (slas.find(sla => sla.slaName === 'sla_service_time')) {
			return slas.find(sla => sla.slaName === 'sla_service_time');
		} if (slas.find(sla => sla.slaName === 'sla_first_reply_time')) {
			return slas.find(sla => sla.slaName === 'sla_first_reply_time');
		}
		return slas[0];
	}, [slas]);

	const getSlaStatusTitle = () => {
		const mainSlaName = getMainSla().slaName;
		const slaStatusMessage = [
			{ status: slaQueuedStatus.status, name: 'sla_queued_time' },
			{ status: slaFirstReplyStatus.status, name: 'sla_first_reply_time' },
			{ status: slaServiceStatus.status, name: 'sla_service_time' },
			{ status: slaMessageStatus.status, name: 'sla_message_time' }
		];
		const currentSlaStatus = slaStatusMessage.find(slaStatus => slaStatus.name === mainSlaName).status;

		return (
			<>
				{currentSlaStatus === 'notExpired' ? formatMessage(messagesIntl.slaNotExpiredTitle) : formatMessage(messagesIntl.slaExpiredTitle)}
				<div style={{ backgroundColor: colors[currentSlaStatus] }}>{formatMessage(messagesIntl[currentSlaStatus])}</div>
			</>
		);
	};

	const getSlaTranslation = (slaName) => {
		const slasTranslate = {
			sla_first_reply_time: formatMessage(messagesIntl.slaFirstReplyTime),
			sla_queued_time: formatMessage(messagesIntl.slaQueuedTime),
			sla_service_time: formatMessage(messagesIntl.slaServiceTime),
			sla_message_time: formatMessage(messagesIntl.slaMessageTime)
		};

		return slasTranslate[slaName];
	};

	const changeStatus = sla => (data) => {
		const title = {
			slaQueuedStatus: formatMessage(messagesIntl.slaQueuedTimeInteractionTitle),
			slaFirstReplyStatus: formatMessage(messagesIntl.slaFirstReplyTimeInteractionTitle),
			slaServiceStatus: formatMessage(messagesIntl.slaServiceTimeInteractionTitle),
			slaMessageStatus: formatMessage(messagesIntl.slaMessageTimeInteractionTitle)
		};
		const notificationContent = {
			slaQueuedStatus: formatMessage(messagesIntl.slaQueuedTimeInteraction, { protocol: interactionInfo.id }),
			slaFirstReplyStatus: formatMessage(messagesIntl.slaFirstReplyTimeInteraction, { protocol: interactionInfo.id }),
			slaServiceStatus: formatMessage(messagesIntl.slaServiceTimeInteraction, { protocol: interactionInfo.id }),
			slaMessageStatus: formatMessage(messagesIntl.slaMessageTimeInteraction, { protocol: interactionInfo.id })
		};

		const slaStatusCompose = [
			{ name: 'slaQueuedStatus', status: slaQueuedStatus.status, notification: slaQueuedStatus.notification },
			{ name: 'slaFirstReplyStatus', status: slaFirstReplyStatus.status, notification: slaFirstReplyStatus.notification },
			{ name: 'slaServiceStatus', status: slaServiceStatus.status, notification: slaServiceStatus.notification },
			{ name: 'slaMessageStatus', status: slaMessageStatus.status, notification: slaMessageStatus.notification }
		];

		const currentSla = slaStatusCompose.find(slaStatus => slaStatus.name === sla.name);

		const { blockSlaNotification } = agent;

		if (!blockSlaNotification && data.status === 'expired' && !currentSla.notification && data.notification) {
			addNotification({
				id: `${messageIndex}-${interactionInfo.id}`,
				title: title[currentSla.name],
				content: notificationContent[currentSla.name],
				type: 'warning'
			});
		}

		if (data.status !== currentSla.status) {
			sla.setStatus(data);
		}
	};

	const handleAnimation = (endedAt, currentStatus) => {
		if (endedAt) return '';

		return currentStatus === 'almostExpired' ? 'ClientMessage__sla-wrapper--animation' : '';
	};

	const handleCloseModal = () => {
		setBlockModal(false);
	};

	useEffect(() => {
		const currentClasses = [''];
		if (selected) currentClasses.push('ClientMessage--selected');
		if (slas.length && getMainSla()) currentClasses.push('ClientMessage--has-sla');
		setClasses(currentClasses.join(' '));
	}, [selected, slas, getMainSla]);

	useEffect(() => {
		setLastMessageTime(timeInfoFormat5(createdAt));
		const interval = setInterval(() => {
			setLastMessageTime(timeInfoFormat5(createdAt));
		}, 1000);
		return () => clearInterval(interval);
	}, [createdAt]);

	const boldText = !!pendingMessages || currentState === 'PENDING';

	return (
		<Wrapper
			className={`ClientMessage ${classes}`}
			action={() => onSelect(interactionInfo)}
			data-testid={`interaction-${interactionHash}`}
		>
			<div className="ClientMessage__wrapper">
				<div className="ClientMessage__message" data-testid="component-client-message">
					<div className="ClientMessage__info__source">
						<ChannelImage
							name={interactionType || lastMessage.media}
							isPartner={!defaultIdentity}
							suffix="gray"
							data-testid={`component-client-message-interaction-image-${lastMessage.media}`}
						/>
						<Text size="big">{interactionTypeLabel(interactionType || lastMessage.media)}</Text>
					</div>
					<div className="ClientMessage__info" data-testid="component-client-message-info">
						<Text bold={boldText} size="bigger" className="ClientMessage__customer" data-testid="component-client-message-info-name">{name || '...'}</Text>
					</div>
					{activeCall ? <VideoNotification /> : (
						<div className="ClientMessage__content" data-testid="component-client-message-content-div">
							{hasLastMessage && verifiedContent ? (
								<div className="ClientMessage__content__last-message">
									{boldText && (
										<CircleDisplay color="primary" className={`ClientMessage__content__last-message__count ${pendingMessages > 9 ? 'ClientMessage__content__last-message__count--big' : ''}`}>
											{pendingMessages || '!'}
										</CircleDisplay>
									)}
									{isAgentMessage && <ImgSvg name="reply" />}
									<Text bold={boldText} size="bigger" data-testid="component-client-message-content-last-message">{cleanBadWords('receivedText', verifiedContent)}</Text>
								</div>
							) : (
								<div className="ClientMessage__content__without-messages">
									<ImgSvg name="info-circle" />
									<Text size="big">
										{formatMessage(messagesIntl.emptyMessages)}
									</Text>
								</div>
							)}
							<div className={`ClientMessage__from ClientMessage__from--${parsedOrigin}`} data-testid="component-client-message-info-origin">
								<Text bold={boldText} size="bigger" data-testid="component-client-message-info-created-at">{createdAt && lastMessageTime}</Text>
							</div>
						</div>
					)}
				</div>
			</div>
			{!!slas.length && (
				<div className="ClientMessage__sla-wrapper">
					<div className="ClientMessage__sla-wrapper__content">
						<div className={handleAnimation(getMainSla().slaEndedAt, handleSlaStatus(getMainSla().slaName).status)}>
							<SlaTimer
								endedAt={getMainSla().slaEndedAt}
								startedAt={getMainSla().slaStartedAt}
								slaTime={getMainSla().slaTime}
								color={getMainSla().slaEndedAt && handleSlaStatus(getMainSla().slaName).status === 'almostExpired' ? colors.notExpired : colors[handleSlaStatus(getMainSla().slaName).status]}
							/>
						</div>
						<div className="ClientMessage__sla-wrapper__content__popover" style={messageIndex > 1 ? { bottom: 24 } : { top: 24 }}>
							<div className="ClientMessage__sla-wrapper__content__popover__title">
								{getSlaStatusTitle()}
							</div>
							<div className="ClientMessage__sla-wrapper__content__popover__warning">
								<ImgSvg name="info-full-circle" />
								<p>{formatMessage(messagesIntl.slaInfo)}</p>
							</div>
							<div className="ClientMessage__sla-wrapper__content__popover__list">
								{slas.map((sla, index) => (
									// eslint-disable-next-line react/no-array-index-key
									<div key={index} className="ClientMessage__sla-wrapper__content__popover__list__item">
										<span>
											{renderSlaIcon(handleSlaStatus(sla.slaName).status, sla.slaEndedAt)}
											{getSlaTranslation(sla.slaName)}
											:
										</span>
										<SlaTimer
											endedAt={sla.slaEndedAt}
											startedAt={sla.slaStartedAt}
											slaTime={sla.slaTime}
											color={sla.slaEndedAt && handleSlaStatus(sla.slaName).status === 'almostExpired' ? colors.notExpired : colors[handleSlaStatus(sla.slaName).status]}
											changeSlaStatus={changeStatus(handleSlaStatus(sla.slaName))}
										/>
									</div>
								))}
							</div>
						</div>
					</div>
					<div className={`ClientMessage__sla-wrapper__alert ${handleAnimation(getMainSla().slaEndedAt, handleSlaStatus(getMainSla().slaName).status)}`}>
						{renderSlaIcon(handleSlaStatus(getMainSla().slaName).status, getMainSla().slaEndedAt)}
						{renderSlaAlert(handleSlaStatus(getMainSla().slaName).status, getMainSla().slaEndedAt)}
					</div>
				</div>
			)}
			{(isRinging || isNotAnswer || isQueued || isMissed || (canReply && isPending)) && canAcceptQueue && !isFullAutomatic && (
				<Wrapper
					className={actionClasses}
					action={(e) => {
						verifyBlocked();
						e.stopPropagation();
						acceptOrReplyAction();
					}}
					style={{ height: '2.6rem', position: 'absolute', maxWidth: 100 }}
					data-testid="component-client-message-content-confirm-changes-button"
				>
					{formatMessage(messagesIntl.activate)}
					{' '}
					<ImgSvg name="confirm-changes" data-testid="component-client-message-confirm-changes-image" />
				</Wrapper>
			)}
			{(isEnded || isUnavailable) && (
				<Wrapper
					className={actionClasses}
					action={() => closeInteraction()}
					data-testid="btnClientMessageActionRed"
				>
					{formatMessage(messagesIntl.close)}
					{' '}
					<ImgSvg name="close" />
				</Wrapper>
			)}

			{showBlockModal && (
				<UnavailableModal onClose={handleCloseModal} tryToAnswer />
			)}
		</Wrapper>
	);
};

const mapStateToProps = state => ({
	defaultIdentity: state.interface.defaultIdentity,
	phoneData: state.agent.phoneData,
	registeredPhone: state.agent.registeredPhone,
	hasPhoneInteraction: state.interaction.interactions.some(({ interactionType, currentState }) => (
		interactionType.toLowerCase().includes('phone')
		&& !['ended', 'missed', 'replying'].includes(currentState.toLowerCase())
	)),
	tagRequired: state.agent.tagRequired,
	onlyChatOrPhone: state.interaction.onlyChatOrPhone,
	interactionsInCall: state.interface.showingCall,
	agent: state.agent,
	agentBlocked: state.agent.agentBlocked
});

const mapActionsToProps = dispatch => ({
	onAcceptInteraction: interactionHash => dispatch(actions.acceptInteraction(interactionHash)),
	replyInteraction: interactionHash => dispatch(actions.replyInteraction(interactionHash)),
	onRemoveInteraction: info => dispatch(actions.removeInteraction(info)),
	onShowTag: interactionHash => dispatch(actions.showTag(interactionHash)),
	addNotification: notification => dispatch(actions.addNotification(notification)),
	defineSelectedInteraction: interactionHash => (
		dispatch(actions.defineSelectedInteraction(interactionHash))
	)
});

ClientMessage.propTypes = {
	interactionInfo: PropTypes.shape({
		id: PropTypes.number,
		name: PropTypes.string,
		department: PropTypes.shape({
			name: PropTypes.string
		}),
		agent: PropTypes.shape({
			id: PropTypes.number
		}),
		image: PropTypes.string,
		interaction: PropTypes.number,
		messages: PropTypes.arrayOf(
			PropTypes.shape({
				text: PropTypes.string,
				time: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
				type: PropTypes.string,
				user: PropTypes.string
			})
		),
		newMessagesQuantity: PropTypes.number,
		customerInfo: PropTypes.shape({
			fields: PropTypes.arrayOf(PropTypes.shape({}))
		}),
		interactionHash: PropTypes.string,
		currentState: PropTypes.string,
		pendingMessages: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		interactionTags: PropTypes.arrayOf(PropTypes.shape({})),
		interactionType: PropTypes.string,
		media: PropTypes.string,
		slas: PropTypes.arrayOf(
			PropTypes.shape({
				slaStartedAt: PropTypes.string,
				slaEndedAt: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
				slaName: PropTypes.string,
				slaTime: PropTypes.number
			})
		).isRequired
	}).isRequired,
	onSelect: PropTypes.func.isRequired,
	selected: PropTypes.bool.isRequired,
	onAcceptInteraction: PropTypes.func.isRequired,
	onRemoveInteraction: PropTypes.func.isRequired,
	intl: PropTypes.shape({
		formatMessage: PropTypes.func.isRequired
	}).isRequired,
	defaultIdentity: PropTypes.bool,
	registeredPhone: PropTypes.bool.isRequired,
	phoneData: PropTypes.shape({
		name: PropTypes.string,
		station: PropTypes.number,
		token: PropTypes.string
	}).isRequired,
	hasPhoneInteraction: PropTypes.bool.isRequired,
	onShowTag: PropTypes.func.isRequired,
	tagRequired: PropTypes.bool.isRequired,
	onlyChatOrPhone: PropTypes.bool.isRequired,
	interactionsInCall: PropTypes.arrayOf(PropTypes.string).isRequired,
	replyInteraction: PropTypes.func.isRequired,
	addNotification: PropTypes.func.isRequired,
	defineSelectedInteraction: PropTypes.func.isRequired,
	agent: PropTypes.shape({
		isFullAutomatic: PropTypes.bool.isRequired,
		info: PropTypes.shape({
			id: PropTypes.number
		}),
		blockSlaNotification: PropTypes.bool
	}).isRequired,
	messageIndex: PropTypes.number.isRequired,
	agentBlocked: PropTypes.shape({
		blocked: PropTypes.bool,
		blockModalViewed: PropTypes.bool
	})
};

export default connect(mapStateToProps, mapActionsToProps)(injectIntl(ClientMessage));
