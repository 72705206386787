/* eslint-disable react/forbid-prop-types */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './index.sass';

export default function DataTable({
	dataSource = [],
	columns = [],
	columnDecorators = [],
	onChange = () => {}
}) {
	const [sortTarget, setSortTarget] = useState({});
	const [sortDirection, setSortDirection] = useState('ascend');

	const mergedColumns = columns.reduce((result, column) => {
		const sorter = columnDecorators.find(c => c.key === column.key);
		result.push({
			...column,
			...sorter
		});
		return result;
	}, []);
	const sortedDataSource = dataSource.sort((a, b) => {
		const { key, sorter } = sortTarget;
		if (sorter) {
			if (sortDirection === 'ascend') {
				return sorter(a[key], b[key]);
			}
			return sorter(b[key], a[key]);
		}
		return 0;
	});

	return (
		<table className="table">
			<thead className="has-background-grey-lighter">
				<tr>
					{mergedColumns.map(column => (
						<th
							style={{ minWidth: column.width }}
							key={`column${column.key}`}
							data-testid={`column${column.key}`}
							className=""
							onClick={() => {
								if (column.sorter) {
									const direction = sortDirection === 'ascend' ? 'descend' : 'ascend';
									setSortTarget(column);
									setSortDirection(direction);
									onChange({ field: column.key, order: direction });
								}
							}}
						>
							<div className="cell">
								{column.value}
								<span>
									{sortTarget.key === column.key
                      && sortDirection === 'ascend' && <div>▴</div>}
									{sortTarget.key === column.key
                      && sortDirection === 'descend' && <div>▾</div>}
								</span>
							</div>
						</th>
					))}
				</tr>
			</thead>
			<tbody>
				{sortedDataSource.map((val, key) => (
					<tr
						key={`row${key}`}
						data-testid={`row${key}`}
						className="row"
					>
						{mergedColumns.map(column => (
							<td
								key={`row${key}cell${column.key}`}
								data-testid={`row${key}cell${column.key}`}
								className="cell"
							>
								{column.wrapper
									? column.wrapper(val[column.key])
									: val[column.key]}
							</td>
						))}
					</tr>
				))}
			</tbody>
		</table>
	);
}

DataTable.propTypes = {
	dataSource: PropTypes.arrayOf(PropTypes.object),
	columns: PropTypes.arrayOf(
		PropTypes.shape({ key: PropTypes.string, value: PropTypes.string })
	),
	columnDecorators: PropTypes.arrayOf(
		PropTypes.shape({
			key: PropTypes.string,
			sorter: PropTypes.func,
			wrapper: PropTypes.func
		})
	),
	onChange: PropTypes.func
};
