import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import './index.sass';
import Wrapper from '../../Atoms/Wrapper';

const Backdrop = ({
	className = '',
	children,
	closeAction,
	higher,
	hasBlur,
	clear,
	style = {}
}) => {
	const [classes, setClasses] = useState([]);
	useEffect(() => {
		const currentClasses = ['Backdrop'];

		if (className) currentClasses.push(className);
		if (higher) currentClasses.push('Backdrop--higher');
		if (clear) currentClasses.push('Backdrop--clear');
		if (hasBlur) currentClasses.push('Backdrop--blur');

		setClasses(currentClasses.join(' '));
	}, [className, closeAction, children, higher, clear, hasBlur]);

	return (
		<div className={classes} style={style}>
			{closeAction && <Wrapper className="Backdrop__closeAction" action={closeAction} />}
			{children}
		</div>
	);
};

Backdrop.propTypes = {
	className: PropTypes.string,
	closeAction: PropTypes.func,
	higher: PropTypes.bool,
	clear: PropTypes.bool,
	style: PropTypes.shape({
		zIndex: PropTypes.number
	}),
	hasBlur: PropTypes.bool
};

export default Backdrop;
