export default [
	'aidético',
	'aidética',
	'aleijado',
	'aleijada',
	'anus',
	'anão',
	'anã',
	'arrombado',
	'apenado',
	'apenada',
	'baba-ovo',
	'babaca',
	'babaovo',
	'bacura',
	'bagos',
	'baianada',
	'baitola',
	'besta',
	'bicha',
	'bisca',
	'bixa',
	'boceta',
	'boiola',
	'bokete',
	'bolcat',
	'boquete',
	'bosseta',
	'bosta',
	'boçal',
	'branquelo',
	'brioco',
	'bronha',
	'buceta',
	'bugre',
	'bunda',
	'bunduda',
	'burra',
	'burro',
	'busseta',
	'bárbaro',
	'caceta',
	'cacete',
	'cadela',
	'cagado',
	'cagao',
	'cagão',
	'cagona',
	'caipira',
	'canalha',
	'canceroso',
	'caralho',
	'casseta',
	'cassete',
	'ceguinho',
	'checheca',
	'chereca',
	'chifruda',
	'chifrudo',
	'chochota',
	'chota',
	'chupada',
	'chupado',
	'ciganos',
	'clitoris',
	'clitóris',
	'cocaina',
	'cocaína',
	'corna',
	'cornagem',
	'cornisse',
	'corno',
	'cornuda',
	'cornudo',
	'cornão',
	'corrupta',
	'corrupto',
	'cretina',
	'cretino',
	'criolo',
	'crioulo',
	'cruz-credo',
	'cu',
	'cú',
	'culhao',
	'culhão',
	'curalho',
	'cuzao',
	'cuzão',
	'cuzuda',
	'cuzudo',
	'debil',
	'débil',
	'debiloide',
	'debilóide',
	'deficiente',
	'defunto',
	'demonio',
	'demônio',
	'denegrir',
	'denigrir',
	'detento',
	'difunto',
	'doida',
	'doido',
	'egua',
	'égua',
	'esclerosado',
	'escrota',
	'escroto',
	'esporrada',
	'esporrado',
	'esporro',
	'estupida',
	'estúpida',
	'estupidez',
	'estupido',
	'estúpido',
	'facista',
	'fanatico',
	'fanático',
	'fedida',
	'fedido',
	'fedor',
	'fedorenta',
	'feia',
	'feio',
	'feiosa',
	'feioso',
	'feioza',
	'feiozo',
	'felacao',
	'felação',
	'fenda',
	'foda',
	'fodao',
	'fodão',
	'fode',
	'fodi',
	'fodida',
	'fodido',
	'fornica',
	'fornição',
	'fudendo',
	'fudeção',
	'fudida',
	'fudido',
	'furnica',
	'furnicar',
	'gai',
	'gaiata',
	'gaiato',
	'gay',
	'goianada',
	'gonorrea',
	'gonorreia',
	'gonorréia',
	'gosmenta',
	'gosmento',
	'grelinho',
	'grelo',
	'gringo',
	'homo-sexual',
	'homosexual',
	'homosexualismo',
	'homossexual',
	'homossexualismo',
	'idiota',
	'idiotice',
	'imbecil',
	'inculto',
	'iscrota',
	'iscroto',
	'ladra',
	'ladrao',
	'ladroeira',
	'ladrona',
	'ladrão',
	'lalau',
	'lazarento',
	'leprosa',
	'leproso',
	'lesbica',
	'lésbica',
	'louco',
	'macaca',
	'macaco',
	'machona',
	'macumbeiro',
	'malandro',
	'maluco',
	'maneta',
	'marginal',
	'masturba',
	'meleca',
	'meliante',
	'merda',
	'mija',
	'mijada',
	'mijado',
	'mijo',
	'minorias',
	'mocrea',
	'mocreia',
	'mocréia',
	'moleca',
	'moleque',
	'mondronga',
	'mondrongo',
	'mongol',
	'mongoloide',
	'mongolóide',
	'mulata',
	'mulato',
	'naba',
	'nadega',
	'nádega',
	'nazista',
	'nhaca',
	'nojeira',
	'nojenta',
	'nojento',
	'olhota',
	'otaria',
	'otario',
	'otária',
	'otário',
	'paca',
	'palhaco',
	'palhaço',
	'paspalha',
	'paspalhao',
	'paspalho',
	'pau',
	'peia',
	'peido',
	'pemba',
	'pentelha',
	'pentelho',
	'perereca',
	'perneta',
	'pica',
	'picao',
	'picão',
	'pilantra',
	'pinel',
	'pinto',
	'pintudo',
	'pintão',
	'piranha',
	'piroca',
	'piroco',
	'piru',
	'pivete',
	'porra',
	'prega',
	'prequito',
	'priquito',
	'prostibulo',
	'prostituta',
	'prostituto',
	'punheta',
	'punhetao',
	'punhetão',
	'puta',
	'puto',
	'puxa-saco',
	'puxasaco',
	'penis',
	'pênis',
	'rabao',
	'rabão',
	'rabo',
	'rabuda',
	'rabudao',
	'rabudão',
	'rabudo',
	'rabudona',
	'racha',
	'rachada',
	'rachadao',
	'rachadinha',
	'rachadinho',
	'ramela',
	'remela',
	'retardada',
	'retardado',
	'ridícula',
	'roceiro',
	'rola',
	'rolinha',
	'sacana',
	'safada',
	'safado',
	'sapatao',
	'sapatão',
	'sifilis',
	'sífilis',
	'siririca',
	'tarada',
	'tarado',
	'testuda',
	'tesuda',
	'tesudo',
	'tezao',
	'tezuda',
	'tezudo',
	'traveco',
	'trocha',
	'trolha',
	'troucha',
	'trouxa',
	'troxa',
	'vaca',
	'vadia',
	'vagal',
	'vagabunda',
	'vagabundo',
	'vagina',
	'veada',
	'veadao',
	'veado',
	'viada',
	'viadagem',
	'viadao',
	'viadão',
	'viado',
	'viadão',
	'víado',
	'xana',
	'xaninha',
	'xavasca',
	'xerereca',
	'xexeca',
	'xibiu',
	'xibumba',
	'xiíta',
	'xochota',
	'xota',
	'xoxota'
];
