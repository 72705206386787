import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, injectIntl } from 'react-intl';

import './index.sass';
import Bubble from '../../../Atoms/Bubble';
import ImgSvg from '../../../Atoms/ImgSvg';
import Text from '../../../Atoms/Text';

const messages = defineMessages({
	bannedImage: {
		id: 'messageBanned.image',
		defaultMessage: 'Não foi possível carregar essa imagem, ela foi bloqueada para você'
	},
	bannedVideo: {
		id: 'messageBanned.video',
		defaultMessage: 'Não foi possível carregar esse vídeo, ele foi bloqueado para você'
	},
	bannedAudio: {
		id: 'messageBanned.audio',
		defaultMessage: 'Não foi possível carregar esse áudio, ele foi bloqueado para você'
	},
	bannedDocument: {
		id: 'messageBanned.document',
		defaultMessage: 'Não foi possível carregar esse documento, ele foi bloqueado para você'
	}
});

const types = {
	receivedImage: {
		icon: 'image-slash',
		text: messages.bannedImage
	},
	receivedVideo: {
		icon: 'video-slash',
		text: messages.bannedVideo
	},
	receivedAudio: {
		icon: 'audio-slash',
		text: messages.bannedAudio
	},
	receivedDocument: {
		icon: 'document-slash',
		text: messages.bannedDocument
	}
};

const MessageBanned = ({ type, messageInfo, intl }) => {
	const { formatMessage } = intl;

	return (
		<Bubble timeOnBottom className="MessageBanned" messageInfo={messageInfo}>
			<div className="is-flex is-justify-content-space-beetwen is-align-items-center ml-1">
				<ImgSvg name={types[type].icon} color={origin === 'CLIENT' ? 'white' : 'default'} />
				<Text className="ml-2">{formatMessage(types[type].text)}</Text>
			</div>
		</Bubble>
	);
};

MessageBanned.propTypes = {
	type: PropTypes.string,
	messageInfo: PropTypes.shape({
		isFromAgent: PropTypes.bool,
		content: PropTypes.string,
		createdAt: PropTypes.string,
		status: PropTypes.string,
		url: PropTypes.string,
		agentName: PropTypes.string,
		agentPhoto: PropTypes.string,
		interactionType: PropTypes.string,
		contentType: PropTypes.string,
		messageId: PropTypes.number
	}).isRequired,
	intl: PropTypes.shape({
		formatMessage: PropTypes.func.isRequired
	}).isRequired
};

export default injectIntl(MessageBanned);
