import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, injectIntl } from 'react-intl';

import IconButton from '../Buttons/IconButton';
import RadioButton from '../../Atoms/RadioButton';
import './index.sass';

const locales = ['pt', 'en', 'es'];

const currentLocale = window.localStorage.getItem('locale') || 'pt';

const messages = defineMessages({
	pt: {
		id: 'languageChooser.pt',
		defaultMessage: 'Português Brasileiro'
	},
	en: {
		id: 'languageChooser.en',
		defaultMessage: 'Inglês'
	},
	es: {
		id: 'languageChooser.es',
		defaultMessage: 'Espanhol'
	},
	languageChooserTitle: {
		id: 'languageChooser.title',
		defaultMessage: 'Alterar idioma'
	}
});

const LanguageChooser = ({
	onChange = () => {},
	onlyFlags = true,
	intl
}) => (
	<div className="LanguageChooser has-text-white">
		<span>{intl.formatMessage(messages.languageChooserTitle)}</span>
		<div className={`LanguageChooser__locales ${onlyFlags ? 'onlyFlags' : ''}`}>
			{locales.map(locale => (
				onlyFlags ? (
					<IconButton
						name={locale}
						click={() => onChange(locale)}
						fill="white"
						key={locale}
						data-testid={`region-${locale}`}
						ariaLabel={intl.formatMessage(messages[locale])}
					/>
				) : (
					// eslint-disable-next-line jsx-a11y/label-has-for
					<label htmlFor={locale} key={locale}>
						<RadioButton checked={currentLocale === locale} id={locale} onChange={e => e.target.name !== currentLocale && onChange(e.target.name)} />
						{intl.formatMessage(messages[locale])}
					</label>
				)
			))}
		</div>
	</div>
);

LanguageChooser.propTypes = {
	onChange: PropTypes.func,
	onlyFlags: PropTypes.bool,
	intl: PropTypes.shape({
		formatMessage: PropTypes.func.isRequired
	}).isRequired
};

export default injectIntl(LanguageChooser);
