import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { defineMessages, injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import './index.sass';
import * as actions from '../../../store/actions';
import Text from '../../Atoms/Text';
import Spinner from '../../Atoms/Spinner';

const messages = defineMessages({
	saved: {
		id: 'actionsHeader.saved',
		defaultMessage: 'Salvo'
	},
	savedContent: {
		id: 'actionsHeader.savedContent',
		defaultMessage: 'Alterações salvas'
	},
	notSaved: {
		id: 'actionsHeader.notSaved',
		defaultMessage: 'Não salvo'
	},
	notSavedContent: {
		id: 'actionsHeader.notSavedContent',
		defaultMessage: 'Alterações não salvas, tente novamente'
	}
});

const ActionsHeader = ({
	title,
	loading,
	success,
	error,
	intl,
	addNotification,
	clearAction = () => {},
	clear = () => {}
}) => {
	const { formatMessage } = intl;

	useEffect(() => {
		if (success || error) {
			const messagePrefix = success ? 'saved' : 'notSaved';
			addNotification({
				title: formatMessage(messages[messagePrefix]),
				content: formatMessage(messages[`${messagePrefix}Content`]),
				type: 'success'
			});
			clearAction();
		}
	}, [success, error, addNotification, clearAction, formatMessage]);

	return (
		<div className={`ActionHeader ${clear ? 'ActionHeader--clear' : ''}`}>
			<Text size="large">{title}</Text>
			{loading && <Spinner />}
		</div>
	);
};

ActionsHeader.propTypes = {
	title: PropTypes.string.isRequired,
	loading: PropTypes.bool,
	success: PropTypes.bool,
	error: PropTypes.bool,
	intl: PropTypes.shape({
		formatMessage: PropTypes.func.isRequired
	}).isRequired,
	addNotification: PropTypes.func.isRequired,
	clearAction: PropTypes.func,
	clear: PropTypes.bool
};

const mapActionToProps = dispatch => ({
	addNotification: notification => dispatch(actions.addNotification(notification))
});

export default connect(null, mapActionToProps)(injectIntl(ActionsHeader));
