import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl, defineMessages } from 'react-intl';

import './index.sass';

import * as actions from '../../../store/actions';
import { usePrevious } from '../../../shared/utility';
import ClientInfo from '../ClientInfo';
import History from '../History';
import NluBot from '../NluBot';
import ToggleNav from '../../Molecules/ToggleNavBar';
import TaskForm from '../TaskForm';
import Wrapper from '../../Atoms/Wrapper';
import ImgSvg from '../../Atoms/ImgSvg';

const messages = defineMessages({
	client: {
		id: 'clientBox.client',
		defaultMessage: 'Cliente'
	},
	history: {
		id: 'clientBox.history',
		defaultMessage: 'Histórico'
	},
	tasks: {
		id: 'clientBox.tasks',
		defaultMessage: 'Tarefas'
	}
});

const ClientBox = ({
	currentInteraction,
	loadedInteractionInfo,
	currentInteractionHash,
	onSetCustomerSection,
	blockedToInteract,
	customerInfo = {},
	customerSection,
	intl,
	defineTaskSidebar,
	isHistoryInteraction,
	agent,
	widgetAndIntegrations
}) => {
	const [interactionHasTask, setInteractionHasTask] = useState(false);
	const [retracted, setRetracted] = useState(false);
	const [setInteractionHasTaskToFalse, setSetInteractionHasTaskToFalse] = useState(false);
	const prevInteractionHash = usePrevious(currentInteractionHash);
	const nluWidget = widgetAndIntegrations.filter(widget => widget.active);
	const { showTasks, taskExtras, info } = agent;

	useEffect(() => {
		const changedInteraction = prevInteractionHash !== currentInteractionHash;
		const hasTask = currentInteraction && currentInteraction.tasks
			&& currentInteraction.tasks.length > 0;

		if (changedInteraction && !hasTask && !setInteractionHasTaskToFalse) {
			setInteractionHasTask(false);
			setSetInteractionHasTaskToFalse(true);
		} else if (taskExtras && hasTask && !interactionHasTask && loadedInteractionInfo) {
			setInteractionHasTask(true);
			setSetInteractionHasTaskToFalse(false);
		}
	}, [
		currentInteraction,
		currentInteractionHash,
		interactionHasTask,
		loadedInteractionInfo,
		prevInteractionHash,
		setInteractionHasTaskToFalse,
		taskExtras
	]);

	const onToggleSection = useCallback((section) => {
		onSetCustomerSection({ hash: currentInteractionHash, section });
	}, [currentInteractionHash, onSetCustomerSection]);

	const toggleRetract = () => {
		setRetracted(false);
	};

	const { formatMessage } = intl;
	const section = customerSection.section || 'client';
	const { historyCount } = customerInfo;
	const toggleNavItems = [
		{
			title: 'info-full-circle',
			label: formatMessage(messages.client),
			active: section === 'client',
			click: () => {
				toggleRetract();
				onToggleSection('client');
			},
			key: 'info'
		}
	];

	if (!blockedToInteract) {
		toggleNavItems.push({
			title: 'history',
			label: formatMessage(messages.history),
			active: section === 'history',
			click: () => {
				toggleRetract();
				onToggleSection('history');
			},
			info: historyCount,
			key: 'history'
		});
	}

	if ((!blockedToInteract && !isHistoryInteraction && showTasks && !taskExtras) || interactionHasTask) {
		toggleNavItems.push({
			title: 'task',
			label: formatMessage(messages.tasks),
			active: section === 'tasks',
			click: () => {
				toggleRetract();
				onToggleSection('tasks');
				defineTaskSidebar('new');
			},
			key: 'tasks'
		});
	}

	if (nluWidget && nluWidget.length > 0) {
		nluWidget.map((widget, index) => {
			toggleNavItems.push({
				title: 'nlu',
				label: widget.name,
				active: section === widget.name,
				click: () => {
					toggleRetract();
					onToggleSection(widget.name);
				},
				tooltip: `NLU - ${widget.name}`,
				icon: widget.icon && widget.icon.imageBase ? widget.icon.imageBase : null,
				key: `nlu-${index}`
			});
			return null;
		});
	}

	return (
		<div className="ClientBoxWrapper">
			<Wrapper action={() => setRetracted(!retracted)} className={`ClientBoxWrapper__button ${retracted ? '' : 'ClientBoxWrapper__button--oppened'}`}>
				<ImgSvg color="#FFF" name="arrow" />
			</Wrapper>
			<div className={`ClientBox ${retracted ? 'retracted' : ''}`}>
				<div className="ClientBox__navigation">
					<ToggleNav infos={toggleNavItems} vertical={retracted} />
				</div>
				<div className="ClientBox__content">
					{section === 'client' && <ClientInfo blockedToInteract={blockedToInteract} customerInfo={customerInfo} />}
					{section === 'history' && <History />}
					{section === 'tasks' && !isHistoryInteraction && showTasks && <TaskForm inInteraction />}
					{nluWidget.map((widget, index) => (
						section === widget.name && (
							<NluBot
								script={widget.script}
								agentId={info.id}
								protocol={currentInteraction.id}
								key={`nlu-${index}`}
							/>
						)
					))}
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => {
	const { agent, interaction, widgetAndIntegrations } = state;
	const {
		currentInteractionHash,
		historyInteractions,
		interactions,
		missedInteractions,
		pendingInteractions
	} = interaction;
	const allInteractions = interactions.concat(pendingInteractions).concat(historyInteractions).concat(missedInteractions);

	return ({
		agent,
		currentInteractionHash,
		currentInteraction: allInteractions.find(({ interactionHash }) => interactionHash === currentInteractionHash) || {},
		isHistoryInteraction: historyInteractions.some(({ interactionHash }) => interactionHash === currentInteractionHash),
		customerSection: state.interface.customerSection.find(({ hash }) => hash === currentInteractionHash) || { value: true },
		loadedInteractionInfo: state.interaction.fetchInteractionInfoStatus.some(({ hash }) => hash === currentInteractionHash),
		widgetAndIntegrations: widgetAndIntegrations.list
	});
};

const mapActionsToProps = dispatch => ({
	onSetCustomerSection: info => dispatch(actions.setCustomerSection(info)),
	defineTaskSidebar: type => dispatch(actions.defineTaskSidebar(type))
});

ClientBox.propTypes = {
	customerInfo: PropTypes.shape({
		historyCount: PropTypes.number
	}),
	currentInteractionHash: PropTypes.string.isRequired,
	onSetCustomerSection: PropTypes.func.isRequired,
	customerSection: PropTypes.shape({
		section: PropTypes.string
	}).isRequired,
	intl: PropTypes.shape({
		formatMessage: PropTypes.func.isRequired
	}).isRequired,
	defineTaskSidebar: PropTypes.func.isRequired,
	isHistoryInteraction: PropTypes.bool.isRequired,
	blockedToInteract: PropTypes.bool.isRequired,
	agent: PropTypes.shape({
		showTasks: PropTypes.bool.isRequired,
		taskExtras: PropTypes.bool.isRequired,
		info: PropTypes.shape({
			id: PropTypes.number
		})
	}).isRequired,
	currentInteraction: PropTypes.shape({
		id: PropTypes.number,
		tasks: PropTypes.arrayOf(PropTypes.shape({
			value: PropTypes.string
		})),
		interactionHash: PropTypes.string
	}).isRequired,
	loadedInteractionInfo: PropTypes.bool.isRequired,
	widgetAndIntegrations: PropTypes.arrayOf(PropTypes.shape({
		value: PropTypes.string
	})).isRequired
};

export default connect(mapStateToProps, mapActionsToProps)(injectIntl(ClientBox));
