import React from 'react';
import PropTypes from 'prop-types';

import './index.sass';

const Card = ({ children, className, isDark }) => (
	<div className={isDark ? `Card-isDark ${className}` : `Card ${className}`}>
		{children}
	</div>
);

Card.propTypes = {
	isDark: PropTypes.bool
};

export default Card;
