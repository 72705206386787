import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { defineMessages, injectIntl } from 'react-intl';

import ModalDefault from '../../Sirius/ModalDefault';

import './index.sass';
import * as actions from '../../../store/actions';
import Button from '../../Molecules/Buttons/Button';


const messages = defineMessages({
	exceededTitle: {
		id: 'breakStatus.breakTime.ExceededModal.title',
		defaultMessage: 'Atenção'
	},
	exceededLabel: {
		id: 'breakStatus.breakTime.ExceededModal.tlabel',
		defaultMessage: 'Você excedeu o tempo de pausa. Por favor, retorne para os atendimentos.'
	},
	exceededButton: {
		id: 'breakStatus.breakTime.ExceededModal.button',
		defaultMessage: 'Entendi'
	}
});

const ExceededTimeModal = ({
	intl
}) => {
	const { formatMessage } = intl;
	const [isVisible, setIsVisible] = useState(true);

	const handleClose = () => {
		setIsVisible(false);
	};

	if (!isVisible) return null;

	return (
		<ModalDefault.Root
			isOpen
			onClose={handleClose}
		>
			<div className="ExceededTimeModal">
				<ModalDefault.Header
					title={formatMessage(messages.exceededTitle)}
					onClose={handleClose}
					className="has-background-grey-lighter"
				/>
				<ModalDefault.Body className="has-background-grey-lighter is-overflow-hidden ExceededTimeModal__label">
					<p>
						{formatMessage(messages.exceededLabel)}
					</p>
				</ModalDefault.Body>

				<ModalDefault.Footer className="has-background-grey-lighter">
					<div className="is-flex">
						<Button className="button is-primary is-large" click={handleClose}>{formatMessage(messages.exceededButton)}</Button>
					</div>
				</ModalDefault.Footer>

			</div>
		</ModalDefault.Root>
	);
};

const mapActionsToProps = dispatch => ({
	addNotification: notification => dispatch(actions.addNotification(notification))
});

ExceededTimeModal.propTypes = {
	intl: PropTypes.shape({
		formatMessage: PropTypes.func.isRequired
	}).isRequired
};

export default connect(mapActionsToProps)(injectIntl(ExceededTimeModal));
