import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, defineMessages } from 'react-intl';

import './index.sass';
import { parsePhoneNumber } from '../../../shared/utility';
import ImgSvg from '../../Atoms/ImgSvg';
import Dial from '../../Molecules/Dial';
import Text from '../../Atoms/Text';
import Button from '../../Molecules/Buttons/Button';
import Spinner from '../../Atoms/Spinner';
import Wrapper from '../../Atoms/Wrapper';

const messages = defineMessages({
	conferenceInfo: {
		id: 'callControl.conferenceInfo',
		defaultMessage: 'Digite acima o número que será adicionado a conferência'
	},
	addToConference: {
		id: 'callControl.addToConference',
		defaultMessage: 'Adicionar'
	}
});

class CallControl extends Component {
	state = {
		conferenceValue: '',
		calling: false,
		phoneCode: '55'
	}

	constructor() {
		super();
		this.inputPhone = createRef();
	}

	componentDidMount() {
		const storedPhoneCode = localStorage.getItem('phoneCode');

		this.setState({ phoneCode: storedPhoneCode || '55' });
		this.inputPhone.current.focus();
	}

	onChangeValue = (e) => {
		const { onChangeInputPhone, showConference } = this.props;
		const { value } = e.target;

		if (showConference) {
			this.setState({ conferenceValue: parsePhoneNumber(value) });
		} else {
			onChangeInputPhone(parsePhoneNumber(value));
		}
	};

	onPressValue = (e) => {
		const { showConference } = this.props;

		if (!showConference && (window.ttvSdk.currentInteraction || window.ttvSdk.conferenceId)) {
			window.ttvSdk.sendDtmf(parsePhoneNumber(e.key));
		}
	}

	deleteValue = () => {
		const { conferenceValue } = this.state;
		const { onChangeInputPhone, inputPhone, showConference } = this.props;

		if (showConference) {
			this.setState({ conferenceValue: conferenceValue.slice(0, -1) });
		} else {
			onChangeInputPhone(inputPhone.slice(0, -1));
		}
	}

	onClickValue = (clickedValue) => {
		const { conferenceValue } = this.state;
		const { inputPhone, onChangeInputPhone, showConference } = this.props;

		if (showConference) {
			this.setState({ conferenceValue: conferenceValue + clickedValue });
		} else {
			onChangeInputPhone(inputPhone + clickedValue[0]);
			if (window.ttvSdk.currentInteraction || window.ttvSdk.conferenceId) {
				window.ttvSdk.sendDtmf(parsePhoneNumber(clickedValue[0]));
			}
		}
	}

	onConfirmCall = () => {
		const { phoneData, inputPhone } = this.props;

		if (phoneData.name === 'totalVoice' && inputPhone !== '') {
			window.ttvSdk.callingNumber = inputPhone;
			window.ttvSdk.callTo(inputPhone);
		} else {
			window.Inov8Sdk.makeCall(inputPhone);
		}

		this.callingEvent();
	}

	onConfirmConferenceCall = () => {
		const { phoneData, updateConferenceUsers, inputPhone } = this.props;

		if (phoneData.name === 'totalVoice' && inputPhone !== '') {
			updateConferenceUsers({
				name: '',
				number: inputPhone
			});
			window.ttvSdk.callConf(phoneData.station, inputPhone);
		} else {
			window.Inov8Sdk.makeCall(inputPhone);
		}

		this.callingEvent();
	}

	onFinishCall = () => {
		const { clearConferenceUsers, onChangeInputPhone } = this.props;

		clearConferenceUsers();
		if (window.ttvSdk.connected) {
			onChangeInputPhone('');
			window.ttvSdk.hangup();
		}
	}

	onAddNumberToConference = () => {
		const { updateConferenceUsers } = this.props;
		const { conferenceValue } = this.state;

		if (conferenceValue !== '') {
			updateConferenceUsers({
				name: '',
				number: conferenceValue
			});
			window.ttvSdk.addNumber(conferenceValue);
			this.setState({ conferenceValue: '' });
		}
	}

	callingEvent = () => {
		this.setState({ calling: true });
		setTimeout(() => this.setState({ calling: false }), 5000);
	}

	changePhoneCode = (e) => {
		const parsedValue = e.target.value.replace(/([^0-9])/g, '');
		this.setState({ phoneCode: parsedValue });
		localStorage.setItem('phoneCode', parsedValue);
	}

	render() {
		const {
			conferenceValue,
			calling,
			phoneCode
		} = this.state;
		const { inputPhone, showConference } = this.props;
		const { phoneData = {}, intl } = this.props;
		const { status = '' } = phoneData;
		const { formatMessage } = intl;
		const canCall = inputPhone.length > 0 && phoneCode.length > 0;

		return (
			<div className="CallControl">
				<div className="CallControl__dial">
					<div className="CallControl__dial__input">
						<input
							value={showConference ? conferenceValue : inputPhone}
							onChange={this.onChangeValue}
							onKeyPress={this.onPressValue}
							ref={this.inputPhone}
						/>
						<Wrapper
							className="CallControl__dial__delete"
							action={this.deleteValue}
						>
							<ImgSvg name="delete" />
						</Wrapper>
					</div>
					<Dial click={this.onClickValue} />
				</div>
				<div className="CallControl__actions">
					{!status || ['conectado', 'encerrada'].includes(status) ? (
						<>
							{calling ? <Spinner /> : (
								<>
									<Wrapper
										className={`CallControl__action CallControl__action__call ${canCall ? 'CallControl__action__call--active' : ''}`}
										action={this.onConfirmCall}
									>
										<ImgSvg name="phone" />
									</Wrapper>
									<Wrapper
										className={`CallControl__action CallControl__action__conference ${canCall ? 'CallControl__action__conference--active' : ''}`}
										action={this.onConfirmConferenceCall}
									>
										<ImgSvg name="conference" />
									</Wrapper>
								</>
							)}
						</>
					) : (
						<Wrapper
							className="CallControl__action CallControl__action__finish"
							action={this.onFinishCall}
						>
							<ImgSvg name="phone" />
						</Wrapper>
					)}
				</div>
				{showConference && (
					<>
						<Text>{formatMessage(messages.conferenceInfo)}</Text>
						<Button selected={conferenceValue.length > 0} click={this.onAddNumberToConference}>
							{formatMessage(messages.addToConference)}
						</Button>
					</>
				)}
			</div>
		);
	}
}

CallControl.propTypes = {
	phoneData: PropTypes.shape({
		name: PropTypes.string,
		station: PropTypes.number,
		status: PropTypes.string
	}).isRequired,
	updateConferenceUsers: PropTypes.func.isRequired,
	clearConferenceUsers: PropTypes.func.isRequired,
	intl: PropTypes.shape({
		formatMessage: PropTypes.func
	}).isRequired,
	onChangeInputPhone: PropTypes.func.isRequired,
	inputPhone: PropTypes.string.isRequired,
	showConference: PropTypes.bool.isRequired
};

export default injectIntl(CallControl);
