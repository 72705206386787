import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, defineMessages } from 'react-intl';
import { connect } from 'react-redux';
import { isEmpty } from '../../../shared/utility';

import './index.sass';
import * as actions from '../../../store/actions';
import TaskSidebarInfo from '../../Organism/TaskSidebarInfo';
import TaskForm from '../../Organism/TaskForm';
import ImgSvg from '../../Atoms/ImgSvg';
import Wrapper from '../../Atoms/Wrapper';
import Text from '../../Atoms/Text';

const messages = defineMessages({
	emptyTitle: {
		id: 'taskSidebar.emptyTitle',
		defaultMessage: 'Nenhuma tarefa selecionada'
	},
	emptySubTitle: {
		id: 'taskSidebar.emptySubTitle',
		defaultMessage: 'Selecione alguma tarefa da lista'
	}
});

const TaskSidebar = ({
	selectedTask,
	fetchAgentTask,
	taskSidebar,
	defineTaskSideBar,
	intl
}) => (
	<div className="TaskSidebar">
		{['new', 'edit'].includes(taskSidebar) && (
			<Wrapper className="TaskSidebar__close" action={defineTaskSideBar}>
				<ImgSvg name="close" />
			</Wrapper>
		)}
		{taskSidebar === 'default' && (
			<>
				{isEmpty(selectedTask) ? (
					<div className="TaskSidebar__empty">
						<div className="TaskSidebar__empty__image">
							<ImgSvg name="task" />
						</div>
						<h3>{intl.formatMessage(messages.emptyTitle)}</h3>
						<Text>{intl.formatMessage(messages.emptySubTitle)}</Text>
					</div>
				) : <TaskSidebarInfo selectedTask={selectedTask} fetchAgentTask={fetchAgentTask} />}
			</>
		)}
		{['new', 'edit'].includes(taskSidebar) && <TaskForm />}
	</div>
);

TaskSidebar.propTypes = {
	intl: PropTypes.shape({
		formatMessage: PropTypes.func.isRequired
	}).isRequired,
	selectedTask: PropTypes.shape().isRequired,
	fetchAgentTask: PropTypes.func.isRequired,
	taskSidebar: PropTypes.string.isRequired,
	defineTaskSideBar: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
	selectedTask: state.agent.selectedTask,
	taskSidebar: state.interface.taskSidebar
});

const mapActionsToProps = dispatch => ({
	fetchAgentTask: task => dispatch(actions.fetchAgentTask(task)),
	defineTaskSideBar: () => dispatch(actions.defineTaskSidebar('default'))
});

export default connect(mapStateToProps, mapActionsToProps)(injectIntl(TaskSidebar));
