import React from 'react';
import PropTypes from 'prop-types';

import './index.sass';
import { useMountEffect } from '../../../shared/utility';
import Backdrop from '../Backdrop';
import Card from '../Card';
import ImgSvg from '../../Atoms/ImgSvg';
import Wrapper from '../../Atoms/Wrapper';

const Modal = ({
	children,
	onClose,
	className = '',
	hasBlur,
	clear,
	isDark,
	size = 'md'
}) => {
	useMountEffect(() => {
		if (onClose) {
			document.addEventListener('keyup', (e) => {
				if (e.which === 27) onClose();
			});
		}
	});

	const closeIconColor = isDark ? '#fff' : '#000';

	return (
		<Backdrop closeAction={onClose} higher clear={clear} style={{ zIndex: 113 }} hasBlur={hasBlur}>
			<div className="Modal" data-size={size}>
				<Card className={className} isDark={isDark}>
					{children}
					{onClose && (
						<Wrapper className="Modal__close" action={onClose}>
							<ImgSvg name="close" color={closeIconColor} />
						</Wrapper>
					)}
				</Card>
			</div>
		</Backdrop>
	);
};

Modal.propTypes = {
	size: PropTypes.oneOf(['sm', 'md', 'bg', 'half-screen']),
	isDark: PropTypes.bool,
	onClose: PropTypes.func,
	children: PropTypes.element.isRequired,
	className: PropTypes.string,
	clear: PropTypes.bool,
	hasBlur: PropTypes.bool
};

export default Modal;
