import React from 'react';
import PropTypes from 'prop-types';

import './index.sass';
import Switch from '../Switch';
import Wrapper from '../../Atoms/Wrapper';

const DropDownItem = ({
	label,
	active,
	showStatus,
	click = () => {}
}) => (
	<Wrapper className="DropDownItem" action={showStatus ? () => {} : click} data-testid={`dropdown-item-${label}`}>
		<span className="has-text-white">{label}</span>
		{showStatus && <Switch active={active} click={click} data-testid={`dropdown-item-${label}-switch`} />}
	</Wrapper>
);

DropDownItem.propTypes = {
	label: PropTypes.string.isRequired,
	active: PropTypes.bool,
	showStatus: PropTypes.bool,
	click: PropTypes.func
};

export default DropDownItem;
