import React from 'react';
import PropTypes from 'prop-types';

function ModalDefaultHeader({ className = '', title, onClose }) {
	return (
		<header className={`${className} modal-card-head`}>
			<span className="modal-card-title">{title}</span>
			<button type="button" className="delete is-medium" onClick={() => onClose()} />
		</header>
	);
}

ModalDefaultHeader.propTypes = {
	title: PropTypes.string.isRequired,
	onClose: PropTypes.func.isRequired
};

export default ModalDefaultHeader;
