import React, { useState, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { defineMessages, injectIntl } from 'react-intl';

import './index.sass';
import * as actions from '../../../store/actions';
import AgentHistoryItem from '../../Molecules/AgentHistoryItem';
import CommonInput from '../../Molecules/Inputs/Common';
import InfiniteList from '../../Molecules/InfiniteList';
import Spinner from '../../Atoms/Spinner';

const messages = defineMessages({
	placeholder: {
		id: 'agentHistory.placeholder',
		defaultMessage: 'Buscar no histórico'
	},
	emptyHistory: {
		id: 'agentHistory.emptyHistory',
		defaultMessage: 'Sem conversas no histórico'
	}
});

const AgentHistory = ({
	fetchAgentHistory,
	fetchAgentHistoryStatus = {
		count: 1,
		loading: false
	},
	historyInteractions,
	defineSelectedInteraction,
	currentInteractionHash,
	searchingHistory,
	intl
}) => {
	const ref = useRef();
	const [searchValue, setSearchValue] = useState('');
	const { formatMessage } = intl;
	const { count, ended, loading } = fetchAgentHistoryStatus;

	const onSearch = useCallback((search) => {
		setTimeout(() => {
			if ((ref.current
					&& ref.current.input
					&& ref.current.input.current
					&& ref.current.input.current.value === search) || search === '') {
				fetchAgentHistory({ search });
			}
		}, 1200);
	}, [fetchAgentHistory]);

	const onChange = useCallback((e) => {
		const { value } = e.target;

		setSearchValue(value);
		onSearch(value);
	}, [onSearch]);

	const loadMoreAgentHistories = useCallback(() => {
		if (!ended && !loading) {
			fetchAgentHistory({ page: count, search: searchValue || '' });
		}
	}, [count, ended, searchValue, fetchAgentHistory, loading]);

	return (
		<div className="AgentHistory">
			<CommonInput
				placeholder={formatMessage(messages.placeholder)}
				onChange={onChange}
				value={searchValue}
				loading={searchingHistory}
				ref={ref}
			/>
			<div className="AgentHistory__list__wrapper">
				<InfiniteList customClass="AgentHistory__list" onBottom={loadMoreAgentHistories}>
					<div className="AgentHistory__interactions">
						{historyInteractions.length > 0 ? historyInteractions.map(interaction => (
							<AgentHistoryItem
								key={interaction.interactionHash}
								interaction={interaction}
								click={defineSelectedInteraction}
								currentInteractionHash={currentInteractionHash}
							/>
						)) : (
							<>{!searchingHistory && !loading && <p>{formatMessage(messages.emptyHistory)}</p>}</>
						)}
					</div>
					{loading && (<Spinner />)}
				</InfiniteList>
			</div>
		</div>
	);
};

const mapStateToProps = state => ({
	agent: state.agent,
	historyInteractions: state.interaction.historyInteractions,
	currentInteractionHash: state.interaction.currentInteractionHash,
	searchingHistory: state.interaction.searchingHistory,
	fetchAgentHistoryStatus: state.interaction.fetchAgentHistoryStatus
});

const mapActionsToProps = dispatch => ({
	fetchAgentHistory: requestInfo => dispatch(actions.fetchAgentHistory(requestInfo)),
	defineSelectedInteraction: interactionHash => dispatch(
		actions.defineSelectedInteraction(interactionHash)
	)
});

AgentHistory.propTypes = {
	historyInteractions: PropTypes.arrayOf((
		PropTypes.shape({})
	)).isRequired,
	fetchAgentHistory: PropTypes.func.isRequired,
	defineSelectedInteraction: PropTypes.func.isRequired,
	currentInteractionHash: PropTypes.string.isRequired,
	searchingHistory: PropTypes.bool,
	fetchAgentHistoryStatus: PropTypes.shape({
		count: PropTypes.number,
		loading: PropTypes.bool,
		ended: PropTypes.bool
	}),
	intl: PropTypes.shape({
		formatMessage: PropTypes.func.isRequired
	}).isRequired
};

export default connect(mapStateToProps, mapActionsToProps)(injectIntl(AgentHistory));
