import React from 'react';
import PropTypes from 'prop-types';
import './index.sass';
import { defineMessages, injectIntl } from 'react-intl';

import ImgSvg from '../../Atoms/ImgSvg';

const messages = defineMessages({
	killSession: {
		id: 'app.killSession',
		defaultMessage: 'Conta acessada em outro local'
	},
	killSessionContent: {
		id: 'app.killSessionContent',
		defaultMessage: 'Não são permitidos acessos simultâneos'
	}
});

const HasAnotherSession = ({ intl }) => {
	const { formatMessage } = intl;

	return (
		<div
			className="HasAnotherSession"
			data-testid="component-hasAnotherSession"
		>
			<div className="HasAnotherSession__icon">
				<ImgSvg name="times-circle" />
			</div>
			<div className="HasAnotherSession__message">
				<div className="HasAnotherSession__title">
					{formatMessage(messages.killSession)}
				</div>
				<div className="HasAnotherSession__content">
					{formatMessage(messages.killSessionContent)}
				</div>
			</div>
		</div>
	);
};

HasAnotherSession.propTypes = {
	intl: PropTypes.shape({
		formatMessage: PropTypes.func.isRequired
	}).isRequired
};

export default (injectIntl(HasAnotherSession));
