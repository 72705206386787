import * as actionTypes from '../actions/actionTypes';
import {
	updateObject,
	pushHashToArray,
	removeHashFromArray,
	removeHashFromObjectArray
} from '../../shared/utility';

const initialState = {
	interactionList: '',
	customerSection: [],
	editingCustomer: [],
	showingTransfer: [],
	showingAnswer: [],
	showingWhatsappTemplate: [],
	showingEmoji: [],
	showingAnnotation: [],
	showingTags: [],
	showingInternalMessages: false,
	showingReplyMessage: [],
	showingVoiceRecording: [],
	showingCreateTask: [],
	inputChat: [],
	inputAnnotation: [],
	inputTag: [],
	inputSearch: [],
	interactionTags: [],
	showingCall: [],
	mainBoxSection: 'default',
	hasPhone: false,
	template: 'default',
	taskSidebar: 'default',
	showReceptiveModal: false,
	inputPhone: '',
	showDial: false,
	showConference: false,
	showingAddCustomer: false,
	showingEditCustomer: {},
	taskSearch: '',
	notifications: [],
	showEditAgent: false,
	showBreakStatus: false,
	tokenExpired: false,
	defaultIdentity: false,
	identity: {
		login_color: '#fff',
		logo_url: ''
	}
};

const setCustomerSection = (state, action) => {
	const { hash, section } = action.payload;
	let existInteraction = false;
	const customerSection = state.customerSection.map((interaction) => {
		if (interaction.hash === hash) {
			existInteraction = true;
			return {
				...interaction,
				section
			};
		}

		return interaction;
	});

	if (!existInteraction) customerSection.push({ hash, section });

	return updateObject(state, { customerSection });
};

const setEditCustomer = (state, action) => {
	const { hash, value } = action.payload;
	let existInteraction = false;
	const editingCustomer = state.editingCustomer.map((interaction) => {
		if (interaction.hash === hash) {
			existInteraction = true;
			return {
				...interaction,
				value
			};
		}

		return interaction;
	});

	if (!existInteraction) editingCustomer.push({ hash, value });

	return updateObject(state, { editingCustomer });
};

const changeInputText = (state, action) => {
	const { value, hash } = action.payload;
	let existInput = false;
	const inputChat = state.inputChat.map((input) => {
		if (input.hash === hash) {
			existInput = true;
			return { ...input, value };
		}

		return input;
	});

	if (!existInput) inputChat.push({ value, hash });

	return updateObject(state, { inputChat });
};

const changeInputAnnotation = (state, action) => {
	const { value, hash } = action.payload;
	let existInput = false;
	const inputAnnotation = state.inputAnnotation.map((input) => {
		if (input.hash === hash) {
			existInput = true;
			return { ...input, value };
		}

		return input;
	});

	if (!existInput) inputAnnotation.push({ value, hash });

	return updateObject(state, { inputAnnotation });
};

const changeInputTags = (state, action) => {
	const { value, hash } = action.payload;
	let existInput = false;
	const inputTag = state.inputTag.map((input) => {
		if (input.hash === hash) {
			existInput = true;
			return { ...input, value };
		}

		return input;
	});

	if (!existInput) inputTag.push({ value, hash });

	return updateObject(state, { inputTag });
};

const changeInputSearch = (state, action) => {
	const { value, hash } = action.payload;
	let existInput = false;
	const inputSearch = state.inputSearch.map((input) => {
		if (input.hash === hash) {
			existInput = true;
			return { ...input, value };
		}

		return input;
	});

	if (!existInput) inputSearch.push({ value, hash });

	return updateObject(state, { inputSearch });
};

const setTag = (state, action) => {
	const { tag, hash } = action.payload;
	let existInput = false;
	const interactionTags = state.interactionTags.map((input) => {
		if (input.hash === hash) {
			existInput = true;
			return {
				...input,
				tags: [
					...input.tags,
					tag
				]
			};
		}

		return input;
	});

	if (!existInput) {
		interactionTags.push({
			hash,
			tags: [
				tag
			]
		});
	}

	return updateObject(state, { interactionTags });
};
const removeTag = (state, action) => {
	const { id, hash } = action.payload;
	const interactionTags = state.interactionTags.map((input) => {
		if (input.hash === hash) {
			return {
				...input,
				tags: [
					...input.tags.filter(tagItem => tagItem.id !== id)
				]
			};
		}

		return input;
	});

	return updateObject(state, { interactionTags });
};

const defineTemplate = (state, action) => {
	const template = action.payload;
	let { customerSection } = state;

	if (template === 'tasks') {
		customerSection = state.customerSection.map((interaction) => {
			if (interaction.section === 'tasks') {
				return {
					...interaction,
					haveToBeUpdated: true,
					section: 'client'
				};
			}

			return interaction;
		});
	}

	return updateObject(state, { template, customerSection });
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
	case actionTypes.CLEAR_INPUT_ANNOTATION:
		return updateObject(state, {
			inputAnnotation: state.inputAnnotation.filter(({ hash }) => hash !== action.payload.interactionHash)
		});
	case actionTypes.SET_INTERACTION_LIST:
		return updateObject(state, {
			interactionList: action.payload
		});
	case actionTypes.SET_CUSTOMER_SECTION:
		return setCustomerSection(state, action);
	case actionTypes.SET_EDIT_CUSTOMER:
		return setEditCustomer(state, action);
	case actionTypes.CLEAR_ACTIONS:
		return updateObject(state, {
			showingInternalMessages: false,
			showingTransfer: removeHashFromArray(state, action, 'showingTransfer'),
			showingAnswer: removeHashFromArray(state, action, 'showingAnswer'),
			showingWhatsappTemplate: removeHashFromArray(state, action, 'showingWhatsappTemplate'),
			showingEmoji: removeHashFromArray(state, action, 'showingEmoji'),
			showingAnnotation: removeHashFromArray(state, action, 'showingAnnotation'),
			showingTags: removeHashFromArray(state, action, 'showingTags'),
			showingReplyMessage: removeHashFromArray(state, action, 'showingReplyMessage'),
			showingVoiceRecording: removeHashFromArray(state, action, 'showingVoiceRecording'),
			showingCreateTask: removeHashFromArray(state, action, 'showingCreateTask')
		});
	case actionTypes.SHOW_TRANSFER:
		return updateObject(state, {
			showingTransfer: pushHashToArray(state, action, 'showingTransfer')
		});
	case actionTypes.HIDE_TRANSFER:
		return updateObject(state, {
			showingTransfer: removeHashFromArray(state, action, 'showingTransfer')
		});
	case actionTypes.SHOW_ANSWER:
		return updateObject(state, {
			showingAnswer: pushHashToArray(state, action, 'showingAnswer')
		});
	case actionTypes.HIDE_ANSWER:
		return updateObject(state, {
			showingAnswer: removeHashFromArray(state, action, 'showingAnswer')
		});
	case actionTypes.SHOW_WHATSAPP_TEMPLATE:
		return updateObject(state, {
			showingWhatsappTemplate: pushHashToArray(state, action, 'showingWhatsappTemplate')
		});
	case actionTypes.HIDE_WHATSAPP_TEMPLATE:
		return updateObject(state, {
			showingWhatsappTemplate: removeHashFromArray(state, action, 'showingWhatsappTemplate')
		});
	case actionTypes.SHOW_EMOJI:
		return updateObject(state, {
			showingEmoji: pushHashToArray(state, action, 'showingEmoji')
		});
	case actionTypes.HIDE_EMOJI:
		return updateObject(state, {
			showingEmoji: removeHashFromArray(state, action, 'showingEmoji')
		});
	case actionTypes.SHOW_ANNOTATION:
		return updateObject(state, {
			showingAnnotation: pushHashToArray(state, action, 'showingAnnotation')
		});
	case actionTypes.HIDE_ANNOTATION:
		return updateObject(state, {
			showingAnnotation: removeHashFromArray(state, action, 'showingAnnotation')
		});
	case actionTypes.SHOW_TAG:
		return updateObject(state, {
			showingTags: pushHashToArray(state, action, 'showingTags')
		});
	case actionTypes.HIDE_TAG:
		return updateObject(state, {
			showingTags: removeHashFromArray(state, action, 'showingTags')
		});
	case actionTypes.SHOW_INTERNAL_MESSAGES:
		return updateObject(state, {
			showingInternalMessages: true
		});
	case actionTypes.HIDE_INTERNAL_MESSAGES:
		return updateObject(state, {
			showingInternalMessages: false
		});
	case actionTypes.SHOW_REPLY_MESSAGE:
		return updateObject(state, {
			showingReplyMessage: pushHashToArray(state, action, 'showingReplyMessage')
		});
	case actionTypes.HIDE_REPLY_MESSAGE:
		return updateObject(state, {
			showingReplyMessage: removeHashFromObjectArray(state, action, 'showingReplyMessage')
		});
	case actionTypes.SHOW_VOICE_RECORDING:
		return updateObject(state, {
			showingVoiceRecording: pushHashToArray(state, action, 'showingVoiceRecording')
		});
	case actionTypes.HIDE_VOICE_RECORDING:
		return updateObject(state, {
			showingVoiceRecording: removeHashFromArray(state, action, 'showingVoiceRecording')
		});
	case actionTypes.SHOW_CREATE_TASK:
		return updateObject(state, {
			showingCreateTask: pushHashToArray(state, action, 'showingCreateTask')
		});
	case actionTypes.HIDE_CREATE_TASK:
		return updateObject(state, {
			showingCreateTask: removeHashFromArray(state, action, 'showingCreateTask')
		});
	case actionTypes.SHOW_CALL:
		return updateObject(state, {
			showingCall: pushHashToArray(state, action, 'showingCall')
		});
	case actionTypes.HIDE_CALL:
		return updateObject(state, {
			showingCall: removeHashFromArray(state, action, 'showingCall')
		});
	case actionTypes.FETCH_IDENTITY_SUCCESS:
		return updateObject(state, {
			identity: action.payload
		});
	case actionTypes.DEFINE_DEFAULT_IDENTITY:
		return updateObject(state, {
			defaultIdentity: true
		});
	case actionTypes.CLEAR_UPDATE_INTERFACE:
		return updateObject(state, {
			customerSection: state.customerSection.map((interaction) => {
				if (interaction.hash === action.payload) {
					return {
						...interaction,
						haveToBeUpdated: false
					};
				}

				return interaction;
			})
		});
	case actionTypes.DEFINE_MAINBOX_SECTION:
		return updateObject(state, { mainBoxSection: action.payload });
	case actionTypes.DEFINE_TEMPLATE:
		return defineTemplate(state, action);
	case actionTypes.DEFINE_TASK_SIDEBAR:
		return updateObject(state, { taskSidebar: action.payload });
	case actionTypes.CHANGE_INPUT_TEXT:
		return changeInputText(state, action);
	case actionTypes.CHANGE_INPUT_ANNOTATION:
		return changeInputAnnotation(state, action);
	case actionTypes.CHANGE_INPUT_TAGS:
		return changeInputTags(state, action);
	case actionTypes.CHANGE_INPUT_SEARCH:
		return changeInputSearch(state, action);
	case actionTypes.CHANGE_INPUT_PHONE:
		return updateObject(state, { inputPhone: action.payload });
	case actionTypes.SET_TAG:
		return setTag(state, action);
	case actionTypes.REMOVE_TAG:
		return removeTag(state, action);
	case actionTypes.ACTIVE_PHONE_INTERFACE:
		return updateObject(state, { hasPhone: true });
	case actionTypes.SHOW_RECEPTIVE_MODAL:
		return updateObject(state, { showReceptiveModal: true });
	case actionTypes.HIDE_RECEPTIVE_MODAL:
		return updateObject(state, { showReceptiveModal: false });
	case actionTypes.SHOW_DIAL:
		return updateObject(state, { showDial: true });
	case actionTypes.HIDE_DIAL:
		return updateObject(state, { showDial: false });
	case actionTypes.SHOW_CONFERENCE:
		return updateObject(state, { showConference: true });
	case actionTypes.HIDE_CONFERENCE:
		return updateObject(state, { showConference: false });
	case actionTypes.SHOW_ADD_CUSTOMER:
		return updateObject(state, { showingAddCustomer: true });
	case actionTypes.HIDE_ADD_CUSTOMER:
		return updateObject(state, { showingAddCustomer: false });
	case actionTypes.SHOW_EDIT_CUSTOMER:
		return updateObject(state, { showingEditCustomer: action.payload });
	case actionTypes.HIDE_EDIT_CUSTOMER:
		return updateObject(state, { showingEditCustomer: {} });
	case actionTypes.ADD_NOTIFICATION:
		return updateObject(state, {
			notifications: state.notifications.concat(action.payload)
		});
	case actionTypes.REMOVE_NOTIFICATION:
		return updateObject(state, {
			notifications: state.notifications.filter(
				notification => notification.id !== action.payload
			)
		});
	case actionTypes.SHOW_EDIT_AGENT:
		return updateObject(state, { showEditAgent: true });
	case actionTypes.HIDE_EDIT_AGENT:
		return updateObject(state, { showEditAgent: false });
	case actionTypes.SHOW_BREAK_STATUS:
		return updateObject(state, { showBreakStatus: true });
	case actionTypes.HIDE_BREAK_STATUS:
		return updateObject(state, { showBreakStatus: false });
	case actionTypes.TOKEN_EXPIRED:
		return updateObject(state, { tokenExpired: true });
	default:
		return state;
	}
};

export default reducer;
