import axios from 'axios';
import * as actionTypes from './actionTypes';

export const fetchAgentDashboardBegin = () => ({
	type: actionTypes.FETCH_AGENT_DASHBOARD_BEGIN
});

export const fetchAgentDashboardSuccess = payload => ({
	type: actionTypes.FETCH_AGENT_DASHBOARD_SUCCESS,
	payload
});
export const fetchAgentDashboardFailed = () => ({
	type: actionTypes.FETCH_AGENT_DASHBOARD_FAILED
});

export const fetchAgentDashboard = ({ departments, from, to } = {}) => (
	(dispatch) => {
		dispatch(fetchAgentDashboardBegin());

		const cleanObject = JSON.parse(JSON.stringify({ departments, from, to }));
		const filters = Object.entries(cleanObject).map(([key, val]) => `${key}=${val}`).join('&');

		return axios.get(`${process.env.OMZ_DASHBOARD}/uai/agent/info?${filters}`, { dispatch })
			.then(({ data }) => {
				const {
					success,
					status,
					adherence,
					productiveTime,
					averageServiceTime,
					voice,
					channels,
					mood
				} = data;
				if (status === 200 && success) {
					dispatch(fetchAgentDashboardSuccess({
						adherence,
						productiveTime,
						averageServiceTime,
						voice,
						channels,
						mood
					}));
				} else {
					dispatch(fetchAgentDashboardFailed());
				}
			}).catch(() => {
				dispatch(fetchAgentDashboardFailed());
			});
	}
);

export const fetchPeersDashboardBegin = () => ({
	type: actionTypes.FETCH_PEERS_DASHBOARD_BEGIN
});

export const fetchPeersDashboardSuccess = payload => ({
	type: actionTypes.FETCH_PEERS_DASHBOARD_SUCCESS,
	payload
});
export const fetchPeersDashboardFailed = () => ({
	type: actionTypes.FETCH_PEERS_DASHBOARD_FAILED
});

export const fetchPeersDashboard = ({ page = 1, limit = 20, firstLoading = false }) => (
	(dispatch) => {
		if (firstLoading) {
			dispatch(fetchPeersDashboardBegin());
		}
		return axios.get(`${process.env.OMZ_DASHBOARD}/uai/agent/peers?limit=${limit}&page=${page}`, { dispatch })
			.then(({ data }) => {
				const {
					success,
					status,
					agents,
					departments
				} = data;
				if (status === 200 && success) {
					dispatch(fetchPeersDashboardSuccess({
						agents,
						departments
					}));
				} else {
					dispatch(fetchPeersDashboardFailed());
				}
			}).catch(() => {
				dispatch(fetchPeersDashboardFailed());
			});
	}
);
