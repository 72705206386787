/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';

import './index.sass';
import ImgSvg from '../../Atoms/ImgSvg';
import Wrapper from '../../Atoms/Wrapper';

const CheckBox = ({ selected, onClick = () => {} }) => (
	<label className="CheckBox" htmlFor="checkBox">
		<input
			type="checkbox"
			checked={selected}
			readOnly
			id="checkBox"
		/>
		<Wrapper className="CheckBox__label" action={onClick}>
			<ImgSvg name="checked" />
		</Wrapper>
	</label>
);

CheckBox.propTypes = {
	selected: PropTypes.bool.isRequired,
	onClick: PropTypes.func
};

export default CheckBox;
