import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import InputMask from 'react-input-mask';
import { injectIntl, defineMessages } from 'react-intl';

import './index.sass';
import Text from '../../../Atoms/Text';

const messages = defineMessages({
	maskDate: {
		id: 'formInput.maskDate',
		defaultMessage: 'dd/mm/yyyy'
	}
});

const FormInput = ({
	label,
	value,
	onChange,
	touched = false,
	valid,
	validationMessage = '',
	type = 'text',
	onKeyUp = () => {},
	disabled,
	mask = '',
	name,
	placeholder,
	isDark,
	intl,
	'data-testid': dataTestId
}) => {
	const { formatMessage } = intl;

	const [inputClasses, setInputClasses] = useState('');
	const [alertClasses, setAlertClasses] = useState('');
	const [errorMessage, setErrorMessage] = useState('');
	useEffect(() => {
		const currentInputClasses = ['FormInput'];
		const currentAlertClasses = ['FormInput__alert'];

		if (touched && !valid) {
			setErrorMessage(validationMessage);
			currentInputClasses.push('FormInput--invalid');
			currentAlertClasses.push('FormInput__alert--show');
		}
		setInputClasses(currentInputClasses.join(' '));
		setAlertClasses(currentAlertClasses.join(' '));
	}, [touched, valid, validationMessage, inputClasses, alertClasses]);

	return (
		<div className={inputClasses}>
			<Text className={`${isDark ? 'FormInput__label--dark' : 'FormInput__label'}`} size="bigger" data-testid={errorMessage}>{label}</Text>
			{type === 'textarea' ? (
				<textarea
					data-testid={dataTestId}
					value={value}
					onChange={e => onChange(e)}
					onKeyUp={onKeyUp}
					disabled={disabled}
					placeholder={placeholder || label}
				/>
			) : (
				<InputMask
					data-testid={dataTestId}
					value={value}
					onChange={e => onChange(e)}
					onKeyUp={onKeyUp}
					type={type}
					disabled={disabled}
					mask={mask}
					maskPlaceholder={name === 'taskDate' ? formatMessage(messages.maskDate) : null}
					placeholder={placeholder || label}
				/>
			)}
			<Text size="small" className={alertClasses} data-testid={errorMessage}>{errorMessage}</Text>
		</div>
	);
};

FormInput.propTypes = {
	label: PropTypes.string.isRequired,
	placeholder: PropTypes.string,
	isDark: PropTypes.bool,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
	onChange: PropTypes.func.isRequired,
	touched: PropTypes.bool,
	valid: PropTypes.bool.isRequired,
	validationMessage: PropTypes.string,
	type: PropTypes.oneOf(['password', 'text', 'textarea', 'date', 'time']),
	onKeyUp: PropTypes.func,
	disabled: PropTypes.bool,
	mask: PropTypes.string,
	name: PropTypes.string,
	intl: PropTypes.shape({
		formatMessage: PropTypes.func.isRequired
	}).isRequired,
	'data-testid': PropTypes.string
};

export default injectIntl(FormInput);
