import React from 'react';

import './index.sass';
import ImgSvg from '../../Atoms/ImgSvg';

const InitialLoader = () => (
	<div className="InitialLoader">
		<div className="InitialLoader__image">
			<ImgSvg color="default" name="empty-chat" />
		</div>
	</div>
);

export default InitialLoader;
