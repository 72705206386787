import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Wrapper from '../../Atoms/Wrapper';
import Select from '../../Molecules/Inputs/Select';

import './index.sass';

const Pagination = ({
	total = 0,
	onClick = () => {}
}) => {
	const [pageSize, setPageSize] = useState('20');
	const [pageNumber, setPageNumber] = useState(1);

	const handleChange = ({ page = pageNumber, limit = pageSize }) => {
		setPageSize(limit);
		setPageNumber(page);
		onClick({ page, limit });
	};

	const renderPageBlock = (index, text) => {
		const pages = Math.ceil(total / pageSize);
		return typeof index !== 'number' || index < 1 || index > pages ? (
			<span
				key={`page_${index}`}
				data-testid={text || index}
				className="page default"
			>
				{text || index}
			</span>
		) : (
			<Wrapper
				key={`page_${index}`}
				className={`page ${pageNumber === index ? 'active' : ''}`}
				action={() => handleChange({ page: index })}
			>
				{text || index}
			</Wrapper>
		);
	};

	const renderMiddleElements = () => {
		const pages = Math.ceil(total / pageSize);
		let middleEles = [renderPageBlock(pageNumber)];
		const leftSideSize = pageNumber - 2 <= 0 ? 1 : pageNumber - 2;
		// eslint-disable-next-line no-plusplus
		for (let i = pageNumber; i > leftSideSize; i--) {
			middleEles = [renderPageBlock(i - 1), ...middleEles];
		}
		const rightSideSize = pageNumber + 2 > pages ? pages : pageNumber + 2;
		// eslint-disable-next-line no-plusplus
		for (let i = pageNumber; i < rightSideSize; i++) {
			middleEles = [...middleEles, renderPageBlock(i + 1)];
		}

		middleEles = [
			pageNumber - 3 >= 1 && renderPageBlock(1),
			pageNumber - 3 > 1 && renderPageBlock(-1, '...'),
			...middleEles,
			pageNumber + 3 < pages && renderPageBlock(-2, '...'),
			pageNumber + 3 <= pages && renderPageBlock(pages)
		];

		return middleEles;
	};

	return (
		<div className="Pagination">
			<div className="Pagination__info">
				<span>Exibir</span>
				<Select
					items={[{ id: 1, label: '20', value: '20' }, { id: 2, label: '50', value: '50' }, { id: 3, label: '75', value: '75' }, { id: 4, label: '100', value: '100' }]}
					onChange={event => handleChange({ limit: event.target.value, page: 1 })}
					value={pageSize}
				/>
				<span>
					{pageSize * (pageNumber - 1) + 1}
					{'-'}
					{pageSize * pageNumber > total ? total : pageSize * pageNumber}
					{' '}
					de
					{' '}
					{total}
				</span>
			</div>
			<div className="Pagination__pages">
				{renderMiddleElements()}
			</div>
		</div>
	);
};

Pagination.propTypes = {
	total: PropTypes.number,
	onClick: PropTypes.func
};

export default Pagination;
