/* eslint-disable */
import * as SIP from 'sip.js'
export var Inov8Sdk = function () {
  this.events = {};
  this.token = null;
  this.ua = null;
  this.session = null;
  this.connected = false;
  this.currentInteraction = null;
  this.callId = null;
  this.number = null;
};

Inov8Sdk.prototype = {
  connect: function (params) {
    var self = this;
    if (!this.token) {
      var xhr = new XMLHttpRequest();
      xhr.open("POST", 'https://api.inov8.cloud:8889/auth', true);

      var body = {
        "user_name": "omnize_inov8@inov8.cloud",
        "user_passwd": "0mn14p1@2019"
      }

      xhr.setRequestHeader("Content-Type", "application/json");

      xhr.onreadystatechange = function () { // Chama a função quando o estado mudar.
        if (this.readyState === XMLHttpRequest.DONE && this.status === 200) {
          self.token = JSON.parse(this.response).ret_token;
        }
      }
      xhr.send(JSON.stringify(body));
    }

    var credentials = {
      uri: "000009@rtc2.inov8.cloud",
      wsServers: 'wss://rtc2.inov8.cloud:8089/ws',
      authorizationUser: "000009",
      password: "0mn1z3",
      hackIpInContact: true,
      rtcpMuxPolicy: 'negotiate',
      displayName: "000009",
      traceSip: true
    };

    const remoteAudio = document.querySelector('.inov8_stream');

    var media = { remote: { audio: remoteAudio } };

    this.ua = new SIP.Web.Simple({
      ua: credentials,
      media: media
    });

    self.ua.on('ringing', function (s) {
      console.log("RINGING:::", s);
      self.session = s;
      self.addListeners();
      this.answer();
    });
  },
  addListeners: function () {
    var self = this;

    this.session.on('progress', function () {
      console.log("PROGRESS");
    });

    this.session.on('accepted', function () {
      self.events['acceptedCall'](self.callId, self.number);
      console.log("GOT STREAM");
    });

    this.session.on('failed', function () {
      console.log("FAILED");
    });

    this.session.on('bye', function () {
      console.log('self', self);
      self.events['finishedInteraction'](self.currentInteraction);
      self.currentInteraction = '';

      if (self.session === this) {
        console.log("BYE2x");
        self.session = null;
      }
    }.bind(this.session));

    // this.session.on('ringing', function () {

    //   this.session.answer();
    // });
  },
  on: function (customEvent, callback) {
    if (typeof callback === 'function') {
      this.events[customEvent] = callback;
    }
  },
  makeCall: function (number) {
    var self = this;
    var xhr = new XMLHttpRequest();
    xhr.open("POST", 'https://api.inov8.cloud:8889/call/create', true);

    var body = {
      "api_key": "OMN-04-1508-000-A0001",
      "call_destination": `55${number}`,
      "call_type": "external",
      "call_cid": "11999990000",
      "call_recorder": "0"
    }

    xhr.setRequestHeader("Content-Type", "application/json");
    xhr.setRequestHeader('Authorization', 'Bearer ' + self.token);

    xhr.onreadystatechange = function () {
      if (this.readyState === XMLHttpRequest.DONE && this.status === 201) {
        const callId = JSON.parse(this.response)._id;
        self.callId = callId;
        self.number = number;
      }
    }
    xhr.send(JSON.stringify(body));
  },
  finishCall: function () {
    var xhr = new XMLHttpRequest();
    xhr.open("DELETE", `https://api.inov8.cloud:8889/call/${this.callId}`, true);
    xhr.setRequestHeader('Authorization', 'Bearer ' + this.token);
    xhr.send();
  },
  createdInteraction: function (interactionHash) {
    var self = this;
    self.currentInteraction = interactionHash
  }
};

if (!window.Inov8Sdk) {
	window.Inov8Sdk = new Inov8Sdk();
}

/* eslint-enable */
