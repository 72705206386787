import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import {
	interactionTypeImage
} from '../../../shared/utility';

import ImgSvg from '../ImgSvg';

const messagesIntl = defineMessages({
	altLabel: {
		id: 'channelImage.altLabel',
		defaultMessage: 'Imagem do canal personalizado'
	}
});

const ChannelImage = ({
	name = '',
	customChannels,
	isPartner,
	suffix,
	intl
}) => {
	const { formatMessage } = intl;

	const interactionImage = interactionTypeImage(name, isPartner);
	const customChannel = customChannels.find(channel => channel.paramName === name.toUpperCase());

	return (
		<>
			{customChannel ? (
				<img src={customChannel.icon.imageBase} alt={formatMessage(messagesIntl.altLabel)} data-testid="component-channel-image--img" />
			) : (
				<ImgSvg name={suffix ? `${interactionImage}-${suffix}` : interactionImage} data-testid="component-channel-image--svg" />
			)}
		</>
	);
};

ChannelImage.propTypes = {
	name: PropTypes.string,
	isPartner: PropTypes.bool,
	customChannels: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
	suffix: PropTypes.string,
	intl: PropTypes.shape({
		formatMessage: PropTypes.func.isRequired
	}).isRequired
};

const mapStateToProps = state => ({
	customChannels: state.customChannels.list
});


export default connect(mapStateToProps)(injectIntl(ChannelImage));
