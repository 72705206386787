import React from 'react';
import PropTypes from 'prop-types';

import './index.sass';
import ImgSvg from '../../Atoms/ImgSvg';
import Wrapper from '../../Atoms/Wrapper';

const TagItem = ({
	tag,
	onClick = () => {},
	action,
	selected,
	type = ''
}) => {
	const {
		id,
		name,
		path,
		// eslint-disable-next-line camelcase
		base_color,
		baseColor
	} = tag;

	return (
		<div
			className={`TagItem ${action ? 'TagItem--action' : ''}`}
			onClick={() => onClick(id)}
			onKeyPress={() => onClick(id)}
			role="presentation"
			/* eslint-disable-next-line camelcase */
			style={{ backgroundColor: base_color || baseColor || '#0096dc' }}
		>
			<Wrapper>{path ? `${path} > ${name}` : name}</Wrapper>
			{action && type === 'remove' && (
				<div className={`TagItem__icon ${selected ? 'TagItem__icon--selected' : ''}`}>
					<ImgSvg name="close" />
				</div>
			)}
		</div>
	);
};

TagItem.propTypes = {
	tag: PropTypes.shape({
		id: PropTypes.number,
		name: PropTypes.string,
		path: PropTypes.string,
		base_color: PropTypes.string,
		baseColor: PropTypes.string
	}).isRequired,
	onClick: PropTypes.func,
	action: PropTypes.bool,
	selected: PropTypes.bool,
	type: PropTypes.string
};

export default TagItem;
