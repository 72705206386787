import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import './index.sass';

const Textarea = ({
	value = '',
	onChange,
	placeholder,
	disabled,
	shouldFocus
}) => {
	const textareaRef = useRef();

	useEffect(() => {
		if (shouldFocus) textareaRef.current.focus();
	}, [shouldFocus]);

	return (
		<textarea
			className="Textarea"
			value={value}
			onChange={onChange}
			placeholder={placeholder}
			disabled={disabled}
			ref={textareaRef}
		/>
	);
};

Textarea.propTypes = {
	value: PropTypes.string,
	onChange: PropTypes.func.isRequired,
	placeholder: PropTypes.string,
	disabled: PropTypes.bool,
	shouldFocus: PropTypes.bool
};

export default Textarea;
