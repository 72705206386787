import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, defineMessages } from 'react-intl';

import './index.sass';
import FilterMultipleItem from '../FilterMultipleItem';
import Button from '../Buttons/Button';

const messages = defineMessages({
	search: {
		id: 'filterMultiple.search',
		defaultMessage: 'Buscar'
	}
});

const FilterMultiple = ({
	items,
	onConfirm,
	loading,
	intl
}) => (
	<div className="FilterMultiple">
		{items.map(filter => <FilterMultipleItem filter={filter} key={filter.id} />)}
		<Button click={onConfirm} loading={loading}>
			{intl.formatMessage(messages.search)}
		</Button>
	</div>
);

FilterMultiple.propTypes = {
	items: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.number.isRequired,
			label: PropTypes.string.isRequired,
			current: PropTypes.bool,
			onClick: PropTypes.func.isRequired
		})
	).isRequired,
	onConfirm: PropTypes.func.isRequired,
	loading: PropTypes.bool,
	intl: PropTypes.shape({
		formatMessage: PropTypes.func.isRequired
	}).isRequired
};

export default injectIntl(FilterMultiple);
