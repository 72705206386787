import React from 'react';
import PropTypes from 'prop-types';

import './index.sass';
import Bubble from '../../../Atoms/Bubble';

const MessageAudio = ({ messageInfo, children }) => (
	<Bubble messageInfo={messageInfo} className="MessageAudio" timeOnBottom>
		{children}
		<audio src={messageInfo.url} controls><track kind="captions" /></audio>
	</Bubble>
);

MessageAudio.propTypes = {
	messageInfo: PropTypes.shape({
		url: PropTypes.string
	}).isRequired
};

export default MessageAudio;
