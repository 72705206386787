import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, defineMessages } from 'react-intl';

import './index.sass';
import TransferItem from '../TransferItem';
import Text from '../../Atoms/Text';
import InfiniteList from '../InfiniteList';

const messages = defineMessages({
	emptyAgents: {
		id: 'transferList.emptyAgents',
		defaultMessage: 'Não há atendentes disponíveis,'
	}
});

const TransferList = ({
	activeTab,
	transferData = {
		departments: [],
		agents: []
	},
	onSelectAgent,
	isPhoneInteraction,
	intl,
	loadMoreTransferData,
	selectedId
}) => {
	const { departments, agents } = transferData;
	let agentsList = agents;
	if (isPhoneInteraction) {
		agentsList = agents.filter(agent => (
			agent.phoneExtension !== null && agent.newStatus.toLowerCase() === 'online'
		));
	}

	const filterSelectedIfNecessary = list => (selectedId ? list.filter(item => item.id === selectedId) : list);

	return (
		<InfiniteList onBottom={loadMoreTransferData} customClass="TransferList">

			{!isPhoneInteraction && activeTab === 'departments' && (
				filterSelectedIfNecessary(departments).map(department => (
					<TransferItem
						type="department"
						key={department.id}
						item={department}
						onSelectAgent={onSelectAgent}
						disableClick={!!selectedId}
					/>
				))
			)}

			{activeTab === 'agents' && (
				filterSelectedIfNecessary(agentsList).map(agent => (
					<TransferItem
						type="agent"
						key={agent.id}
						item={agent}
						onSelectAgent={onSelectAgent}
						disableClick={!!selectedId}
					/>
				))
			)}

			{isPhoneInteraction && agentsList.length === 0 && (
				<Text>{intl.formatMessage(messages.emptyAgents)}</Text>
			)}
		</InfiniteList>
	);
};

TransferList.propTypes = {
	transferData: PropTypes.shape({
		departments: PropTypes.arrayOf(PropTypes.shape({
			id: PropTypes.number
		})),
		agents: PropTypes.arrayOf(PropTypes.shape({}))
	}),
	onSelectAgent: PropTypes.func.isRequired,
	isPhoneInteraction: PropTypes.bool.isRequired,
	intl: PropTypes.shape({
		formatMessage: PropTypes.func.isRequired
	}).isRequired,
	loadMoreTransferData: PropTypes.func.isRequired,
	selectedId: PropTypes.number,
	activeTab: PropTypes.string.isRequired
};

export default injectIntl(TransferList);
