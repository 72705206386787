import React from 'react';
import { defineMessages, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';

// Components
import Icon from '../../Atoms/ImgSvg';

import './index.sass';

const scope = 'agentStatusBadge';

const translateMessages = defineMessages({
	online: {
		id: `${scope}.online`,
		defaultMessage: 'Logado'
	},
	disconnected: {
		id: `${scope}.disconnected`,
		defaultMessage: 'Offline'
	},
	unavailable: {
		id: `${scope}.unavailable`,
		defaultMessage: 'Indisponível'
	},
	break: {
		id: `${scope}.break`,
		defaultMessage: 'Pausa'
	},
	preBreak: {
		id: `${scope}.preBreak`,
		defaultMessage: 'Pré-pausa'
	}
});

function AgentStatusBadge({ status, intl }) {
	const types = {
		ONLINE: {
			icon: 'online',
			color: 'is-success',
			name: intl.formatMessage(translateMessages.online)
		},
		OFFLINE: {
			icon: 'disconnected',
			color: '',
			name: intl.formatMessage(translateMessages.disconnected)
		},
		UNAVAILABLE: {
			icon: 'unavailable',
			color: 'is-success',
			name: intl.formatMessage(translateMessages.unavailable)
		},
		BREAK: {
			icon: 'break',
			color: 'is-danger',
			name: intl.formatMessage(translateMessages.break)
		},
		PREBREAK: {
			icon: 'pre-break',
			color: 'is-warning',
			name: intl.formatMessage(translateMessages.preBreak)
		}
	};

	return (
		<div
			className={`AgentStatusBadge badge ${types[status].color} is-light`}
			style={{
				fontWeight: 400,
				color: '#393946',
				fontSize: 13
			}}
		>
			<Icon
				color="default"
				name={types[status].icon}
			/>
			{types[status].name}
		</div>
	);
}

AgentStatusBadge.propTypes = {
	status: PropTypes.string.isRequired,
	intl: PropTypes.shape({
		formatMessage: PropTypes.func.isRequired
	}).isRequired
};

export default injectIntl(AgentStatusBadge);
