/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable indent */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { injectIntl, defineMessages } from 'react-intl';
import classNames from 'classnames';

import { SelectDropdownProvider, useSelectDropdown } from '../../../context/SelectDropdown';

import styles from './SelectDropdown.module.sass';

const messages = defineMessages({
	select: {
		id: 'breakStatus.breakTime.createTime.input',
		defaultMessage: 'Criar tempo de pausa:'
	},
	breakStatusBreakTimeMinute: {
		id: 'breakStatus.breakTime.minute',
		defaultMessage: 'minutos'
	}
});

function SelectDropdownRootProvider({ initialSelected, children, className }) {
	return (
		<SelectDropdownProvider>
			<SelectDropdownRoot className={className} initialSelected={initialSelected}>
				{children}
			</SelectDropdownRoot>
		</SelectDropdownProvider>
	);
}

function SelectDropdownRoot({ initialSelected, children, className }) {
	const { isActive, setNewItem } = useSelectDropdown();

	useEffect(() => {
		setNewItem(initialSelected?.label);
	}, [initialSelected?.label, setNewItem]);

	return (
		<div className={`dropdown is-multiselect is-fullwidth ${classNames({ 'is-active': isActive })} ${className}`}>
			{children}
		</div>
	);
}

function SelectDropdownTrigger({ children }) {
	return (
		<div className="dropdown-trigger is-fullwidth">
			{children}
		</div>
	);
}

function SelectDropdownLabel({ children }) {
	return (
		<label className="label">{children}</label>
	);
}

function SelectDropdownTriggerContent({
	placeholder,
	isReadonly = false
}) {
	const { isActive, onChangeActive } = useSelectDropdown();
	return (
		<button
			className="button is-fullwidth is-justify-content-space-between"
			aria-haspopup="true"
			aria-controls="dropdown-menu"
			onClick={() => onChangeActive()}
			disabled={isReadonly}
			type="button"
		>
			<span>{placeholder}</span>
			{
				!isReadonly && (
					<span className="icon is-small">
						<i
							className={classNames({
								'fas fa-angle-down': !isActive,
								'fas fa-angle-up': isActive
							})}
							aria-hidden="true"
						/>
					</span>
				)
			}
		</button>
	);
}


function SelectDropdownTriggerContentCreatable({
	placeholder,
	isReadonly = false,
	isCreatable = true,
	allowOnlyNumbers = false,
	selectItem
}) {
	const {
		isActive,
		onChangeActive,
		isCreating,
		setIsCreating,
		newItem,
		setNewItem
	} = useSelectDropdown();

	useEffect(() => {
		if (selectItem) setNewItem(null);
	}, [selectItem, setNewItem]);

	const handleOnChange = (value) => {
		if (!isCreatable) return;

		if (allowOnlyNumbers && !/^\d*$/.test(value)) return;

		setNewItem(value);
		if (value && !isCreating) setIsCreating(true);
		if (!value && isCreating) setIsCreating(false);
		if (!isActive) onChangeActive();
	};

	return (
		<p className="control has-icons-right">
			<input
				className={`input is-clickable ${styles['creatable-input']}`}
				type="text"
				placeholder={placeholder}
				value={newItem || ''}
				onClick={() => onChangeActive()}
				onChange={e => handleOnChange(e.target.value)}
				disabled={isReadonly}
			/>
			{
				!isReadonly && (
					<a
						className="icon is-right is-action"
						onClick={() => onChangeActive()}
						role="button"
						tabIndex={0}
					>
						<i
							className={classNames({
								'fas fa-angle-down': !isActive,
								'fas fa-angle-up': isActive
							})}
							aria-hidden="true"
						/>
					</a>
				)
			}
		</p>
	);
}

function SelectDropdownHelp({ children }) {
	return (
		<p className={`${styles['help-text']} help mt-1`}>{children}</p>
	);
}

function SelectDropdownMenu({
	options,
	hasItemDefault = true,
	position,
	onSelectItem,
	menuIsFullwidth
}) {
	return (
		<div className={`dropdown-menu ${position === 'top' && 'dropdown-menu-top'} ${menuIsFullwidth && 'is-fullwidth'}`} id="dropdown-menu" role="menu">
			<div className="dropdown-content">
				{
					hasItemDefault && (
						<SelectDropdownMenuItem
							key="SelectDropdownMenuItemDefault"
							onClick={() => onSelectItem('')}
						>
							Manter configuração original
						</SelectDropdownMenuItem>
					)
				}
				{
                    options?.map((option, key) => (
	<SelectDropdownMenuItem
		key={key}
		onClick={() => onSelectItem(option)}
	>
		{option.label}
	</SelectDropdownMenuItem>
                    ))
				}
			</div>
		</div>
	);
}

const SelectDropdownMenuCreatable = injectIntl(({
	options,
	hasItemDefault = true,
	position,
	onAddNewOption,
	onSelectItem,
	menuIsFullwidth,
	sufix,
	intl
}) => {
	const {
		isCreating, setIsCreating, newItem, setNewItem, onChangeActive
	} = useSelectDropdown();

	const handleOnCreateNewItem = async () => {
		const newCreatableItem = { value: newItem, label: `${newItem} ${sufix}` };
		await onAddNewOption(newCreatableItem);
		await setNewItem('');
		setIsCreating(false);
	};

	return (
		<div className={`dropdown-menu ${position === 'top' && 'dropdown-menu-top'} ${menuIsFullwidth && 'is-fullwidth'} `} id="dropdown-menu" role="menu">
			<div
				className="dropdown-menu-overlay"
				onClick={() => onChangeActive(false)}
			/>
			<div className="dropdown-content">
				{
					!isCreating && hasItemDefault && (
						<SelectDropdownMenuItem
							key="SelectDropdownMenuCreatableItemDefault"
							onClick={() => {
								onSelectItem('');
								setNewItem('');
								onChangeActive();
							}}
						>
							Manter configuração original
						</SelectDropdownMenuItem>
					)
				}
				{
					!isCreating && options?.map((option, key) => (
						<SelectDropdownMenuItem
							key={key}
							onClick={() => {
								onSelectItem(option);
								setNewItem(option.label);
								onChangeActive();
							}}
						>
							{option.label}
						</SelectDropdownMenuItem>
					))
				}
				{
					isCreating && (
						<SelectDropdownMenuItem onClick={handleOnCreateNewItem}>
							<span>
								{`${intl.formatMessage(messages.select)} ${newItem} ${sufix}`}
							</span>
						</SelectDropdownMenuItem>
					)
				}
			</div>
		</div>
	);
});

function SelectDropdownMenuItem({ onClick = () => {}, children }) {
	return (
		<div className={`dropdown-item is-clickable ${styles['menu-item']}`} onClick={() => onClick()}>
			{children}
		</div>
	);
}

const SelectDropdown = {
	Root: SelectDropdownRootProvider,
	Label: SelectDropdownLabel,
	Trigger: SelectDropdownTrigger,
	Help: SelectDropdownHelp,
	Content: SelectDropdownTriggerContent,
	ContentCreatable: SelectDropdownTriggerContentCreatable,
	Menu: SelectDropdownMenu,
	MenuCreatable: SelectDropdownMenuCreatable,
	MenuItem: SelectDropdownMenuItem
};

export default injectIntl(SelectDropdown);
