import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, defineMessages } from 'react-intl';

import './index.sass';

import Button from '../Buttons/Button';
import Modal from '../Modal';
import Text from '../../Atoms/Text';
import ImgSvg from '../../Atoms/ImgSvg';

const messages = defineMessages({
	logout: {
		id: 'agentHasNoActiveDepartmentModal.logout',
		defaultMessage: 'Sair'
	},
	message: {
		id: 'agentHasNoActiveDepartmentModal.message',
		defaultMessage: 'Você não está cadastrado em nenhum departamento ativo, entre em contato com um administrador.'
	}
});

const AgentHasNoActiveDepartmentModal = ({ close, intl, hasBlur }) => {
	const { formatMessage } = intl;

	return (
		<Modal
			className="AgentHasNoActiveDepartmentModal"
			hasBlur={hasBlur}
		>
			<div className="AgentHasNoActiveDepartmentModal__content">
				<ImgSvg name="protocolo-off" />
				<Text size="bigger">
					{formatMessage(messages.message)}
				</Text>
				<Button
					click={close}
					selected
				>
					{formatMessage(messages.logout)}
				</Button>
			</div>
		</Modal>
	);
};

AgentHasNoActiveDepartmentModal.propTypes = {
	close: PropTypes.func.isRequired,
	intl: PropTypes.shape({
		formatMessage: PropTypes.func
	}).isRequired,
	hasBlur: PropTypes.bool
};

export default injectIntl(AgentHasNoActiveDepartmentModal);
