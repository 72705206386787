import React from 'react';
import PropTypes from 'prop-types';

import './index.sass';
import Tooltip from '../Tooltip';

const Wrapper = ({
	className,
	action = () => {},
	children,
	disableKeyPressEvent,
	info,
	ariaLabel = '',
	tooltipPosition = 'center',
	style = {},
	'data-testid': dataTestId
}) => {
	if (info) {
		return (
			<Tooltip
				position={tooltipPosition}
				header={(
					<div
						onClick={action}
						onKeyPress={disableKeyPressEvent ? () => { } : action}
						role="button"
						tabIndex={0}
						className={`Wrapper ${className}`}
						data-testid={dataTestId}
						aria-label={ariaLabel}
					>
						{children}
					</div>
				)}
			>
				{info}
			</Tooltip>
		);
	}

	return (
		<div
			onClick={action}
			onKeyPress={disableKeyPressEvent ? () => { } : action}
			role="button"
			tabIndex={0}
			className={`Wrapper ${className}`}
			data-testid={dataTestId}
			aria-label={ariaLabel}
			style={style}
		>
			{children}
		</div>
	);
};

Wrapper.propTypes = {
	className: PropTypes.string,
	action: PropTypes.func,
	disableKeyPressEvent: PropTypes.bool,
	info: PropTypes.string,
	ariaLabel: PropTypes.string,
	tooltipPosition: PropTypes.oneOf(['left', 'right', 'center', 'top']),
	'data-testid': PropTypes.string,
	style: PropTypes.shape({})
};

export default Wrapper;
