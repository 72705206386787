import React, {
	useEffect,
	useState,
	useCallback,
	useRef
} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl, defineMessages } from 'react-intl';

import './index.sass';
import * as actions from '../../../store/actions';
import { usePrevious } from '../../../shared/utility';
import HistoryList from '../../Molecules/HistoryList';
import Input from '../../Molecules/Inputs/Common';
import Text from '../../Atoms/Text';

const messages = defineMessages({
	search: {
		id: 'history.search',
		defaultMessage: 'Buscar no histórico'
	},
	title: {
		id: 'history.title',
		defaultMessage: 'Histórico de atendimentos'
	}
});

const History = ({
	onFetchHistory,
	agent,
	currentInteraction,
	onFetchInteractionHistory,
	fetchHistoryStatus,
	intl
}) => {
	const ref = useRef();
	const { customerInfo } = currentInteraction;
	const { customerKey } = customerInfo;
	const [showHistoryMessages, setShowHistoryMessages] = useState('');
	const [searchedValue, setSearchedValue] = useState('');
	const prevCustomerKey = usePrevious(customerKey);
	const { count, ended, loading } = fetchHistoryStatus;

	useEffect(() => {
		if (customerKey !== prevCustomerKey) {
			onFetchHistory({ accountId: agent.info.account.id, customerKey: customerKey || prevCustomerKey });
		}
	}, [agent.info.account.id, customerKey, onFetchHistory, prevCustomerKey]);

	const onSelectHistory = useCallback((interactionHash) => {
		if (interactionHash && interactionHash !== showHistoryMessages) {
			onFetchInteractionHistory({ accountId: agent.info.account.id, interactionHash });
		}
		if (showHistoryMessages === interactionHash) return setShowHistoryMessages('');
		return setShowHistoryMessages(interactionHash);
	}, [agent.info.account.id, onFetchInteractionHistory, showHistoryMessages]);

	const loadMoreHistories = useCallback(() => {
		if (!ended && !loading) {
			onFetchHistory({
				accountId: agent.info.account.id,
				customerKey,
				page: count
			});
		}
	}, [agent.info.account.id, count, customerKey, ended, loading, onFetchHistory]);

	const onSearch = useCallback((typedValue) => {
		setTimeout(() => {
			if (ref.current.input.current.value === typedValue) {
				onFetchHistory({
					accountId: agent.info.account.id,
					customerKey: currentInteraction.customerInfo.customerKey,
					search: typedValue
				});
			}
		}, 1200);
	}, [agent.info.account.id, currentInteraction.customerInfo.customerKey, onFetchHistory]);

	const onType = useCallback((e) => {
		const { value } = e.target;
		setSearchedValue(value);
		onSearch(value);
	}, [onSearch]);

	return (
		<div className="History">
			<Text size="biggest" bold className="History__title">
				{intl.formatMessage(messages.title)}
			</Text>
			<Input
				placeholder={intl.formatMessage(messages.search)}
				value={searchedValue}
				onChange={onType}
				loading={loading && searchedValue !== ''}
				ref={ref}
			/>
			<HistoryList
				onSelectHistory={onSelectHistory}
				loading={fetchHistoryStatus.loading && searchedValue === ''}
				customer={customerInfo}
				showHistoryMessages={showHistoryMessages}
				loadMoreHistories={loadMoreHistories}
			/>
		</div>
	);
};

const mapStateToProps = ({ agent, interaction }) => {
	const {
		currentInteractionHash,
		fetchHistoryStatus,
		historyInteractions,
		interactions,
		missedInteractions,
		pendingInteractions
	} = interaction;
	const allInteractions = interactions.concat(pendingInteractions).concat(historyInteractions).concat(missedInteractions);

	return ({
		currentInteraction: allInteractions.find(({ interactionHash }) => interactionHash === currentInteractionHash) || {},
		fetchHistoryStatus: fetchHistoryStatus.find(({ hash }) => hash === currentInteractionHash) || {},
		agent
	});
};

const mapActionToProps = dispatch => ({
	onFetchHistory: requestInfo => (dispatch(actions.fetchHistory(requestInfo))),
	onFetchInteractionHistory: requestInfo => (dispatch(actions.fetchInteractionHistory(requestInfo)))
});

History.propTypes = {
	onFetchHistory: PropTypes.func.isRequired,
	onFetchInteractionHistory: PropTypes.func.isRequired,
	currentInteraction: PropTypes.shape({
		customerInfo: PropTypes.shape({
			customerKey: PropTypes.string,
			history: PropTypes.arrayOf(PropTypes.shape({}))
		}).isRequired,
		history: PropTypes.shape({}),
		currentInteractionHash: PropTypes.string
	}).isRequired,
	fetchHistoryStatus: PropTypes.shape({
		loading: PropTypes.bool,
		error: PropTypes.string,
		count: PropTypes.number,
		ended: PropTypes.bool
	}),
	agent: PropTypes.shape({
		info: PropTypes.shape({
			id: PropTypes.number.isRequired,
			account: PropTypes.shape({
				id: PropTypes.number
			})
		}),
		account: PropTypes.shape({
			id: PropTypes.number
		})
	}).isRequired,
	intl: PropTypes.shape({
		formatMessage: PropTypes.func
	}).isRequired
};

export default connect(mapStateToProps, mapActionToProps)(injectIntl(History));
