import axios from 'axios';
import * as actionTypes from './actionTypes';

export const fetchFormFieldsBegin = () => ({
	type: actionTypes.FETCH_FORM_FIELDS_BEGIN
});
export const fetchFormFieldsSuccess = fields => ({
	type: actionTypes.FETCH_FORM_FIELDS_SUCCESS,
	payload: fields
});
export const fetchFormFieldsFailed = () => ({
	type: actionTypes.FETCH_FORM_FIELDS_FAILED
});
export const fetchFormFields = () => (
	(dispatch) => {
		dispatch(fetchFormFieldsBegin());
		return axios.get(`${process.env.OMZ_ZAPI}/admin/form_fields`, { dispatch })
			.then(({ data }) => {
				const { errorMessage, formFields } = data;
				if (errorMessage) {
					dispatch(fetchFormFieldsFailed(errorMessage));
				} else {
					dispatch(fetchFormFieldsSuccess(formFields));
				}
			}).catch(() => {});
	}
);
