import React, {
	useRef,
	useState,
	useEffect,
	useMemo,
	useCallback
} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { defineMessages, injectIntl } from 'react-intl';
import { v4 as uuidv4 } from 'uuid';

import { useBan } from '../../../context/bannedContent';
import * as actions from '../../../store/actions';
import {
	verifyPermissions,
	useMountEffect,
	usePrevious,
	letSendAttachment
} from '../../../shared/utility';
import ImgSvg from '../../Atoms/ImgSvg';
import Wrapper from '../../Atoms/Wrapper';
import ChatInputText from '../../Molecules/Inputs/ChatText';
import Attachment from '../../Molecules/Attachment';
import Send from '../../Molecules/Send';
import Emoji from '../../Molecules/Emoji';
import Answers from '../../Molecules/Answers';
import Annotations from '../../Molecules/Annotation';
import FileUploadConfirmation from '../../Molecules/FileUploadConfirmation';
import ChooseWhatsappTemplate from '../../Molecules/ChooseWhatsappTemplate';
import WhatsappModalConfirmation from '../../Molecules/WhatsappModalConfirmation';
import Tags from '../Tags';
import ReplyMessage from '../ReplyMessage';
import ChatButton from '../../Molecules/Buttons/ChatButton';
import './index.sass';
import VoiceRecorder from '../VoiceRecorder';

const messages = defineMessages({
	type: {
		id: 'chatInput.type',
		defaultMessage: 'Escreva uma mensagem'
	},
	notes: {
		id: 'chatInput.notes',
		defaultMessage: 'Criar uma nota'
	},
	tags: {
		id: 'chatInput.tags',
		defaultMessage: 'Aplicar tag'
	},
	answers: {
		id: 'chatInput.answers',
		defaultMessage: 'Respostas rapidas'
	},
	recordAudio: {
		id: 'chatInput.recordAudio',
		defaultMessage: 'Gravar áudio'
	},
	replyingInternalMessage: {
		id: 'chatInput.replyingInternalMessage',
		defaultMessage: 'Respondendo uma mensagem interna'
	},
	cancel: {
		id: 'chatInput.cancel',
		defaultMessage: 'Cancelar'
	},
	whatsappCallTooltip: {
		id: 'chatInput.whatsappCall.tooltip',
		defaultMessage: 'Iniciar chamada por voz - via Whatsapp'
	},
	whatsappCallMessageContent: {
		id: 'chatInput.whatsappCall.messageContent',
		defaultMessage: 'O atendimento por voz está disponível, basta iniciar a chamada que iremos te atender.'
	},
	whatsappCallMessageHeader: {
		id: 'chatInput.whatsappCall.messageHeader',
		defaultMessage: 'Atendimento por voz'
	},
	whatsappCallMessageDisplayText: {
		id: 'chatInput.whatsappCall.messageDisplayText',
		defaultMessage: 'Iniciar a chamada'
	},
	profaneWords: {
		id: 'chatInput.whatsappCall.profaneWords',
		defaultMessage: 'Não foi possível enviar sua mensagem porque ela contém palavras inadequadas. Por favor, remova-as e tente novamente.'
	}
});

const blockedStates = ['FINISHING', 'ENDED', 'UNAVAILABLE', 'MISSED', 'REPLYING'];
const blockedMessageTypes = ['PHONE', 'PHONE_CONF'];

const ChatInput = ({
	inputChat,
	onChangeInputText,
	currentInteractionHash,
	onFetchAnswers,
	agent,
	showAnswer,
	onHideAnswer,
	onShowAnswer,
	showWhatsappTemplate,
	onHideWhatsappTemplate,
	onShowWhatsappTemplate,
	onSendMessage,
	onSendAttachment,
	onHideEmoji,
	onClearActions,
	onClearAttachmentMail,
	onShowEmoji,
	showEmoji,
	onAddAttachment,
	onShowTag,
	showTags,
	onHideTag,
	showReplyMessage,
	messageToReply = {},
	onHideReplyMessage,
	onShowAnnotation,
	showAnnotation,
	onHideAnnotation,
	showVoiceRecording,
	onShowVoiceRecording,
	onHideVoiceRecording,
	currentInteraction,
	whatsappVoice,
	intl,
	isHistoryInteraction
}) => {
	const stateValueRef = useRef();
	const [stateValue, setStateValue] = useState('');
	const [answer, setAnswer] = useState({});
	const [isTyping, setIsTyping] = useState(false);
	const [inputHeight, setInputHeight] = useState(90);
	const [autoResize, setAutoResize] = useState(true);
	const [openWhatsappModal, setOpenWhatsappModal] = useState(false);
	const [hasProfanyText, setHasProfanyText] = useState(false);

	const prevInteractionHash = usePrevious(currentInteractionHash);
	const { checkIsNotBanned, hasBadWords } = useBan();
	const {
		attachments = [],
		currentState,
		interactionType,
		rejected = false,
		blocked
	} = currentInteraction;
	const accountId = agent.info.account.id;
	const departmentId = currentInteraction && currentInteraction.department && currentInteraction.department.id;
	const { value = '' } = inputChat;

	useMountEffect(() => {
		if (value !== '') {
			setStateValue(value);
			stateValueRef.current = value;
		}

		return () => {
			if (stateValue !== '') onChangeInputText({ hash: prevInteractionHash, stateValue });
		};
	});

	useEffect(() => {
		if (currentInteractionHash !== prevInteractionHash) {
			onChangeInputText({ hash: prevInteractionHash, value: stateValue });
			setStateValue(value);
			stateValueRef.current = value;
		}
	}, [
		currentInteractionHash,
		value,
		onChangeInputText,
		prevInteractionHash,
		stateValue
	]);

	const onFetchDynamicAnswers = useCallback((inputValue, shouldFetch = false) => {
		if (inputValue === '/' || shouldFetch) {
			onFetchAnswers({
				search: '',
				accountId,
				departmentId,
				interactionHash: currentInteractionHash
			});
		} else {
			setTimeout(() => {
				if (stateValueRef.current === inputValue) {
					onFetchAnswers({
						search: inputValue,
						accountId,
						departmentId,
						interactionHash: currentInteractionHash
					});
				}
			}, 1200);
		}
	}, [accountId, departmentId, currentInteractionHash, onFetchAnswers]);


	const toggleAnswer = useCallback((eventValue) => {
		if (showAnswer) {
			return onHideAnswer(currentInteractionHash);
		}
		setInputHeight(90);
		onFetchDynamicAnswers(eventValue, true);
		onClearActions(currentInteractionHash);
		return onShowAnswer(currentInteractionHash);
	}, [currentInteractionHash, onClearActions, onHideAnswer, onShowAnswer, showAnswer, onFetchDynamicAnswers]);

	useEffect(() => {
		if (stateValue === '' && isTyping && !attachments.length) {
			setIsTyping(false);
			window.omzVish.cleared(currentInteractionHash);
		} else if ((stateValue !== '' || attachments.length) && !isTyping) {
			setIsTyping(true);
			window.omzVish.typing(currentInteractionHash);
		}
	}, [attachments.length, currentInteractionHash, isTyping, stateValue]);

	const onChangeValue = (e) => {
		const eventValue = e.target.value;

		if (hasProfanyText) setHasProfanyText(false);
		if (eventValue[0] === '/' && !showAnswer) toggleAnswer(eventValue);
		if (eventValue === '' && showAnswer) toggleAnswer(eventValue);
		if (showAnswer && eventValue !== '') onFetchDynamicAnswers(eventValue);

		setStateValue(eventValue);
		stateValueRef.current = eventValue;
	};

	const onSelectAnswer = useCallback((data) => {
		setAnswer(data);

		if (data.mediaType === 'file') {
			const attachment = JSON.parse(data.body);
			attachment.name = attachment.filename;
			onAddAttachment({ attachment, interactionHash: currentInteractionHash });
		} else {
			setStateValue(data.body);
		}

		onHideAnswer(currentInteractionHash);
	}, [currentInteractionHash, onHideAnswer, onAddAttachment]);

	const onSelectWhatsapTemplate = useCallback(() => {
		setOpenWhatsappModal(true);
		onHideWhatsappTemplate(currentInteractionHash);
	}, [currentInteractionHash, onHideWhatsappTemplate]);

	const handleCloseWhatsappModal = useCallback(() => {
		setOpenWhatsappModal(false);
	}, []);


	const onClearAttachment = useCallback((name) => {
		onClearAttachmentMail({
			name,
			interactionHash: currentInteractionHash
		});
		setStateValue('');
		setAnswer({});
	}, [currentInteractionHash, onClearAttachmentMail]);

	const onSelectEmoji = useCallback((e) => {
		const emoji = e.native;
		setStateValue(stateValue + emoji);
	}, [stateValue]);

	const onSend = useCallback(() => {
		const { info } = agent;
		const { photo, name } = info;

		const { body, mediaType } = answer;
		const isFile = mediaType === 'file';
		const file = isFile ? JSON.parse(body) : {};

		if (hasBadWords('sendText', stateValue)) return setHasProfanyText(true);

		const mediaInfo = {
			contentType: file.filetype,
			content: file.filename,
			fileName: file.filename,
			url: file.url
		};

		const textInfo = {
			messageId: '',
			contentType: 'text',
			content: stateValue
		};

		const origin = showReplyMessage && messageToReply.message.origin === 'INT_ADMIN' ? 'INT_AGENT' : 'agent';

		const sentMessage = {
			createdAt: new Date(),
			tempId: '',
			origin,
			status: 'sent',
			agentName: name,
			agentPhoto: photo,
			referenceId: showReplyMessage ? messageToReply.message.messageId : '',
			...(isFile ? mediaInfo : textInfo)
		};

		if (stateValue.replace(/\r?\n|\r/g, '') !== '' || answer.id) {
			sentMessage.tempId = window.omzVish.newMessage(
				isFile ? file : stateValue,
				currentInteractionHash,
				isFile ? file.filetype : 'text',
				sentMessage.referenceId,
				origin
			);
			if (origin === 'agent') onSendMessage(sentMessage, currentInteractionHash);
			setStateValue('');
			setAnswer({});
			stateValueRef.current = '';
			onHideEmoji(currentInteractionHash);
			setAutoResize(!autoResize);
			if (isFile) onClearAttachment(file.filename);
			if (showReplyMessage) onHideReplyMessage(currentInteractionHash);
		}

		return true;
	}, [
		autoResize,
		agent,
		currentInteractionHash,
		onHideEmoji,
		onSendMessage,
		stateValue,
		answer,
		onClearAttachment,
		showReplyMessage,
		messageToReply,
		onHideReplyMessage,
		hasBadWords
	]);

	const onSendVoiceRecording = useCallback((blob) => {
		const formData = new FormData();
		const { size, type } = blob;
		const { approved } = letSendAttachment(size, type, interactionType);
		const now = String(Date.now());
		const filename = `audio_${uuidv4()}.mp3`;

		formData.append('files[]', blob, filename);
		if (approved) {
			onSendAttachment({
				formData,
				agent,
				currentInteractionHash,
				fileInfo: { now, size, type },
				referenceId: showReplyMessage ? messageToReply.message.messageId : ''
			});
			if (showReplyMessage) onHideReplyMessage(currentInteractionHash);
		}
	}, [agent, interactionType, currentInteractionHash, onSendAttachment, showReplyMessage, messageToReply, onHideReplyMessage]);

	const toggleEmoji = useCallback(() => {
		if (showEmoji) return onHideEmoji(currentInteractionHash);
		onClearActions(currentInteractionHash);
		return onShowEmoji(currentInteractionHash);
	}, [currentInteractionHash, onClearActions, onHideEmoji, onShowEmoji, showEmoji]);

	const toggleTags = useCallback(() => {
		if (showTags) return onHideTag(currentInteractionHash);
		setInputHeight(90);
		onClearActions(currentInteractionHash);
		return onShowTag(currentInteractionHash);
	}, [currentInteractionHash, onClearActions, onHideTag, onShowTag, showTags]);

	const toggleAnnotation = useCallback(() => {
		if (showAnnotation) return onHideAnnotation(currentInteractionHash);
		onClearActions(currentInteractionHash);
		return onShowAnnotation(currentInteractionHash);
	}, [currentInteractionHash, onClearActions, onHideAnnotation, onShowAnnotation, showAnnotation]);

	const toggleVoiceRecording = useCallback(() => {
		if (showVoiceRecording) return onHideVoiceRecording(currentInteractionHash);
		onClearActions(currentInteractionHash);
		return onShowVoiceRecording(currentInteractionHash);
	}, [currentInteractionHash, onClearActions, onHideVoiceRecording, onShowVoiceRecording, showVoiceRecording]);

	const toggleWhatsappTemplate = useCallback(() => {
		if (showWhatsappTemplate) return onHideWhatsappTemplate(currentInteractionHash);
		onClearActions(currentInteractionHash);
		return onShowWhatsappTemplate(currentInteractionHash);
	}, [currentInteractionHash, onClearActions, onHideWhatsappTemplate, onShowWhatsappTemplate, showWhatsappTemplate]);

	const handleConfirm = () => {
		const header = intl.formatMessage(messages.whatsappCallMessageHeader);
		const content = intl.formatMessage(messages.whatsappCallMessageContent);
		const displayText = intl.formatMessage(messages.whatsappCallMessageDisplayText);
		const interactionHash = currentInteractionHash;
		window.omzVish.newWhatsappVoiceCall(header, content, displayText, interactionHash);
		handleCloseWhatsappModal();
	};

	const onType = ({ which, shiftKey }) => {
		if (which === 13 && !shiftKey) onSend();
	};

	const handleContainerHeight = (e) => {
		const maxHeight = window.innerHeight - 280;

		if (window.innerHeight - e.clientY < 600 && window.innerHeight - e.clientY < maxHeight) {
			setInputHeight(window.innerHeight - e.clientY - 20);
		}
	};

	const handleDragMouseUp = () => {
		document.removeEventListener('mousemove', handleContainerHeight);
	};

	const handleDragMouseDown = () => {
		const enableResize = !showReplyMessage;
		if (enableResize) {
			document.addEventListener('mousemove', handleContainerHeight);
			document.addEventListener('mouseup', handleDragMouseUp, { once: true });
		}
	};

	useEffect(() => {
		const handleWindowResize = () => {
			setInputHeight(90);
		};

		window.addEventListener('resize', handleWindowResize);

		return () => {
			window.removeEventListener('resize', handleWindowResize);
		};
	});

	const cannotReply = currentInteraction.agent && agent ? (currentInteraction.agent.id !== agent.info.id) && ['PENDING', 'POSTPONED'].includes(currentState) : false;
	const blockedToSendMessage = blockedStates.includes(currentState) || blocked || rejected || blockedMessageTypes.includes(interactionType) || cannotReply;
	const permissions = agent && agent.info ? agent.info.permissions : [];
	const canSendAttachment = verifyPermissions(permissions, 'sendAttachment');
	const canSendVoiceRecordings = checkIsNotBanned('sendAudio') && interactionType && interactionType.toLowerCase() === 'whatsapp';
	const showSeparator = !blockedToSendMessage && (checkIsNotBanned('sendEmoji') && checkIsNotBanned('sendAudio') && checkIsNotBanned('sendDocument'));
	const { blockRemoveTag } = agent;
	const countTags = useMemo(() => currentInteraction.interactionTags && currentInteraction.interactionTags.length, [currentInteraction.interactionTags]);
	const dynamicIcons = (
		<>
			{canSendVoiceRecordings && (
				<ChatButton
					ariaLabel={intl.formatMessage(messages.recordAudio)}
					name="microphone-off"
					disabled={blockedToSendMessage}
					click={toggleVoiceRecording}
				/>
			)}
			{canSendAttachment && (
				<Attachment
					disabled={blockedToSendMessage}
					interactionType={interactionType}
				/>
			)}
			{showSeparator && <span className="separator" />}
			<ChatButton
				ariaLabel={intl.formatMessage(messages.notes)}
				name="notes-off"
				click={toggleAnnotation}
				filled={(currentInteraction.note && currentInteraction.note !== '') || false}
				type="note"
			/>
			<ChatButton
				ariaLabel={intl.formatMessage(messages.tags)}
				name="tags-off"
				click={toggleTags}
				type="tags"
				count={countTags}
			/>
			<ChatButton
				ariaLabel={intl.formatMessage(messages.answers)}
				name="saved-off"
				disabled={blockedToSendMessage}
				click={toggleAnswer}
			/>
			{whatsappVoice && (
				<ChatButton
					ariaLabel={intl.formatMessage(messages.whatsappCallTooltip)}
					name="whatsapp-off"
					click={toggleWhatsappTemplate}
					type="note"
				/>
			)}
		</>
	);

	return (
		<>
			{hasProfanyText && (
				<div className="profani-message alert is-danger">
					<div className="alert-content">
						<span className="icon is-medium">
							<i className="fa-regular fa-circle-minus" />
						</span>
						<p>{intl.formatMessage(messages.profaneWords)}</p>
					</div>
				</div>
			)}

			{showReplyMessage && !rejected && (
				<ReplyMessage selectedMessage={messageToReply.message} />
			)}
			{showTags && !rejected && (
				<Tags
					onClose={toggleTags}
					onlyShowValue={isHistoryInteraction}
					blockRemoveTag={blockRemoveTag}
				/>
			)}
			{showAnnotation && !rejected && (
				<Annotations
					onClose={toggleAnnotation}
					onlyShowValue={isHistoryInteraction}
				/>
			)}
			{showEmoji && (
				<div className="ChatInput__wrapper__emojiPicker">
					<Emoji onSelectEmoji={onSelectEmoji} onClose={toggleEmoji} />
				</div>
			)}
			{showAnswer && !rejected && (
				<Answers
					onSelectAnswer={onSelectAnswer}
					onClose={toggleAnswer}
					currentInteraction={currentInteraction}
				/>
			)}
			{showWhatsappTemplate && !rejected && (
				<ChooseWhatsappTemplate
					onSelectWhatsapTemplate={onSelectWhatsapTemplate}
					onClose={toggleWhatsappTemplate}
					currentInteraction={currentInteraction}
				/>
			)}

			<div style={{ minHeight: inputHeight }} className="ChatInput">
				<div
					className={
						`ChatInput__wrapper ${messageToReply.message && messageToReply.message.origin === 'INT_ADMIN' ? 'ChatInput__wrapper--internal-message' : ''} ${hasProfanyText ? 'ChatInput__wrapper--hasProfanyText' : ''}`
					}
				>
					{showVoiceRecording
						? (
							<>
								<div className="ChatInput__wrapper__voice-recorder">
									<VoiceRecorder onSend={onSendVoiceRecording} maxTime={120} />
									<ChatButton
										name="cross"
										click={toggleVoiceRecording}
										ariaLabel="Fechar gravação"
									/>
								</div>
							</>
						)
						: (
							<>
								<div className="ChatInput__wrapper__header">
									{' '}
									<ImgSvg name="lock" />
									{intl.formatMessage(messages.replyingInternalMessage)}
								</div>
								<div style={{ height: '100%' }}>
									<div role="presentation" className={`Resize ${showReplyMessage ? 'Resize--disable' : ''}`} onMouseDown={handleDragMouseDown}>
										<span className="Resize__dot" />
										<span className="Resize__dot" />
										<span className="Resize__dot" />
									</div>
									<div className="ChatInput__wrapper__input">
										<ChatInputText
											onChange={onChangeValue}
											onType={onType}
											placeholder={intl.formatMessage(messages.type)}
											disabled={blockedToSendMessage}
											value={stateValue}
											maxHeight={inputHeight}
											autoResize={autoResize}
										/>
										{!!attachments.length && (
											<FileUploadConfirmation
												file={attachments[0]}
												onClose={() => onClearAttachment(attachments[0].filename)}
												onSend={onSend}
											/>
										)}
									</div>
								</div>
								<div className="ChatInput__wrapper__actions">
									<div className="ChatInput__wrapper__actions__icons">
										{messageToReply.message && messageToReply.message.origin === 'INT_ADMIN' ? (
											<Wrapper className="ChatInput__wrapper__actions__icons__cancel" action={() => onHideReplyMessage(currentInteractionHash)}>{intl.formatMessage(messages.cancel)}</Wrapper>
										) : (
											<>
												{checkIsNotBanned('sendEmoji') && (
													<ChatButton
														ariaLabel="Emoji"
														name="emoji-off"
														disabled={blockedToSendMessage}
														click={toggleEmoji}
														className="ChatInput__actions__emoji"
													/>
												)}
												{dynamicIcons}
											</>
										)}
									</div>
									<Send disabled={hasProfanyText || !isTyping} onSend={onSend} />
								</div>
							</>
						)}
				</div>
			</div>
			{openWhatsappModal && <WhatsappModalConfirmation isOpen={openWhatsappModal} onClose={handleCloseWhatsappModal} onConfirm={handleConfirm} />}
		</>
	);
};

ChatInput.propTypes = {
	currentInteractionHash: PropTypes.string.isRequired,
	currentInteraction: PropTypes.shape({
		id: PropTypes.number,
		currentState: PropTypes.string,
		blocked: PropTypes.bool,
		interactionType: PropTypes.string,
		rejected: PropTypes.bool,
		note: PropTypes.shape({
			body: PropTypes.string,
			url: PropTypes.string,
			s3: PropTypes.shape({
				key: PropTypes.string,
				bucket: PropTypes.string,
				filename: PropTypes.string
			})
		}),
		attachments: PropTypes.arrayOf(PropTypes.shape({
			name: PropTypes.string,
			url: PropTypes.string
		})),
		interactionTags: PropTypes.arrayOf(PropTypes.shape({})),
		department: PropTypes.shape({
			id: PropTypes.number
		}),
		agent: PropTypes.shape({
			id: PropTypes.number
		})
	}).isRequired,
	agent: PropTypes.shape({
		info: PropTypes.shape({
			id: PropTypes.number,
			account: PropTypes.shape({
				id: PropTypes.number
			}),
			photo: PropTypes.string,
			name: PropTypes.string,
			permissions: PropTypes.arrayOf(PropTypes.string)
		}),
		blockRemoveTag: PropTypes.bool
	}).isRequired,
	onSendMessage: PropTypes.func.isRequired,
	onSendAttachment: PropTypes.func.isRequired,
	onShowAnswer: PropTypes.func.isRequired,
	onHideAnswer: PropTypes.func.isRequired,
	showAnswer: PropTypes.bool.isRequired,
	showWhatsappTemplate: PropTypes.bool.isRequired,
	onHideWhatsappTemplate: PropTypes.func.isRequired,
	onShowWhatsappTemplate: PropTypes.func.isRequired,
	onShowEmoji: PropTypes.func.isRequired,
	onHideEmoji: PropTypes.func.isRequired,
	onAddAttachment: PropTypes.func.isRequired,
	showEmoji: PropTypes.bool.isRequired,
	onClearAttachmentMail: PropTypes.func.isRequired,
	onShowAnnotation: PropTypes.func.isRequired,
	onHideAnnotation: PropTypes.func.isRequired,
	showAnnotation: PropTypes.bool.isRequired,
	onShowVoiceRecording: PropTypes.func.isRequired,
	onHideVoiceRecording: PropTypes.func.isRequired,
	showVoiceRecording: PropTypes.bool.isRequired,
	whatsappVoice: PropTypes.bool.isRequired,
	onShowTag: PropTypes.func.isRequired,
	onHideTag: PropTypes.func.isRequired,
	showTags: PropTypes.bool.isRequired,
	messageToReply: PropTypes.shape({
		interactionHash: PropTypes.string,
		message: PropTypes.shape({
			interactionType: PropTypes.string,
			contentType: PropTypes.string,
			isFromAgent: PropTypes.bool,
			agentPhoto: PropTypes.string,
			agentName: PropTypes.string,
			content: PropTypes.string,
			externalId: PropTypes.string,
			messageId: PropTypes.number,
			origin: PropTypes.string
		})
	}),
	onHideReplyMessage: PropTypes.func.isRequired,
	showReplyMessage: PropTypes.bool.isRequired,
	onClearActions: PropTypes.func.isRequired,
	onChangeInputText: PropTypes.func.isRequired,
	inputChat: PropTypes.shape({
		value: PropTypes.string
	}).isRequired,
	onFetchAnswers: PropTypes.func.isRequired,
	intl: PropTypes.shape({
		formatMessage: PropTypes.func.isRequired
	}).isRequired,
	isHistoryInteraction: PropTypes.bool
};

const MapActionsToProps = dispatch => ({
	onSendMessage: (sentMessage, interactionHash) => dispatch(actions.onSendMessage(
		sentMessage, interactionHash
	)),
	onSendAttachment: info => dispatch(actions.sendAttachment(info)),
	onClearAttachmentMail: nameAndHash => dispatch(actions.clearEmailAttachment(nameAndHash)),
	onShowAnswer: hash => dispatch(actions.showAnswer(hash)),
	onHideAnswer: hash => dispatch(actions.hideAnswer(hash)),
	onShowWhatsappTemplate: hash => dispatch(actions.showWhatsappTemplate(hash)),
	onHideWhatsappTemplate: hash => dispatch(actions.hideWhatsappTemplate(hash)),
	onShowEmoji: hash => dispatch(actions.showEmoji(hash)),
	onHideEmoji: hash => dispatch(actions.hideEmoji(hash)),
	onShowAnnotation: hash => dispatch(actions.showAnnotation(hash)),
	onHideAnnotation: hash => dispatch(actions.hideAnnotation(hash)),
	onShowTag: hash => dispatch(actions.showTag(hash)),
	onHideTag: hash => dispatch(actions.hideTag(hash)),
	onHideReplyMessage: hash => dispatch(actions.hideReplyMessage(hash)),
	onAddAttachment: nameAndHash => dispatch(actions.addAttachment(nameAndHash)),
	onShowVoiceRecording: hash => dispatch(actions.showVoiceRecording(hash)),
	onHideVoiceRecording: hash => dispatch(actions.hideVoiceRecording(hash)),
	onClearActions: hash => dispatch(actions.clearActions(hash)),
	onChangeInputText: inputChat => dispatch(actions.changeInputText(inputChat)),
	onFetchAnswers: answerInfo => dispatch(actions.fetchAnswers(answerInfo))
});

const MapStateToProps = (state) => {
	const { agent, interaction } = state;
	const {
		currentInteractionHash,
		historyInteractions,
		interactions,
		missedInteractions,
		pendingInteractions
	} = interaction;
	const allInteractions = interactions.concat(pendingInteractions).concat(historyInteractions).concat(missedInteractions);

	return ({
		agent,
		currentInteraction: allInteractions.find(({ interactionHash }) => interactionHash === state.interaction.currentInteractionHash) || {},
		currentInteractionHash,
		showAnswer: state.interface.showingAnswer.some(hash => hash === currentInteractionHash),
		showWhatsappTemplate: state.interface.showingWhatsappTemplate.some(hash => hash === currentInteractionHash),
		showEmoji: state.interface.showingEmoji.some(hash => hash === currentInteractionHash),
		showAnnotation: state.interface.showingAnnotation.some(hash => hash === currentInteractionHash),
		showTags: state.interface.showingTags.some(hash => hash === currentInteractionHash),
		showReplyMessage: state.interface.showingReplyMessage.some(item => item.hash === currentInteractionHash),
		messageToReply: state.interface.showingReplyMessage.find(item => item.hash === currentInteractionHash),
		showVoiceRecording: state.interface.showingVoiceRecording.some(hash => hash === currentInteractionHash),
		inputChat: state.interface.inputChat.find(({ hash }) => hash === currentInteractionHash) || { value: '' },
		whatsappVoice: agent.whatsappVoice
	});
};

export default connect(MapStateToProps, MapActionsToProps)(injectIntl(ChatInput));
