import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { defineMessages, injectIntl } from 'react-intl';

import './index.sass';
import ClientSearchItemField from '../ClientSearchItemField';
import { timeInfoFormat1 } from '../../../shared/utility';
import Button from '../Buttons/Button';

const messages = defineMessages({
	firstContact: {
		id: 'clientSearchItem.firstContact',
		defaultMessage: 'Primeiro contato'
	},
	conversationTitle: {
		id: 'clientSearchItem.conversationTitle',
		defaultMessage: 'Atendimentos'
	},
	conversations: {
		id: 'clientSearchItem.conversations',
		defaultMessage: '{itemCount, plural, one {atendimento} other {atendimentos}}'
	},
	change: {
		id: 'clientSearchItem.change',
		defaultMessage: 'trocar'
	},
	merge: {
		id: 'clientSearchItem.merge',
		defaultMessage: 'agrupar'
	}
});

const fieldIdentifiers = ['main_identifier', 'channel_email', 'document'];

const ClientSearchItem = ({
	customer,
	onUpdateCustomer,
	intl,
	onMergeCustomer
}) => {
	const { formatMessage } = intl;
	const {
		historyCount,
		firstContact,
		fields,
		customerKey
	} = customer;

	const [firstFixedFields, setFirstFixedFields] = useState([]);
	const [otherFields, setOtherFields] = useState([]);
	useEffect(() => {
		const currentfirstFixedFields = [];

		fieldIdentifiers.forEach((identifier) => {
			const field = fields.find(({ name }) => name === identifier);
			if (field) currentfirstFixedFields.push(field);
		});

		setFirstFixedFields(currentfirstFixedFields);
		setOtherFields(fields.filter(({ name }) => !fieldIdentifiers.includes(name)));
	}, [fields]);

	return (
		<div className="ClientSearchItem">
			<div className="ClientSearch__info">
				{firstFixedFields.concat(otherFields).map(({ label, value }) => (
					<ClientSearchItemField
						key={label}
						label={label}
						value={value || ''}
					/>
				))}
				<div className="ClientSearch__info__additional">
					<ClientSearchItemField
						label={formatMessage(messages.firstContact)}
						value={timeInfoFormat1(firstContact)}
					/>
					<ClientSearchItemField
						label={formatMessage(messages.conversationTitle)}
						value={`${historyCount} ${formatMessage(messages.conversations, { itemCount: historyCount })}`}
					/>
				</div>
			</div>
			<div className="ClientSearch__actions">
				<Button click={() => onMergeCustomer(customerKey, fields)}>
					{formatMessage(messages.merge)}
				</Button>
				<Button click={() => onUpdateCustomer(customerKey, historyCount)}>
					{formatMessage(messages.change)}
				</Button>
			</div>
		</div>
	);
};

ClientSearchItem.propTypes = {
	onUpdateCustomer: PropTypes.func.isRequired,
	onMergeCustomer: PropTypes.func.isRequired,
	customer: PropTypes.shape({
		id: PropTypes.number,
		historyCount: PropTypes.number,
		firstContact: PropTypes.string,
		customerKey: PropTypes.string,
		fields: PropTypes.arrayOf(
			PropTypes.shape({
				name: PropTypes.string,
				value: PropTypes.oneOfType([
					PropTypes.string,
					PropTypes.arrayOf(PropTypes.string)
				])
			})
		)
	}).isRequired,
	intl: PropTypes.shape({
		formatMessage: PropTypes.func.isRequired
	}).isRequired
};

export default injectIntl(ClientSearchItem);
