import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { defineMessages, injectIntl } from 'react-intl';

import './index.sass';
import * as actions from '../../../store/actions';
import ActionsHeader from '../ActionsHeader';
import { useMountEffect } from '../../../shared/utility';
import Wrapper from '../../Atoms/Wrapper';
import Text from '../../Atoms/Text';
import Backdrop from '../Backdrop';
import InfiniteList from '../InfiniteList';
import Spinner from '../../Atoms/Spinner';
import ImgSvg from '../../Atoms/ImgSvg';

const messages = defineMessages({
	title: {
		id: 'answers.title',
		defaultMessage: 'Respostas prontas'
	}
});

const Answers = ({
	onSelectAnswer,
	intl,
	agent,
	onClose,
	fetchAnswers,
	answersStatus,
	currentInteraction,
	showShortcutWithHtml
}) => {
	const { loading, ended, page } = answersStatus;
	const { info } = agent;
	const { answers, interactionHash } = currentInteraction;

	useMountEffect(() => {
		fetchAnswers({
			accountId: info.account.id,
			departmentId: currentInteraction && currentInteraction.department && currentInteraction.department.id,
			interactionHash
		});
	});

	const loadMoreShortcuts = useCallback(() => {
		if (!ended && !loading) {
			fetchAnswers({
				accountId: info.account.id,
				departmentId: currentInteraction && currentInteraction.department && currentInteraction.department.id,
				interactionHash,
				page
			});
		}
	}, [ended, fetchAnswers, info.account.id, loading, page, currentInteraction, interactionHash]);

	useEffect(() => {
		document.addEventListener('keyup', (e) => {
			if (e.which === 27) onClose();
		});
	}, [onClose]);

	const shortcutItem = (shortcut) => {
		const { mediaType, keyword, body } = shortcut;

		switch (mediaType) {
		case 'file':
			return (
				<>
					<Text>{keyword}</Text>
					<Text>
						{JSON.parse(body).filename}
						<ImgSvg name="attach-off" />
					</Text>
				</>
			);
		default:
			return (
				<>
					<Text>{keyword}</Text>
					<Text>{body}</Text>
				</>
			);
		}
	};

	return (
		<>
			<div className="Answers">
				<ActionsHeader
					title={intl.formatMessage(messages.title)}
					type="answer"
					validAction
				/>
				<InfiniteList customClass="Answers__answers" onBottom={loadMoreShortcuts}>
					{answers && answers.filter(shortcut => (showShortcutWithHtml ? shortcut : !shortcut.ishtml)).map(answer => (
						<Wrapper
							className="Answers__item"
							action={() => onSelectAnswer(answer)}
							key={answer.id}
						>
							{shortcutItem(answer)}
						</Wrapper>
					))}
					<div className="Answers__loader">
						{loading && <Spinner />}
					</div>
				</InfiniteList>
			</div>
			<Backdrop closeAction={onClose} clear higher />
		</>
	);
};

const mapStateToProps = state => ({
	agent: state.agent,
	answersStatus: state.interaction.answersStatus
});

const mapActionsToProps = dispatch => ({
	fetchAnswers: requestInfo => dispatch(actions.fetchAnswers(requestInfo))
});

Answers.propTypes = {
	onSelectAnswer: PropTypes.func.isRequired,
	intl: PropTypes.shape({
		formatMessage: PropTypes.func.isRequired
	}).isRequired,
	agent: PropTypes.shape({
		info: PropTypes.shape({
			account: PropTypes.shape({
				id: PropTypes.number
			})
		})
	}).isRequired,
	showShortcutWithHtml: PropTypes.bool,
	onClose: PropTypes.func.isRequired,
	fetchAnswers: PropTypes.func.isRequired,
	answersStatus: PropTypes.shape({
		page: PropTypes.number,
		loading: PropTypes.bool,
		ended: PropTypes.bool
	}).isRequired,
	currentInteraction: PropTypes.shape({
		answers: PropTypes.arrayOf(PropTypes.shape({
			id: PropTypes.number,
			body: PropTypes.string,
			key: PropTypes.string
		})).isRequired,
		interactionHash: PropTypes.string,
		department: PropTypes.shape({
			id: PropTypes.number
		})
	}).isRequired
};


export default connect(mapStateToProps, mapActionsToProps)(injectIntl(Answers));
