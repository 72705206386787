import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import './index.sass';
import LoaderBlack from '../../../assets/images/gif/loader_black.gif';
import LoaderWhite from '../../../assets/images/gif/loader_white.gif';

const Loader = ({ size = 'medium', fill = 'black' }) => {
	const [classes, setClasses] = useState('');
	useEffect(() => {
		const currentClasses = ['Loader__img'];

		if (size === 'small') currentClasses.push(' Loader__img--small');
		setClasses(currentClasses.join(' '));
	}, [size]);

	let loader = LoaderBlack;
	if (fill === 'white') loader = LoaderWhite;

	return (
		<div className="Loader">
			<img className={classes} src={loader} alt="loader" />
		</div>
	);
};

Loader.propTypes = {
	size: PropTypes.oneOf(['small', 'medium']),
	fill: PropTypes.oneOf(['white', 'black'])
};

export default Loader;
