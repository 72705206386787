import React from 'react';
import { MESSAGE_CONTENT_TYPE } from '../../../../shared/consts';
import { useBan } from '../../../../context/bannedContent';

import MessageAttachment from '../MessageAttachment';
import MessageText from '../MessageText';
import MessageState from '../MessageState';
import MessageImage from '../MessageImage';
import MessageAudio from '../MessageAudio';
import MessageVideo from '../MessageVideo';
import MessageLocation from '../MessageLocation';
import MessageInternal from '../MessageInternal';
import ReferencedMessage from '../ReferencedMessage';
import MessageBanned from '../MessageBanned';
import { isFileMessage } from '../../../Sirius/AttachmentModal/Helper';

const getVerifiedContentType = (contentType) => {
	let result = contentType;

	if (result.match(MESSAGE_CONTENT_TYPE.IMAGE)) {
		result = MESSAGE_CONTENT_TYPE.IMAGE;
	} else if (result.match(MESSAGE_CONTENT_TYPE.AUDIO)) {
		result = MESSAGE_CONTENT_TYPE.AUDIO;
	} else if (result.match(MESSAGE_CONTENT_TYPE.VIDEO)) {
		result = MESSAGE_CONTENT_TYPE.VIDEO;
	}

	return result;
};

const getComponentByType = (messageInfo, children = null) => {
	const { checkIsBanned } = useBan();
	const {
		contentType,
		isInternalHistory,
		isFromInternalAdmin,
		isFromInternalAgent,
		agentName
	} = messageInfo;

	const verifiedContentType = getVerifiedContentType(contentType);
	const props = { messageInfo, children };
	const isClientMessage = messageInfo.origin === 'CLIENT';

	if (isInternalHistory) {
		return (
			<MessageText {...props}>
				<div><b>{agentName}</b></div>
			</MessageText>
		);
	}

	if (isFromInternalAdmin || isFromInternalAgent) {
		return <MessageInternal {...props} />;
	}

	const contentFilters = {
		[MESSAGE_CONTENT_TYPE.IMAGE]: 'receivedImage',
		[MESSAGE_CONTENT_TYPE.AUDIO]: 'receivedAudio',
		[MESSAGE_CONTENT_TYPE.VIDEO]: 'receivedVideo',
		[MESSAGE_CONTENT_TYPE.DOCUMENT]: 'receivedDocument'
	};

	const documentType = isFileMessage(contentType) && contentType !== MESSAGE_CONTENT_TYPE.STATE ? MESSAGE_CONTENT_TYPE.DOCUMENT : '';
	const targetKey = documentType || verifiedContentType;

	if (
		isClientMessage
		&& contentFilters[targetKey]
		&& checkIsBanned(contentFilters[targetKey])
	) {
		return <MessageBanned type={contentFilters[targetKey]} {...props} />;
	}

	switch (verifiedContentType) {
	case MESSAGE_CONTENT_TYPE.TEXT:
	case MESSAGE_CONTENT_TYPE.EMAIL:
		return <MessageText {...props} />;
	case MESSAGE_CONTENT_TYPE.STATE:
		return <MessageState {...props} />;
	case MESSAGE_CONTENT_TYPE.IMAGE:
		return <MessageImage {...props} />;
	case MESSAGE_CONTENT_TYPE.AUDIO:
		return <MessageAudio {...props} />;
	case MESSAGE_CONTENT_TYPE.VIDEO:
		return <MessageVideo {...props} />;
	case MESSAGE_CONTENT_TYPE.LOCATION:
		return <MessageLocation {...props} />;
	case MESSAGE_CONTENT_TYPE.DOCUMENT:
	default:
		return <MessageAttachment {...props} />;
	}
};

const getReferencedMessageWrapped = messageInfo => (
	<ReferencedMessage messageInfo={messageInfo}>
		{getComponentByType(messageInfo)}
	</ReferencedMessage>
);

const getMessageComponent = (messageInfo) => {
	// Obtain referenced message if it exists.
	const hasReferencedMessage = messageInfo && messageInfo.referencedMessage && !messageInfo.isInternalHistory;
	const referencedMessageComponent = (
		hasReferencedMessage ? getReferencedMessageWrapped(messageInfo.referencedMessage) : null
	);

	return getComponentByType(messageInfo, referencedMessageComponent);
};

export default getMessageComponent;
