export default [
	{
		isValid: false,
		label: 'taskName',
		name: 'taskName',
		placeholder: 'taskNamePlaceholder',
		validation: {
			isRequired: true
		},
		validationMessage: '',
		value: '',
		wasTouched: false,
		hiddenble: false,
		defaultField: true
	},
	{
		isValid: false,
		label: 'taskDescription',
		name: 'taskDescription',
		placeholder: 'taskDescriptionPlaceholder',
		type: 'textarea',
		validation: {
			isRequired: false
		},
		validationMessage: '',
		value: '',
		wasTouched: false,
		hiddenble: false,
		defaultField: true
	},
	{
		isValid: false,
		label: 'taskCustomer',
		name: 'taskCustomer',
		validation: {
			isRequired: false
		},
		validationMessage: '',
		value: '',
		wasTouched: false,
		hiddenble: false,
		defaultField: true
	},
	{
		isValid: false,
		label: 'taskDate',
		name: 'taskDate',
		placeholder: 'taskDatePlaceholder',
		validation: {
			isRequired: true
		},
		validationMessage: '',
		value: '',
		wasTouched: false,
		hiddenble: false,
		mask: '99/99/9999',
		defaultField: true
	},
	{
		isValid: false,
		label: 'taskTime',
		name: 'taskTime',
		placeholder: 'taskTimePlaceholder',
		validation: {
			isRequired: true
		},
		validationMessage: '',
		value: '',
		wasTouched: false,
		hiddenble: false,
		mask: '99:99',
		defaultField: true
	},
	{
		isValid: false,
		label: 'Objetivo do contato',
		name: 'taskContactReason',
		type: 'select',
		validation: {
			isRequired: true
		},
		validationMessage: '',
		value: '',
		wasTouched: false,
		items: [
			{
				id: 0,
				label: 'Selecione...',
				value: ''
			},
			{
				id: 1,
				label: 'Programação',
				value: 'PROGRAMAÇÃO'
			},
			{
				id: 2,
				label: 'Intraday - encaixe',
				value: 'INTRADAY - ENCAIXE'
			},
			{
				id: 3,
				label: 'Reversão de tratativa',
				value: 'REVERSÃO DE TRATATIVA'
			},
			{
				id: 4,
				label: 'Confirmar agendamento',
				value: 'CONFIRMAR AGENDAMENTO'
			},
			{
				id: 5,
				label: 'Reagendamento',
				value: 'REAGENDAMENTO'
			},
			{
				id: 6,
				label: 'Auditoria',
				value: 'AUDITORIA'
			}
		],
		hiddenble: false,
		extras: true
	},
	{
		isValid: false,
		label: 'Contato realizado?',
		name: 'taskContactMade',
		type: 'select',
		validation: {
			isRequired: true
		},
		validationMessage: '',
		value: '',
		wasTouched: false,
		items: [
			{
				id: 0,
				label: 'Selecione...',
				value: ''
			},
			{
				id: 1,
				label: 'Sim',
				value: 'SIM'
			},
			{
				id: 2,
				label: 'Não',
				value: 'NÃO'
			}
		],
		hiddenble: false,
		extras: true,
		matching: {
			SIM: 'taskStatus'
		}
	},
	{
		isValid: false,
		label: 'Status da medida',
		name: 'taskStatus',
		type: 'select',
		validation: {
			isRequired: true
		},
		validationMessage: '',
		value: '',
		wasTouched: false,
		items: [
			{
				id: 0,
				label: 'Selecione...',
				value: ''
			},
			{
				id: 1,
				label: 'Agendado',
				value: 'AGENDADO'
			},
			{
				id: 2,
				label: 'Contato futuro',
				value: 'CONTATO FUTURO'
			},
			{
				id: 3,
				label: 'GLP',
				value: 'GLP'
			},
			{
				id: 4,
				label: 'Infra cliente',
				value: 'INFRA CLIENTE'
			},
			{
				id: 5,
				label: 'Programar',
				value: 'PROGRAMAR'
			},
			{
				id: 6,
				label: 'Tratativa comercial',
				value: 'TRATATIVA COMERCIAL'
			},
			{
				id: 7,
				label: 'Tratativa suporte',
				value: 'TRATATIVA SUPORTE'
			},
			{
				id: 8,
				label: 'Tratativa técnica',
				value: 'TRATATIVA TÉCNICA'
			}
		],
		hidden: true,
		hiddenble: true,
		matching: {
			AGENDADO: 'taskScheduleDate',
			'CONTATO FUTURO': 'taskFutureContractSpecification',
			'TRATATIVA COMERCIAL': 'taskComercialNegotiation',
			'TRATATIVA TÉCNICA': 'taskTechnicalNegotiation',
			'TRATATIVA SUPORTE': 'taskSupportNegotiation',
			GLP: 'taskContactDate',
			'INFRA CLIENTE': 'taskContactDate'
		},
		extras: true
	},
	{
		isValid: false,
		label: 'Especificação contato futuro',
		name: 'taskFutureContractSpecification',
		type: 'select',
		validation: {
			isRequired: true
		},
		validationMessage: '',
		value: '',
		wasTouched: false,
		items: [
			{
				id: 0,
				label: 'Selecione...',
				value: ''
			},
			{
				id: 1,
				label: 'Problemas de saúde',
				value: 'PROBLEMAS DE SAÚDE'
			},
			{
				id: 2,
				label: 'Problemas financeiros',
				value: 'PROBLEMAS FINANCEIROS'
			},
			{
				id: 3,
				label: 'Viagem',
				value: 'VIAGEM'
			},
			{
				id: 4,
				label: 'Reforma',
				value: 'REFORMA'
			},
			{
				id: 5,
				label: 'Aguardando conversão da equipe por terceiros',
				value: 'AGUARDANDO CONVERSÃO DA EQUIPE POR TERCEIROS'
			},
			{
				id: 6,
				label: 'Motivos pessoais',
				value: 'MOTIVOS PESSOAIS'
			}
		],
		hidden: true,
		hiddenble: true,
		matching: {
			'PROBLEMAS DE SAÚDE': 'taskContactDate',
			'PROBLEMAS FINANCEIROS': 'taskContactDate',
			VIAGEM: 'taskContactDate',
			REFORMA: 'taskContactDate',
			'AGUARDANDO CONVERSÃO DA EQUIPE POR TERCEIROS': 'taskContactDate',
			'MOTIVOS PESSOAIS': 'taskContactDate'
		},
		extras: true
	},
	{
		isValid: false,
		label: 'Tratativa comercial',
		name: 'taskComercialNegotiation',
		type: 'select',
		validation: {
			isRequired: true
		},
		validationMessage: '',
		value: '',
		wasTouched: false,
		items: [
			{
				id: 0,
				label: 'Selecione',
				value: ''
			},
			{
				id: 1,
				label: 'Necessidade de revisão de orçamento/inviabilidade técnica',
				value: 'NECESSIDADE DE REVISÃO DE ORÇAMENTO/INVIABILIDADE TÉCNICA'
			},
			{
				id: 2,
				label: 'Cliente se recusa a agendar/definir contato futuro',
				value: 'CLIENTE SE RECUSA A AGENDAR/DEFINIR CONTATO FUTURO'
			},
			{
				id: 3,
				label: 'Cliente sem projeto de rede aprovado',
				value: 'CLIENTE SEM PROJETO DE REDE APROVADO'
			},
			{
				id: 4,
				label: 'Cliente com dúvidas de contrato',
				value: 'CLIENTE COM DÚVIDAS DE CONTRATO'
			},
			{
				id: 5,
				label: 'Contato sem sucesso',
				value: 'CONTATO SEM SUCESSO'
			},
			{
				id: 6,
				label: 'Imóvel a venda/alugado',
				value: 'IMÓVEL A VENDA/ALUGADO'
			},
			{
				id: 7,
				label: 'Necessidade de revisão de orçamento/cliente',
				value: 'NECESSIDADE DE REVISÃO DE ORÇAMENTO/CLIENTE'
			},
			{
				id: 8,
				label: 'Telefone incorreto ou inexistente',
				value: 'TELEFONE INCORRETO OU INEXISTENTE'
			},
			{
				id: 9,
				label: 'Necessidade de revisão de orçamento/LKG',
				value: 'NECESSIDADE DE REVISÃO DE ORÇAMENTO/LKG'
			},
			{
				id: 10,
				label: 'Cliente solicita cancelamento',
				value: 'CLIENTE SOLICITA CANCELAMENTO'
			}
		],
		hidden: true,
		matching: {
			'NECESSIDADE DE REVISÃO DE ORÇAMENTO/INVIABILIDADE TÉCNICA': 'taskTechnicalQuoteRevision',
			'CLIENTE COM DÚVIDAS DE CONTRATO': 'taskContractQuestions',
			'NECESSIDADE DE REVISÃO DE ORÇAMENTO/CLIENTE': 'taskCustomerQuoteRevision',
			'CLIENTE SOLICITA CANCELAMENTO': 'taskCustomerRequestsCancellation'
		},
		hiddenble: true,
		extras: true
	},
	{
		isValid: false,
		label: 'Tratativa técnica',
		name: 'taskTechnicalNegotiation',
		type: 'select',
		validation: {
			isRequired: true
		},
		validationMessage: '',
		value: '',
		wasTouched: false,
		items: [
			{
				id: 0,
				label: 'Selecione',
				value: ''
			},
			{
				id: 1,
				label: 'Aguarda liberação de campo',
				value: 'AGUARDA LIBERAÇÃO DE CAMPO'
			},
			{
				id: 2,
				label: 'Atendimento aos sábados',
				value: 'ATENDIMENTO AOS SÁBADOS'
			},
			{
				id: 3,
				label: 'Cliente com dúvidas técnicas',
				value: 'CLIENTE COM DÚVIDAS TÉCNICAS'
			},
			{
				id: 4,
				label: 'Falta mobilização',
				value: 'FALTA MOBILIZAÇÃO'
			},
			{
				id: 5,
				label: 'Material',
				value: 'MATERIAL'
			},
			{
				id: 6,
				label: 'Sem capacidade de atendimento',
				value: 'SEM CAPACIDADE DE ATENDIMENTO'
			},
			{
				id: 7,
				label: 'Visita',
				value: 'VISITA'
			}
		],
		hidden: true,
		hiddenble: true,
		extras: true
	},
	{
		isValid: false,
		label: 'Tratativa suporte',
		name: 'taskSupportNegotiation',
		type: 'select',
		validation: {
			isRequired: true
		},
		validationMessage: '',
		value: '',
		wasTouched: false,
		items: [
			{
				id: 0,
				label: 'Selecione...',
				value: ''
			},
			{
				id: 1,
				label: 'Cancelamento via comprova/CIG',
				value: 'CANCELAMENTO VIA COMPROVA/CIG'
			},
			{
				id: 2,
				label: 'CAP de canc. em processamento',
				value: 'CAP DE CANC. EM PROCESSAMENTO'
			},
			{
				id: 3,
				label: 'Cliente inserido errado na carteira',
				value: 'CLIENTE INSERIDO ERRADO NA CARTEIRA'
			},
			{
				id: 4,
				label: 'Pendência TI',
				value: 'PENDÊNCIA TI'
			}
		],
		hidden: true,
		hiddenble: true,
		extras: true
	},
	{
		isValid: false,
		label: 'Necessidade de revisão de orçamento/inviabilidade técnica',
		name: 'taskTechnicalQuoteRevision',
		type: 'select',
		validation: {
			isRequired: true
		},
		validationMessage: '',
		value: '',
		wasTouched: false,
		items: [
			{
				id: 0,
				label: 'Selecione...',
				value: ''
			},
			{
				id: 1,
				label: 'Abrigo nos fundos',
				value: 'ABRIGO NOS FUNDOS'
			},
			{
				id: 2,
				label: 'Adequação da tubulação',
				value: 'ADEQUAÇÃO DA TUBULAÇÃO'
			},
			{
				id: 3,
				label: 'Aquecedor em local inadequado',
				value: 'AQUECEDOR EM LOCAL INADEQUADO'
			},
			{
				id: 4,
				label: 'Contrato incorreto',
				value: 'CONTRATO INCORRETO'
			},
			{
				id: 5,
				label: 'Contrato incorreto - tubulação existente não será aproveitada',
				value: 'CONTRATO INCORRETO - TUBULAÇÃO EXISTENTE NÃO SERÁ APROVEITADA'
			},
			{
				id: 6,
				label: 'Discordância de trajeto',
				value: 'DISCORDÂNCIA DE TRAJETO'
			},
			{
				id: 7,
				label: 'Estética',
				value: 'ESTÉTICA'
			},
			{
				id: 8,
				label: 'Extensão de rede',
				value: 'EXTENSÃO DE REDE'
			},
			{
				id: 9,
				label: 'Falta de acesso à válvula',
				value: 'FALTA DE ACESSO À VÁLVULA'
			},
			{
				id: 10,
				label: 'Localização do abrigo',
				value: 'LOCALIZAÇÃO DO ABRIGO'
			},
			{
				id: 11,
				label: 'Orçamento não prevê todos os materiais necessários',
				value: 'ORÇAMENTO NÃO PREVÊ TODOS OS MATERIAIS NECESSÁRIOS'
			},
			{
				id: 12,
				label: 'Projeto de rede não aprovado',
				value: 'PROJETO DE REDE NÃO APROVADO'
			},
			{
				id: 13,
				label: 'Sem rede no local',
				value: 'SEM REDE NO LOCAL'
			},
			{
				id: 14,
				label: 'Trabalho em altura',
				value: 'TRABALHO EM ALTURA'
			},
			{
				id: 15,
				label: 'Trajeto inviável',
				value: 'TRAJETO INVIÁVEL'
			}
		],
		hidden: true,
		hiddenble: true,
		extras: true
	},
	{
		isValid: false,
		label: 'Cliente com dúvidas de contrato',
		name: 'taskContractQuestions',
		type: 'select',
		validation: {
			isRequired: true
		},
		validationMessage: '',
		value: '',
		wasTouched: false,
		items: [
			{
				id: 0,
				label: 'Selecione...',
				value: ''
			},
			{
				id: 1,
				label: 'Conversão de equipamentos',
				value: 'CONVERSÃO DE EQUIPAMENTOS'
			},
			{
				id: 2,
				label: 'Dado cadastral incorreto',
				value: 'DADO CADASTRAL INCORRETO'
			},
			{
				id: 3,
				label: 'Dúvidas de valores',
				value: 'DÚVIDAS DE VALORES'
			},
			{
				id: 4,
				label: 'Dúvidas sobre o produto',
				value: 'DÚVIDAS SOBRE O PRODUTO'
			},
			{
				id: 5,
				label: 'Dúvidas sobre recomposição do piso',
				value: 'DÚVIDAS SOBRE RECOMPOSIÇÃO DO PISO'
			},
			{
				id: 6,
				label: 'Dúvidas variadas (taxas)',
				value: 'DÚVIDAS VARIADAS (TAXAS)'
			},
			{
				id: 7,
				label: 'Embutimento não contemplado',
				value: 'EMBUTIMENTO NÃO CONTEMPLADO'
			},
			{
				id: 8,
				label: 'Multa contratual com a concorrência',
				value: 'MULTA CONTRATUAL COM A CONCORRÊNCIA'
			},
			{
				id: 9,
				label: 'Orçamento/contrato não enviado ao cliente',
				value: 'ORÇAMENTO/CONTRATO NÃO ENVIADO AO CLIENTE'
			},
			{
				id: 10,
				label: 'Orientações sobre instalação',
				value: 'ORIENTAÇÕES SOBRE INSTALAÇÃO'
			},
			{
				id: 11,
				label: 'Ponto adicional não comtemplado',
				value: 'PONTO ADICIONAL NÃO COMTEMPLADO'
			},
			{
				id: 12,
				label: 'RVT (Não enviada/incorreta)',
				value: 'RVT (NÃO ENVIADA/INCORRETA)'
			},
			{
				id: 13,
				label: 'Solicita falar com o consultor novamente',
				value: 'SOLICITA FALAR COM O CONSULTOR NOVAMENTE'
			}
		],
		hidden: true,
		hiddenble: true,
		extras: true
	},
	{
		isValid: false,
		label: 'Necessidade de revisão de orçamento/cliente',
		name: 'taskCustomerQuoteRevision',
		type: 'select',
		validation: {
			isRequired: true
		},
		validationMessage: '',
		value: '',
		wasTouched: false,
		items: [
			{
				id: 0,
				label: 'Selecione...',
				value: ''
			},
			{
				id: 1,
				label: 'Contrato incorreto',
				value: 'CONTRATO INCORRETO'
			},
			{
				id: 2,
				label: 'Contrato incorreto - necessário adicionar metragem',
				value: 'CONTRATO INCORRETO - NECESSÁRIO ADICIONAR METRAGEM'
			},
			{
				id: 3,
				label: 'Conversão de equipamentos',
				value: 'CONVERSÃO DE EQUIPAMENTOS'
			},
			{
				id: 4,
				label: 'Dado cadastral incorreto',
				value: 'DADO CADASTRAL INCORRETO'
			},
			{
				id: 5,
				label: 'Dimensionamento não descrito',
				value: 'DIMENSIONAMENTO NÃO DESCRITO'
			},
			{
				id: 6,
				label: 'Discorda do tipo de instalação',
				value: 'DISCORDA DO TIPO DE INSTALAÇÃO'
			},
			{
				id: 7,
				label: 'Divergência de informações (vendedor x contrato)',
				value: 'DIVERGÊNCIA DE INFORMAÇÕES (VENDEDOR X CONTRATO)'
			},
			{
				id: 8,
				label: 'Dúvidas de valores',
				value: 'DÚVIDAS DE VALORES'
			},
			{
				id: 9,
				label: 'Embutimento',
				value: 'EMBUTIMENTO'
			},
			{
				id: 10,
				label: 'Localização do abrigo',
				value: 'LOCALIZAÇÃO DO ABRIGO'
			},
			{
				id: 11,
				label: 'Orçamento não prevê adequação do fogão',
				value: 'ORÇAMENTO NÃO PREVÊ ADEQUAÇÃO DO FOGÃO'
			},
			{
				id: 12,
				label: 'Orçamento/contrato não enviado ao cliente',
				value: 'ORÇAMENTO/CONTRATO NÃO ENVIADO AO CLIENTE'
			},
			{
				id: 13,
				label: 'Ponto adicional não contemplado',
				value: 'PONTO ADICIONAL NÃO CONTEMPLADO'
			},
			{
				id: 14,
				label: 'Venda incorreta',
				value: 'VENDA INCORRETA'
			}
		],
		hidden: true,
		hiddenble: true,
		extras: true
	},
	{
		isValid: false,
		label: 'Cliente solicita cancelamento',
		name: 'taskCustomerRequestsCancellation',
		type: 'select',
		validation: {
			isRequired: true
		},
		validationMessage: '',
		value: '',
		wasTouched: false,
		items: [
			{
				id: 0,
				label: 'Selecione...',
				value: ''
			},
			{
				id: 1,
				label: 'Cancelamento via telefone - solic. consultor',
				value: 'CANCELAMENTO VIA TELEFONE - SOLIC. CONSULTOR'
			},
			{
				id: 2,
				label: 'Casa será demolida/reformada',
				value: 'CASA SERÁ DEMOLIDA/REFORMADA'
			},
			{
				id: 3,
				label: 'Cliente não reconhece a venda',
				value: 'CLIENTE NÃO RECONHECE A VENDA'
			},
			{
				id: 4,
				label: 'Cliente recusa informar motivo',
				value: 'CLIENTE RECUSA INFORMAR MOTIVO'
			},
			{
				id: 5,
				label: 'Concorrência',
				value: 'CONCORRÊNCIA'
			},
			{
				id: 6,
				label: 'Contrato refeito',
				value: 'CONTRATO REFEITO'
			},
			{
				id: 7,
				label: 'Estética',
				value: 'ESTÉTICA'
			},
			{
				id: 8,
				label: 'Falecimento',
				value: 'FALECIMENTO'
			},
			{
				id: 9,
				label: 'Imóvel alugado/vendido',
				value: 'IMÓVEL ALUGADO/VENDIDO'
			},
			{
				id: 10,
				label: 'Insatisfação com atendimento - 0800',
				value: 'INSATISFAÇÃO COM ATENDIMENTO - 0800'
			},
			{
				id: 11,
				label: 'Insatisfação com atendimento - Agendamento',
				value: 'INSATISFAÇÃO COM ATENDIMENTO - AGENDAMENTO'
			},
			{
				id: 12,
				label: 'Insatisfação com atendimento - Execução',
				value: 'INSATISFAÇÃO COM ATENDIMENTO - EXECUÇÃO'
			},
			{
				id: 13,
				label: 'Insatisfação com atendimento - Vendas',
				value: 'INSATISFAÇÃO COM ATENDIMENTO - VENDAS'
			},
			{
				id: 14,
				label: 'Já assinou o termo de cancelamento',
				value: 'JÁ ASSINOU O TERMO DE CANCELAMENTO'
			},
			{
				id: 15,
				label: 'Motivos de saúde',
				value: 'MOTIVOS DE SAÚDE'
			},
			{
				id: 16,
				label: 'Motivos financeiros',
				value: 'MOTIVOS FINANCEIROS'
			},
			{
				id: 17,
				label: 'Motivos pessoais',
				value: 'MOTIVOS PESSOAIS'
			},
			{
				id: 18,
				label: 'Nota duplicada',
				value: 'NOTA DUPLICADA'
			},
			{
				id: 19,
				label: 'Restrição de orçamento após revisão',
				value: 'RESTRIÇÃO DE ORÇAMENTO APÓS REVISÃO'
			}
		],
		hidden: true,
		hiddenble: true,
		extras: true
	},
	{
		isValid: false,
		label: 'taskScheduleDate',
		name: 'taskScheduleDate',
		validation: {
			isRequired: true
		},
		validationMessage: '',
		value: '',
		wasTouched: false,
		hidden: true,
		hiddenble: true,
		extras: true,
		mask: '99/99/9999'
	},
	{
		isValid: false,
		label: 'taskContactDate',
		name: 'taskContactDate',
		validation: {
			isRequired: true
		},
		validationMessage: '',
		value: '',
		wasTouched: false,
		hidden: true,
		hiddenble: true,
		extras: true,
		mask: '99/99/9999'
	},
	{
		isValid: false,
		label: 'taskSupportInfo',
		name: 'taskSupportInfo',
		type: 'textarea',
		validation: {
			isRequired: true
		},
		validationMessage: '',
		value: '',
		wasTouched: false,
		hidden: false,
		hiddenble: false,
		extras: true
	}
];
