import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { defineMessages, injectIntl } from 'react-intl';

import './index.sass';
import ImgSvg from '../../../Atoms/ImgSvg';
import Bubble from '../../../Atoms/Bubble';
import AttachmentModal from '../../../Sirius/AttachmentModal';

const messages = defineMessages({
	attachment: {
		id: 'messageContent.file',
		defaultMessage: 'Arquivo'
	}
});

const MessageAttachment = ({
	messageInfo, children = null, intl, settings
}) => {
	const { fontSize = '14px' } = settings;
	const [attachmentIsOpen, setAttachmentIsOpen] = useState(false);

	return (
		<>
			<Bubble messageInfo={messageInfo}>
				{children}
				<div
					className={`MessageAttachment__info ${!messageInfo.isFromAgent ? 'MessageAttachment__info--client' : ''} is-clickable`}
					onClick={() => setAttachmentIsOpen(true)}
					role="button"
					tabIndex={0}
				>
					<ImgSvg name="file" />
					<span style={{ fontSize }}>{messageInfo.fileName || messageInfo.content || intl.formatMessage(messages.attachment)}</span>
				</div>
			</Bubble>
			{
				attachmentIsOpen && (
					<AttachmentModal
						url={messageInfo.url}
						mimeType={messageInfo.contentType}
						messageId={messageInfo.messageId}
						onClose={() => setAttachmentIsOpen(false)}
					/>
				)
			}
		</>
	);
};

MessageAttachment.propTypes = {
	messageInfo: PropTypes.shape({
		url: PropTypes.string,
		fileName: PropTypes.string,
		isFromAgent: PropTypes.bool,
		content: PropTypes.string,
		contentType: PropTypes.string,
		messageId: PropTypes.number
	}).isRequired,
	intl: PropTypes.shape({
		formatMessage: PropTypes.func.isRequired
	}).isRequired,
	settings: PropTypes.shape({
		fontSize: PropTypes.string
	}).isRequired
};

const mapStateToProps = state => ({
	settings: state.agent.info.settings
});

export default connect(mapStateToProps)(injectIntl(MessageAttachment));
