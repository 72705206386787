import React from 'react';
import PropTypes from 'prop-types';

import './index.sass';
import Text from '../../Atoms/Text';
import Wrapper from '../../Atoms/Wrapper';
import CircleDisplay from '../../Atoms/CircleDisplay';

const ToggleNav = ({ infos, responsive }) => (
	<div className={`ToggleNav ${responsive ? 'ToggleNav-responsive' : ''}`}>
		{infos && infos.constructor === Array && infos.map(({
			active,
			info,
			title,
			click,
			notify
		}) => {
			const parsedInfo = info && info > 99 ? '+99' : info;
			const toggleNavItemClasses = ['ToggleNav__item'];

			if (active) toggleNavItemClasses.push('ToggleNav__item--active');
			if (info) toggleNavItemClasses.push('ToggleNav__item--info');
			if (notify) toggleNavItemClasses.push('ToggleNav__item--notify');

			return (
				<Wrapper className={toggleNavItemClasses.join(' ')} action={click} key={title} data-testid={`toggleNavItem${title}`}>
					<Text size="bigger">
						{title}
					</Text>
					<CircleDisplay color="grey" className={info > 9 ? 'ToggleNav__count' : ''}>
						{parsedInfo || 0}
					</CircleDisplay>
				</Wrapper>
			);
		})}
	</div>
);

ToggleNav.propTypes = {
	infos: PropTypes.arrayOf(PropTypes.shape({
		title: PropTypes.string.isRequired,
		click: PropTypes.func.isRequired,
		active: PropTypes.bool.isRequired,
		info: PropTypes.number,
		notify: PropTypes.bool,
		tooltip: PropTypes.string
	})).isRequired,
	responsive: PropTypes.bool
};

export default ToggleNav;
