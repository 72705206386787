import { MESSAGE_CONTENT_TYPE } from '../../../shared/consts';

export const isTextMessage = type => type === MESSAGE_CONTENT_TYPE.TEXT;

export const isImageMessage = type => type.match(MESSAGE_CONTENT_TYPE.IMAGE);

export const isVideoMessage = type => type.match(MESSAGE_CONTENT_TYPE.VIDEO);

export const isAudioMessage = type => type.match(MESSAGE_CONTENT_TYPE.AUDIO);

export const isApplicationMessage = type => type.match(MESSAGE_CONTENT_TYPE.APPLICATION);

export const isFileMessage = type => (
	!isImageMessage(type)
  && !isVideoMessage(type)
  && !isAudioMessage(type)
  && !isTextMessage(type)
);
