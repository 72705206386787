import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { injectIntl, defineMessages } from 'react-intl';
import PropTypes from 'prop-types';
import moment from 'moment';

import './index.sass';
import * as actions from '../../../store/actions';
import { checkValidity, fillTasks, verifyTaskExtrasBeforeEnd } from '../../../shared/utility';
import InputText from '../../Molecules/Inputs/Form';
import Select from '../../Molecules/Inputs/Select';
import SearchInput from '../../Molecules/SearchInput';
import Button from '../../Molecules/Buttons/Button';
import Text from '../../Atoms/Text';
import formData from './formData';
import TaskCheckBox from '../../Molecules/TaskCheckbox';

const messages = defineMessages({
	taskName: {
		id: 'taskForm.taskName',
		defaultMessage: 'Nome da Tarefa *'
	},
	taskNamePlaceholder: {
		id: 'taskForm.taskNamePlaceholder',
		defaultMessage: 'Digite um nome'
	},
	taskDescription: {
		id: 'taskForm.taskDescription',
		defaultMessage: 'Descrição'
	},
	taskDescriptionPlaceholder: {
		id: 'taskForm.taskDescriptionPlaceholder',
		defaultMessage: 'Escreva uma descrição'
	},
	taskCustomer: {
		id: 'taskForm.taskCustomer',
		defaultMessage: 'Cliente *'
	},
	taskDate: {
		id: 'taskForm.taskDate',
		defaultMessage: 'Data prevista *'
	},
	taskDatePlaceholder: {
		id: 'taskForm.taskDatePlaceholder',
		defaultMessage: 'dd/mm/aaaa'
	},
	taskTime: {
		id: 'taskForm.taskTime',
		defaultMessage: 'Hora limite *'
	},
	taskTimePlaceholder: {
		id: 'taskForm.taskTimePlaceholder',
		defaultMessage: 'hh:mm'
	},
	taskScheduleDate: {
		id: 'taskForm.taskScheduleDate',
		defaultMessage: 'Data de agendamento'
	},
	taskContactDate: {
		id: 'taskForm.taskContactDate',
		defaultMessage: 'Data para entrar em contato'
	},
	taskSupportInfo: {
		id: 'taskForm.taskSupportInfo',
		defaultMessage: 'Informações de atendimento'
	},
	save: {
		id: 'taskForm.save',
		defaultMessage: 'Criar tarefa'
	},
	success: {
		id: 'taskForm.success',
		defaultMessage: 'Ação realizada com sucesso'
	},
	createTask: {
		id: 'taskForm.createTask',
		defaultMessage: 'Tarefa'
	},
	createTaskDescription: {
		id: 'taskForm.createTaskDescription',
		defaultMessage: 'Você pode criar uma tarefa por conversa.'
	},
	requiredWarning: {
		id: 'taskForm.requiredWarning',
		defaultMessage: 'O asterisco (*) marca um campo obrigatório.'
	},
	updateTask: {
		id: 'taskForm.updateTask',
		defaultMessage: 'Editar Tarefa'
	},
	task: {
		id: 'taskForm.task',
		defaultMessage: 'Tarefas'
	},
	taskContent: {
		id: 'taskForm.taskContent',
		defaultMessage: 'A ação foi realizada'
	},
	unfilledTask: {
		id: 'taskForm.unfilledTask',
		defaultMessage: 'Tarefas'
	},
	unfilledTaskContent: {
		id: 'taskForm.unfilledTaskContent',
		defaultMessage: 'Preencha os campos na task antes de finalizar'
	},
	invalidCustomer: {
		id: 'taskForm.invalidCustomer',
		defaultMessage: 'Cliente não é válido'
	},
	invalidCustomerContent: {
		id: 'taskForm.invalidCustomerContent',
		defaultMessage: 'Selecione um cliente válido da lista'
	}
});


class TaskForm extends Component {
	state = {
		formControls: formData,
		isFormValid: false,
		changedForm: false,
		openCustomersList: false,
		selectedCustomerId: '',
		enabledSelects: []
	}

	baseState = this.state;

	componentDidMount() {
		const { formControls } = this.state;
		const {
			taskSidebar,
			selectedTask = {},
			inInteraction,
			currentInteraction = {},
			defineTaskSideBar,
			taskExtras
		} = this.props;

		const filteredFormControls = taskExtras ? formControls : formControls.filter(
			control => control.defaultField
		);

		let fillInfo = selectedTask;
		if (inInteraction && currentInteraction && currentInteraction.tasks
			&& currentInteraction.tasks.length > 0) {
			defineTaskSideBar('edit');
			[fillInfo] = currentInteraction.tasks;
		}

		const interactionHasTask = inInteraction && currentInteraction.tasks
			&& currentInteraction.tasks.length > 0;

		if (taskSidebar === 'edit' || interactionHasTask) {
			const updatedControls = fillTasks(fillInfo, filteredFormControls);

			let isFormValid = true;
			updatedControls.forEach((field, controlsIndex) => {
				const { validation, wasTouched, value } = field;
				const formField = updatedControls[controlsIndex];
				if (validation.isRequired && !wasTouched && value !== '') {
					isFormValid = !formField.isValid && isFormValid;
				} else if (validation.isRequired || wasTouched) {
					isFormValid = formField.isValid && isFormValid;
				}
			});

			const filteredControls = updatedControls;

			this.setState({
				formControls: filteredControls,
				isFormValid,
				changedForm: true,
				selectedCustomerId: fillInfo.customer ? fillInfo.customer.id : ''
			});
		} else {
			this.setState({
				formControls: filteredFormControls,
				selectedCustomerId: fillInfo.customer ? fillInfo.customer.id : ''
			});
		}
	}

	componentDidUpdate(prevProps) {
		const { formControls } = this.state;
		const {
			taskSidebar,
			selectedTask,
			inInteraction,
			currentInteraction,
			defineTaskSideBar,
			createdOrUpdatedTask,
			createdInteractionTaskClear,
			taskExtras,
			creatingOrUpdatingSuccess,
			intl,
			addNotification,
			createOrUpdateInteractionTaskClear
		} = this.props;
		const { formatMessage } = intl;

		const filteredFormControls = taskExtras ? formControls : formControls.filter(
			control => control.defaultField
		);

		if (prevProps.taskSidebar !== taskSidebar && taskSidebar === 'new' && !inInteraction) {
			const defaultBaseState = {
				...this.baseState,
				formControls: this.baseState.formControls.filter(control => control.defaultField)
			};
			this.setState(taskExtras ? this.baseState : defaultBaseState);
		}

		const changedInteraction = prevProps.currentInteraction.interactionHash
			!== currentInteraction.interactionHash;

		if (createdOrUpdatedTask || changedInteraction) {
			createdInteractionTaskClear();

			let fillInfo = selectedTask;
			if (inInteraction && currentInteraction && currentInteraction.tasks
				&& currentInteraction.tasks.length > 0) {
				defineTaskSideBar('edit');
				[fillInfo] = currentInteraction.tasks;
			} else {
				defineTaskSideBar('new');
			}

			const updatedControls = fillTasks(fillInfo, filteredFormControls);

			let isFormValid = true;
			updatedControls.forEach((field, controlsIndex) => {
				const { validation, wasTouched, value } = field;
				const formField = updatedControls[controlsIndex];
				if (validation.isRequired && !wasTouched && value !== '') {
					isFormValid = !formField.isValid && isFormValid;
				} else if (validation.isRequired || wasTouched) {
					isFormValid = formField.isValid && isFormValid;
				}
			});

			let filteredControls = updatedControls;
			if (!taskExtras) filteredControls = filteredControls.filter(control => control.defaultField);

			this.setState({
				formControls: filteredControls,
				isFormValid,
				changedForm: true,
				selectedCustomerId: fillInfo && fillInfo.customer ? fillInfo.customer.id : ''
			});
		}

		if (creatingOrUpdatingSuccess && (taskSidebar === 'edit' || taskSidebar === 'new')) {
			addNotification({
				title: formatMessage(messages.task),
				content: formatMessage(messages.taskContent),
				type: 'success'
			});

			if (!inInteraction) {
				defineTaskSideBar('default');
			}

			createOrUpdateInteractionTaskClear();
		}
	}

	onCustomerSelectHandler(customerId) {
		this.setState({
			selectedCustomerId: customerId,
			openCustomersList: false
		});
	}

	inputChangedHandler = (event, inputIdentifier) => {
		const { formControls } = this.state;
		const { intl } = this.props;
		const { formatMessage } = intl;

		const updatedControls = formControls.map((formItem) => {
			if (formItem.name === inputIdentifier) {
				const { isValid, validationMessage } = checkValidity(
					event.target.value, formItem.validation, formItem.label
				);

				let formattedMessage = '';
				if (Object.keys(validationMessage).length > 0) {
					const { label, intlObject } = validationMessage;
					formattedMessage = `${label} ${formatMessage(intlObject)}`;
				}

				return {
					...formItem,
					value: event.target.value,
					isValid,
					validationMessage: formattedMessage,
					wasTouched: true
				};
			}

			return formItem;
		});

		let isFormValid = true;
		updatedControls.forEach((field, controlsIndex) => {
			const { validation, wasTouched, value } = field;
			const formField = updatedControls[controlsIndex];
			if (validation.isRequired && !wasTouched && value !== '') {
				isFormValid = !formField.isValid && isFormValid;
			} else if (validation.isRequired || wasTouched) {
				isFormValid = formField.isValid && isFormValid;
			}
		});

		this.setState({
			formControls: updatedControls,
			isFormValid,
			changedForm: true
		});
	}

	onSubmit = () => {
		const { formControls, selectedCustomerId } = this.state;
		const {
			onCreateTask,
			updateTask,
			taskSidebar,
			selectedTask,
			inInteraction,
			currentInteraction,
			createInteractionTask,
			agent,
			blockReopenTasks,
			addNotification,
			intl
		} = this.props;
		const { formatMessage } = intl;

		let customerId = null;
		if (inInteraction) {
			customerId = currentInteraction.customerInfo.id;
		}

		if (!selectedCustomerId && !customerId) {
			addNotification({
				title: formatMessage(messages.invalidCustomer),
				content: formatMessage(messages.invalidCustomerContent),
				type: 'warning'
			});
			return;
		}

		const date = formControls.find(field => field.name === 'taskDate').value;
		const time = formControls.find(field => field.name === 'taskTime').value;

		const expiresAt = moment(`${date} ${time}`, 'DD-MM-YYYY HH:mm').utc();

		const extras = [];
		formControls.filter(item => item.extras).forEach((control) => {
			extras.push({
				name: control.name,
				value: control.hidden ? '' : control.value
			});
		});

		const payload = {
			name: formControls.find(field => field.name === 'taskName').value,
			description: formControls.find(field => field.name === 'taskDescription').value,
			status: 'OPENED',
			extras,
			customerId: inInteraction ? customerId : selectedCustomerId,
			expiresAt
		};

		const currentTask = inInteraction && currentInteraction
			&& currentInteraction.tasks && currentInteraction.tasks.length > 0
			? currentInteraction.tasks[0]
			: selectedTask;

		if (inInteraction) payload.interactionHash = currentInteraction.interactionHash;

		if (currentTask.agents) {
			payload.agentIds = currentTask.agents.map(agentTask => agentTask.id);
		} else {
			payload.agentIds = [agent.info.id];
		}

		const updateInfo = {
			...payload,
			id: currentTask.id,
			status: blockReopenTasks && currentTask.status === 'FINISHED' ? currentTask.status : 'OPENED'
		};

		if (inInteraction) updateInfo.interactionHash = currentInteraction.interactionHash;

		if (taskSidebar === 'edit') {
			updateTask({ taskInfo: updateInfo, createInteractionTask });
			return;
		}

		onCreateTask({ payload, inInteraction, createInteractionTask });
		if (!inInteraction) {
			const defaultBaseState = {
				...this.baseState,
				formControls: this.baseState.formControls.filter(control => control.defaultField)
			};
			this.setState(defaultBaseState);
		}
	}

	onUpdateTask = (value) => {
		const {
			createInteractionTask,
			currentInteraction,
			updateTask,
			taskExtras
		} = this.props;

		const { tasks, interactionHash } = currentInteraction;
		let task = {};
		if (tasks) [task] = tasks;

		const extras = [];
		if (task.aditionalFields) {
			Object.entries(task.aditionalFields).forEach(([key, valueItem]) => {
				extras.push({
					name: key,
					valueItem
				});
			});
		}

		const taskInfo = {
			id: task.id,
			name: task.name,
			description: task.description,
			status: value ? 'FINISHED' : 'OPENED',
			customerId: task.customer.id,
			expiresAt: task.expiresAt,
			interactionHash,
			agentIds: task.agents.map(agent => agent.id)
		};

		if (taskExtras) taskInfo.extras = extras;

		updateTask({
			taskInfo,
			createInteractionTask
		});
	};

	toggleTaskStatus = (status) => {
		const {
			taskExtras,
			currentInteraction,
			intl,
			addNotification
		} = this.props;
		const { formatMessage } = intl;
		const { tasks } = currentInteraction;
		let task = {};

		if (tasks) [task] = currentInteraction.tasks;
		const { aditionalFields = [] } = task;

		if (taskExtras) {
			if (aditionalFields) {
				if (verifyTaskExtrasBeforeEnd(task, formData)) {
					this.onUpdateTask(status);
				} else {
					addNotification({
						title: formatMessage(messages.unfilledTask),
						content: formatMessage(messages.unfilledTaskContent),
						type: 'warning'
					});
				}
			} else {
				this.onUpdateTask(status);
			}
		} else {
			this.onUpdateTask(status);
		}
	};

	inputCustomerHandler(event) {
		const { agent, fetchAgentContactsSearch } = this.props;
		const accountId = agent.info.account.id;
		const { value } = event.target;

		setTimeout(() => {
			fetchAgentContactsSearch({
				accountId,
				search: value
			});
			this.setState({ openCustomersList: true });
		}, 1200);
	}

	selectHandler(event, matching, name) {
		const { formControls, enabledSelects } = this.state;
		const { value } = event.target;

		if (matching) {
			let currentChange = false;
			const nextSelect = matching[value];

			this.setState({
				enabledSelects: enabledSelects.concat(name)
			}, () => {
				// eslint-disable-next-line no-shadow
				const { formControls, enabledSelects } = this.state;
				const result = formControls.map((formItem) => {
					if (formItem.name === name) {
						currentChange = true;
						return {
							...formItem,
							hidden: false,
							value
						};
					}
					if (formItem.name === nextSelect) {
						return {
							...formItem,
							hidden: false,
							value: ''
						};
					}

					if (currentChange) return formData.find(field => field.name === formItem.name);

					return {
						...formItem,
						hidden: formItem.hiddenble ? !enabledSelects.includes(formItem.name) : false
					};
				});

				this.setState({
					formControls: result
				});
			});

			return;
		}

		let currentChange = false;
		const updatedFormControls = formControls.map((formItem) => {
			if (formItem.name === name) {
				currentChange = true;
				return {
					...formItem,
					value
				};
			}
			if (currentChange) return formData.find(field => field.name === formItem.name);
			return formItem;
		});

		this.setState({ formControls: updatedFormControls });
	}

	render() {
		const {
			formControls,
			isFormValid,
			changedForm,
			openCustomersList
		} = this.state;
		const {
			contactsSearch = [],
			taskSidebar,
			intl,
			inInteraction,
			creatingOrUpdatingTask,
			taskExtras,
			currentInteraction,
			blockReopenTasks
		} = this.props;
		const { formatMessage } = intl;
		const parsedFormControl = inInteraction ? formControls.filter(control => control.name !== 'taskCustomer') : formControls;
		const canSendRequest = (isFormValid && changedForm) || taskExtras ? this.onSubmit : () => {};

		let task = {};
		if (currentInteraction.tasks) [task] = currentInteraction.tasks;

		return (
			<div className="TaskForm__wrapper">
				<div className="TaskForm">
					<div className="TaskForm__header">
						<div className="TaskForm__header__wrapper">
							<Text size="biggest" bold className="TaskForm__header__wrapper__title">
								{taskSidebar === 'new' ? formatMessage(messages.createTask) : formatMessage(messages.updateTask)}
							</Text>
							<Text size="bigger">{formatMessage(messages.createTaskDescription)}</Text>
							<Text className="TaskForm__header__wrapper__required" size="big">{formatMessage(messages.requiredWarning)}</Text>
						</div>
						{taskExtras && inInteraction && (
							<TaskCheckBox
								onChecked={value => this.toggleTaskStatus(value)}
								checked={task.status === 'FINISHED'}
								loading={creatingOrUpdatingTask}
								blockReopenTasks={blockReopenTasks}
							/>
						)}
					</div>
					<form className="TaskForm__form">
						{parsedFormControl.map((field) => {
							const {
								label,
								value,
								name,
								placeholder,
								type,
								validationMessage,
								isValid,
								wasTouched,
								items,
								hidden,
								matching,
								mask,
								defaultField
							} = field;

							let parsedValue = value;
							if (name === 'taskDate' && value && moment(value).isValid()) parsedValue = moment(value).format('MM-DD-YYYY');

							return (
								<Fragment key={label}>
									{!hidden && (
										<div className="TaskForm__field">
											{name === 'taskCustomer' ? (
												<SearchInput
													key={name}
													label={formatMessage(messages[label])}
													validationMessage={validationMessage}
													value={value}
													onChange={event => this.inputCustomerHandler(event)}
													valid={isValid}
													type={type || 'text'}
													onKeyUp={() => {}}
													list={contactsSearch}
													openList={openCustomersList}
													onSelect={
														selectedValue => this.onCustomerSelectHandler(
															selectedValue
														)
													}
													touched={wasTouched}
													disabled={taskExtras ? defaultField : false}
												/>
											) : (
												<>
													{type === 'select' ? (
														<>
															{!hidden && (
																<Select
																	label={label}
																	items={items}
																	onChange={event => this.selectHandler(event, matching, name)}
																	value={value || ''}
																/>
															)}
														</>
													) : (
														<>
															{!hidden && (
																<InputText
																	key={name}
																	label={formatMessage(messages[label])}
																	placeholder={placeholder && formatMessage(messages[placeholder])}
																	validationMessage={validationMessage}
																	value={parsedValue}
																	onChange={event => this.inputChangedHandler(event, name)}
																	valid={isValid}
																	type={type || 'text'}
																	onKeyUp={() => {}}
																	touched={wasTouched}
																	mask={mask}
																	disabled={taskExtras ? defaultField : false}
																	name={name}
																/>
															)}
														</>
													)}
												</>
											)}
										</div>
									)}
								</Fragment>
							);
						})}
						<div className="TaskForm__actions">
							<Button
								className="TaskForm__actions__button"
								active={((isFormValid && changedForm) || taskExtras) && !creatingOrUpdatingTask}
								selected
								disabled={creatingOrUpdatingTask}
								click={canSendRequest}
							>
								<Text size="bigger">{formatMessage(messages.save)}</Text>
							</Button>
						</div>
					</form>
				</div>
			</div>
		);
	}
}

TaskForm.propTypes = {
	intl: PropTypes.shape({
		formatMessage: PropTypes.func.isRequired
	}).isRequired,
	onCreateTask: PropTypes.func.isRequired,
	taskSidebar: PropTypes.string.isRequired,
	selectedTask: PropTypes.shape({
		id: PropTypes.number,
		name: PropTypes.string,
		description: PropTypes.string,
		expiresAt: PropTypes.string,
		customer: PropTypes.shape({
			id: PropTypes.number,
			fields: PropTypes.arrayOf(
				PropTypes.shape({
					name: PropTypes.string
				})
			)
		})
	}),
	fetchAgentContactsSearch: PropTypes.func.isRequired,
	agent: PropTypes.shape({
		info: PropTypes.shape({
			id: PropTypes.number,
			account: PropTypes.shape({
				id: PropTypes.number.isRequired
			})
		})
	}).isRequired,
	contactsSearch: PropTypes.arrayOf(
		PropTypes.shape({
			label: PropTypes.string,
			value: PropTypes.number
		})
	),
	inInteraction: PropTypes.bool,
	currentInteraction: PropTypes.shape({
		interactionHash: PropTypes.string,
		tasks: PropTypes.arrayOf(PropTypes.shape),
		customerInfo: PropTypes.shape({
			id: PropTypes.number
		})
	}),
	createInteractionTask: PropTypes.func.isRequired,
	defineTaskSideBar: PropTypes.func.isRequired,
	creatingOrUpdatingTask: PropTypes.bool.isRequired,
	createdOrUpdatedTask: PropTypes.bool.isRequired,
	createdInteractionTaskClear: PropTypes.func.isRequired,
	taskExtras: PropTypes.bool.isRequired,
	updateTask: PropTypes.func.isRequired,
	creatingOrUpdatingSuccess: PropTypes.bool.isRequired,
	addNotification: PropTypes.func.isRequired,
	createOrUpdateInteractionTaskClear: PropTypes.func.isRequired,
	blockReopenTasks: PropTypes.bool.isRequired
};

const mapStateToProps = (state) => {
	const { agent, interaction } = state;
	const {
		createdOrUpdatedTask,
		currentInteractionHash,
		interactions,
		pendingInteractions
	} = interaction;
	const allInteractions = interactions.concat(pendingInteractions);

	return ({
		agent,
		blockReopenTasks: agent.blockReopenTasks,
		contactsSearch: agent.contactsSearch,
		createdOrUpdatedTask,
		creatingOrUpdatingSuccess: agent.creatingOrUpdatingSuccess,
		creatingOrUpdatingTask: agent.creatingOrUpdatingTask,
		currentInteraction: allInteractions.find(({ interactionHash }) => interactionHash === currentInteractionHash) || {},
		selectedTask: agent.selectedTask,
		taskSidebar: state.interface.taskSidebar,
		taskExtras: agent.taskExtras
	});
};

const mapActionsToProps = dispatch => ({
	onCreateTask: taskInfo => dispatch(actions.createTask(taskInfo)),
	fetchAgentContactsSearch: requestInfo => dispatch(actions.fetchAgentContactsSearch(requestInfo)),
	createInteractionTask: taskInfo => dispatch(actions.createdInteractionTask(taskInfo)),
	defineTaskSideBar: option => dispatch(actions.defineTaskSidebar(option)),
	createdInteractionTaskClear: () => dispatch(actions.createdInteractionTaskClear()),
	updateTask: taskInfo => dispatch(actions.updateTask(taskInfo)),
	addNotification: notification => dispatch(actions.addNotification(notification)),
	createOrUpdateInteractionTaskClear: () => dispatch(actions.createOrUpdateInteractionTaskClear())
});

export default connect(mapStateToProps, mapActionsToProps)(injectIntl(TaskForm));
