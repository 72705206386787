import React from 'react';
import PropTypes from 'prop-types';

import './index.sass';
import Notificaton from '../Notification';

const Notifications = ({ notifications }) => (
	<div className="Notifications">
		{notifications.map(notification => (
			<Notificaton notification={notification} key={notification.id} />
		))}
	</div>
);

Notifications.propTypes = {
	notifications: PropTypes.arrayOf(PropTypes.shape({
		title: PropTypes.string.isRequired,
		content: PropTypes.string.isRequired,
		id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		type: PropTypes.string,
		automaticClose: PropTypes.bool
	})).isRequired
};

export default Notifications;
