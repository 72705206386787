import React, { useState, useContext, createContext } from 'react';

const SelectDropdownContext = createContext();

export const SelectDropdownProvider = ({ children }) => {
	const [isActive, setIsActive] = useState(false);
	const [isCreating, setIsCreating] = useState(false);
	const [newItem, setNewItem] = useState('');

	const onChangeActive = () => {
		setIsActive(!isActive);
	};

	return (
		<SelectDropdownContext.Provider
			value={{
				isActive,
				setIsActive,
				onChangeActive,
				isCreating,
				setIsCreating,
				newItem,
				setNewItem
			}}
		>
			{children}
		</SelectDropdownContext.Provider>
	);
};

export const useSelectDropdown = () => useContext(SelectDropdownContext);
