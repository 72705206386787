import React from 'react';
import PropTypes from 'prop-types';

import './index.sass';

const RatingBar = ({
	backgroundColor = '#7EB23C',
	width = '100%',
	percent
}) => (
	<div style={{ width }} className="RatingBar">
		<div className="RatingBar__bar">
			<div style={{ width: `${percent}%`, backgroundColor }} className="RatingBar__bar__progress" />
		</div>
	</div>
);

RatingBar.propTypes = {
	backgroundColor: PropTypes.string,
	percent: PropTypes.number.isRequired,
	width: PropTypes.string
};

export default RatingBar;
