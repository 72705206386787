import React from 'react';
import PropTypes from 'prop-types';
import Wrapper from '../../Atoms/Wrapper';

import './index.sass';

const Switch = ({ active, click, 'data-testid': dataTestId }) => (
	<Wrapper
		className={`Switch ${active ? 'Switch--active' : ''}`}
		action={click}
		data-testid={dataTestId}
	>
		<span className="Switch__label" />
	</Wrapper>
);

Switch.propTypes = {
	active: PropTypes.bool.isRequired,
	click: PropTypes.func.isRequired,
	'data-testid': PropTypes.string
};

export default Switch;
