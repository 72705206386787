import ModalDefaultRoot from './ModalDefaultRoot';
import ModalDefaultHeader from './ModalDefaultHeader';
import ModalDefaultBody from './ModalDefaultBody';
import ModalDefaultFooter from './ModalDefaultFooter';

const ModalDefault = {
	Root: ModalDefaultRoot,
	Header: ModalDefaultHeader,
	Body: ModalDefaultBody,
	Footer: ModalDefaultFooter
};

export default ModalDefault;
