import axios from 'axios';
import * as actionTypes from './actionTypes';

export const fetchCustomChannelsBegin = () => ({
	type: actionTypes.FETCH_CUSTOM_CHANNELS_BEGIN
});
export const fetchCustomChannelsSuccess = customChannels => ({
	type: actionTypes.FETCH_CUSTOM_CHANNELS_SUCCESS,
	payload: customChannels
});
export const fetchCustomChannelsFailed = () => ({
	type: actionTypes.FETCH_CUSTOM_CHANNELS_FAILED
});
export const fetchCustomChannels = () => (
	(dispatch) => {
		dispatch(fetchCustomChannelsBegin());
		return axios.get(`${process.env.OMZ_ZAPI}/admin/custom_channels`, { dispatch })
			.then(({ data }) => {
				const { errorMessage, customChannels } = data;
				if (errorMessage) {
					dispatch(fetchCustomChannelsFailed(errorMessage));
				} else {
					dispatch(fetchCustomChannelsSuccess(customChannels));
				}
			}).catch(() => {});
	}
);
