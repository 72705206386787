import React from 'react';
import PropTypes from 'prop-types';

const fontSizes = {
	smallest: '0.9',
	smaller: '1.1',
	small: '1.2',
	normal: '1.3',
	big: '1.4',
	bigger: '1.6',
	biggest: '1.8',
	large: '2',
	larger: '2.4'
};

const Text = ({
	size = 'normal',
	children,
	className = '',
	'data-testid': dataTestId,
	bold
}) => (
	<p className={className} style={{ fontSize: `${fontSizes[size]}rem`, fontWeight: bold ? 500 : '' }} data-testid={`${dataTestId}-${size}`}>
		{children}
	</p>
);

Text.propTypes = {
	size: PropTypes.oneOf(['smallest', 'smaller', 'small', 'normal', 'big', 'bigger', 'biggest', 'large', 'larger']),
	className: PropTypes.string,
	'data-testid': PropTypes.string,
	bold: PropTypes.bool
};

export default Text;
