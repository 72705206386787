import React from 'react';
import PropTypes from 'prop-types';

function ModalDefaultRoot({
	isOpen,
	onClose,
	isExtended = false,
	children
}) {
	return (
		<div
			id="modal-default"
			className={`modal ${isOpen ? 'is-active' : ''} ${isExtended ? 'is-small' : ''}`}
			role="dialog"
		>
			{/* eslint-disable-next-line jsx-a11y/interactive-supports-focus */}
			<div className="modal-background" role="button" onClick={() => onClose()} />
			<div className="modal-card">
				{children}
			</div>
		</div>
	);
}

ModalDefaultRoot.propTypes = {
	isExtended: PropTypes.bool,
	onClose: PropTypes.func.isRequired,
	isOpen: PropTypes.bool.isRequired
};

export default ModalDefaultRoot;
