/* eslint-disable react/prop-types */
import React from 'react';
import styles from './Toast.module.sass';

function ToastContainer({ type, children, className }) {
	return (
		<div className={`${styles[className] || ''} toast is-${type}`}>
			{children}
		</div>
	);
}

function ToastContent({ children }) {
	return (
		<div className={`${styles.toastContent} toast-content`}>
			{children}
		</div>
	);
}

function ToastAction({ children }) {
	return (
		<div className={`${styles.toastAction} toast-action`}>
			{children}
		</div>
	);
}

function ToastButton({ children, onClick }) {
	return (
		<button type="button" className="button is-text" onClick={onClick}>
			{children}
		</button>
	);
}

function ToastCloseButton({ onClick }) {
	return (
		<button className="delete" onClick={onClick} type="button" />
	);
}

const Toast = {
	Container: ToastContainer,
	Content: ToastContent,
	Action: ToastAction,
	Button: ToastButton,
	CloseButton: ToastCloseButton
};

export default Toast;
