import React from 'react';
import PropTypes from 'prop-types';

import './index.sass';
import Wrapper from '../../Atoms/Wrapper';

const keys = [
	{ 1: '' },
	{ 2: 'ABC' },
	{ 3: 'DEF' },
	{ 4: 'GHI' },
	{ 5: 'JKL' },
	{ 6: 'MNO' },
	{ 7: 'PQRS' },
	{ 8: 'TUV' },
	{ 9: 'WXYZ' },
	{ '*': '' },
	{ 0: '+' },
	{ '#': '' }
];

const Dial = ({ click }) => (
	<div className="Dial">
		{keys.map(key => (
			<Wrapper className="Dial__button" action={() => click(Object.keys(key))} key={Object.keys(key)}>
				<span>{Object.keys(key)}</span>
				<span>{Object.values(key)}</span>
			</Wrapper>
		))}
	</div>
);

Dial.propTypes = {
	click: PropTypes.func.isRequired
};

export default Dial;
