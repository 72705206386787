import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl, defineMessages } from 'react-intl';

import './index.sass';
import * as actions from '../../../store/actions';
import IconButton from '../Buttons/IconButton';
import Button from '../Buttons/Button';
import Text from '../../Atoms/Text';
import Alert from '../../Atoms/Alert';

const messages = defineMessages({
	openTasks: {
		id: 'taskNotification.openTasks',
		defaultMessage: 'Ver tasks'
	},
	remindMeLater: {
		id: 'taskNotification.remindMeLater',
		defaultMessage: 'lembrar em 5 minutos'
	},
	info: {
		id: 'taskNotification.info',
		defaultMessage: 'Você possui tasks que expirarão em breve.'
	}
});

const TaskNotification = ({
	intl,
	informExpiringSoonTasks,
	defineTemplate,
	defineMainBoxSection
}) => {
	const closeTaskNotification = useCallback(() => {
		informExpiringSoonTasks(false, false);
	}, [informExpiringSoonTasks]);

	const onSelectTasks = useCallback(() => {
		defineMainBoxSection('');
		defineTemplate('tasks');
		informExpiringSoonTasks(false, false);
	}, [defineMainBoxSection, defineTemplate, informExpiringSoonTasks]);

	const setCurrentTime = useCallback(() => {
		localStorage.setItem('remindLater', new Date());
		informExpiringSoonTasks(false, false);
	}, [informExpiringSoonTasks]);

	const { formatMessage } = intl;

	return (
		<>
			<Alert
				notify
				content={formatMessage(messages.info)}
				type="newMessage"
				showBrowserNotification
			/>
			<div className="TaskNotification">
				<div className="TaskNotification__header">
					<Text>{formatMessage(messages.info)}</Text>
					<IconButton
						click={closeTaskNotification}
						name="close"
						clear
					/>
				</div>
				<div className="TaskNotification__actions">
					<Button active selected click={onSelectTasks}>
						{formatMessage(messages.openTasks)}
					</Button>
					<Button active selected click={setCurrentTime}>
						{formatMessage(messages.remindMeLater)}
					</Button>
				</div>
			</div>
		</>
	);
};

TaskNotification.propTypes = {
	intl: PropTypes.shape({
		formatMessage: PropTypes.func.isRequired
	}).isRequired,
	informExpiringSoonTasks: PropTypes.func.isRequired,
	defineMainBoxSection: PropTypes.func.isRequired,
	defineTemplate: PropTypes.func.isRequired
};

const mapActionsToProps = dispatch => ({
	informExpiringSoonTasks: inform => dispatch(actions.informExpiringSoonTasks(inform)),
	defineTemplate: template => dispatch(actions.defineTemplate(template)),
	defineMainBoxSection: section => dispatch(actions.defineMainboxSection(section))
});

export default connect(null, mapActionsToProps)(injectIntl(TaskNotification));
