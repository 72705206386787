import store from '../store';

export const sendFullStoryEvent = (eventName) => {
	const {
		id, name, email, account
	} = store.getState().agent.info;

	window.FS.event(eventName, {
		user: {
			id_int: id,
			name_str: name,
			email_str: email,
			account_id_int: account.id
		}
	});
};

export default sendFullStoryEvent;
