import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, defineMessages } from 'react-intl';

import './index.sass';
import Text from '../../Atoms/Text';
import Modal from '../../Molecules/Modal';
import Button from '../../Molecules/Buttons/Button';
import Input from '../../Molecules/Inputs/Common';

const messages = defineMessages({
	header: {
		id: 'altuWhatsAppTemplate.header',
		defaultMessage: 'Template'
	},
	headerDescription: {
		id: 'altuWhatsAppTemplate.headerDescription',
		defaultMessage: 'Personalize a mensagem enviada para seus clientes'
	},
	send: {
		id: 'altuWhatsAppTemplate.send',
		defaultMessage: 'Enviar'
	},
	inputMessage: {
		id: 'altuWhatsAppTemplate.inputMessage',
		defaultMessage: 'Mensagem'
	},
	inputExpirationTime: {
		id: 'altuWhatsAppTemplate.inputExpirationTime',
		defaultMessage: 'Tempo de expiração (minutos)'
	}
});

const AltuWhatsAppTemplates = ({
	onClose,
	intl,
	interactionHash
}) => {
	const { formatMessage } = intl;
	const [message, setMessage] = useState('');
	const [expirationTime, setExpirationTime] = useState('');

	const reopenAltuInteraction = () => {
		window.omzVish.reopenConversation(interactionHash, message, expirationTime);
		onClose();
	};

	return (
		<Modal onClose={onClose} className="AltuWhatsAppTemplates">
			<div className="AltuWhatsAppTemplates__header">
				<Text size="bigger">{formatMessage(messages.header)}</Text>
				<Text>{formatMessage(messages.headerDescription)}</Text>
			</div>
			<div className="AltuWhatsAppTemplates__form">
				<div className="AltuWhatsAppTemplates__form__input">
					<Input
						label={formatMessage(messages.inputMessage)}
						value={message}
						onChange={e => setMessage(e.target.value)}
						showIcon={false}
						placeholder=""
					/>
				</div>
				<div className="AltuWhatsAppTemplates__form__input">
					<Input
						label={formatMessage(messages.inputExpirationTime)}
						value={expirationTime}
						onChange={e => setExpirationTime(e.target.value)}
						showIcon={false}
						type="number"
						placeholder=""
					/>
				</div>
			</div>
			<Button
				click={() => reopenAltuInteraction()}
				active={!!message && !!expirationTime}
			>
				{formatMessage(messages.send)}
			</Button>
		</Modal>
	);
};

AltuWhatsAppTemplates.propTypes = {
	intl: PropTypes.shape({
		formatMessage: PropTypes.func
	}).isRequired,
	interactionHash: PropTypes.string.isRequired,
	onClose: PropTypes.func.isRequired
};

export default injectIntl(AltuWhatsAppTemplates);
