import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, injectIntl } from 'react-intl';

import './index.sass';
import Text from '../../Atoms/Text';

const messages = defineMessages({
	notInformed: {
		id: 'clientSearchItemField.notInformed',
		defaultMessage: 'Não informado'
	}
});

const ClientSearchItemField = ({ label, value, intl }) => (
	<div className="ClientSearchItemField">
		<Text size="big">{label}</Text>
		<Text size="big">{value.constructor === Array ? value[0] : value || intl.formatMessage(messages.notInformed)}</Text>
	</div>
);

ClientSearchItemField.propTypes = {
	label: PropTypes.string.isRequired,
	value: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.arrayOf(PropTypes.string)
	]).isRequired,
	intl: PropTypes.shape({
		formatMessage: PropTypes.func.isRequired
	}).isRequired
};

export default injectIntl(ClientSearchItemField);
