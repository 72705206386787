import React from 'react';

function ModalDefaultFooter({ className = '', children }) {
	return (
		<footer className={`${className} modal-card-foot`}>
			{children}
		</footer>
	);
}

export default ModalDefaultFooter;
