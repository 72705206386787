import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, injectIntl } from 'react-intl';

import './index.sass';
import Input from '../Inputs/Common';
import ToggleNav from '../ToggleNav';

const messages = defineMessages({
	talking: {
		id: 'mainBoxHeader.talking',
		defaultMessage: 'Conversas ativas'
	},
	queue: {
		id: 'mainBoxHeader.queue',
		defaultMessage: 'Em fila'
	},
	inbox: {
		id: 'mainBoxHeader.inbox',
		defaultMessage: 'Pendentes'
	},
	missed: {
		id: 'mainBoxHeader.missed',
		defaultMessage: 'Inativas'
	},
	searchInbox: {
		id: 'mainBoxHeader.searchInbox',
		defaultMessage: 'Buscar pendentes'
	},
	searchQueue: {
		id: 'mainBoxHeader.searchQueue',
		defaultMessage: 'Buscar em fila'
	},
	searchMissed: {
		id: 'mainBoxHeader.searchMissed',
		defaultMessage: 'Buscar inativas'
	}
});

const MainBoxHeader = ({
	isFullAutomatic,
	interactionList,
	selectList,
	onSearch,
	search,
	interactionQuantity,
	hiddenSearch,
	talkingInteractionsCount,
	loading,
	intl,
	notifyInboxMessage
}) => {
	const {
		inbox, queue, missed
	} = interactionQuantity;
	const { formatMessage } = intl;

	const tabs = isFullAutomatic ? [
		{
			title: formatMessage(messages.talking),
			info: talkingInteractionsCount,
			click: () => selectList('talking'),
			active: interactionList === 'talking',
			notify: false
		},
		{
			title: formatMessage(messages.inbox),
			info: inbox > 0 ? inbox : null,
			click: () => selectList('inbox'),
			active: interactionList === 'inbox',
			notify: notifyInboxMessage
		}
	] : [
		{
			title: formatMessage(messages.queue),
			info: queue > 0 ? queue : null,
			click: () => selectList('queue'),
			active: interactionList === 'queue',
			notify: false
		},
		{
			title: formatMessage(messages.inbox),
			info: inbox > 0 ? inbox : null,
			click: () => selectList('inbox'),
			active: interactionList === 'inbox',
			notify: notifyInboxMessage
		},
		{
			title: formatMessage(messages.missed),
			info: missed > 0 ? missed : null,
			click: () => selectList('missed'),
			active: interactionList === 'missed',
			notify: false
		}
	];

	return (
		<div className="MainBoxHeader">
			<div className="MainBoxHeader__actions">
				<ToggleNav infos={tabs} />
			</div>
			{hiddenSearch && (
				<>
					{interactionList === 'inbox' && inbox > 0 && (
						<Input
							onChange={onSearch}
							value={search}
							placeholder={formatMessage(messages.searchInbox)}
							loading={loading}
						/>
					)}
					{interactionList === 'queue' && queue > 0 && (
						<Input
							onChange={onSearch}
							value={search}
							placeholder={formatMessage(messages.searchQueue)}
							loading={loading}
						/>
					)}
					{interactionList === 'missed' && missed > 0 && (
						<Input
							onChange={onSearch}
							value={search}
							placeholder={formatMessage(messages.searchMissed)}
							loading={loading}
						/>
					)}
				</>
			)}
		</div>
	);
};

MainBoxHeader.propTypes = {
	isFullAutomatic: PropTypes.bool,
	interactionList: PropTypes.oneOf(['talking', 'queue', 'inbox', 'missed']).isRequired,
	selectList: PropTypes.func.isRequired,
	talkingInteractionsCount: PropTypes.number.isRequired,
	interactionQuantity: PropTypes.shape({
		talking: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		inbox: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		queue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		missed: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
	}).isRequired,
	onSearch: PropTypes.func.isRequired,
	search: PropTypes.string.isRequired,
	loading: PropTypes.bool,
	hiddenSearch: PropTypes.bool,
	intl: PropTypes.shape({
		formatMessage: PropTypes.func.isRequired
	}).isRequired,
	notifyInboxMessage: PropTypes.bool.isRequired
};

export default injectIntl(MainBoxHeader);
