import './index.sass';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import moment from 'moment';

import messages from './translations';
import * as actions from '../../../store/actions';
import Spinner from '../../Atoms/Spinner';

import Box from '../../Molecules/Box';

import Tooltip from '../../Atoms/Tooltip';
import ImgSvg from '../../Atoms/ImgSvg';
import Wrapper from '../../Atoms/Wrapper';
import RatingBar from '../../Atoms/RatingBar';

import Table from '../Table';
import MultiSelect from '../../Molecules/Inputs/MultiSelect';

const DashboardBox = ({
	intl,
	agentDashboard,
	peersDashboard,
	fetchAgentDashboardStatus,
	fetchPeersDashboardStatus,
	onFetchAgentDashboard,
	onFetchPeersDashboard,
	departmentList,
	showVoiceTrends
}) => {
	const [activeTab, setActiveTab] = useState('self');
	const [departmentsFilter, setDepartmentsFilter] = useState();
	const [periodFilter, setPeriodFilter] = useState('today');

	const {
		adherence,
		productiveTime,
		averageServiceTime,
		mood,
		voice,
		channels
	} = agentDashboard;
	const {
		agents,
		departments
	} = peersDashboard;

	const { loading: agentLoading } = fetchAgentDashboardStatus;
	const { loading: peersLoading } = fetchPeersDashboardStatus;

	const periodOptions = [
		{ value: 'today', label: intl.formatMessage(messages.today) },
		{ value: 'yesterday', label: intl.formatMessage(messages.yesterday) },
		{ value: 'last7days', label: intl.formatMessage(messages.last7days) },
		{ value: 'last30days', label: intl.formatMessage(messages.last30days) },
		{ value: 'last90days', label: intl.formatMessage(messages.last90days) }
	];

	const periodsConfig = {
		today: {
			from: moment().startOf('day').format('YYYY-MM-DD[T00:00:00]'),
			to: moment().startOf('day').format('YYYY-MM-DD[T23:59:59]')
		},
		yesterday: {
			from: moment().subtract(1, 'day').format('YYYY-MM-DD[T00:00:00]'),
			to: moment().subtract(1, 'day').format('YYYY-MM-DD[T23:59:59]')
		},
		last7days: {
			from: moment().subtract(7, 'day').format('YYYY-MM-DD[T00:00:00]'),
			to: moment().startOf('day').format('YYYY-MM-DD[T23:59:59]')
		},
		last30days: {
			from: moment().subtract(30, 'day').format('YYYY-MM-DD[T00:00:00]'),
			to: moment().startOf('day').format('YYYY-MM-DD[T23:59:59]')
		},
		last90days: {
			from: moment().subtract(90, 'day').format('YYYY-MM-DD[T00:00:00]'),
			to: moment().startOf('day').format('YYYY-MM-DD[T23:59:59]')
		}
	};

	const handleFilters = (filter) => {
		let periodData = '';
		let departmentData = '';

		if (Object.keys(filter)[0] === 'period') {
			periodData = filter.period;
			departmentData = departmentsFilter;
		} else if (Object.keys(filter)[0] === 'selectedDepartments') {
			periodData = periodFilter;
			departmentData = filter.selectedDepartments;
		} else {
			departmentData = departmentsFilter;
			periodData = periodFilter;
		}

		onFetchAgentDashboard({ departments: departmentData, from: periodsConfig[periodData].from, to: periodsConfig[periodData].to });
	};

	const renderMetrics = () => (
		<div className="DashboardBox__wrapper__row">
			{/* NPS */}
			{(!!mood.npsPromoters || !!mood.npsNeutrals || !!mood.npsDetractors) && (
				<Box size="medium" header={intl.formatMessage(messages.npsData)}>
					<div className="DashboardBox__wrapper__nps">
						<div className="DashboardBox__wrapper__row__content__column">
							<span className="DashboardBox__wrapper__row__content__column__title">{intl.formatMessage(messages.npsScore)}</span>
							<span className="DashboardBox__wrapper__row__content__column__description">{mood.npsRate}</span>
						</div>
						<div className="DashboardBox__wrapper__nps__content">
							<div className="DashboardBox__wrapper__nps__content__item">
								<span className="DashboardBox__wrapper__nps__content__item__title">{intl.formatMessage(messages.promoters)}</span>
								<div className="DashboardBox__wrapper__nps__content__item__percent">
									<span>
										{mood.npsPromoters.toFixed(1).replace(/\.0$/, '')}
										%
									</span>
									<ImgSvg color="#7EB23C" name="promoters" />
								</div>
								<RatingBar percent={mood.npsPromoters} backgroundColor="#7EB23C" width="120px" />
							</div>
							<div className="DashboardBox__wrapper__nps__content__item">
								<span className="DashboardBox__wrapper__nps__content__item__title">{intl.formatMessage(messages.liabilities)}</span>
								<div className="DashboardBox__wrapper__nps__content__item__percent">
									<span>
										{mood.npsNeutrals.toFixed(1).replace(/\.0$/, '')}
										%
									</span>
									<ImgSvg color="#FF9720" name="liabilities" />
								</div>
								<RatingBar percent={mood.npsNeutrals} backgroundColor="#FF9720" width="120px" />
							</div>
							<div className="DashboardBox__wrapper__nps__content__item">
								<span className="DashboardBox__wrapper__nps__content__item__title">{intl.formatMessage(messages.detractors)}</span>
								<div className="DashboardBox__wrapper__nps__content__item__percent">
									<span>
										{mood.npsDetractors.toFixed(1).replace(/\.0$/, '')}
										%
									</span>
									<ImgSvg color="#B00020" name="detractors" />
								</div>
								<RatingBar percent={mood.npsDetractors} backgroundColor="#B00020" width="120px" />
							</div>
						</div>
					</div>
				</Box>
			)}
			{/* STAR */}
			{!!mood.starsAverage && (
				<Box size="small" header={intl.formatMessage(messages.averageCustomerSatisfactionStar)}>
					<div className="DashboardBox__wrapper__survey">
						<div
							style={{ '--rating': mood.starsAverage }}
							className="DashboardBox__wrapper__survey__stars"
							aria-label="Rating of this product is 2.3 out of 5."
						/>
						<span className="DashboardBox__wrapper__survey__rating">
							{mood.starsAverage}
							/5
						</span>
					</div>
				</Box>
			)}
			{/* POSITIVE/NEGATIVE */}
			{(!!mood.thumbsAverage || !!mood.thumbsNegative) && (
				<Box size="small" header={intl.formatMessage(messages.averageSatisfactionPositiveNegative)}>
					<div className="DashboardBox__wrapper__bars">
						<div className="DashboardBox__wrapper__bars__bar">
							<span className="DashboardBox__wrapper__bars__bar__title">{intl.formatMessage(messages.positive)}</span>
							<RatingBar percent={mood.thumbsAverage} />
							<span className="DashboardBox__wrapper__bars__bar__percent">
								{mood.thumbsAverage}
								% (
								{mood.thumbsPositive}
								)
							</span>
						</div>
						<div className="DashboardBox__wrapper__bars__bar">
							<span className="DashboardBox__wrapper__bars__bar__title">{intl.formatMessage(messages.negative)}</span>
							<RatingBar percent={mood.thumbsAverage || mood.thumbsNegative ? 100 - mood.thumbsAverage : 0} backgroundColor="#B00020" />
							<span className="DashboardBox__wrapper__bars__bar__percent">
								{mood.thumbsAverage || mood.thumbsNegative ? 100 - mood.thumbsAverage : 0}
								% (
								{mood.thumbsNegative}
								)
							</span>
						</div>
					</div>
				</Box>
			)}
		</div>
	);

	const handleShowMetric = () => {
		const hasNotMetric = Object.values(mood).every(value => !value);

		if (hasNotMetric) {
			return (
				<div className="DashboardBox__wrapper__metrics">
					<span className="DashboardBox__wrapper__metrics__title">{intl.formatMessage(messages.noMetricsTitle)}</span>
					<p className="DashboardBox__wrapper__metrics__description">{intl.formatMessage(messages.noMetricsDescription)}</p>
				</div>
			);
		}
		return renderMetrics();
	};

	return (
		<div className="DashboardBox" data-testid="component-dashboardBox">
			<div className="DashboardBox__header">
				<h2>{intl.formatMessage(messages.pageTitle)}</h2>
				<button
					type="button"
					disabled={agentLoading}
					onClick={() => (activeTab === 'self' ? handleFilters({}) : onFetchPeersDashboard({ firstLoading: true }))}
					data-testid="component-dashboardBox-buttonRefresh"
				>
					{intl.formatMessage(messages.refresh)}
				</button>
			</div>
			<div className="DashboardBox__content">
				<div className="DashboardBox__tabs">
					<Wrapper
						action={() => setActiveTab('self')}
						className={`DashboardBox__tabs__tab ${activeTab === 'self' ? 'DashboardBox__tabs__tab--active' : ''}`}
					>
						{intl.formatMessage(messages.yourInformation)}
					</Wrapper>
					<Wrapper
						action={() => {
							setActiveTab('peers');
							onFetchPeersDashboard({ firstLoading: true });
						}}
						className={`DashboardBox__tabs__tab ${activeTab === 'peers' ? 'DashboardBox__tabs__tab--active' : ''}`}
					>
						{intl.formatMessage(messages.peersInformation)}
					</Wrapper>
				</div>
				{activeTab === 'self' && (
				<>
					<div className="DashboardBox__wrapper">
						<div className="DashboardBox__wrapper__filters">
							<MultiSelect
								options={departmentList}
								onChange={handleFilters}
								setFilter={setDepartmentsFilter}
								keyName="selectedDepartments"
								icon="departments"
								placeholder={intl.formatMessage(messages.allDepartments)}
								defaultValue={departmentList.filter(dep => departmentsFilter && departmentsFilter.includes(dep.value))}
							/>
							<MultiSelect
								options={periodOptions}
								onChange={handleFilters}
								setFilter={setPeriodFilter}
								defaultValue={periodOptions.find(opt => opt.value === periodFilter)}
								keyName="period"
								icon="calendar"
								width={230}
								isMultiple={false}
							/>
						</div>
						{agentLoading ? <Spinner /> : (
							<>
								<div className="DashboardBox__wrapper__row">
									<Box size="medium" header={intl.formatMessage(messages.serviceTrends)}>
										<div className="DashboardBox__wrapper__row__content">
											<div className="DashboardBox__wrapper__row__content__column">
												<span className="DashboardBox__wrapper__row__content__column__title">{intl.formatMessage(messages.totalAttendances)}</span>
												<span className="DashboardBox__wrapper__row__content__column__description">{channels.total}</span>
											</div>
											<div className="DashboardBox__wrapper__row__content__column">
												<span className="DashboardBox__wrapper__row__content__column__title">{intl.formatMessage(messages.callsTransferred)}</span>
												<span className="DashboardBox__wrapper__row__content__column__description">{channels.transferred}</span>
											</div>
											<div className="DashboardBox__wrapper__row__content__column">
												<span className="DashboardBox__wrapper__row__content__column__title">{intl.formatMessage(messages.completedCalls)}</span>
												<span className="DashboardBox__wrapper__row__content__column__description">{channels.ended}</span>
											</div>
										</div>
									</Box>
									{showVoiceTrends && (
										<Box size="medium" header={intl.formatMessage(messages.voiceTrends)}>
											<div className="DashboardBox__wrapper__row__content">
												<div className="DashboardBox__wrapper__row__content__column">
													<span className="DashboardBox__wrapper__row__content__column__title">{intl.formatMessage(messages.totalAttendances)}</span>
													<span className="DashboardBox__wrapper__row__content__column__description">{voice.total}</span>
												</div>
												<div className="DashboardBox__wrapper__row__content__column">
													<span className="DashboardBox__wrapper__row__content__column__title">{intl.formatMessage(messages.callsTransferred)}</span>
													<span className="DashboardBox__wrapper__row__content__column__description">{voice.transferred}</span>
												</div>
												<div className="DashboardBox__wrapper__row__content__column">
													<span className="DashboardBox__wrapper__row__content__column__title">{intl.formatMessage(messages.completedCalls)}</span>
													<span className="DashboardBox__wrapper__row__content__column__description">{voice.ended}</span>
												</div>
											</div>
										</Box>
									)}
									<Box size="small" header="Produtividade">
										<div className="DashboardBox__wrapper__row__content">
											<div className="DashboardBox__wrapper__row__content__column">
												<span className="DashboardBox__wrapper__row__content__column__title">{intl.formatMessage(messages.productiveHours)}</span>
												<span className="DashboardBox__wrapper__row__content__column__description">{productiveTime}</span>
											</div>
											<div className="DashboardBox__wrapper__row__content__column">
												<span className="DashboardBox__wrapper__row__content__column__title">{intl.formatMessage(messages.adherence)}</span>
												<span className="DashboardBox__wrapper__row__content__column__description">
													{parseFloat(adherence).toFixed(2)}
													%
												</span>
											</div>
										</div>
									</Box>
									<Box size="small" header="Performance de atendimento">
										<div className="DashboardBox__wrapper__row__content">
											<div className="DashboardBox__wrapper__row__content__column">
												<span className="DashboardBox__wrapper__row__content__column__title">{intl.formatMessage(messages.averageServiceTime)}</span>
												<span className="DashboardBox__wrapper__row__content__column__description">{averageServiceTime}</span>
											</div>
										</div>
									</Box>
								</div>

								{handleShowMetric()}
							</>
						)}
					</div>
				</>
				)}

				{activeTab === 'peers' && (
				<>
					{agentLoading || peersLoading ? <Spinner /> : (
						<div className="DashboardBox__wrapper">
							<div className="DashboardBox__wrapper__row">
								<Box header="Disponibilidade geral">
									<div className="DashboardBox__wrapper__row__content">
										<div className="DashboardBox__wrapper__row__content__column">
											<span className="DashboardBox__wrapper__row__content__column__title">{intl.formatMessage(messages.logged)}</span>
											<span className="DashboardBox__wrapper__row__content__column__description">
												<span className="DashboardBox__wrapper__row__content__column__description__status--online" />
												{agents.online}
											</span>
										</div>
										<div className="DashboardBox__wrapper__row__content__column">
											<span className="DashboardBox__wrapper__row__content__column__title">{intl.formatMessage(messages.preBreak)}</span>
											<span className="DashboardBox__wrapper__row__content__column__description">
												<span className="DashboardBox__wrapper__row__content__column__description__status--preBreak" />
												{agents.preBreak}
											</span>
										</div>
										<div className="DashboardBox__wrapper__row__content__column">
											<span className="DashboardBox__wrapper__row__content__column__title">{intl.formatMessage(messages.break)}</span>
											<span className="DashboardBox__wrapper__row__content__column__description">
												<span className="DashboardBox__wrapper__row__content__column__description__status--break" />
												{agents.break}
											</span>
										</div>
										<div className="DashboardBox__wrapper__row__content__column">
											<span className="DashboardBox__wrapper__row__content__column__title">{intl.formatMessage(messages.offline)}</span>
											<span className="DashboardBox__wrapper__row__content__column__description">
												<span className="DashboardBox__wrapper__row__content__column__description__status--offline" />
												{agents.offline}
											</span>
										</div>
									</div>
								</Box>
							</div>

							<div className="DashboardBox__wrapper__row">
								<div className="DashboardBox__wrapper__row__table">
									<span className="DashboardBox__wrapper__row__table__title">
										{intl.formatMessage(messages.departmentTableTitle)}
										{' '}
										<Tooltip header={<ImgSvg name="info-circle" />} position="right">
											{intl.formatMessage(messages.agentStatusTooltip)}
										</Tooltip>
									</span>
									<Table
										columns={[
											{ key: 'name', value: intl.formatMessage(messages.department), width: 250 },
											{ key: 'online', value: intl.formatMessage(messages.online) },
											{ key: 'unavailable', value: intl.formatMessage(messages.unavailable) },
											{ key: 'preBreak', value: intl.formatMessage(messages.preBreak) },
											{ key: 'break', value: intl.formatMessage(messages.break) },
											{ key: 'offline', value: intl.formatMessage(messages.offline) }
										]}
										dataSource={departments.data}
										pagination={{
											total: departments.total
										}}
										onChange={onFetchPeersDashboard}
									/>
								</div>
							</div>
						</div>
					)}
				</>
				)}
			</div>
		</div>
	);
};

DashboardBox.propTypes = {
	intl: PropTypes.shape({
		formatMessage: PropTypes.func.isRequired
	}).isRequired,
	fetchAgentDashboardStatus: PropTypes.shape({
		loading: PropTypes.bool
	}).isRequired,
	fetchPeersDashboardStatus: PropTypes.shape({
		loading: PropTypes.bool
	}).isRequired,
	onFetchAgentDashboard: PropTypes.func.isRequired,
	onFetchPeersDashboard: PropTypes.func.isRequired,
	departmentList: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.number,
			name: PropTypes.string
		})
	).isRequired,
	agentDashboard: PropTypes.shape({
		adherence: PropTypes.number,
		averageServiceTime: PropTypes.string,
		productiveTime: PropTypes.string,
		channels: PropTypes.shape({
			ended: PropTypes.number,
			transferred: PropTypes.number,
			total: PropTypes.number
		}),
		voice: PropTypes.shape({
			ended: PropTypes.number,
			transferred: PropTypes.number,
			total: PropTypes.number
		}),
		mood: PropTypes.shape({
			npsDetractors: PropTypes.number,
			npsNeutrals: PropTypes.number,
			npsPromoters: PropTypes.number,
			npsRate: PropTypes.number,
			npsAverage: PropTypes.number,
			starsAverage: PropTypes.number,
			thumbsAverage: PropTypes.number,
			thumbsPositive: PropTypes.number,
			thumbsNegative: PropTypes.number
		})
	}).isRequired,
	peersDashboard: PropTypes.shape({
		agents: PropTypes.shape({
			online: PropTypes.number,
			preBreak: PropTypes.number,
			break: PropTypes.number,
			offline: PropTypes.number
		}),
		departments: PropTypes.shape({
			data: PropTypes.arrayOf(
				PropTypes.shape({
					name: PropTypes.string,
					break: PropTypes.number,
					offline: PropTypes.number,
					online: PropTypes.number,
					pre_break: PropTypes.number,
					unavailable: PropTypes.number
				})
			),
			total: PropTypes.number
		})
	}).isRequired,
	showVoiceTrends: PropTypes.bool
};

const mapStateToProps = ({ agent, dashboard, filters }) => ({
	fetchAgentDashboardStatus: dashboard.fetchAgentDashboardStatus,
	fetchPeersDashboardStatus: dashboard.fetchPeersDashboardStatus,
	agentDashboard: dashboard.agentDashboard,
	peersDashboard: dashboard.peersDashboard,
	departmentList: filters.departments,
	showVoiceTrends: !!agent.phoneData.station
});

const mapActionsToProps = dispatch => ({
	onFetchAgentDashboard: filter => dispatch(actions.fetchAgentDashboard(filter)),
	onFetchPeersDashboard: filter => dispatch(actions.fetchPeersDashboard(filter))
});

export default connect(mapStateToProps, mapActionsToProps)(injectIntl(DashboardBox));
