import React from 'react';
import PropTypes from 'prop-types';

import './index.sass';
import CheckBox from '../CheckBox';
import Text from '../../Atoms/Text';

const FilterMultipleItem = ({ filter }) => {
	const { label, selected, onClick } = filter;

	return (
		<div className="FilterMultipleItem">
			<CheckBox selected={selected} onClick={onClick} />
			<Text>{label}</Text>
		</div>
	);
};

FilterMultipleItem.propTypes = {
	filter: PropTypes.shape({
		label: PropTypes.string.isRequired,
		selected: PropTypes.bool.isRequired,
		onClick: PropTypes.func.isRequired
	}).isRequired
};

export default FilterMultipleItem;
