/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { defineMessages, injectIntl } from 'react-intl';

import ModalDefault from '../../Sirius/ModalDefault';

import './index.sass';
import * as actions from '../../../store/actions';
import Button from '../../Molecules/Buttons/Button';


const messages = defineMessages({
	AutomaticallyPauseTitle: {
		id: 'breakStatus.breakTime.AutomaticallyPause.title',
		defaultMessage: 'Status de Pausa'
	},
	AutomaticallyPauseLabel: {
		id: 'breakStatus.breakTime.AutomaticallyPause.tlabel',
		defaultMessage: 'Você concluiu todos os seus atendimentos e foi automaticamente colocado no status de Pausa.'
	},
	AutomaticallyPauseButton: {
		id: 'breakStatus.breakTime.AutomaticallyPause.button',
		defaultMessage: 'Entendi'
	}
});

const AutomaticallyPause = ({
	intl,
	onClose
}) => {
	const { formatMessage } = intl;

	return (
		<ModalDefault.Root
			isOpen
			onClose={onClose}
		>
			<div className="AutomaticallyPause">
				<ModalDefault.Header
					title={formatMessage(messages.AutomaticallyPauseTitle)}
					onClose={onClose}
					className="has-background-grey-lighter"
				/>
				<ModalDefault.Body className="has-background-grey-lighter is-overflow-hidden AutomaticallyPause__label">
					<p dangerouslySetInnerHTML={{ __html: intl.formatMessage(messages.AutomaticallyPauseLabel) }} />
				</ModalDefault.Body>

				<ModalDefault.Footer className="has-background-grey-lighter">
					<div className="is-flex">
						<Button className="button is-primary is-large" click={onClose}>{formatMessage(messages.AutomaticallyPauseButton)}</Button>
					</div>
				</ModalDefault.Footer>

			</div>
		</ModalDefault.Root>
	);
};

const mapActionsToProps = dispatch => ({
	addNotification: notification => dispatch(actions.addNotification(notification))
});

AutomaticallyPause.propTypes = {
	intl: PropTypes.shape({
		formatMessage: PropTypes.func.isRequired
	}).isRequired,
	onClose: PropTypes.func.isRequired
};

export default connect(mapActionsToProps)(injectIntl(AutomaticallyPause));
