import React from 'react';
import PropTypes from 'prop-types';

import './index.sass';

const Tooltip = ({ children, header, position = 'center' }) => (
	<div className="Tooltip">
		{header}
		{children && (
			<div className={`Tooltip__body Tooltip__body--${position}`}>
				{children}
			</div>
		)}
	</div>
);

Tooltip.propTypes = {
	header: PropTypes.element.isRequired,
	position: PropTypes.oneOf(['left', 'right', 'center', 'top'])
};

export default Tooltip;
