import React from 'react';
import PropTypes from 'prop-types';

import './index.sass';

const RadioButton = ({ checked, id, onChange }) => (
	<input
		id={id}
		name={id}
		checked={checked}
		className="RadioButton"
		type="radio"
		onChange={onChange}
	/>
);

RadioButton.propTypes = {
	id: PropTypes.string.isRequired,
	checked: PropTypes.bool.isRequired,
	onChange: PropTypes.func.isRequired
};

export default RadioButton;
