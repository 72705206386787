import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { defineMessages, injectIntl } from 'react-intl';

import './index.sass';
import * as actions from '../../../store/actions';
import { useMountEffect } from '../../../shared/utility';
import Button from '../../Molecules/Buttons/Button';
import ImgSvg from '../../Atoms/ImgSvg';
import Modal from '../../Molecules/Modal';
import Wrapper from '../../Atoms/Wrapper';
import Text from '../../Atoms/Text';

const messages = defineMessages({
	header: {
		id: 'mergeCustomer.header',
		defaultMessage: 'Agrupar cadastros'
	},
	conflicts: {
		id: 'mergeCustomer.conflicts',
		defaultMessage: 'Há conflitos no cadastro, selecione abaixo os dados a serem mantidos.'
	},
	conflictsObs: {
		id: 'mergeCustomer.conflictsObs',
		defaultMessage: 'Os dados mais recentes são selecionados por padrão'
	},
	noConflicts: {
		id: 'mergeCustomer.noConflicts',
		defaultMessage: 'Não há conflitos entre os cadastros'
	},
	action: {
		id: 'mergeCustomer.action',
		defaultMessage: 'Agrupar'
	},
	merged: {
		id: 'mergeCustomer.merged',
		defaultMessage: 'Cadastro agrupado'
	},
	mergedContent: {
		id: 'mergeCustomer.mergedContent',
		defaultMessage: 'Os dados foram agrupados'
	}
});

const MergeCustomer = ({
	currentInteraction,
	oldFields,
	mergeCustomerStatus = {},
	onCloseMerge,
	onClearMergeStatus,
	activeEdit,
	intl,
	addNotification,
	mergeCustomer,
	oldCustomerKey,
	agent
}) => {
	const { formatMessage } = intl;
	const { customerInfo, interactionHash } = currentInteraction;
	const { fields, customerKey } = customerInfo;
	const { success, loading } = mergeCustomerStatus;
	const { id } = agent.info.account;
	const [mergedFields, setMergedFields] = useState([]);
	const [formattedForm, setFormattedForm] = useState([]);

	const onSelectValue = useCallback((identifier, value) => {
		const parsedFields = mergedFields.map((field) => {
			if (field.name === identifier) return { ...field, value };
			return field;
		});

		setMergedFields(parsedFields);
	}, [mergedFields]);

	useMountEffect(() => {
		const initialFormattedForm = [];
		fields.forEach(({ name }) => {
			const oldField = oldFields.find(oldFieldItem => oldFieldItem.name === name);
			const field = fields.find(fieldItem => fieldItem.name === name);

			if (oldField.value && field.value) {
				if (oldField.value === field.value) {
					onSelectValue(oldField.name, oldField.value);
				} else {
					initialFormattedForm.push({ [field.name]: [oldField, field] });
				}
			}

			if (oldField.value) onSelectValue(oldField.name, oldField.value);
			if (field.value) onSelectValue(field.name, field.value);
		});
		setMergedFields(fields);
		setFormattedForm(initialFormattedForm);
	});

	useEffect(() => {
		if (success) {
			addNotification({
				title: formatMessage(messages.merged),
				content: formatMessage(messages.mergedContent),
				type: 'success'
			});
			onCloseMerge();
			onClearMergeStatus();
			activeEdit();
		}
	}, [activeEdit, addNotification, formatMessage, onClearMergeStatus, onCloseMerge, success]);

	const onMergeCustomer = useCallback(() => {
		const oldCustomerAndFields = {
			oldCustomer: oldCustomerKey,
			fields: mergedFields
		};
		mergeCustomer({
			oldCustomerAndFields,
			customerKey,
			accountId: id,
			interactionHash
		});
	}, [customerKey, id, interactionHash, mergeCustomer, mergedFields, oldCustomerKey]);

	return (
		<Modal onClose={onCloseMerge} className="MergeCustomer">
			<div className="MergeCustomer__header">
				<Text>{formatMessage(messages.header)}</Text>
				{formattedForm.length > 0 && (
					<>
						<Text>{formatMessage(messages.conflicts)}</Text>
						<Text>{`*${formatMessage(messages.conflictsObs)}*`}</Text>
					</>
				)}
			</div>
			<div>
				<div className="MergeCustomer__info">
					{formattedForm.length > 0 ? formattedForm.map(formItem => (
						<div className="MergeCustomer__field" key={Object.values(formItem)[0][0].label}>
							<Text>{Object.values(formItem)[0][0].label}</Text>
							<div className="MergeCustomer__field__values">
								{Object.values(formItem)[0].map(({ name, value }, index) => {
									const isSelected = mergedFields.some(field => value === field.value);
									const customKey = `${name}${index}`;

									return (
										<Wrapper
											className={`CheckName ${isSelected ? 'CheckName--selected' : ''}`}
											action={() => onSelectValue(name, value)}
											key={customKey}
										>
											<Text>{value}</Text>
										</Wrapper>
									);
								})}
							</div>
						</div>
					)) : (
						<div className="MergeCustomer__emptyInfo">
							<div>
								<ImgSvg name="emoji-off" />
							</div>
							<Text>{formatMessage(messages.noConflicts)}</Text>
						</div>
					)}
				</div>
			</div>
			<div className="MergeCustomer__options">
				<Button click={onMergeCustomer} loading={loading}>
					{formatMessage(messages.action)}
				</Button>
			</div>
		</Modal>
	);
};

const mapStateToProps = ({ agent, interaction }) => {
	const {
		currentInteractionHash,
		interactions,
		mergeCustomerStatus,
		missedInteractions,
		pendingInteractions
	} = interaction;
	const allInteractions = interactions.concat(pendingInteractions).concat(missedInteractions);

	return ({
		agent,
		currentInteraction: allInteractions.find(({ interactionHash }) => interactionHash === currentInteractionHash) || {},
		mergeCustomerStatus
	});
};

const mapActionsToProps = dispatch => ({
	mergeCustomer: requestInfo => dispatch(actions.mergeCustomer(requestInfo)),
	onClearMergeStatus: () => dispatch(actions.clearMergeCustomerStatus()),
	addNotification: notification => dispatch(actions.addNotification(notification))
});

MergeCustomer.propTypes = {
	oldCustomerKey: PropTypes.string.isRequired,
	oldFields: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
	currentInteraction: PropTypes.shape({
		customerInfo: PropTypes.shape({
			customerKey: PropTypes.string,
			fields: PropTypes.arrayOf(PropTypes.shape({}))
		}),
		interactionHash: PropTypes.string
	}).isRequired,
	mergeCustomer: PropTypes.func.isRequired,
	agent: PropTypes.shape({
		info: PropTypes.shape({
			account: PropTypes.shape({
				id: PropTypes.number
			})
		})
	}).isRequired,
	mergeCustomerStatus: PropTypes.shape({
		loading: PropTypes.bool,
		success: PropTypes.bool
	}),
	onClearMergeStatus: PropTypes.func.isRequired,
	onCloseMerge: PropTypes.func.isRequired,
	activeEdit: PropTypes.func.isRequired,
	intl: PropTypes.shape({
		formatMessage: PropTypes.func
	}).isRequired,
	addNotification: PropTypes.func.isRequired
};

export default connect(mapStateToProps, mapActionsToProps)(injectIntl(MergeCustomer));
