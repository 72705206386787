import React from 'react';
import PropTypes from 'prop-types';

import './index.sass';

const Box = ({
	children,
	header = '',
	size = '',
	className = ''
}) => (
	<div className={`Box Box--${size} ${className}`}>
		<div className="Box__header">{header}</div>
		<div className="Box__body">
			{children}
		</div>
	</div>
);

Box.propTypes = {
	className: PropTypes.string,
	header: PropTypes.string,
	size: PropTypes.string
};

export default Box;
