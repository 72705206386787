import React from 'react';
import PropTypes from 'prop-types';

import './index.sass';

const CallWave = ({ animate = true }) => (
	<div className={`CallWave ${!animate ? 'CallWave--stop' : ''}`}>
		<div className="CallWave__bar is-third" />
		<div className="CallWave__bar is-second" />
		<div className="CallWave__bar is-first" />
		<div className="CallWave__bar is-second" />
		<div className="CallWave__bar is-third" />
	</div>
);

CallWave.propTypes = {
	animate: PropTypes.bool
};

export default CallWave;
