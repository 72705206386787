import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, defineMessages } from 'react-intl';

import './index.sass';
import Text from '../../Atoms/Text';
import AvatarName from '../../Atoms/AvatarName';
import IconButton from '../Buttons/IconButton';
import Wrapper from '../../Atoms/Wrapper';
import Modal from '../Modal';

const messages = defineMessages({
	calling: {
		id: 'receptiveCall.calling',
		defaultMessage: 'Chamando'
	},
	accept: {
		id: 'receptiveCall.accept',
		defaultMessage: 'Aceitar'
	},
	refuse: {
		id: 'receptiveCall.refuse',
		defaultMessage: 'Recusar'
	}
});

const ReceptiveCallModal = ({
	closeReceptiveModal,
	interactionHash,
	intl,
	updateInteractionStatus
}) => {
	const { formatMessage } = intl;
	const acceptCall = useCallback(() => {
		if (window.ttvSdk.connected) window.ttvSdk.answer();
		window.omzVish.acceptInteraction(interactionHash);
		updateInteractionStatus('TALKING', interactionHash);
		closeReceptiveModal();
	}, [closeReceptiveModal, interactionHash, updateInteractionStatus]);
	const declineCall = useCallback(() => {
		if (window.ttvSdk.connected) {
			window.ttvSdk.refusedId = window.ttvSdk.chamadaId;
			window.ttvSdk.hangup();
		}
		closeReceptiveModal();
	}, [closeReceptiveModal]);

	return (
		<Modal className="ReceptiveCallModal">
			<div className="ReceptiveCallModal__user">
				<AvatarName />
				<Text size="bigger">{`${formatMessage(messages.calling)}...`}</Text>
			</div>
			<div className="ReceptiveCallModal__call">
				<div className="ReceptiveCallModal__call__actions">
					<Wrapper
						className="ReceptiveCallModal__call__actions__item"
						action={declineCall}
					>
						<IconButton name="decline" fill="red" />
						<Text size="big">{formatMessage(messages.refuse)}</Text>
					</Wrapper>
					<Wrapper
						className="ReceptiveCallModal__call__actions__item"
						action={acceptCall}
					>
						<IconButton name="accept" fill="green" />
						<Text size="big">{formatMessage(messages.accept)}</Text>
					</Wrapper>
				</div>
			</div>
		</Modal>
	);
};

ReceptiveCallModal.propTypes = {
	closeReceptiveModal: PropTypes.func.isRequired,
	interactionHash: PropTypes.string.isRequired,
	intl: PropTypes.shape({
		formatMessage: PropTypes.func
	}).isRequired,
	updateInteractionStatus: PropTypes.func.isRequired
};

export default injectIntl(ReceptiveCallModal);
