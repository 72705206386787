import React from 'react';
import PropTypes from 'prop-types';

import './index.sass';
import ImgSvg from '../ImgSvg';

const tickIcons = {
	sent: 'clock-off',
	received: 'confirm-changes',
	delivered: 'read',
	read: 'read',
	readed: 'read'
};

const CheckTick = ({ status = 'sent' }) => (
	<div className={`CheckTick CheckTick--${tickIcons[status.toLowerCase()] || 'read'}`} data-testid={`component-check-tick-${status || 'read'}`}>
		<ImgSvg name={tickIcons[status.toLowerCase()] || 'read'} data-testid={`component-check-tick-img-${status || 'read'}`} />
	</div>
);

CheckTick.propTypes = {
	status: PropTypes.oneOf(['', 'sent', 'received', 'delivered', 'read', 'READED'])
};

export default CheckTick;
