import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import './index.sass';
import Loader from '../../../Atoms/Loader';

const Button = ({
	className = '',
	click,
	children,
	loading,
	success,
	active = true,
	onlyText,
	secondary,
	isDark,
	'data-testid': dataTestId
}) => {
	const [classes, setClasses] = useState([]);
	useEffect(() => {
		const currentClasses = [className, `Button button ${!secondary && !onlyText && !isDark ? 'is-primary' : ''}`];

		if (!active) currentClasses.push('Button--inactive');
		if (success && !loading) currentClasses.push('Button--success');
		if (onlyText) currentClasses.push('is-text');
		if (secondary) currentClasses.push('Button--secondary');
		if (isDark) currentClasses.push('Button--secondary--dark');

		setClasses(currentClasses.join(' '));
	}, [className, active, success, loading, onlyText, secondary, isDark]);

	return (
		<button
			className={classes}
			onClick={active ? () => click() : () => {}}
			onKeyPress={active ? () => click() : () => {}}
			type="button"
			data-testid={dataTestId}
		>
			{loading ? <Loader /> : children}
		</button>
	);
};

Button.propTypes = {
	className: PropTypes.string,
	click: PropTypes.func.isRequired,
	loading: PropTypes.bool,
	success: PropTypes.bool,
	active: PropTypes.bool,
	onlyText: PropTypes.bool,
	isDark: PropTypes.bool,
	secondary: PropTypes.bool,
	'data-testid': PropTypes.string
};

export default Button;
