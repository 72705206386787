import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, injectIntl } from 'react-intl';

import './index.sass';

import { MESSAGE_CONTENT_TYPE } from '../../../shared/consts';

import Button from '../Buttons/Button';
import IconButton from '../Buttons/IconButton';
import Modal from '../Modal';
import Text from '../../Atoms/Text';
import ImgSvg from '../../Atoms/ImgSvg';

const messages = defineMessages({
	title: {
		id: 'fileUploadConfirmation.title',
		defaultMessage: 'Deseja enviar este arquivo?'
	},
	send: {
		id: 'fileUploadConfirmation.send',
		defaultMessage: 'Enviar'
	},
	cancel: {
		id: 'fileUploadConfirmation.cancel',
		defaultMessage: 'Cancelar'
	},
	expandImage: {
		id: 'dragAndDropFileUpload.expandImage',
		defaultMessage: 'Expandir imagem'
	},
	downloadFile: {
		id: 'dragAndDropFileUpload.downloadFile',
		defaultMessage: 'Baixar arquivo'
	}
});

const FileUploadConfirmation = ({
	file,
	onClose,
	onSend,
	intl
}) => {
	const getVerifiedContentType = (contentType) => {
		let result = contentType;

		if (result.match(MESSAGE_CONTENT_TYPE.IMAGE)) {
			result = MESSAGE_CONTENT_TYPE.IMAGE;
		} else if (result.match(MESSAGE_CONTENT_TYPE.AUDIO)) {
			result = MESSAGE_CONTENT_TYPE.AUDIO;
		} else if (result.match(MESSAGE_CONTENT_TYPE.VIDEO)) {
			result = MESSAGE_CONTENT_TYPE.VIDEO;
		}

		return result;
	};

	const getComponentByType = (messageInfo) => {
		const verifiedContentType = getVerifiedContentType(messageInfo.filetype);

		switch (verifiedContentType) {
		case MESSAGE_CONTENT_TYPE.IMAGE:
			return (
				<>
					<a className="FileUploadConfirmation__wrapper__content__file__icon" href={messageInfo.url} target="_blank" rel="noopener noreferrer">
						<IconButton ariaLabel={intl.formatMessage(messages.expandImage)} name="expand" fill="grey-light" />
					</a>
					<img src={messageInfo.url} alt="media" />
				</>
			);
		case MESSAGE_CONTENT_TYPE.AUDIO:
			return (
				<audio src={messageInfo.url} controls><track kind="captions" /></audio>
			);
		case MESSAGE_CONTENT_TYPE.VIDEO:
			return (
				<video src={messageInfo.url} controls><track kind="captions" /></video>
			);
		default:
			return (
				<div className="FileUploadConfirmation__wrapper__content__file__default">
					<a
						href={messageInfo.url}
						target="_blank"
						download
						rel="noopener noreferrer"
						aria-label={intl.formatMessage(messages.downloadFile)}
					>
						<ImgSvg name="attach-off" />
					</a>
					<Text>{messageInfo.filename}</Text>
				</div>
			);
		}
	};

	return (
		<div className="FileUploadConfirmation">
			<Modal
				className="FileUploadConfirmation__wrapper"
				onClose={onClose}
			>
				<div className="FileUploadConfirmation__wrapper__content">
					<h3>
						{intl.formatMessage(messages.title)}
					</h3>
					<div className="FileUploadConfirmation__wrapper__content__file">
						{getComponentByType(file)}
					</div>
					<div className="FileUploadConfirmation__wrapper__content__actions">
						<Button
							data-testid="cancel-attachment"
							click={onClose}
							selected
						>
							{intl.formatMessage(messages.cancel)}
						</Button>
						<Button
							data-testid="send-attachment"
							click={onSend}
							selected
						>
							{intl.formatMessage(messages.send)}
						</Button>
					</div>
				</div>
			</Modal>
		</div>
	);
};

FileUploadConfirmation.propTypes = {
	file: PropTypes.shape({
		filetype: PropTypes.string
	}).isRequired,
	onClose: PropTypes.func.isRequired,
	onSend: PropTypes.func.isRequired,
	intl: PropTypes.shape({
		formatMessage: PropTypes.func.isRequired
	}).isRequired
};

export default injectIntl(FileUploadConfirmation);
