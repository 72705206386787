import React, {
	useCallback, useState, useEffect, useMemo
} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { debounce } from 'lodash';
import { injectIntl, defineMessages } from 'react-intl';

import './index.sass';
import { getCustomerFieldValue, usePrevious } from '../../../shared/utility';
import * as actions from '../../../store/actions';
import ChatHeader from '../ChatHeader';
import ChatInput from '../ChatInput';
import ReceivingWhatsappCall from '../../Molecules/ReceivingWhatsappCall';
import CallDuration from '../../Molecules/CallDuration';
import InternalMessages from '../../Molecules/InternalMessages';
import ChatMail from '../ChatMail';
import MessageList from '../MessageList';
import DragAndDropFileUpload from '../../Molecules/DragAndDropFileUpload';
import PhoneInteraction from '../PhoneInteraction';

const messagesIntl = defineMessages({
	previewMessage: {
		id: 'chatBox.previewMessage',
		defaultMessage: 'Esta é uma pré-visualização da conversa, para iniciar este atendimento clique no botão de ”atender” acima.'
	}
});

const ChatBox = ({
	currentInteraction,
	isHistoryInteraction,
	onFinishInteraction,
	agent,
	closeReceptiveModal,
	blockedToInteract,
	newWhatsappVoiceCall,
	onNewWhatsappVoiceCall,
	onNewWhatsappMediaStream,
	newWhatsappMediaStream,
	onWhatsappCallDuration,
	whatsappCallDuration,
	updateCallDuration,
	showingInternalMessages,
	intl
}) => {
	const {
		messages,
		typing,
		interactionType,
		startTime,
		createdAt,
		customerInfo,
		currentState,
		endTime,
		finishedAt,
		media,
		interactionHash
	} = currentInteraction;
	const { formatMessage } = intl;
	const showPhoneInteraction = interactionType.includes('PHONE') && !['MISSED', 'REPLYING'].includes(currentState);
	const isEnded = currentState === 'ENDED';
	const isEmail = ['offcontact', 'email'].includes(interactionType.toLowerCase());
	const prevInteractionHash = usePrevious(interactionHash);

	const [openedDragAndDrop, setOpenedDragAndDrop] = useState(false);
	const [hasFileDragged, setHasFileDragged] = useState(false);
	const [acceptedCallSdp, setAcceptedCallSdp] = useState(null);

	const finishInteraction = useCallback(() => onFinishInteraction({
		interactionHash,
		media,
		id: agent.info.account.id
	}), [agent.info.account.id, interactionHash, media, onFinishInteraction]);

	const showReceivingWhatsappCall = useMemo(() => interactionHash === newWhatsappVoiceCall.interactionHash, [interactionHash, newWhatsappVoiceCall.interactionHash]);
	const showCallDuration = useMemo(() => interactionHash === whatsappCallDuration.interactionHash, [interactionHash, whatsappCallDuration.interactionHash]);

	useEffect(() => {
		if (interactionHash !== prevInteractionHash) {
			setOpenedDragAndDrop(false);
		}
	}, [
		interactionHash,
		prevInteractionHash
	]);

	const debouncedDrag = debounce((status) => {
		if (!isHistoryInteraction) {
			setOpenedDragAndDrop(status);
		}
	}, 100, { leading: !openedDragAndDrop });

	const checkIfDragFileIsValid = () => {
		setTimeout(() => {
			if (!hasFileDragged) {
				setOpenedDragAndDrop(false);
			}
		}, 1000);
	};

	async function getAnswerSdp(offerSdp) {
		try {
			const peerConnection = new RTCPeerConnection();

			peerConnection.ontrack = (event) => {
				const audioElement = document.createElement('audio');
				// eslint-disable-next-line prefer-destructuring
				audioElement.srcObject = event.streams[0];
				audioElement.autoplay = true;
				document.body.appendChild(audioElement);
			};

			const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
			onNewWhatsappMediaStream(stream);
			stream.getTracks().forEach(track => peerConnection.addTrack(track, stream));

			await peerConnection.setRemoteDescription(
				new RTCSessionDescription({
					type: 'offer',
					sdp: offerSdp
				})
			);


			const answer = await peerConnection.createAnswer();
			await peerConnection.setLocalDescription(answer);

			return answer.sdp;
		} catch (error) {
			// eslint-disable-next-line no-console
			console.error('Error generating SDP Answer:', error);
			return null;
		}
	}

	const handleAcceptCall = async () => {
		const answerSdp = await getAnswerSdp(newWhatsappVoiceCall.data.sdp);
		window.omzVish.acceptWhatsappVoiceCall(interactionHash, answerSdp);
		onWhatsappCallDuration({ interactionHash });
		setAcceptedCallSdp(answerSdp);
	};

	const handleRefuseCall = async () => {
		const answerSdp = await getAnswerSdp(newWhatsappVoiceCall.data.sdp);
		window.omzVish.rejectWhatsappVoiceCall(interactionHash, answerSdp);
		onNewWhatsappVoiceCall({});
	};

	const handleEndCall = () => {
		if (acceptedCallSdp) {
			window.omzVish.terminateWhatsappVoiceCall(interactionHash, acceptedCallSdp);
		}

		onWhatsappCallDuration({});

		onNewWhatsappVoiceCall({});

		updateCallDuration(0);
	};

	const handleDragOver = (event) => {
		event.preventDefault();
		const { items } = event.dataTransfer;

		if (items && items.length > 0 && items[0].kind === 'file') {
			debouncedDrag(true);
		}
	};

	return (
		<div
			onDragOver={handleDragOver}
			onDragLeave={() => debouncedDrag(false)}
			onDrop={checkIfDragFileIsValid}
			className="ChatBox"
		>
			<DragAndDropFileUpload
				open={openedDragAndDrop}
				onClose={() => setOpenedDragAndDrop(false)}
				setHasFileDragged={setHasFileDragged}
				interactionType={interactionType}
			/>
			<ChatHeader blockedToInteract={blockedToInteract} isHistoryInteraction={isHistoryInteraction} />
			<div className="ChatBox__chatInfo">
				<div className="ChatBox__chat">
					{blockedToInteract && (
						<div className="ChatBox__chat__warning">
							<p>{formatMessage(messagesIntl.previewMessage)}</p>
						</div>
					)}
					{showReceivingWhatsappCall && !showCallDuration && <ReceivingWhatsappCall callAccept={handleAcceptCall} callRefuse={handleRefuseCall} />}
					{showPhoneInteraction ? (
						<PhoneInteraction
							startTime={startTime || createdAt}
							phoneNumber={customerInfo ? getCustomerFieldValue('channel_phone', customerInfo.fields) : ''}
							isEnded={isEnded}
							endTime={endTime || finishedAt}
							finishInteraction={finishInteraction}
							interactionHash={interactionHash}
							closeReceptiveModal={closeReceptiveModal}
						/>
					) : (
						<MessageList messages={messages} typing={typing} />
					)}
					{showCallDuration && showReceivingWhatsappCall && (
						<CallDuration callEnd={handleEndCall} mediaStream={newWhatsappMediaStream} />
					)}
				</div>
			</div>
			{blockedToInteract ? (
				<div className="ChatBox__chat__warning-input">
					<p>{formatMessage(messagesIntl.previewMessage)}</p>
				</div>
			) : (
				<>
					{isEmail
						? <ChatMail isHistoryInteraction={isHistoryInteraction} />
						: <ChatInput isHistoryInteraction={isHistoryInteraction} />
					}
				</>
			)}
			{showingInternalMessages && <InternalMessages />}
		</div>
	);
};

const mapStateToProps = state => ({
	isHistoryInteraction: state.interaction.currentInteractionHash
		? state.interaction.historyInteractions.some(interaction => (
			interaction.interactionHash === state.interaction.currentInteractionHash
		)) : false,
	agent: state.agent,
	transferData: state.interaction.transferData,
	newWhatsappVoiceCall: state.interaction.newWhatsappVoiceCall,
	newWhatsappMediaStream: state.interaction.newWhatsappMediaStream,
	terminateWhatsappVoiceCall: state.interaction.terminateWhatsappVoiceCall,
	whatsappCallDuration: state.interaction.whatsappCallDuration,
	showingInternalMessages: state.interface.showingInternalMessages
});

const mapActionsToProps = dispatch => ({
	onFinishInteraction: info => dispatch(actions.onFinish(info)),
	closeReceptiveModal: () => dispatch(actions.hideReceptiveModal()),
	onNewWhatsappVoiceCall: info => dispatch(actions.onNewWhatsappVoiceCall(info)),
	onNewWhatsappMediaStream: info => dispatch(actions.onNewWhatsappMediaStream(info)),
	onTerminateWhatsappVoiceCall: info => dispatch(actions.onTerminateWhatsappVoiceCall(info)),
	onWhatsappCallDuration: info => dispatch(actions.onWhatsappCallDuration(info)),
	updateCallDuration: callDuration => dispatch(actions.updateCallDuration(callDuration))
});

ChatBox.propTypes = {
	protocolInfo: PropTypes.shape({
		department: PropTypes.string.isRequired,
		protocol: PropTypes.oneOf(['string', 'number'])
	}),
	currentInteraction: PropTypes.shape({
		messages: PropTypes.arrayOf(PropTypes.shape({})),
		message: PropTypes.shape({}),
		id: PropTypes.number,
		typing: PropTypes.bool,
		department: PropTypes.shape({
			name: PropTypes.string
		}),
		interactionType: PropTypes.string,
		startTime: PropTypes.string,
		createdAt: PropTypes.string,
		customerInfo: PropTypes.shape({
			fields: PropTypes.arrayOf(PropTypes.shape({}))
		}),
		currentState: PropTypes.string,
		endTime: PropTypes.string,
		finishedAt: PropTypes.instanceOf(Date),
		media: PropTypes.string,
		interactionHash: PropTypes.string,
		departmentId: PropTypes.number
	}),
	isHistoryInteraction: PropTypes.bool.isRequired,
	newWhatsappVoiceCall: PropTypes.shape({
		interactionHash: PropTypes.string,
		data: PropTypes.shape({
			sdp: PropTypes.string
		})
	}),
	showingInternalMessages: PropTypes.bool.isRequired,
	onNewWhatsappVoiceCall: PropTypes.func.isRequired,
	onNewWhatsappMediaStream: PropTypes.func.isRequired,
	newWhatsappMediaStream: PropTypes.shape({}),
	onWhatsappCallDuration: PropTypes.func,
	whatsappCallDuration: PropTypes.shape({
		interactionHash: PropTypes.string
	}),
	updateCallDuration: PropTypes.func,
	terminateWhatsappVoiceCall: PropTypes.shape({
		interactionHash: PropTypes.string
	}),
	onFinishInteraction: PropTypes.func.isRequired,
	agent: PropTypes.shape({
		info: PropTypes.shape({
			account: PropTypes.shape({
				id: PropTypes.number
			})
		})
	}).isRequired,
	intl: PropTypes.shape({
		formatMessage: PropTypes.func.isRequired
	}).isRequired,
	closeReceptiveModal: PropTypes.func.isRequired,
	blockedToInteract: PropTypes.bool
};

export default connect(mapStateToProps, mapActionsToProps)(injectIntl(ChatBox));
