import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import ReferenceContent from '../ReferenceContent';

import { mapNewCustomerItem, getTaskCustomerName } from '../../../../shared/utility';

const ReferencedMessage = ({ messageInfo }) => {
	const seekReferencedMessage = () => {
		const referencedMessage = document.querySelector(`[messageid="${messageInfo.referenceId}"]`);
		if (referencedMessage) {
			referencedMessage.scrollIntoView();
		}
	};

	const customerName = useSelector(
		(state) => {
			const {
				interactions,
				pendingInteractions,
				historyInteractions,
				missedInteractions,
				currentInteractionHash
			} = state.interaction;
			const allInteractions = (
				interactions.concat(pendingInteractions).concat(historyInteractions).concat(missedInteractions)
			);

			const currentInteraction = (
				allInteractions.find(({ interactionHash }) => interactionHash === currentInteractionHash) || {}
			);
			const currentCostumer = mapNewCustomerItem(currentInteraction.customerInfo);
			return getTaskCustomerName(currentCostumer);
		}
	);

	return (
		<div
			role="presentation"
			onClick={seekReferencedMessage}
			className="Bubble__referenced-message"
		>
			<ReferenceContent messageInfo={messageInfo} iconColor="#8BABF1" senderName={messageInfo.agentName || customerName} />
		</div>
	);
};

ReferencedMessage.propTypes = {
	messageInfo: PropTypes.shape({
		agentName: PropTypes.string,
		referenceId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
	}).isRequired
};

export default ReferencedMessage;
