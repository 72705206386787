import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, defineMessages } from 'react-intl';
import { connect } from 'react-redux';

import './index.sass';
import * as actions from '../../../store/actions';
import AgentTasks from '../AgentTasks';
import Button from '../../Molecules/Buttons/Button';
import Input from '../../Molecules/Inputs/Common';
import FilterMultiple from '../../Molecules/FilterMultiple';
import Backdrop from '../../Molecules/Backdrop';
import IconButton from '../../Molecules/Buttons/IconButton';

const messages = defineMessages({
	title: {
		id: 'taskBox.title',
		defaultMessage: 'Tarefas'
	},
	createTask: {
		id: 'taskBox.createTask',
		defaultMessage: 'Criar Tarefa'
	},
	activeTasks: {
		id: 'taskBox.allTasks',
		defaultMessage: 'Todas'
	},
	pendingTasks: {
		id: 'taskBox.pendingTasks',
		defaultMessage: 'Pendentes'
	},
	finishedTasks: {
		id: 'taskBox.finishedTasks',
		defaultMessage: 'Finalizadas'
	},
	expiredTasks: {
		id: 'taskBox.expiredTasks',
		defaultMessage: 'Expiradas'
	},
	search: {
		id: 'taskBox.search',
		defaultMessage: 'Buscar tarefa'
	}
});

class TaskBox extends Component {
	state = {
		taskSearch: '',
		selectedFilters: [],
		selectedStatus: [],
		selectedAll: false,
		showFilters: false,
		applyedFilters: false
	};

	toggleFilter = () => {
		const { showFilters } = this.state;
		this.setState({ showFilters: !showFilters });
	};

	updateFilters = (filterItem, allFields) => {
		const { taskExtras } = this.props;
		const { selectedAll } = this.state;

		if (allFields) {
			const fields = [
				{ name: 'FINISHED', type: 'status' },
				{ name: 'OPENED', type: 'status' },
				{ name: 'EXPIRED', type: 'status' }
			];
			if (taskExtras) {
				fields.push({ name: 'inspection', type: 'filter' });
				fields.push({ name: 'internal', type: 'filter' });
				fields.push({ name: 'extension', type: 'filter' });
				fields.push({ name: 'connection', type: 'filter' });
			}

			if (selectedAll) {
				this.setState({
					selectedAll: false,
					selectedFilters: [],
					selectedStatus: []
				});
			} else {
				this.setState({
					selectedAll: true,
					selectedFilters: fields.filter(field => field.type === 'filter'),
					selectedStatus: fields.filter(field => field.type === 'status')
				});
			}
		} else {
			const { selectedFilters, selectedStatus } = this.state;
			const { type } = filterItem;
			let updatedFilters = selectedFilters;

			if (type === 'status') updatedFilters = selectedStatus;

			if (updatedFilters.some(filter => filter.name === filterItem.name)) {
				updatedFilters = updatedFilters.filter(({ name }) => name !== filterItem.name);
			} else {
				updatedFilters.push(filterItem);
			}

			this.setState({
				selectedFilters: type === 'filter' ? updatedFilters : selectedFilters,
				selectedStatus: type === 'status' ? updatedFilters : selectedStatus
			});
		}
	}

	onSearchTasks = (inputValue) => {
		setTimeout(() => {
			const { fetchAgentTasks } = this.props;
			const { taskSearch, selectedFilters } = this.state;

			if (taskSearch === inputValue) {
				const requestObject = { search: taskSearch };
				if (selectedFilters.length > 0) requestObject.filter = selectedFilters.join();
				fetchAgentTasks(requestObject);
			}
		}, 1200);
	};

	onType = (e) => {
		const { value } = e.target;
		this.setState({ taskSearch: value });
		this.onSearchTasks(value);
	};

	onConfirmFilters = () => {
		const { selectedFilters, taskSearch, selectedStatus } = this.state;
		const { fetchAgentTasks } = this.props;
		const requestObject = {
			search: taskSearch,
			status: selectedStatus.map(status => status.name).join(),
			filter: selectedFilters.map(filter => filter.name).join()
		};
		this.setState({ applyedFilters: selectedFilters.length > 0 || selectedStatus.length > 0 });
		fetchAgentTasks(requestObject);
	}

	render() {
		const {
			defineTaskSidebar,
			clearNewTask,
			intl,
			taskExtras,
			fetchAgentTasksStatus
		} = this.props;
		const {
			taskSearch,
			selectedFilters,
			selectedAll,
			showFilters,
			applyedFilters,
			selectedStatus
		} = this.state;
		const { formatMessage } = intl;
		const { isSearching, isFiltering } = fetchAgentTasksStatus;
		const taskFilterClasses = ['TaskBox__filter'];
		const filterItems = [
			{
				id: 0,
				label: formatMessage(messages.activeTasks),
				current: true,
				onClick: () => this.updateFilters(null, true),
				selected: selectedAll
			},
			{
				id: 1,
				label: formatMessage(messages.finishedTasks),
				onClick: () => this.updateFilters({ name: 'FINISHED', type: 'status' }),
				selected: selectedStatus.some(status => status.name === 'FINISHED')
			},
			{
				id: 2,
				label: formatMessage(messages.pendingTasks),
				onClick: () => this.updateFilters({ name: 'OPENED', type: 'status' }),
				selected: selectedStatus.some(status => status.name === 'OPENED')
			}
		];

		if (taskExtras) {
			filterItems.push({
				id: 4,
				label: 'Agendamento Pré vistoria',
				onClick: () => this.updateFilters({ name: 'inspection', type: 'filter' }),
				selected: selectedFilters.some(filter => filter.name === 'inspection')
			});
			filterItems.push({
				id: 5,
				label: 'Agendamento Interna',
				onClick: () => this.updateFilters({ name: 'internal', type: 'filter' }),
				selected: selectedFilters.some(filter => filter.name === 'internal')
			});
			filterItems.push({
				id: 6,
				label: 'Agendamento Ramal',
				onClick: () => this.updateFilters({ name: 'extension', type: 'filter' }),
				selected: selectedFilters.some(filter => filter.name === 'extension')
			});
			filterItems.push({
				id: 7,
				label: 'Agendamento Ligação',
				onClick: () => this.updateFilters({ name: 'connection', type: 'filter' }),
				selected: selectedFilters.some(filter => filter.name === 'connection')
			});
		} else {
			filterItems.push({
				id: 3,
				label: formatMessage(messages.expiredTasks),
				onClick: () => this.updateFilters({ name: 'EXPIRED', type: 'status' }),
				selected: selectedStatus.some(status => status.name === 'EXPIRED')
			});
		}

		if (showFilters) taskFilterClasses.push('TaskBox__filter');
		if (applyedFilters) taskFilterClasses.push('TaskBox__filter--selected');

		return (
			<div className="TaskBox">
				<div className="TaskBox__top">
					<h1>{formatMessage(messages.title)}</h1>
					<Input
						placeholder={formatMessage(messages.search)}
						onChange={this.onType}
						value={taskSearch}
						loading={isSearching}
					/>
					<div className="TaskBox__filter_wrapper">
						<IconButton
							click={this.toggleFilter}
							name="funnel"
							fill="grey-light"
						/>
						{showFilters && (
							<>
								<Backdrop clear closeAction={this.toggleFilter} />
								<FilterMultiple
									items={filterItems}
									onConfirm={this.onConfirmFilters}
									loading={isFiltering}
								/>
							</>
						)}
					</div>
					<div className="TaskBox__actions">
						{!taskExtras && (
							<Button
								className="TaskBox__newTask"
								click={() => {
									clearNewTask();
									defineTaskSidebar('new');
								}}
							>
								{formatMessage(messages.createTask)}
							</Button>
						)}
					</div>
				</div>
				<Input
					placeholder={formatMessage(messages.search)}
					onChange={this.onType}
					value={taskSearch}
					loading={isSearching}
				/>
				<AgentTasks />
			</div>
		);
	}
}

TaskBox.propTypes = {
	intl: PropTypes.shape({
		formatMessage: PropTypes.func.isRequired
	}).isRequired,
	defineTaskSidebar: PropTypes.func.isRequired,
	fetchAgentTasks: PropTypes.func.isRequired,
	clearNewTask: PropTypes.func.isRequired,
	taskExtras: PropTypes.bool.isRequired,
	fetchAgentTasksStatus: PropTypes.shape({
		isSearching: PropTypes.bool,
		isFiltering: PropTypes.bool
	}).isRequired
};

const mapStateToProps = state => ({
	taskExtras: state.agent.taskExtras,
	fetchAgentTasksStatus: state.agent.fetchAgentTasksStatus,
	taskSearch: state.interface.taskSearch
});

const mapActionsToProps = dispatch => ({
	defineTaskSidebar: content => dispatch(actions.defineTaskSidebar(content)),
	fetchAgentTasks: requestObject => dispatch(actions.fetchAgentTasks(requestObject)),
	clearNewTask: () => dispatch(actions.clearNewTask())
});

export default connect(mapStateToProps, mapActionsToProps)(injectIntl(TaskBox));
