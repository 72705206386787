import React from 'react';
import PropTypes from 'prop-types';

import { injectIntl, defineMessages } from 'react-intl';

import './index.sass';
import ModalDefault from '../../Sirius/ModalDefault';
import Button from '../Buttons/Button';

const messages = defineMessages({
	title: {
		id: 'chatInput.whatsappCall.modalTitle',
		defaultMessage: 'Aviso'
	},
	description: {
		id: 'chatInput.whatsappCall.modalDescription',
		defaultMessage: 'Ao enviar este template, você oferece ao cliente a opção de entrar em contato por uma chamada de voz no WhatsApp. Para garantir uma experiência positiva, certifique-se de estar disponível para atender a ligação.'
	},
	confirmButton: {
		id: 'chatInput.whatsappCall.modalConfirm',
		defaultMessage: 'Confirmar envio'
	},
	cancelButton: {
		id: 'chatInput.whatsappCall.modalCancel',
		defaultMessage: 'Canecelar'
	}
});

const WhatsappModalConfirmation = ({
	isOpen, onClose, onConfirm, intl
}) => (
	<ModalDefault.Root
		isOpen={isOpen}
		onClose={onClose}
	>
		<div className="Transfer">
			<ModalDefault.Header
				title={intl.formatMessage(messages.title)}
				onClose={onClose}
				className="has-background-grey-lighter"
			/>
			<ModalDefault.Body className="has-background-grey-lighter is-overflow-hidden">
				<p style={{ fontSize: '16px', lineHeight: '24px' }} className="has-text-body">{intl.formatMessage(messages.description)}</p>
			</ModalDefault.Body>
			<ModalDefault.Footer className="has-background-grey-lighter">
				<div className="Transfer__actions">
					<Button secondary click={onClose}>{intl.formatMessage(messages.cancelButton)}</Button>
					<Button click={onConfirm}>{intl.formatMessage(messages.confirmButton)}</Button>
				</div>
			</ModalDefault.Footer>
		</div>
	</ModalDefault.Root>
);

WhatsappModalConfirmation.propTypes = {
	intl: PropTypes.shape({
		formatMessage: PropTypes.func.isRequired
	}).isRequired,
	isOpen: PropTypes.bool,
	onClose: PropTypes.func,
	onConfirm: PropTypes.func
};


export default injectIntl(WhatsappModalConfirmation);
