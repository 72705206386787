import { defineMessages } from 'react-intl';

const messages = defineMessages({
	noTemplates: {
		id: 'whatsAppTemplates',
		defaultMessage: 'Nenhum template encontrado'
	},
	placeholder: {
		id: 'whatsAppTemplates.placeholder',
		defaultMessage: 'Pesquise um template por nome'
	},
	descriptionExpirationTime: {
		id: 'whatsAppTemplate.descriptionExpirationTime',
		defaultMessage: 'Para usar um template, você precisa definir um tempo limite que a conversa ficará ativa. Ao passar do tempo definido, a conversa será automaticamente encerrada.'
	},
	placeholderExpirationTime: {
		id: 'whatsAppTemplate.placeholderExpirationTime',
		defaultMessage: 'Selecione uma opção'
	},
	labelExpirationTime: {
		id: 'whatsAppTemplate.labelExpirationTime',
		defaultMessage: 'Tempo ativo da sessão'
	},
	headerExpirationTime: {
		id: 'whatsAppTemplate.headerExpirationTime',
		defaultMessage: 'Tempo que a conversa ficará aberta aguardando uma resposta'
	},
	header: {
		id: 'whatsAppTemplate.header',
		defaultMessage: 'Template'
	},
	headerWithFields: {
		id: 'whatsAppTemplate.headerWithFields',
		defaultMessage: 'Template de mensagem com campos personalizáveis (variáveis)'
	},
	headerWithFieldsDescription: {
		id: 'whatsAppTemplate.headerWithFieldsDescription',
		defaultMessage: 'O template selecionado possui campos com variáveis, ou seja, campos personalizáveis que servem para você preencher com as informações necessárias de acordo com o contexto da sua mensagem.'
	},
	fillVariables: {
		id: 'whatsAppTemplate.fillVariables',
		defaultMessage: 'Texto da variável'
	},
	select: {
		id: 'whatsAppTemplate.select',
		defaultMessage: 'Selecione'
	},
	title: {
		id: 'whatsAppTemplate.title',
		defaultMessage: 'Templates de mensagem para WhatsApp'
	},
	previewTitle: {
		id: 'whatsAppTemplate.previewTitle',
		defaultMessage: 'Pré-visualização da mensagem'
	},
	previewDescription: {
		id: 'whatsAppTemplate.previewDescription',
		defaultMessage: 'Este campo não é editável, serve apenas para visualizar como ficará o texto da mensagem.'
	},
	previewPlaceholder: {
		id: 'whatsAppTemplate.previewPlaceholder',
		defaultMessage: 'Sua mensagem aparecerá aqui.'
	},
	back: {
		id: 'whatsAppTemplate.back',
		defaultMessage: 'Voltar'
	},
	next: {
		id: 'whatsAppTemplate.next',
		defaultMessage: 'Próximo'
	},
	sendMessage: {
		id: 'whatsAppTemplate.sendMessage',
		defaultMessage: 'Enviar mensagem'
	},
	cancel: {
		id: 'whatsAppTemplate.cancel',
		defaultMessage: 'Cancelar'
	}
});

export default messages;
