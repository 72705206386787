import React, {
	useState,
	useEffect,
	Fragment,
	useCallback
} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { defineMessages, injectIntl } from 'react-intl';

import './index.css';
import * as actions from '../../../store/actions';
import { useMountEffect, usePrevious } from '../../../shared/utility';
import ClientBox from '../ClientBox';
import ChatBox from '../ChatBox';
import ImgSvg from '../../Atoms/ImgSvg';
import Text from '../../Atoms/Text';
import ToggleNav from '../../Molecules/ToggleNav';
import IconButton from '../../Molecules/Buttons/IconButton';

const messages = defineMessages({
	slogan: {
		id: 'interaction.slogan',
		defaultMessage: 'Olá'
	},
	description: {
		id: 'interaction.description',
		defaultMessage: 'Para começar um atendimento, ative uma conversa da fila ou selecione uma conversa ativa da lista à esquerda.'
	},
	interaction: {
		id: 'interaction.interaction',
		defaultMessage: 'conversa'
	},
	customer: {
		id: 'interaction.customer',
		defaultMessage: 'cliente'
	}
});

const Interaction = ({
	currentInteractionInterface,
	currentInteraction = {},
	agent,
	loadInteractionInfo,
	clearUpdateInterface,
	currentInteractionHash = '',
	canLoadInteractionInfo,
	isHistoryInteraction,
	defineSelectedInteraction,
	intl,
	hasRingingInteractions
}) => {
	const { haveToBeUpdated } = currentInteractionInterface;
	const accountId = agent && agent.info && agent.info.account && agent.info.account.id;
	const agentName = agent.info.name;
	const [showResponsiveCustomer, setShowResponsiveCustomer] = useState(false);
	const {
		interactionHash,
		customerInfo = {},
		currentState,
		department
	} = currentInteraction;
	const departmentId = department && department.id;
	const previousInteractionHash = usePrevious(interactionHash);
	const blockedToInteract = ['RINGING', 'ACCEPTING', 'NOT_ANSWER', 'QUEUED', 'MISSED'].includes(currentState);
	const { formatMessage } = intl;

	useMountEffect(() => {
		if (interactionHash) {
			loadInteractionInfo(accountId, interactionHash);
		}

		if (haveToBeUpdated) {
			loadInteractionInfo(accountId, interactionHash);
			clearUpdateInterface(interactionHash);
		}
	});

	useEffect(() => {
		if (currentInteractionHash) {
			const changedInteraction = previousInteractionHash !== currentInteractionHash && currentInteractionHash !== '';
			const hasNoTags = !currentInteraction.tags || !currentInteraction.tags.length;

			if (interactionHash && (haveToBeUpdated || (changedInteraction && ((canLoadInteractionInfo && hasNoTags) || isHistoryInteraction)))) {
				loadInteractionInfo(accountId, interactionHash);
				clearUpdateInterface(interactionHash);
			}
		}
	}, [
		currentInteraction,
		accountId,
		canLoadInteractionInfo,
		clearUpdateInterface,
		currentInteractionHash,
		haveToBeUpdated,
		interactionHash,
		isHistoryInteraction,
		loadInteractionInfo,
		previousInteractionHash,
		departmentId
	]);

	const showCustomerSection = useCallback(() => setShowResponsiveCustomer(true), []);

	const showCurrentInteraction = useCallback(() => setShowResponsiveCustomer(false), []);

	const goToMainBox = useCallback(() => {
		defineSelectedInteraction();
		setShowResponsiveCustomer(false);
	}, [defineSelectedInteraction]);

	return (
		<div className={`Interaction ${showResponsiveCustomer ? 'Interaction-customer' : ''}`}>
			{currentInteraction && Object.keys(currentInteraction).length > 0 ? (
				<Fragment>
					<div className="Interaction__responsive">
						<IconButton
							name="back"
							fill="grey-light"
							click={goToMainBox}
							className={hasRingingInteractions ? 'Interaction__responsive--ringing' : ''}
						/>
						<ToggleNav
							infos={[
								{
									title: formatMessage(messages.interaction),
									info: null,
									click: showCurrentInteraction,
									active: !showResponsiveCustomer
								},
								{
									title: formatMessage(messages.customer),
									info: null,
									click: showCustomerSection,
									active: showResponsiveCustomer
								}
							]}
							responsive
						/>
					</div>
					<div className="Interaction__sections">
						<ChatBox blockedToInteract={blockedToInteract} currentInteraction={currentInteraction} />
						<ClientBox blockedToInteract={blockedToInteract} customerInfo={customerInfo} />
					</div>
				</Fragment>
			) : (
				<div className="Interaction__empty">
					<div className="Interaction__empty__image">
						<ImgSvg color="default" name="empty-chat" />
					</div>
					<span className="Interaction__empty__info">
						<Text size="large">
							{`${formatMessage(messages.slogan)}, ${agentName}!`}
						</Text>
						<Text size="bigger">{formatMessage(messages.description)}</Text>
					</span>
				</div>
			)}
		</div>
	);
};

Interaction.propTypes = {
	currentInteraction: PropTypes.shape({
		id: PropTypes.number,
		currentState: PropTypes.string,
		customerInfo: PropTypes.shape({}),
		tags: PropTypes.arrayOf(PropTypes.shape({
			id: PropTypes.number,
			name: PropTypes.string
		})),
		interactionHash: PropTypes.string,
		department: PropTypes.shape({
			id: PropTypes.number
		})
	}),
	currentInteractionHash: PropTypes.string,
	agent: PropTypes.shape({
		info: PropTypes.shape({
			name: PropTypes.string.isRequired,
			account: PropTypes.shape({
				id: PropTypes.number.isRequired
			}).isRequired
		}).isRequired
	}).isRequired,
	canLoadInteractionInfo: PropTypes.bool.isRequired,
	loadInteractionInfo: PropTypes.func.isRequired,
	intl: PropTypes.shape({
		formatMessage: PropTypes.func.isRequired
	}).isRequired,
	currentInteractionInterface: PropTypes.shape({
		haveToBeUpdated: PropTypes.bool
	}).isRequired,
	clearUpdateInterface: PropTypes.func.isRequired,
	isHistoryInteraction: PropTypes.bool.isRequired,
	defineSelectedInteraction: PropTypes.func.isRequired,
	hasRingingInteractions: PropTypes.bool.isRequired
};

const mapStateToProps = (state) => {
	const { agent, interaction } = state;
	const {
		currentInteractionHash,
		fetchInteractionInfoStatus,
		interactions,
		historyInteractions,
		missedInteractions,
		pendingInteractions
	} = interaction;
	const allInteractions = interactions.concat(pendingInteractions).concat(historyInteractions).concat(missedInteractions);

	return ({
		agent,
		canLoadInteractionInfo: !fetchInteractionInfoStatus.some(({ hash }) => hash === currentInteractionHash),
		currentInteraction: allInteractions.find(({ interactionHash }) => interactionHash === currentInteractionHash) || {},
		currentInteractionHash,
		currentInteractionInterface: state.interface.customerSection.find(({ hash }) => hash === currentInteractionHash) || { haveToBeUpdated: false },
		hasRingingInteractions: interactions.some(({ currentState }) => currentState === 'RINGING'),
		isHistoryInteraction: historyInteractions.some(({ interactionHash }) => interactionHash === currentInteractionHash)
	});
};

const mapActionsToProps = dispatch => ({
	loadInteractionInfo: (accountId, interactionHash) => {
		dispatch(actions.loadInteractionInfo(accountId, interactionHash));
	},
	clearUpdateInterface: interactionHash => dispatch(actions.clearUpdateInterface(interactionHash)),
	defineSelectedInteraction: () => dispatch(actions.defineSelectedInteraction(''))
});

export default connect(mapStateToProps, mapActionsToProps)(injectIntl(Interaction));
