import React, { useState, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { defineMessages, injectIntl } from 'react-intl';

import './index.sass';
import * as actions from '../../../store/actions';
import InputText from '../../Molecules/Inputs/Common';
import ClientSearchItem from '../../Molecules/ClientSearchItem';
import Text from '../../Atoms/Text';
import MergeCustomer from '../MergeCustomer';

const messages = defineMessages({
	updatedCustomer: {
		id: 'clientSearch.updatedCustomer',
		defaultMessage: 'Cliente alterado'
	},
	updatedCustomerContent: {
		id: 'clientSearch.updatedCustomerContent',
		defaultMessage: 'Cliente da interação alterado'
	},
	placeholder: {
		id: 'clientSearch.placeholder',
		defaultMessage: 'Digite um nome ou email'
	},
	clientNotFound: {
		id: 'clientSearch.clientNotFound',
		defaultMessage: 'Nenhum cliente encontrado'
	}
});

const ClientSearch = ({
	onFetchCustomers,
	agent,
	currentInteraction,
	onChangeInputSearch,
	currentInteractionHash,
	inputSearch,
	fetchCustomerStatus,
	intl,
	activeEdit,
	onUpdateInteractionCustomer,
	addNotification
}) => {
	const ref = useRef();
	const [oldCustomerKey, setOldCustomerKey] = useState('');
	const [oldFields, setOldFields] = useState({});

	const { customerInfo, interactionHash, searchedCustomers } = currentInteraction;
	const { customerKey } = customerInfo;
	const { formatMessage } = intl;
	const value = inputSearch ? inputSearch.value : '';

	const onMergeCustomer = useCallback((selectedOldCustomerKey, selectedOldFields) => {
		setOldCustomerKey(selectedOldCustomerKey);
		setOldFields(selectedOldFields);
	}, []);

	const onSearch = useCallback((inputValue) => {
		setTimeout(() => {
			if (ref.current.input.current.value === inputValue) {
				onFetchCustomers({
					accountId: agent.info.account.id,
					interactionHash,
					search: value,
					customerKey
				});
			}
		}, 1200);
	}, [agent.info.account.id, customerKey, interactionHash, onFetchCustomers, value]);

	const onType = useCallback((e) => {
		const typedValue = e.target.value;
		onChangeInputSearch({ value: typedValue, hash: currentInteractionHash });
		onSearch(typedValue);
	}, [currentInteractionHash, onChangeInputSearch, onSearch]);

	const onUpdateCustomer = useCallback((selectedCustomerKey, historyCount) => {
		const selectedCustomer = searchedCustomers.find(searchedCustomer => (
			searchedCustomer.customerKey === selectedCustomerKey
		));
		const { id, fields } = selectedCustomer;

		onUpdateInteractionCustomer({
			interactionHash,
			customerId: id,
			customerKey: selectedCustomer.customerKey,
			accountId: agent.info.account.id,
			fields,
			historyCount
		});

		addNotification({
			title: formatMessage(messages.updatedCustomer),
			content: formatMessage(messages.updatedCustomerContent),
			type: 'success'
		});

		activeEdit();
	}, [
		activeEdit,
		addNotification,
		agent.info.account.id,
		formatMessage,
		interactionHash,
		onUpdateInteractionCustomer,
		searchedCustomers
	]);

	const onCloseMerge = useCallback(() => {
		setOldFields({});
		setOldCustomerKey('');
	}, []);

	return (
		<div className="ClientSearch">
			{oldCustomerKey && oldFields && (
				<MergeCustomer
					oldCustomerKey={oldCustomerKey}
					oldFields={oldFields}
					onCloseMerge={onCloseMerge}
					activeEdit={activeEdit}
				/>
			)}
			<InputText
				placeholder={formatMessage(messages.placeholder)}
				value={value}
				onChange={onType}
				loading={fetchCustomerStatus.loading}
				ref={ref}
			/>
			<div className="ClientSearch__wrapper">
				<div className="ClientSearch__item">
					{searchedCustomers && Object.keys(searchedCustomers).length > 0 && (
						searchedCustomers.map(customer => (
							<ClientSearchItem
								key={customer.customerKey}
								customer={customer}
								onUpdateCustomer={onUpdateCustomer}
								onMergeCustomer={onMergeCustomer}
							/>
						))
					)}
					{searchedCustomers && Object.keys(searchedCustomers).length === 0
						&& fetchCustomerStatus.success && (
						<div className="ClientSearch__message">
							<Text>{formatMessage(messages.clientNotFound)}</Text>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

const mapActionsToProps = dispatch => ({
	onFetchCustomers: requestInfo => (dispatch(actions.fetchCustomer(requestInfo))),
	onUpdateInteractionCustomer: requestInfo => (
		dispatch(actions.updateInteractionCustomer(requestInfo))
	),
	onChangeInputSearch: inputSearch => dispatch(actions.changeInputSearch(inputSearch)),
	addNotification: notification => dispatch(actions.addNotification(notification))
});
const mapStateToProps = (state) => {
	const { agent, interaction } = state;
	const {
		currentInteractionHash,
		interactions,
		missedInteractions,
		pendingInteractions
	} = interaction;
	const allInteractions = interactions.concat(pendingInteractions).concat(missedInteractions);

	return ({
		agent,
		currentInteraction: allInteractions.find(({ interactionHash }) => interactionHash === currentInteractionHash) || {},
		currentInteractionHash,
		fetchCustomerStatus: state.interaction.fetchCustomerStatus.find(({ hash }) => hash === currentInteractionHash) || {},
		inputSearch: state.interface.inputSearch.find(({ hash }) => hash === currentInteractionHash) || { value: '' }
	});
};

ClientSearch.propTypes = {
	onFetchCustomers: PropTypes.func.isRequired,
	onUpdateInteractionCustomer: PropTypes.func.isRequired,
	currentInteraction: PropTypes.shape({
		interactionHash: PropTypes.string,
		customerInfo: PropTypes.shape({
			customerKey: PropTypes.string
		}),
		searchedCustomers: PropTypes.arrayOf(
			PropTypes.shape({
				customerKey: PropTypes.string
			})
		)
	}).isRequired,
	agent: PropTypes.shape({
		info: PropTypes.shape({
			account: PropTypes.shape({
				id: PropTypes.number
			})
		})
	}).isRequired,
	inputSearch: PropTypes.shape({
		value: PropTypes.string
	}).isRequired,
	onChangeInputSearch: PropTypes.func.isRequired,
	currentInteractionHash: PropTypes.string.isRequired,
	activeEdit: PropTypes.func.isRequired,
	fetchCustomerStatus: PropTypes.shape({
		loading: PropTypes.bool,
		error: PropTypes.bool,
		success: PropTypes.bool
	}).isRequired,
	intl: PropTypes.shape({
		formatMessage: PropTypes.func.isRequired
	}).isRequired,
	addNotification: PropTypes.func.isRequired
};

export default connect(mapStateToProps, mapActionsToProps)(injectIntl(ClientSearch));
