import React, { useState, useEffect } from 'react';

import './index.sass';
import PropTypes from 'prop-types';
import ImgSvg from '../../../Atoms/ImgSvg';
import Wrapper from '../../../Atoms/Wrapper';

const ChatButton = ({
	name,
	disabled,
	click,
	filled,
	className,
	ariaLabel,
	count
}) => {
	const [classes, setClasses] = useState('');
	useEffect(() => {
		const currentClasses = ['ChatButton', className];

		if (disabled) currentClasses.push('ChatButton--disabled');
		if (filled) currentClasses.push('ChatButton--filled');

		setClasses(currentClasses.join(' '));
	}, [className, disabled, filled]);

	return (
		<Wrapper
			className={classes}
			action={disabled ? () => {} : () => click()}
			disableKeyPressEvent
			ariaLabel={ariaLabel}
			info={ariaLabel}
			tooltipPosition="top"
		>
			<ImgSvg name={name} />
			{!!count && <span className="ChatButton__count">{count}</span>}
		</Wrapper>
	);
};
ChatButton.propTypes = {
	name: PropTypes.oneOf([
		'tags-off', 'saved-off', 'notes-off',
		'emoji-off', 'task', 'microphone-off',
		'cross', 'whatsapp-off'
	]).isRequired,
	disabled: PropTypes.bool,
	click: PropTypes.func.isRequired,
	filled: PropTypes.bool,
	className: PropTypes.string,
	ariaLabel: PropTypes.string,
	count: PropTypes.number
};

export default ChatButton;
