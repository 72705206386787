import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import AvatarName from '../../Atoms/AvatarName';
import AvatarQuantity from '../../Atoms/AvatarQuantity';
import './index.sass';

const AvatarNameList = ({ agentList, status = '', small }) => {
	const [classes, setClasses] = useState([]);
	useEffect(() => {
		const currentClasses = ['AvatarNameList'];

		if (status) {
			currentClasses.push('AvatarNameList--status');
			currentClasses.push(`AvatarNameList--${status.toLowerCase()}`);
		}

		if (small) currentClasses.push('AvatarNameList--small');

		setClasses(currentClasses.join(' '));
	}, [small, status]);

	return (
		<div className={classes}>
			{agentList.map(({ id, photo, name }, i) => {
				if (i === 3) {
					return <AvatarQuantity quantity={agentList.length - i} key={agentList.length} />;
				}
				return i < 3 && <AvatarName key={id} url={photo} name={name} />;
			})}
		</div>
	);
};

AvatarNameList.propTypes = {
	agentList: PropTypes.arrayOf(PropTypes.shape({
		photo: PropTypes.string
	})).isRequired,
	status: PropTypes.oneOf(['', 'ONLINE', 'OFFLINE']),
	small: PropTypes.bool
};

export default AvatarNameList;
