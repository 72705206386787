/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl, defineMessages } from 'react-intl';

import './index.sass';
import * as actions from '../../../store/actions';
import { parsePhoneNumber, getCustomerFieldValue, interactionTypeLabel } from '../../../shared/utility';
import ChannelImage from '../../Atoms/ChannelImage';
import Text from '../../Atoms/Text';
import SelectOptions from '../../Molecules/SelectOptions';
import Wrapper from '../../Atoms/Wrapper';
import WhatsAppTemplates from '../WhatsAppTemplates';
import AltuWhatsAppTemplates from '../AltuWhatsAppTemplates';

const messages = defineMessages({
	noIcons: {
		id: 'activeInteractions.noIcons',
		defaultMessage: 'Não é possível realizar contato ativo'
	},
	interaction: {
		id: 'activeInteractions.interaction',
		defaultMessage: 'na interação'
	},
	activeInteraction: {
		id: 'activeInteractions.activeInteraction',
		defaultMessage: 'Você possui interações ativas'
	},
	activeInteractionContent: {
		id: 'activeInteractions.activeInteractionContent',
		defaultMessage: 'Finalize as interações para prosseguir'
	},
	informActivePhone: {
		id: 'activeInteractions.informActivePhone',
		defaultMessage: 'Telefonia'
	},
	informActivePhoneContent: {
		id: 'activeInteractions.informActivePhoneContent',
		defaultMessage: 'Ative a telefonia para prosseguir'
	}
});

const ActiveInteractions = ({
	taskId,
	currentInteraction = {},
	phoneData,
	updateCreatingInteraction,
	updateConferenceUsers,
	defineMainboxSection,
	activeInteractions,
	defineSelectedInteraction,
	customerKey = '',
	intl,
	addNotification,
	fields,
	registeredPhone,
	hasPhoneInteraction,
	hasPhoneConfInteraction,
	onlyChatOrPhone,
	hasActiveChat,
	hasPhone,
	notShowPhone,
	isHistoryInteraction,
	isExpiredInteraction,
	altuWappTemplates,
	emailType = ''
}) => {
	const [showContacts, setShowContacts] = useState('');
	const { interactionHash, customerInfo } = currentInteraction;
	const { formatMessage } = intl;

	const phoneCall = useCallback((phone) => {
		const parsedPhone = parsePhoneNumber(phone);

		if (phoneData.name === 'totalVoice') {
			window.ttvSdk.callingNumber = parsedPhone;
			window.ttvSdk.callTo(parsedPhone);
		}

		updateCreatingInteraction({
			type: 'phone',
			info: parsedPhone,
			taskId,
			finishInteraction: interactionHash || false
		});
	}, [interactionHash, phoneData.name, taskId, updateCreatingInteraction]);

	const conferenceCall = useCallback((phone) => {
		const parsedPhone = parsePhoneNumber(phone);

		if (phoneData.name === 'totalVoice') {
			updateConferenceUsers({
				name: '',
				number: parsedPhone,
				finishInteraction: interactionHash || false
			});
			window.ttvSdk.callConf(phoneData.station, parsedPhone);
		}

		updateCreatingInteraction({ type: 'phone_conf', info: parsedPhone, taskId });
	}, [
		interactionHash,
		phoneData.name,
		phoneData.station,
		taskId,
		updateConferenceUsers,
		updateCreatingInteraction
	]);

	const addToConference = useCallback((phone) => {
		const parsedPhone = parsePhoneNumber(phone);

		if (parsedPhone !== '') {
			updateConferenceUsers({ name: '', number: parsedPhone });
			window.ttvSdk.addNumber(parsedPhone);
		}
		defineMainboxSection('default');
		defineSelectedInteraction(activeInteractions.find(({ interactionType }) => (
			interactionType.toLowerCase() === 'phone_conf'
		)).interactionHash);
	}, [activeInteractions, defineMainboxSection, defineSelectedInteraction, updateConferenceUsers]);

	const createEmail = useCallback((email) => {
		const currentInteractionHash = currentInteraction && currentInteraction.interactionHash;

		if (currentInteraction && currentInteraction.currentState === 'REPLYING') {
			window.omzVish.replyMissedInteraction(interactionHash, 'EMAIL', customerInfo.customerKey);
		} else {
			window.omzVish.newInteraction(currentInteractionHash, 'EMAIL', customerKey, true, taskId, '', emailType);
		}

		updateCreatingInteraction({ type: 'EMAIL', info: email, taskId });
	}, [
		currentInteraction.interactionHash,
		customerInfo,
		customerKey,
		interactionHash,
		taskId,
		updateCreatingInteraction
	]);

	const onInformActiveChat = useCallback(() => {
		addNotification({
			title: formatMessage(messages.activeInteraction),
			content: formatMessage(messages.activeInteractionContent),
			type: 'warning'
		});
	}, [addNotification, formatMessage]);

	const onInformActivePhone = useCallback(() => {
		addNotification({
			title: formatMessage(messages.informActivePhone),
			content: formatMessage(messages.informActivePhoneContent),
			type: 'warning'
		});
	}, [addNotification, formatMessage]);

	const showSelection = useCallback((iconName) => {
		if (showContacts === iconName) return setShowContacts('');
		return setShowContacts(iconName);
	}, [showContacts]);

	const hasPhoneActive = hasPhoneInteraction || hasPhoneConfInteraction;
	const phoneActive = onlyChatOrPhone
		? registeredPhone && !hasActiveChat && !hasPhoneActive
		: registeredPhone;

	const phoneAction = (onlyChatOrPhone && hasActiveChat) || hasPhoneActive
		? onInformActiveChat
		: onInformActivePhone;

	const [activeEmail, setActiveEmail] = useState([]);
	useEffect(() => {
		const currentActiveEmail = [];
		const email = getCustomerFieldValue('channel_email', fields);
		const hasEmail = email && email.constructor === Array ? email[0] : email || '';
		let pushEmail = true;
		if (Object.keys(currentInteraction).length > 0) {
			pushEmail = !['email', 'offcontact'].includes(currentInteraction.interactionType.toLowerCase()) || isHistoryInteraction;
		}

		if (hasEmail && !hasPhoneActive && pushEmail) {
			currentActiveEmail.push({
				name: 'email',
				action: createEmail,
				className: 'ActiveInteraction__icon',
				isMultiple: email.constructor === Array && email && email.length > 1,
				options: email
			});
		}
		setActiveEmail(currentActiveEmail);
	}, [currentInteraction.interactionHash, createEmail, fields, hasPhoneActive, isHistoryInteraction]);

	const [activePhone, setActivePhone] = useState([]);
	useEffect(() => {
		const currentActivePhone = [];
		const phoneNumber = getCustomerFieldValue('channel_phone', fields);
		const isMultiple = phoneNumber && phoneNumber.constructor === Array && phoneNumber.length > 1;
		if (phoneNumber && hasPhone && !notShowPhone && !hasPhoneConfInteraction) {
			currentActivePhone.push({
				name: 'phone',
				action: phoneActive && !hasPhoneActive ? phoneCall : () => phoneAction(),
				className: `ActiveInteraction__icon ${!phoneActive || hasPhoneActive ? 'ActiveInteraction__icon--phone--inactive' : ''}`,
				isMultiple,
				options: phoneNumber
			});
			currentActivePhone.push({
				name: 'conference',
				action: phoneActive && !hasPhoneActive ? conferenceCall : () => phoneAction(),
				className: `ActiveInteraction__icon ${!phoneActive || hasPhoneActive ? 'ActiveInteraction__icon--conference--inactive' : ''}`,
				isMultiple,
				options: phoneNumber
			});
		} else if (phoneNumber && hasPhoneConfInteraction) {
			currentActivePhone.push({
				name: 'addToConf',
				action: addToConference,
				className: `ActiveInteraction__icon--addToconf ${!phoneActive ? 'ActiveInteraction__icon--addToConf--inactive' : ''}`,
				isMultiple,
				options: phoneNumber
			});
		}

		setActivePhone(currentActivePhone);
	}, [
		addToConference,
		phoneActive,
		phoneCall,
		conferenceCall,
		hasPhone,
		hasPhoneActive,
		hasPhoneConfInteraction,
		notShowPhone,
		phoneAction,
		fields
	]);

	const [senderId, setSenderId] = useState('');
	const [activeWhatsapp, setActiveWhatsapp] = useState([]);

	useEffect(() => {
		const { whatsappId, interactionType, currentState } = currentInteraction;

		if (currentInteraction.senderId && whatsappId && (interactionType.toLowerCase() !== 'whatsapp' || isHistoryInteraction || isExpiredInteraction || currentState === 'REPLYING')) {
			setActiveWhatsapp([{
				name: 'whatsapp',
				action: () => setSenderId(currentInteraction.senderId),
				className: 'ActiveInteraction__icon',
				isMultiple: false
			}]);
		} else {
			setActiveWhatsapp([]);
		}
	}, [fields, currentInteraction.interactionHash, isHistoryInteraction, isExpiredInteraction]);

	const [altuInteractionHash, setAltuInteractionHash] = useState('');
	const [altuActiveWhatsapp, setAltuActiveWhatsapp] = useState([]);

	useEffect(() => {
		const { interactionType, currentState, source } = currentInteraction;

		if (source && source.toLowerCase() === 'altu' && altuWappTemplates && interactionType.toLowerCase() === 'whatsapp' && (isHistoryInteraction || isExpiredInteraction || currentState === 'REPLYING')) {
			setAltuActiveWhatsapp([{
				name: 'whatsapp',
				action: () => setAltuInteractionHash(currentInteraction.interactionHash),
				className: 'ActiveInteraction__icon',
				isMultiple: false
			}]);
		} else {
			setAltuActiveWhatsapp([]);
		}
	}, [currentInteraction.interactionHash, currentInteraction.source, isHistoryInteraction, isExpiredInteraction, altuWappTemplates]);

	const activeTypes = activeEmail.concat(activePhone).concat(activeWhatsapp).concat(altuActiveWhatsapp);

	return (
		<>
			<div className="ActiveInteractions">
				{activeTypes.length > 0 ? activeTypes.map(({
					name,
					className,
					isMultiple,
					options,
					action
				}) => (
					<div className="ActiveInteractions__actions" key={name}>
						<Wrapper
							className={`ActiveInteraction__icon ${className}`}
							action={isMultiple ? () => showSelection(name) : () => action(options)}
						>
							<ChannelImage
								name={name}
								suffix="gray"
							/>
							<Text size="bigger">{interactionTypeLabel(name)}</Text>
						</Wrapper>
						{showContacts === name && <SelectOptions options={options} action={action} />}
					</div>
				)) : (
					<Text size="bigger">
						{`${intl.formatMessage(messages.noIcons)} ${Object.keys(currentInteraction).length > 0 ? formatMessage(messages.interaction) : ''}`}
					</Text>
				)}
			</div>
			{senderId && (
				<WhatsAppTemplates
					onClose={() => setSenderId('')}
					customerKey={customerKey}
					senderId={senderId}
					channelWhatsapp={currentInteraction.whatsappId}
					interactionHash={currentInteraction.interactionHash}
				/>
			)}
			{altuInteractionHash && (
				<>
					<AltuWhatsAppTemplates
						onClose={() => setAltuInteractionHash('')}
						interactionHash={altuInteractionHash}
					/>
				</>
			)}
		</>
	);
};

const mapStateToProps = state => ({
	phoneData: state.agent.phoneData,
	registeredPhone: state.agent.registeredPhone,
	altuWappTemplates: state.agent.altuWappTemplates,
	hasPhoneInteraction: state.interaction.interactions.some(({ interactionType, currentState }) => (
		interactionType.toLowerCase().includes('phone')
		&& !['ended', 'missed', 'replying'].includes(currentState.toLowerCase())
	)),
	hasPhoneConfInteraction: state.interaction.interactions.some(interaction => (
		interaction.interactionType.toLowerCase() === 'phone_conf'
		&& interaction.interactionType.toLowerCase() !== 'ended'
	)),
	activeInteractions: state.interaction.interactions,
	onlyChatOrPhone: state.interaction.onlyChatOrPhone,
	hasActiveChat: state.interaction.hasActiveChat,
	hasPhone: state.interface.hasPhone,
	isHistoryInteraction: state.interaction.historyInteractions.some(({ interactionHash }) => interactionHash === state.interaction.currentInteractionHash),
	isExpiredInteraction: state.interaction.missedInteractions.some(({ interactionHash }) => interactionHash === state.interaction.currentInteractionHash)
});

const mapActionsToProps = dispatch => ({
	updateCreatingInteraction: info => dispatch(actions.updateCreatingInteraction(info)),
	updateConferenceUsers: user => dispatch(actions.updateConferenceUsers(user)),
	defineMainboxSection: section => dispatch(actions.defineMainboxSection(section)),
	defineSelectedInteraction: hash => dispatch(actions.defineSelectedInteraction(hash)),
	addNotification: notification => dispatch(actions.addNotification(notification))
});

ActiveInteractions.propTypes = {
	fields: PropTypes.oneOfType([
		PropTypes.shape({
			channel_phone: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)])
		}),
		PropTypes.arrayOf(PropTypes.shape({
			name: PropTypes.string
		}))
	]).isRequired,
	phoneData: PropTypes.shape({
		name: PropTypes.string,
		station: PropTypes.number
	}).isRequired,
	updateConferenceUsers: PropTypes.func.isRequired,
	registeredPhone: PropTypes.bool.isRequired,
	updateCreatingInteraction: PropTypes.func.isRequired,
	hasPhoneInteraction: PropTypes.bool.isRequired,
	hasPhoneConfInteraction: PropTypes.bool.isRequired,
	defineMainboxSection: PropTypes.func.isRequired,
	defineSelectedInteraction: PropTypes.func.isRequired,
	activeInteractions: PropTypes.arrayOf(PropTypes.shape({
		interactionType: PropTypes.string,
		interactionHash: PropTypes.string
	})).isRequired,
	onlyChatOrPhone: PropTypes.bool.isRequired,
	hasActiveChat: PropTypes.bool.isRequired,
	taskId: PropTypes.number,
	customerKey: PropTypes.string,
	hasPhone: PropTypes.bool.isRequired,
	notShowPhone: PropTypes.bool,
	currentInteraction: PropTypes.shape({
		interactionType: PropTypes.string,
		currentState: PropTypes.string,
		interactionHash: PropTypes.string,
		customerInfo: PropTypes.shape({
			customerKey: PropTypes.string
		}),
		senderId: PropTypes.string,
		whatsappId: PropTypes.string,
		source: PropTypes.string
	}),
	intl: PropTypes.shape({
		formatMessage: PropTypes.func
	}).isRequired,
	addNotification: PropTypes.func.isRequired,
	isHistoryInteraction: PropTypes.bool.isRequired,
	isExpiredInteraction: PropTypes.bool.isRequired,
	altuWappTemplates: PropTypes.bool,
	emailType: PropTypes.string
};

export default connect(mapStateToProps, mapActionsToProps)(injectIntl(ActiveInteractions));
