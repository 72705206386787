import './index.sass';

import React from 'react';
import PropTypes from 'prop-types';
import ImgSvg from '../../../Atoms/ImgSvg';
import Text from '../../../Atoms/Text';

const Select = ({
	items,
	value,
	onChange = () => {},
	placeholder,
	label
}) => (
	<div className="Select">
		{label && <Text className="Select__label">{label}</Text>}
		<div className="Select__select">
			<select onChange={e => onChange(e)} value={value}>
				{placeholder && (<option disabled value="">{placeholder}</option>)}
				{items.map(item => (<option key={item.value} value={item.value}>{item.label}</option>))}
			</select>
			<ImgSvg className="Select__arrow" name="arrow" />
		</div>
	</div>
);

Select.propTypes = {
	placeholder: PropTypes.string,
	label: PropTypes.string,
	items: PropTypes.arrayOf(
		PropTypes.shape({
			label: PropTypes.string.isRequired,
			value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired
		}).isRequired
	).isRequired,
	onChange: PropTypes.func,
	value: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

export default Select;
