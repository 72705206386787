import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import * as actions from '../../../store/actions';

import Controls from './Controls';
import ImgSvg from '../../Atoms/ImgSvg';
import './index.sass';
import { ACCOUNT_FEATURES } from '../../../shared/consts';

class Call extends React.Component {
	localVideoRef = React.createRef();

	remoteVideoRef = React.createRef();

	state = {
		enabledMic: false,
		enabledVideo: false
	}

	componentDidMount() {
		const { autostartWebcam } = this.props;
		const { localStream, remoteStream } = window.omzVish;

		if (localStream && localStream.constructor === MediaStream) {
			this.localVideoRef.current.srcObject = localStream;
			this.localVideoRef.current.play();
		}

		if (remoteStream && remoteStream.constructor === MediaStream) {
			this.remoteVideoRef.current.srcObject = remoteStream;
			this.remoteVideoRef.current.play();
		}

		this.setState({
			enabledMic: localStream ? localStream.getAudioTracks()[0].enabled : false,
			enabledVideo: localStream ? localStream.getVideoTracks()[0].enabled : false
		});

		if (autostartWebcam) {
			this.onToggleVideo();
			this.onToggleMic();
		}
	}

	componentWillUnmount() {
		const { enabledVideo } = this.state;

		if (enabledVideo) {
			window.omzVish.closeAgentVideo();
		}
	}


	onToggleVideo = () => {
		window.omzVish.toggleVideoTrack().then((enabledVideo) => {
			this.setState({ enabledVideo });
		});
	};

	onToggleMic = () => {
		window.omzVish.toggleAudioTrack().then((enabledMic) => {
			this.setState({ enabledMic });
		});
	};

	onCloseCall = () => {
		const { onCloseCall, interactionHash } = this.props;
		window.omzVish.closeAgentVideo();
		onCloseCall(interactionHash);
	}

	render() {
		const { enabledMic, enabledVideo } = this.state;
		const video = true;
		const audio = true;

		const callCamClasses = ['Call__cam__video'];
		if (!video) callCamClasses.push('Call__cam__video--off');
		const callCamMiniClasses = ['Call__cam--mini'];
		if (!video) callCamMiniClasses.push('Call__cam--mini--off');

		return (
			<div className="Call">
				<div className="Call__cam">
					<video className={callCamClasses.join(' ')} id="remoteVideo" autoPlay playsInline ref={this.remoteVideoRef}>
						<track kind="captions" />
					</video>
					<div className={callCamMiniClasses.join(' ')}>
						<div className="Call__cam--mini__info">
							{!audio ? <ImgSvg name="microphone-off-off" /> : null}
							{!video ? <ImgSvg name="video-turnoff-off" /> : null}
						</div>
						<video id="localVideo" autoPlay playsInline ref={this.localVideoRef} muted="muted">
							<track kind="captions" />
						</video>
					</div>
					<div className="Call__controls">
						<Controls
							enabledVideo={enabledVideo}
							enabledMic={enabledMic}
							onExpand={this.onExpandRemoteVideo}
							onToggleVideo={this.onToggleVideo}
							onToggleMic={this.onToggleMic}
							onEnd={this.onCloseCall}
						/>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	interactionHash: state.interaction.currentInteractionHash,
	autostartWebcam: (
		state.agent.info.account.appearance && state.agent.info.account.appearance[ACCOUNT_FEATURES.AUTOSTART_WEBCAM]
			? state.agent.info.account.appearance[ACCOUNT_FEATURES.AUTOSTART_WEBCAM] : false
	)
});

const mapActionsToProps = dispatch => ({
	onCloseCall: interactionHash => dispatch(actions.hideCall(interactionHash))
});

Call.propTypes = {
	interactionHash: PropTypes.string.isRequired,
	autostartWebcam: PropTypes.bool.isRequired,
	onCloseCall: PropTypes.func.isRequired
};

export default connect(mapStateToProps, mapActionsToProps)(Call);
