import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, injectIntl } from 'react-intl';

import './index.sass';
import AvatarName from '../../Atoms/AvatarName';
import AvatarNameList from '../AvatarNameList';
import Text from '../../Atoms/Text';
import Button from '../Buttons/Button';
import AgentStatusBadge from '../../Sirius/AgentStatusBadge';

const messages = defineMessages({
	agents: {
		id: 'transferItem.agents',
		defaultMessage: 'atendentes'
	},
	select: {
		id: 'transferItem.select',
		defineMessage: 'Selecionar'
	}
});

const TransferItem = ({
	type = 'agent',
	item,
	onSelectAgent,
	intl,
	disableClick
}) => {
	const {
		name,
		department,
		agents,
		newStatus,
		slots,
		talking,
		photo
	} = item;
	const departmentName = agents ? `${(`0${agents.length}`).slice(-2)} ${intl.formatMessage(messages.agents)}` : department.name;
	const isAgentType = type === 'agent';
	let avatar;

	if (type === 'department' && agents.length > 0) {
		let departmentStatus = 'OFFLINE';
		if (agents.some(agent => agent.status === 'ONLINE')) departmentStatus = 'ONLINE';
		avatar = <AvatarNameList agentList={agents} status={departmentStatus} />;
	} else if (type === 'agent') {
		avatar = <AvatarName name={name} url={photo} />;
	}

	return (
		<div className={`TransferItem ${disableClick && 'TransferItem--disabled'}`}>
			<div className="TransferItem__wrapper">
				{isAgentType && (
					<div className="TransferItem__avatar">
						{avatar}
					</div>
				)}
				<div className={`TransferItem__info mr-5 ${isAgentType ? 'TransferItem__info--withStatus' : ''}`}>
					<Text bold size="bigger">{name}</Text>
					{isAgentType ? (<Text size="big">{`${talking}/${slots} chats`}</Text>) : <Text size="big">{departmentName}</Text>}
				</div>
				{isAgentType && (
					<AgentStatusBadge status={newStatus} />
				)}
			</div>
			{!disableClick && (
				<Button click={() => onSelectAgent(item)} secondary>{intl.formatMessage(messages.select)}</Button>
			)}
		</div>
	);
};

TransferItem.propTypes = {
	type: PropTypes.oneOf(['agent', 'department']),
	item: PropTypes.shape({
		name: PropTypes.string.isRequired,
		id: PropTypes.number.isRequired,
		slots: PropTypes.number,
		talking: PropTypes.number,
		department: PropTypes.shape({
			name: PropTypes.string,
			id: PropTypes.number
		}),
		agents: PropTypes.arrayOf(PropTypes.shape({
			photo: PropTypes.string
		})),
		newStatus: PropTypes.string,
		photo: PropTypes.string
	}).isRequired,
	onSelectAgent: PropTypes.func.isRequired,
	intl: PropTypes.shape({
		formatMessage: PropTypes.func.isRequired
	}).isRequired,
	disableClick: PropTypes.bool
};

export default injectIntl(TransferItem);
