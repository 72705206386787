import React from 'react';
import PropTypes from 'prop-types';

import './index.sass';
import Bubble from '../../../Atoms/Bubble';

const MessageLocation = ({ messageInfo }) => {
	const { url, content } = messageInfo;
	return (
		<>
			<Bubble timeOnBottom className="MessageLocation" messageInfo={messageInfo}>
				<a href={url} target="_blank" rel="noreferrer">
					<div className="MessageLocation__container">
						<i className="fa-sharp fa-thin fa-map-location-dot" />
					</div>
					{content}
				</a>
			</Bubble>
		</>
	);
};

MessageLocation.propTypes = {
	messageInfo: PropTypes.shape({
		content: PropTypes.string,
		url: PropTypes.string
	}).isRequired
};

export default MessageLocation;
