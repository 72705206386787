/* eslint-disable react/forbid-prop-types */
import React, { useState, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl, defineMessages } from 'react-intl';

import './index.sass';
import * as actions from '../../../store/actions';
import AgentContact from '../../Molecules/AgentContactItem';
import Spinner from '../../Atoms/Spinner';
import InfiniteList from '../../Molecules/InfiniteList';
import InputText from '../../Molecules/Inputs/Common';
import IconButton from '../../Molecules/Buttons/IconButton';
import Text from '../../Atoms/Text';

const messages = defineMessages({
	emptyContacts: {
		id: 'agentContacts.emptyContacts',
		defaultMessage: 'Sem contatos'
	},
	search: {
		id: 'agentContacts.search',
		defaultMessage: 'Buscar'
	}
});

const AgentContacts = ({
	fetchAgentContacts,
	agent,
	fetchAgentContactsStatus,
	isSearchingContacts,
	searchAgentContacts,
	searchAgentContactsStatus,
	contactsInfo,
	showEditCustomer,
	intl,
	showAddCustomer
}) => {
	const ref = useRef();
	const [searchValue, setSearchValue] = useState('');
	const { info } = agent;
	const accountId = info.account.id;
	const { loading } = fetchAgentContactsStatus;
	const { customers } = contactsInfo;
	const { formatMessage } = intl;
	const loadingSearch = searchAgentContactsStatus.loading && searchAgentContactsStatus.count > 1;

	const loadMoreAgentContacts = useCallback(() => {
		if (!fetchAgentContactsStatus.ended
			&& !fetchAgentContactsStatus.loading
			&& !isSearchingContacts) {
			fetchAgentContacts({ accountId, page: fetchAgentContactsStatus.count });
		} else if (!searchAgentContactsStatus.ended
			&& !searchAgentContactsStatus.loading
			&& isSearchingContacts) {
			searchAgentContacts({
				accountId,
				page: searchAgentContactsStatus.count,
				search: searchValue
			});
		}
	}, [
		accountId,
		fetchAgentContacts,
		fetchAgentContactsStatus.count,
		fetchAgentContactsStatus.ended,
		fetchAgentContactsStatus.loading,
		isSearchingContacts,
		searchAgentContacts,
		searchAgentContactsStatus.count,
		searchAgentContactsStatus.ended,
		searchAgentContactsStatus.loading,
		searchValue
	]);

	const onSearch = useCallback((search) => {
		if (ref.current && ref.current.input && ref.current.input.current) {
			setTimeout(() => {
				if (ref.current
						&& ref.current.input
						&& ref.current.input.current
						&& ref.current.input.current.value === search) {
					if (search === '') {
						fetchAgentContacts({ accountId });
					} else {
						searchAgentContacts({ accountId, search });
					}
				}
			}, 1200);
		}
	}, [accountId, fetchAgentContacts, searchAgentContacts]);

	const onChangeSearch = useCallback((e) => {
		const { value } = e.target;

		setSearchValue(value);
		onSearch(value);
	}, [onSearch]);

	return (
		<div className="AgentContacts">
			<div className="AgentContacts__actions">
				<InputText
					placeholder={formatMessage(messages.search)}
					onChange={onChangeSearch}
					value={searchValue}
					loading={searchAgentContactsStatus.loading && searchAgentContactsStatus.count === 1}
					ref={ref}
				/>
				<IconButton
					name="new-user"
					fill="grey-light"
					click={showAddCustomer}
					square
				/>
			</div>
			<div className="AgentContacts__customers__wrapper">
				<InfiniteList customClass="AgentContacts__customers" onBottom={loadMoreAgentContacts}>
					{customers && customers.map(customer => (
						<AgentContact
							customer={customer}
							key={customer.id}
							showEditCustomer={showEditCustomer}
						/>
					))}
					{customers && customers.length === 0 && (
						<Text>{formatMessage(messages.emptyContacts)}</Text>
					)}
				</InfiniteList>
			</div>
			{(loadingSearch || loading) && <Spinner />}
		</div>
	);
};

const mapStateToProps = state => ({
	contactsInfo: state.agent.contactsInfo,
	agent: state.agent,
	currentInteractionHash: state.interaction.currentInteractionHash,
	fetchAgentContactsStatus: state.agent.fetchAgentContactsStatus,
	isSearchingContacts: state.agent.isSearchingContacts,
	searchAgentContactsStatus: state.agent.searchAgentContactsStatus
});

const mapActionsToProps = dispatch => ({
	fetchAgentContacts: requestInfo => dispatch(actions.fetchAgentContacts(requestInfo)),
	searchAgentContacts: requestInfo => dispatch(actions.searchAgentContacts(requestInfo)),
	showAddCustomer: () => dispatch(actions.showAddCustomer()),
	showEditCustomer: customer => dispatch(actions.showEditCustomer(customer))
});

AgentContacts.propTypes = {
	fetchAgentContacts: PropTypes.func.isRequired,
	agent: PropTypes.shape({
		info: PropTypes.shape({
			account: PropTypes.shape({
				id: PropTypes.number
			})
		})
	}).isRequired,
	contactsInfo: PropTypes.shape({
		count: PropTypes.number,
		customers: PropTypes.arrayOf(PropTypes.object)
	}).isRequired,
	fetchAgentContactsStatus: PropTypes.shape({
		loading: PropTypes.bool,
		ended: PropTypes.bool,
		page: PropTypes.number,
		count: PropTypes.number
	}).isRequired,
	searchAgentContacts: PropTypes.func.isRequired,
	searchAgentContactsStatus: PropTypes.shape({
		loading: PropTypes.bool,
		ended: PropTypes.bool,
		page: PropTypes.number,
		count: PropTypes.number
	}).isRequired,
	isSearchingContacts: PropTypes.bool.isRequired,
	showAddCustomer: PropTypes.func.isRequired,
	showEditCustomer: PropTypes.func.isRequired,
	intl: PropTypes.shape({
		formatMessage: PropTypes.func.isRequired
	}).isRequired
};

export default connect(mapStateToProps, mapActionsToProps)(injectIntl(AgentContacts));
