/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-console */
import React, {
	createContext,
	useState,
	useEffect,
	useContext
} from 'react';
import filter from 'leo-profanity';

import bannedContentService from '../services/bannedContents';

import profaneWords from '../shared/profaneWords';
import { useStore } from './store';

const BanContext = createContext();
export const BanProvider = ({ children }) => {
	const [banSettings, setBanSettings] = useState({});
	const { currentInteraction } = useStore();

	const { department } = currentInteraction;

	filter.addDictionary('pt-br', profaneWords);

	useEffect(() => {
		const loadBanSettings = async () => {
			try {
				const response = await bannedContentService.list();

				setBanSettings(response.bannedContent);
			} catch (error) {
				console.error('Erro ao carregar as configurações de banimento:', error);
			}
		};
		loadBanSettings();
	}, []);

	const checkIsBanned = (type) => {
		if (banSettings[type]) {
			if (type === 'receivedText' || type === 'sendText') return true;
			if (!banSettings[`${type}DepartmentList`].length) return true;

			const departmentId = department.id;

			return banSettings[`${type}DepartmentList`].some(depId => Number(depId) === departmentId);
		}

		return false;
	};

	const checkIsNotBanned = type => !checkIsBanned(type);

	const hasBadWords = (type, message) => (message && checkIsBanned(type) ? filter.check(message) : false);
	const cleanBadWords = (type, message) => (message && checkIsBanned(type) ? filter.clean(message) : message);

	return (
		<BanContext.Provider value={{
			checkIsBanned,
			checkIsNotBanned,
			hasBadWords,
			cleanBadWords
		}}
		>
			{children}
		</BanContext.Provider>
	);
};

export const useBan = () => useContext(BanContext);
