import { defineMessages } from 'react-intl';

const messages = defineMessages({
	pageTitle: {
		id: 'navBar.dashboard',
		defaultMessage: 'Monitorar atendimentos'
	},
	infoTitle: {
		id: 'dashboardBox.infoTitle',
		defaultMessage: 'Informações diárias'
	},
	ended: {
		id: 'dashboardBox.ended',
		defaultMessage: 'Atendimentos finalizados'
	},
	transferred: {
		id: 'dashboardBox.transferred',
		defaultMessage: 'Atendimentos transferidos'
	},
	averageServiceTime: {
		id: 'dashboardBox.averageServiceTime',
		defaultMessage: 'Tempo médio de atendimento'
	},
	department: {
		id: 'dashboardBox.department',
		defaultMessage: 'Departamento'
	},
	logged: {
		id: 'dashboardBox.logged',
		defaultMessage: 'Logado'
	},
	online: {
		id: 'dashboardBox.online',
		defaultMessage: 'Logado e disponível'
	},
	unavailable: {
		id: 'dashboardBox.unavailable',
		defaultMessage: 'Logado e indisponível'
	},
	preBreak: {
		id: 'dashboardBox.preBreak',
		defaultMessage: 'Pré-pausa'
	},
	break: {
		id: 'dashboardBox.break',
		defaultMessage: 'Pausa'
	},
	offline: {
		id: 'dashboardBox.offline',
		defaultMessage: 'Deslogado'
	},
	agentStatus: {
		id: 'dashboardBox.agentStatus',
		defaultMessage: 'Status dos agentes'
	},
	agentStatusTooltip: {
		id: 'dashboardBox.agentStatusTooltip',
		defaultMessage: 'Quantidade de agentes em cada um dos status nos departamentos que o seu usuário está incluído'
	},
	refresh: {
		id: 'dashboardBox.refresh',
		defaultMessage: 'Atualizar'
	},

	npsScore: {
		id: 'dashboardBox.npsScore',
		defaultMessage: 'Pontuação NPS'
	},
	promoters: {
		id: 'dashboardBox.promoters',
		defaultMessage: 'Promotores'
	},
	liabilities: {
		id: 'dashboardBox.liabilities',
		defaultMessage: 'Passivos'
	},
	detractors: {
		id: 'dashboardBox.detractors',
		defaultMessage: 'Detratores'
	},
	npsData: {
		id: 'dashboardBox.npsData',
		defaultMessage: 'Dados do NPS'
	},
	averageCustomerSatisfactionStar: {
		id: 'dashboardBox.averageCustomerSatisfactionStar',
		defaultMessage: 'Média de satisfação do cliente - Nota'
	},
	averageSatisfactionPositiveNegative: {
		id: 'dashboardBox.averageSatisfactionPositiveNegative',
		defaultMessage: 'Média de satisfação do cliente - Positivo ou negativo'
	},
	positive: {
		id: 'dashboardBox.positive',
		defaultMessage: 'Positivo'
	},
	negative: {
		id: 'dashboardBox.negative',
		defaultMessage: 'Negativo'
	},
	noMetricsTitle: {
		id: 'dashboardBox.noMetricsTitle',
		defaultMessage: 'Sem dados de pesquisa de satisfação disponíveis.'
	},
	noMetricsDescription: {
		id: 'dashboardBox.noMetricsDescription',
		defaultMessage: 'Tente novamente com outro filtro de departamento, data, ou aguarde receber mais respostas dos clientes.'
	},
	yourInformation: {
		id: 'dashboardBox.yourInformation',
		defaultMessage: 'Suas informações'
	},
	peersInformation: {
		id: 'dashboardBox.peersInformation',
		defaultMessage: 'Colegas'
	},
	serviceTrends: {
		id: 'dashboardBox.serviceTrends',
		defaultMessage: 'Tendências de atendimento'
	},
	voiceTrends: {
		id: 'dashboardBox.voiceTrends',
		defaultMessage: 'Tendências de atendimento - Voz'
	},
	totalAttendances: {
		id: 'dashboardBox.totalAttendances',
		defaultMessage: 'Total de atendimentos'
	},
	callsTransferred: {
		id: 'dashboardBox.callsTransferred',
		defaultMessage: 'Atendimentos transferidos'
	},
	completedCalls: {
		id: 'dashboardBox.completedCalls',
		defaultMessage: 'Atendimentos finalizados'
	},
	productiveHours: {
		id: 'dashboardBox.productiveHours',
		defaultMessage: 'Horas produtivas'
	},
	adherence: {
		id: 'dashboardBox.adherence',
		defaultMessage: 'Aderência'
	},
	departmentTableTitle: {
		id: 'dashboardBox.departmentTableTitle',
		defaultMessage: 'Disponibilidade detalhada por departamento'
	},
	allDepartments: {
		id: 'dashboardBox.allDepartments',
		defaultMessage: 'Todos os departamentos'
	},
	today: {
		id: 'dashboardBox.today',
		defaultMessage: 'Hoje'
	},
	yesterday: {
		id: 'dashboardBox.yesterday',
		defaultMessage: 'Ontem'
	},
	last7days: {
		id: 'dashboardBox.last7days',
		defaultMessage: 'Últimos 7 dias'
	},
	last30days: {
		id: 'dashboardBox.last30days',
		defaultMessage: 'Últimos 30 dias'
	},
	last90days: {
		id: 'dashboardBox.last90days',
		defaultMessage: 'Últimos 90 dias'
	}
});

export default messages;
