import React from 'react';
import PropTypes from 'prop-types';
import './index.sass';
import { defineMessages, injectIntl } from 'react-intl';

import ImgSvg from '../../Atoms/ImgSvg';

const messages = defineMessages({
	deniedAccessTitle: {
		id: 'deniedAccess.title',
		defaultMessage: 'Acesso bloqueado'
	},
	deniedAccessDescription: {
		id: 'deniedAccess.description',
		defaultMessage: 'Seu acesso à plataforma está bloqueado porque você está fora do horário de trabalho permitido'
	},
	logout: {
		id: 'deniedAccess.buttonLabel',
		defaultMessage: 'Sair'
	}
});


const BlockedAccess = ({ redirectToLogin, intl }) => (
	<div className="BlockedAccess">
		<div className="BlockedAccess__content">
			<div className="BlockedAccess__image">
				<ImgSvg name="warning" color="#FFC94A" />
			</div>
			<div className="BlockedAccess__text is-flex is-flex-direction-column is-justify-content-flex-start is-align-items-flex-start">
				<h1>{intl.formatMessage(messages.deniedAccessTitle)}</h1>
				<p className="has-text-body">{intl.formatMessage(messages.deniedAccessDescription)}</p>
				<div className="container is-flex is-justify-content-center">
					<button onClick={redirectToLogin} type="button" className="mt-4 button is-danger-text is-inverted" aria-haspopup="true">
						<span className="is-size-2 mr-2">{intl.formatMessage(messages.logout)}</span>
						<span className="icon is-small">
							<i className="fa-solid fa-arrow-right-from-bracket" />
						</span>
					</button>
				</div>
			</div>
		</div>
	</div>
);

BlockedAccess.propTypes = {
	intl: PropTypes.shape({
		formatMessage: PropTypes.func.isRequired
	}).isRequired,
	redirectToLogin: PropTypes.func
};


export default injectIntl(BlockedAccess);
