import React from 'react';
import PropTypes from 'prop-types';

import Template from '../Templates';
import { BanProvider } from '../../context/bannedContent';
import { StoreProvider } from '../../context/store';


const Page = ({ template }) => (
	<StoreProvider>
		<BanProvider>
			<Template template={template} />
		</BanProvider>
	</StoreProvider>
);

Page.propTypes = {
	template: PropTypes.string.isRequired
};

export default Page;
