import React, { useState, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { defineMessages, injectIntl } from 'react-intl';

import ModalDefault from '../../Sirius/ModalDefault';

import './index.sass';
import * as actions from '../../../store/actions';
import { useMountEffect } from '../../../shared/utility';
import TransferList from '../../Molecules/TransferList';
import Spinner from '../../Atoms/Spinner';
import Button from '../../Molecules/Buttons/Button';
import Input from '../../Molecules/Inputs/Common';

const messages = defineMessages({
	transferred: {
		id: 'transfer.transferred',
		defaultMessage: 'Transferência realizada'
	},
	transferredContent: {
		id: 'transfer.transferredContent',
		defaultMessage: 'A interação foi transferida'
	},
	header: {
		id: 'transfer.header',
		defaultMessage: 'Transferir Atendimento'
	},
	confirmHeader: {
		id: 'transfer.confirmHeader',
		defaultMessage: 'Transferindo atendimento'
	},
	action: {
		id: 'transfer.action',
		defaultMessage: 'Transferindo o atendimento'
	},
	cancel: {
		id: 'transfer.cancel',
		defaultMessage: 'Cancelar'
	},
	canceled: {
		id: 'transfer.canceled',
		defaultMessage: 'Cancelado'
	},
	transfer: {
		id: 'transfer.transferButton',
		defaultMessage: 'Transferir'
	},
	search: {
		id: 'transfer.search',
		defaultMessage: 'Buscar agentes ou departamentos'
	},
	talkToAnAdminMessage: {
		id: 'transfer.talkToAnAdminMessage',
		defaultMessage: 'Qualquer dúvida consulte com o administrador de sua conta'
	},
	noAgentsMessage: {
		id: 'transfer.noAgentsMessage',
		defaultMessage: 'Nenhum agente disponível para transferência'
	},
	noDepartmentsMessage: {
		id: 'transfer.noDepartmentsMessage',
		defaultMessage: 'Nenhum departamento disponível para transferência'
	},
	agentsTab: {
		id: 'transfer.agentsTab',
		defaultMessage: 'Agentes'
	},
	departmentsTab: {
		id: 'transfer.departmentsTab',
		defaultMessage: 'Departamentos'
	},
	transferTo: {
		id: 'transfer.transferTo',
		defaultMessage: 'Transferindo atendimento para'
	},
	reasonForTransfer: {
		id: 'transfer.reasonForTransfer',
		defaultMessage: 'Motivo da transferência (opcional)'
	},
	transferHelp: {
		id: 'transfer.transferHelp',
		defaultMessage: 'O motivo de transferência ficará registrado no chat e histórico de atendimento.'
	}
});

const Transfer = ({
	fetchTransferData,
	accountId,
	toggleTransfer,
	currentInteraction,
	onChangeInputPhone,
	onClearInputAnnotation,
	addNotification,
	transferData = {
		departments: [],
		agents: []
	},
	fetchTransferDataStatus = {},
	intl
}) => {
	const { formatMessage } = intl;
	const { interactionHash, interactionType, id: protocol } = currentInteraction;
	const isPhoneInteraction = interactionType.toLowerCase().includes('phone');
	const [searchValue, setSearchValue] = useState('');
	const [selectedAgentOrDepartment, setSelectedAgentOrDepartment] = useState({});
	const [activeTab, setActiveTab] = useState('departments');
	const [message, setMessage] = useState('');
	const isTabActive = tabName => tabName === activeTab;
	const searchValueRef = useRef();
	const { loading, ended, page } = fetchTransferDataStatus;
	const currentDepartmentId = currentInteraction && currentInteraction.department && currentInteraction.department.id;

	useMountEffect(() => {
		fetchTransferData({ accountId, departmentId: currentDepartmentId });
	});

	const onChangeTab = async (tabName) => {
		setActiveTab(tabName);
	};

	const onSelectAgent = useCallback(({ id, department, phoneExtension }) => {
		setSelectedAgentOrDepartment({ id, department, phoneExtension });
	}, []);

	const onConfirmTransfer = () => {
		const { id, department, phoneExtension } = selectedAgentOrDepartment;
		const departmentId = department ? department.id : '';

		if (isPhoneInteraction) {
			window.ttvSdk.onTransfer = true;
			window.ttvSdk.transfer(phoneExtension);
			window.omzVish.transferPhoneToAgent(interactionHash, phoneExtension);
			onChangeInputPhone('');
		} else if (!departmentId) {
			window.omzVish.transferToDepartment(id, interactionHash, message);
			onClearInputAnnotation(interactionHash);
		} else {
			window.omzVish.transferToAgent(id, departmentId, interactionHash, message);
			onClearInputAnnotation(interactionHash);
		}
		addNotification({
			title: formatMessage(messages.transferred),
			content: formatMessage(messages.transferredContent),
			type: 'success'
		});
		toggleTransfer();
	};

	const dynamicFetchTransfer = (search) => {
		setTimeout(() => {
			if (search === searchValueRef.current) fetchTransferData({ accountId, departmentId: currentDepartmentId, search });
		}, 1200);
	};

	const onChangeSearch = (e) => {
		const { value } = e.target;
		setSearchValue(value);
		searchValueRef.current = value;
		dynamicFetchTransfer(value);
	};

	const loadMoreTransferData = () => {
		if (!loading && !ended) fetchTransferData({ accountId, departmentId: currentDepartmentId, page });
	};

	return (
		<ModalDefault.Root
			isOpen
			onClose={toggleTransfer}
		>
			<div className="Transfer">
				<ModalDefault.Header
					title={`${formatMessage(messages.header)} #${protocol}`}
					onClose={toggleTransfer}
					className="has-background-grey-lighter"
				/>
				<ModalDefault.Body className="has-background-grey-lighter is-overflow-hidden">
					{
						!selectedAgentOrDepartment.id && (
						<>
							<div className="tabs is-fullwidth mb-1">
								<ul>
									<li
										role="button"
										className={isTabActive('departments') ? 'is-active' : ''}
										onClick={() => onChangeTab('departments')}
									>
										<a>
											<span>{formatMessage(messages.departmentsTab)}</span>
										</a>
									</li>
									<li
										role="button"
										className={isTabActive('agents') ? 'is-active' : ''}
										onClick={() => onChangeTab('agents')}
									>
										<a>
											<span>{formatMessage(messages.agentsTab)}</span>
										</a>
									</li>
								</ul>
							</div>
						</>
						)}
					{!selectedAgentOrDepartment.id && (
						<Input
							value={searchValue}
							onChange={onChangeSearch}
							placeholder={formatMessage(messages.search)}
							loading={loading && searchValue !== ''}
						/>
					)}
					{selectedAgentOrDepartment.id && (
						<div className="Transfer__info">
							<p>
								{formatMessage(messages.transferTo)}
								:
							</p>
						</div>
					)}
					<TransferList
						activeTab={activeTab}
						transferData={transferData}
						onSelectAgent={onSelectAgent}
						isPhoneInteraction={interactionType.toLowerCase().includes('phone')}
						loadMoreTransferData={loadMoreTransferData}
						selectedId={selectedAgentOrDepartment.id}
					/>

					{selectedAgentOrDepartment.id && (
						<div className="Transfer__info mt-3">
							<p>
								{formatMessage(messages.reasonForTransfer)}
								:
							</p>
							<p className="control mt-1">
								<textarea
									value={message}
									onChange={e => setMessage(e.target.value)}
									className="textarea"
									placeholder="Digite o motivo"
								/>
							</p>
							<p className="help">{formatMessage(messages.transferHelp)}</p>
						</div>
					)}

					{loading && !searchValue && <Spinner />}
					{!loading && (isTabActive('departments') && !transferData.departments.length) && (
						<div className="Transfer__message">
							<span>{formatMessage(messages.noDepartmentsMessage)}</span>
							<span>{formatMessage(messages.talkToAnAdminMessage)}</span>
						</div>
					)}
					{!loading && (isTabActive('agents') && !transferData.agents.length) && (
						<div className="Transfer__message">
							<span>{formatMessage(messages.noAgentsMessage)}</span>
							<span>{formatMessage(messages.talkToAnAdminMessage)}</span>
						</div>
					)}
				</ModalDefault.Body>
				{selectedAgentOrDepartment.id && (
					<ModalDefault.Footer className="has-background-grey-lighter">
						<div className="Transfer__actions">
							<Button secondary click={() => setSelectedAgentOrDepartment({})}>{formatMessage(messages.cancel)}</Button>
							<Button click={onConfirmTransfer}>{formatMessage(messages.transfer)}</Button>
						</div>
					</ModalDefault.Footer>
				)}
			</div>
		</ModalDefault.Root>
	);
};

const mapStateToProps = ({ interaction, agent }) => {
	const {
		transferData,
		interactions,
		pendingInteractions,
		missedInteractions,
		currentInteractionHash,
		fetchTransferDataStatus
	} = interaction;
	const allInteractions = interactions.concat(pendingInteractions).concat(missedInteractions);

	return ({
		accountId: agent.info.account.id,
		currentInteraction: allInteractions.find(({ interactionHash }) => interactionHash === currentInteractionHash) || {},
		fetchTransferDataStatus,
		transferData
	});
};

const mapActionsToProps = dispatch => ({
	fetchTransferData: requestInfo => dispatch(actions.fetchTransferData(requestInfo)),
	onChangeInputPhone: value => dispatch(actions.changeInputPhone(value)),
	onClearInputAnnotation: interactionHash => dispatch(actions.clearInputAnnotation(interactionHash)),
	addNotification: notification => dispatch(actions.addNotification(notification))
});

Transfer.propTypes = {
	transferData: PropTypes.shape({
		agents: PropTypes.arrayOf(PropTypes.shape({})),
		departments: PropTypes.arrayOf(PropTypes.shape({}))
	}),
	toggleTransfer: PropTypes.func.isRequired,
	fetchTransferData: PropTypes.func.isRequired,
	accountId: PropTypes.number.isRequired,
	currentInteraction: PropTypes.shape({
		id: PropTypes.number,
		interactionHash: PropTypes.string,
		interactionType: PropTypes.string,
		department: PropTypes.shape({
			id: PropTypes.number
		})
	}).isRequired,
	fetchTransferDataStatus: PropTypes.shape({
		loading: PropTypes.bool,
		ended: PropTypes.bool,
		page: PropTypes.number
	}),
	intl: PropTypes.shape({
		formatMessage: PropTypes.func.isRequired
	}).isRequired,
	onChangeInputPhone: PropTypes.func.isRequired,
	onClearInputAnnotation: PropTypes.func.isRequired,
	addNotification: PropTypes.func.isRequired
};

export default connect(mapStateToProps, mapActionsToProps)(injectIntl(Transfer));
